'use strict';
/**
 * @param {object} $http http service
 * @param {object} $rootScope root scope
 * @param {object} configuration service configuration
 * @param {object} tiersConstant tiers constant values
 * @ngdoc service
 * @name tiers.services.tiersAccountsService
 * @requires $http, $rootScope, $log, configuration, tiersConstant
 * @description Access to a tiers' linked users
 * @returns {void}
 */
angular.module('tiers.services').factory('tiersAccountsService', [
  '$http',
  '$rootScope',
  'configuration',
  'tiersConstant',

  function ($http, $rootScope, configuration, tiersConstant) {
    'use strict';

    /**
     * get referentiel tiers url from configuration
     *
     * @returns {string} referentiel tiers url
     */
    function referentielTiersUrl() {
      return configuration.tiers?.url || '/referentiel-tiers';
    }

    return {
      /**
       * Get linked users of tiers
       *
       * @param  {object} tiers Tiers
       * @returns {Array}       Linked users
       */
      getLinkedUsers: function (tiers) {
        return $http.get(configuration.tiers.rattachement.url + '/tiers/' + tiers.reference + '/linkedUsers');
      },

      /**
       * Define an administrator on a tiers
       *
       * @param  {object} tiers Tiers
       * @param  {string} userName  User who will become administrator
       * @returns {Promise<object>} response
       */
      defineAdministratorOnTiers: function (tiers, userName) {
        return $http.post(`${referentielTiersUrl()}/tiers/${tiers.reference}/administrator/${userName}`);
      },

      /**
       * Invite user to attach on a tiers
       *
       * @param  {object} tiers Tiers
       * @param  {string} email Email
       * @returns {Promise<object>} response
       */
      inviteUserToAttachOnTiers: function (tiers, email) {
        return $http.post(configuration.tiers.rattachement.url + '/tiers/' + tiers.reference + '/rattachements', {
          email: email,
        });
      },

      /**
       * AutoRattachement for user on tiers
       *
       * @param  {object} tiers Tiers
       * @param  {string} email Email
       * @returns {Promise<object>} response
       */
      inviteUserToAutoAttachOnTiers: function (tiers, email) {
        return $http.post(configuration.tiers.rattachement.url + '/tiers/' + tiers.reference + '/autorattachement', {
          email: email,
        });
      },

      /**
       * Remove the administrator specify on tiers
       *
       * @param  {object} tiers    Tiers
       * @returns {Promise<object>} response
       */
      removeAdministratorOnTiers: function (tiers) {
        var userIndex = _.findIndex(tiers.linkedUsers, function (linkedUser) {
          return linkedUser.form === 'ADMINISTRATOR';
        });

        var patch = [
          {
            op: 'remove',
            path: '/linkedUsers/' + userIndex,
          },
        ];

        return $http({
          method: 'PATCH',
          url: referentielTiersUrl() + '/tiers/' + tiers.reference,
          data: patch,
        });
      },

      /**
       * Send mail when delete user on tiers
       *
       * @param {object} tiers reference of the tiers
       * @param {object} account reference of a user
       * @returns {Promise<object>} response
       */
      mailDeleteUserTiers: function (tiers, account) {
        var userId = account.href.split('/').pop();
        return $http.put(
          configuration.tiers.rattachement.url + '/tiers/' + tiers.reference + '/deleteUser/' + userId,
          tiers
        );
      },

      /**
       * Detach an user from tiers
       *
       * @param  {object} tiers Tiers
       * @param  {string} accountId id of the account to remove
       * @returns {Promise<object>} response
       */
      removeUserOnTiers: function (tiers, accountId) {
        const patches = [];

        _.forEachRight(tiers.linkedUsers, (linkedUser, index) => {
          if (linkedUser.href === accountId && linkedUser.form !== 'OWNER') {
            patches.push({
              op: 'remove',
              path: `/linkedUsers/${index}`,
            });
          }
        });

        return $http({
          method: 'PATCH',
          url: referentielTiersUrl() + '/tiers/' + tiers.reference,
          data: patches,
        });
      },

      /**
       * Fetch a user by its email from account-management using a service account.
       *
       * @param {string} email Email of the user
       * @returns {Promise<object>} response
       */
      getUserByEmail: function (email) {
        return $http({
          url: configuration.tiers.rattachement.url + '/users',
          params: {
            $filter: "emails eq '" + email + "'",
          },
        });
      },

      /**
       * Computes if the current user is the administrator of the tiers
       *
       * @param {object} tiers the tiers we want to know if current user is ADMINISTRATOR
       * @returns {boolean} true if currentUser is ADMINISTRATOR of the given tiers
       */
      isCurrentUserAdministrator: function (tiers) {
        const linkedUsers = tiers?.linkedUsers || [];
        const currentUserId = $rootScope?.currentUser?.self;

        return linkedUsers.some(
          (linkedUser) =>
            linkedUser.href === currentUserId && linkedUser.form === tiersConstant.linkedUsers.form.ADMINISTRATOR
        );
      },
    };
  },
]);

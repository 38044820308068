'use strict';
angular.module('portailDepotDemandeAide.dashboard').controller('dashboardOffresStagePiecesController', [
  '$scope',
  'offreStage',
  'piecesConfiguration',
  '$state',
  'publicSettingsFinancement',
  'offresStageService',
  'piecesService',
  function (
    $scope,
    offreStage,
    piecesConfiguration,
    $state,
    publicSettingsFinancement,
    offresStageService,
    piecesService
  ) {
    'use strict';

    $scope.offreStage = offreStage;
    $scope.viewConfiguration = piecesConfiguration;
    $scope.navigate = {
      lock: 0,
    };

    // set setting parameter for 1 document by piece
    $scope.unDocumentParPiece = _.get(publicSettingsFinancement, 'unDocumentParPiece', false);

    $scope.documentsModified = false;

    // Url where to upload files
    $scope.urlDocuments = $scope.offreStage._links['mgs:documents'].href;

    // Directory of file's icons
    $scope.urlFileIcons = './resources/images/file-icons/';

    // Gestion des pièces
    // On ne va pas chercher les valeurs par défaut car la directive piece-offre-stage-form s'en occupe
    var teleservicePersistence = _.get($scope, 'offreStage.teleservice.expand.persistenceConfiguration.expand');
    $scope.allowedExtensions = _.get(teleservicePersistence, 'allowedExtensions');
    $scope.maxDocumentSize = _.get(teleservicePersistence, 'maxDocumentSize');
    $scope.maxNumberDocumentsPerPersistence = _.get(teleservicePersistence, 'maxNumberDocumentsPerPersistence');

    // Copie dans le scope du tableau de pièces pour l'ajout des nouveaux documents
    $scope.pieces = angular.copy($scope.offreStage.pieces);

    // Allow moment.js to be available in scope, so isPieceVisible or isPieceRequired function can evaluate conditions based on date
    $scope.moment = moment;

    /**
     * L'utilisateur ne peut valider uniquement si les pièces obligatoires ont été déposées
     */
    var isValidationAvailable = function () {
      $scope.isValidationAvailable = true;
      _.forEach(_.get($scope, 'offreStage.pieces'), function (piece) {
        if (piece.obligatoire && _.isEmpty(piece.documents)) {
          $scope.isValidationAvailable = false;
        }
      });
    };
    isValidationAvailable();

    $scope.savePersistence = function () {
      $scope.offreStage.pieces = $scope.pieces;
      isValidationAvailable();
      offresStageService.update($scope.offreStage);
    };

    // On passe le statut de l'offre de stage à COMPLETE et on retourne à la liste des offres de stage
    $scope.toStatutComplete = function () {
      $scope.currentlyClickingValidateBtn = false;

      if ($scope.pieceUploadInProgress) {
        $scope.currentlyClickingValidateBtn = true;
      } else {
        $scope.offreStage.statut = 'COMPLETED';
        offresStageService.update($scope.offreStage).then(function () {
          $state.go('app.connected.dashboard.offresStage.mesOffres');
        });
      }
    };

    // Allow pages to manually block next and previous buttons
    $scope.navigate.lockNavigation = function () {
      $scope.navigate.lock++;
    };
    $scope.navigate.unlockNavigation = function () {
      $scope.navigate.lock = Math.max(0, $scope.navigate.lock - 1);
    };

    /**
     * Evaluate conditionAffichage (visible/hidden) setting on a piece
     *
     * @function isPieceVisible
     * @param {object} piece the piece
     * @returns {boolean} the evaluate condition
     */
    $scope.isPieceVisible = function (piece) {
      return piecesService.evalConditionPiece($scope, piece, 'conditionAffichage');
    };

    $scope.preventValidationOnEnter = function (event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    };

    /**
     * Before upload piece
     *
     * @returns {void}
     */
    $scope.beforeUploadPiece = function (updatingDescription = false) {
      $scope.pieceUploadInProgress = true;
      if (!updatingDescription) {
        $scope.navigate.lockNavigation();
      }
    };

    /**
     * After upload piece
     *
     * @returns {void}
     */
    $scope.afterUploadPiece = function (updatingDescription = false, success = false) {
      $scope.pieceUploadInProgress = false;
      if (!updatingDescription) {
        $scope.navigate.unlockNavigation();
      }

      if ($scope.currentlyClickingValidateBtn) {
        $scope.currentlyClickingValidateBtn = false;
        if (success) {
          $scope.toStatutComplete();
        }
      }
    };
  },
]);

'use strict';
groupeGestionService.$inject = ['jwtSessionService'];
angular.module('common.services').factory('groupesGestionService', groupeGestionService);

groupeGestionService.$inject = ['jwtSessionService'];

/**
 *
 * @param jwtSessionService
 */
function groupeGestionService(jwtSessionService) {
  return {
    areGroupesGestionActive: () => {
      return jwtSessionService.user().groupeGestionActif;
    },
  };
}

'use strict';
documentComptableAideFormController.$inject = ['$scope', 'documentComptableService'];
/**
 * @ngdoc component
 * @module aides
 * @name documentComptableAideForm
 * @restrict 'EA'
 * @description
 *
 *   Plan de financement éditable d'une aide
 * @param {object} documentComptable - document's model
 * @param {string} typeMontant - type to display after the financial values (ex: 'ttc')
 * @param {object} viewConfiguraton - view configuration
 * @param {boolean} equilibre - initial value of the balance
 * @example
 *
 *  `<document-comptable-aide-form
 *    document-comptable="demande.plan.fonctionnement"
 *    view-configuration="documentComptableConfiguration"
 *    type-montant="documentComptableConfiguration.typeMontant[0]"
 *    equilibre="equilibre">
 *  </document-comptable-aide-form>`
 */
angular.module('aides').component('documentComptableAideForm', {
  templateUrl: 'aides/aides-directives/document-comptable-aide-form/document-comptable-aide-form.html',
  controller: documentComptableAideFormController,
  bindings: {
    documentComptable: '<',
    typeMontant: '<',
    viewConfiguration: '<',
    exerciceBudgetaire: '<',
    equilibre: '=',
    readOnly: '<',
    teleserviceConfiguration: '<',
    isFirst: '<',
    hidePrecisions: '<',
    precisionObligatoire: '<',
    iscontribution: '<?',
    postesPlanFinancement: '<',
    dateCreation: '<',
    demandeFinancement: '<',
  },
});

documentComptableAideFormController.$inject = ['$scope', 'documentComptableService'];

/**
 *
 * @param $scope
 * @param documentComptableService
 */
function documentComptableAideFormController($scope, documentComptableService) {
  'use strict';

  var vm = this;

  vm.$onInit = function () {
    vm.documentComptableService = documentComptableService;
    vm.depenseViewConfiguration = angular.copy(vm.viewConfiguration);
    vm.depenseViewConfiguration.ns = vm.depenseViewConfiguration.ns + '.depense';
    vm.recetteViewConfiguration = angular.copy(vm.viewConfiguration);
    vm.recetteViewConfiguration.ns = vm.recetteViewConfiguration.ns + '.recette';

    if (vm.teleserviceConfiguration) {
      //Dans le cas du récap, pas besoin de la configuration du téléservice
      copieOptionsDocumentComptable();
      var pageDocumentComptable = _.get(vm.teleserviceConfiguration, 'workflow.pageDocumentComptable');
      vm.affichageRecette =
        _.get(pageDocumentComptable, 'formeDocumentComptable') !== 'DEPENSE' &&
        _.get(pageDocumentComptable, 'typeDocumentComptable.planFinancement.recette.postes', []).length > 0;
      vm.affichageDepense =
        _.get(pageDocumentComptable, 'formeDocumentComptable') !== 'RECETTE' &&
        _.get(pageDocumentComptable, 'typeDocumentComptable.planFinancement.depense.postes', []).length > 0;
    } else {
      vm.affichageRecette = _.get(vm.documentComptable, 'depense.postes', []).length > 0;
      vm.affichageDepense = _.get(vm.documentComptable, 'recette.postes', []).length > 0;
    }

    watchEquilibrePlanFinancement();

    var areNegativeAmountsAllowed = _.get(
      vm,
      'teleserviceConfiguration.workflow.pageDocumentComptable.autoriserMontantNegatif'
    );

    vm.configurationOptions = {
      'hide-precisions': vm.hidePrecisions,
      'precision-obligatoire': vm.precisionObligatoire,
      'negative-amounts': areNegativeAmountsAllowed,
    };

    vm.affichageSousTotalPublicPrive = _.get(
      vm,
      'teleserviceConfiguration.workflow.pageDocumentComptable.afficherSousTotalPublicPrive',
      false
    );
  };

  /**
   * Permet de valider que le plan de financement est équilibré
   */
  function watchEquilibrePlanFinancement() {
    $scope.$watch(
      '[$ctrl.documentComptable.recette.montant.total, $ctrl.documentComptable.depense.montant.total]',
      function () {
        const depense = _.get(vm, 'documentComptable.depense.montant.total');
        const recette = _.get(vm, 'documentComptable.recette.montant.total');
        vm.equilibre = depense === recette;
        vm.updateSousTotal();
      },
      true
    );
  }

  /**
   *
   */
  function copieOptionsDocumentComptable() {
    // Use _.cloneDeep because of issues with references bewteen config and actual planFinancement
    var planFinancementConfiguration = _.cloneDeep(
      _.get(vm.teleserviceConfiguration, 'workflow.pageDocumentComptable.typeDocumentComptable.planFinancement')
    );

    if (!planFinancementConfiguration) {
      throw new Error('Teleservice has not planFinancement');
    }
    var planFinancementAide = vm.documentComptable;
    if (planFinancementConfiguration.recette) {
      _.each(planFinancementConfiguration.recette.postes, function (posteTeleservice) {
        // On n'a pas toujours le même nombre de postes entre téléservice et aide
        var postesRecette = _.get(planFinancementAide, 'recette.postes');
        var poste = _.find(postesRecette, { reference: posteTeleservice.reference });

        if (_.isNil(poste)) {
          return; // équivalent pour lodash de continue
        }
        _.each(posteTeleservice.sousPostes, function (sousPosteTeleservice) {
          var sousPoste = _.find(poste.sousPostes, { reference: sousPosteTeleservice.reference });
          if (_.isNil(sousPoste)) {
            return; // équivalent pour lodash de continue
          }
          sousPoste = _.merge(sousPosteTeleservice, sousPoste);
        });
      });
    }

    if (planFinancementConfiguration.depense) {
      _.each(planFinancementConfiguration.depense.postes, function (poste, indexPoste) {
        _.each(poste.sousPostes, function (sousPoste, indexSousPoste) {
          planFinancementAide.depense.postes[indexPoste].sousPoste[indexSousPoste] = _.merge(
            sousPoste,
            planFinancementAide.depense.postes[indexPoste].sousPoste[indexSousPoste]
          );
        });
      });
    }
  }

  /**
   * Calculate autofinancement value
   */
  vm.calculateAutofinancement = function () {
    return vm.documentComptableService.calculateAutofinancement(vm.documentComptable, vm.typeMontant);
  };

  /**
   * Calculate Sous total
   */
  vm.updateSousTotal = () => {
    return vm.documentComptableService.updateSousTotal(vm.documentComptable);
  };
}

'use strict';
themeManagementService.$inject = ['configuration', '$http', '$window', '$rootScope', '$log', '$location'];
angular.module('common.services').factory('themeManagementService', themeManagementService);

/* @ngInject */
/**
 *
 * @param configuration
 * @param $http
 * @param $window
 * @param $rootScope
 * @param $log
 * @param $location
 */
function themeManagementService(configuration, $http, $window, $rootScope, $log, $location) {
  'use strict';
  return {
    /**
     * A callback of a $transitions hook. Used to check and register a new theme
     * or favicon to the localStorage.
     *
     * @param {object} transition transition passed to $transitions hook callbacks
     * @returns {Promise|Void} return a promise if a request is made to referentiel-themes
     */
    registerTheme: (transition) => {
      const params = transition.params();
      if (params.theme) {
        return $http
          .get(`${configuration.referentielThemes.api}/${params.theme}`)
          .then((res) => {
            const themeHref = _.get(res, 'data.theme');
            const faviconHref = _.get(res, 'data.favicon');
            // store theme url if it is defined
            if (themeHref) {
              $window.localStorage.setItem('theme', themeHref);
            } else {
              $window.localStorage.removeItem('theme');
            }
            // store favicon url if it is defined
            if (faviconHref) {
              $window.localStorage.setItem('favicon', faviconHref);
            } else {
              $window.localStorage.removeItem('favicon');
            }
          })
          .catch((err) => {
            // default theme
            $log.error('Could not fetch themes files', err);
            $window.localStorage.removeItem('theme');
            $window.localStorage.removeItem('favicon');
          });
      } else {
        // default theme
        $window.localStorage.removeItem('theme');
        $window.localStorage.removeItem('favicon');
      }
    },
    /**
     * Apply the theme and favicon stored in localStorage
     */
    applyTheme: () => {
      const id = 'theme-ressource-link';
      const themeLink = document.getElementById(id);

      if (!themeLink) {
        $rootScope.specificFavicon = $window.localStorage.getItem('favicon');

        const specificTheme = $window.localStorage.getItem('theme');
        const refThemeMatch = /^\/referentiel-themes\/(.*)/.exec(specificTheme);
        const themeRef = document.createElement('link');

        themeRef.id = id;
        themeRef.rel = 'stylesheet';
        themeRef.type = 'text/css';
        themeRef.href = 'resources/theme/spec.css';

        if (specificTheme && refThemeMatch) {
          themeRef.href = specificTheme;
        }

        document.head.appendChild(themeRef);
      }
    },
    /**
     * Set the theme query param to the theme stored locally
     */
    themeInParams: () => {
      const theme = $window.localStorage.getItem('theme') ? $window.localStorage.getItem('theme').split('/')[4] : null;
      $location.search('theme', theme);
    },
    /**
     * Get main theme infos and set it in $rootScope
     *
     * @returns {Promise|void} return a promise if a request is made to referentiel-themes
     */
    getMainThemeInfos: () => {
      const tenant = $location.path().split('/')?.[1];

      if (tenant) {
        const refThemesUrl = `${configuration.referentielThemes.api}/main`.replace('{{tenantId}}', tenant);
        return $http
          .get(refThemesUrl)
          .then(({ data }) => {
            $rootScope.favicon = data?.favicon;
            $rootScope.primaryColor = data?.primaryColor;
          })
          .catch(() => {})
          .finally(() => {
            if (!$rootScope.primaryColor) $rootScope.primaryColor = '#1f4854';
          });
      }
    },
  };
}

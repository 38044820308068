'use strict';
angular.module('portailDepotDemandeAide.depot').controller('demandePaiementInformationsComplementairesController', [
  'viewsService',
  '$scope',
  '$timeout',
  'demandesPaiementService',
  'IFrameCommunicationManager',
  function demandePaiementInformationsComplementairesController(
    viewsService,
    $scope,
    $timeout,
    demandesPaiementService,
    IFrameCommunicationManager
  ) {
    $scope.pageOptions = _.get($scope, 'teleserviceConfiguration.workflow.pageInformationsComplementaires', {
      actif: false,
    });

    $scope.cleanNavigate();

    $scope.navigate.ns = $scope.informationsComplementairesConfiguration.ns;

    $scope.stepsWizard.steps = $scope.getDemandePaiementSteps();
    $scope.stepsWizard.active = 'informationsGenerales';

    const iFrameCommunicationManagers = [];
    const iFrameCommunicationManager = new IFrameCommunicationManager();
    iFrameCommunicationManagers.push(iFrameCommunicationManager);

    const updateStateViewsId = 'updateStateViews';

    // If page is not active we just go next
    if ($scope.pageOptions.actif) {
      $scope.activePage();
    } else {
      $scope.navigate.next(true);
    }
    this.$onInit = function () {
      // Get iframe's URL
      $scope.iframeInformationsComplementairesPaiement = viewsService.getPageViewsIframeUrl({
        entity: $scope.demandePaiement,
        page: 'pageInformationsComplementaires',
        theme: 'demandeur',
        readOnly: false,
        topTitleLevel: 5,
        contextHeaders: {
          'referentiel-tiers': [
            'Accept:application/vnd.mgdis.tiers-3.19.0+json',
            'Content-Type:application/vnd.mgdis.tiers-3.19.0+json',
          ],
        },
      });

      // Add iframe's listener to update demande
      iFrameCommunicationManager.manageEvent(iframeEventListener);
    };

    $scope.$on('$destroy', function () {
      _.each(iFrameCommunicationManagers, (manager) => {
        manager.close();
      });
    });

    /**
     *
     * @param msg
     */
    function iframeEventListener(msg) {
      const action = _.get(msg, 'data.action');

      // Update view's datas on current demande de paiement
      if (action === 'viewsUpdated') {
        const index = _.get(msg, 'data.index');
        const values = _.get(msg, 'data.values');
        const viewUpdated = $scope?.demandePaiement?.views.at(index);
        if (!viewUpdated.schema.href.includes('/forms-common/')) {
          _.set($scope, `demandePaiement.views.${index}.values`, values);
        }
      }
    }

    /**
     * Function triggered when we naviguate to the next Step progress
     *
     * @param {object} forget
     * @returns {void}
     */
    $scope.navigate.next = function (forget) {
      const goToNextStep = () => {
        if ($scope.displayIndicateursRealisation) {
          $scope.goToStep('indicateurs', forget);
        } else if (
          _.get($scope, 'teleserviceConfiguration.workflow.pageDocumentComptable.actif') &&
          demandesPaiementService.getPlanFinancementDepose($scope.demandePaiement)
        ) {
          $scope.goToStep('document-comptable', forget);
        } else {
          $scope.goToStep('domiciliation-bancaire', forget);
        }
      };

      if (_.isEmpty(_.get($scope, 'demandePaiement.views', []))) {
        goToNextStep();
      } else {
        const viewsCommunicationManager = new IFrameCommunicationManager('#iframeInformationsComplementairesPaiement');
        const viewsValidationPromise = viewsCommunicationManager
          .sendEvent({
            action: 'validViews',
            options: {
              showAllErrors: true,
              skipRequiredErrors: false,
            },
          })
          .manageUniqueEventWithPromise({
            type: updateStateViewsId,
            callback: (msg, resolve, reject) => {
              viewsService.updateStateViewsPaiement($scope, msg, resolve, reject);
            },
          });
        iFrameCommunicationManagers.push(viewsCommunicationManager);

        viewsValidationPromise
          .then(() => {
            goToNextStep();
          })
          .catch(() => {
            // iframe validation / user rejected
            viewsCommunicationManager.closeUniqueEvent({ type: updateStateViewsId });
          });
      }
    };

    $timeout(function () {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
        },

        '#iframeInformationsComplementairesPaiement'
      );
    }, 0);
  },
]);

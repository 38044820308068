'use strict';
(function () {
  'use strict';
  abonnementService.$inject = ['$http', 'configuration'];

  angular.module('components.abonnements').factory('abonnementService', abonnementService);

  /**
   *
   * @param $http
   * @param configuration
   */
  function abonnementService($http, configuration) {
    var _urlAgreementList = urljoin(configuration.user.accountManagement, 'agreements');

    return {
      getList: function (tenantId) {
        return $http
          .get(_urlAgreementList.replace('{{tenantId}}', tenantId))
          .then(function (response) {
            return _.filter(response.data.results, ['type', 'ABONNEMENT']);
          })
          .catch(function (err) {
            throw new Error('Fail list agreement ', err);
          });
      },
    };
  }
})();

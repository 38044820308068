'use strict'; /**
"""""""""""""" * @param $translate
"""""""""""""" * @param $modal
"""""""""""""" * @param $timeout
"""""""""""""" * @param Representant
"""""""""""""" * @param Geographique
"""""""""""""" * @param Coordonnees
"""""""""""""" * @ngdoc directive
"""""""""""""" * @name tiers.directive:representants
"""""""""""""" * @restrict EA
"""""""""""""" * @description Directive who display a list of representant
"""""""""""""" * @element
"""""""""""""" * @example
         <div representants="demande.demandeur.expand.representants"
      user="currentUser"
      situation-tiers="demande.demandeur.expand.situations[0]"
      telephones-tiers="demande.demandeur.expand.telephones"
      read-only="false"
      view-configuration="representantsDemandeurConfiguration"
      elements-per-line="2"
      mdm="mdm">
        </div>
""""""""""""""
'''''''''''''' * @param $translate
'''''''''''''' * @param $modal
'''''''''''''' * @param $timeout
'''''''''''''' * @param Representant
'''''''''''''' * @param Geographique
'''''''''''''' * @param Coordonnees
''''''''''''''
'''''''''''''' * @param $translate
'''''''''''''' * @param $modal
'''''''''''''' * @param $timeout
'''''''''''''' * @param Representant
'''''''''''''' * @param Geographique
'''''''''''''' * @param Coordonnees
''''''''''''''
'''''''''''''' * @param $translate
'''''''''''''' * @param $modal
'''''''''''''' * @param $timeout
'''''''''''''' * @param Representant
'''''''''''''' * @param Geographique
'''''''''''''' * @param Coordonnees
'''''''''''''' */
angular.module('tiers').directive('representants', [
  '$translate',
  '$modal',
  '$timeout',
  'Representant',
  'Geographique',
  'Coordonnees',
  function ($translate, $modal, $timeout, Representant, Geographique, Coordonnees) {
    'use strict';

    /**
     * Permet de trier la liste des représentants par nom, prenom, titre
     * Le premier élément devant être le représentant légal
     *
     * @param  {Representant} representant Représentant à trier
     * @returns {string}              Chaine permettant de triers les représentants
     */
    var sortByRepresentants = function (representant) {
      if (representant.principal) {
        return (
          '000' +
          representant.individu.Noms.NomUsage +
          representant.individu.Prenoms.PrenomUsuel +
          representant.fonction
        );
      }
      return representant.individu.Noms.NomUsage + representant.individu.Prenoms.PrenomUsuel + representant.fonction;
    };

    /**
     * Groupe la liste des représentants par ligne de 3 éléments
     *
     * @param  {Array} representants     Liste des représentants
     * @param  {number} groupSize         Nombre d'éléments par ligne
     * @returns {Array} Tableau de ligne de représentants
     */
    var groupByRepresentantsByRows = function (representants, groupSize) {
      var rows = _.groupBy(_.sortBy(representants, sortByRepresentants), function (val, index) {
        return Math.floor(index / groupSize);
      });

      if (representants.length % groupSize === 0) {
        rows[Object.keys(rows).length] = [];
      }

      return rows;
    };

    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      templateUrl: 'tiers/tiers-directives/representants/representants.html',
      scope: {
        representants: '=',
        situationTiers: '=',
        user: '=',
        telephones: '=',
        edit: '=',
        viewConfiguration: '=',
        readOnly: '=',
        elementsPerLine: '=',
        validate: '&',
        mdm: '=',
        modified: '=',
        diff: '=',
        masterdata: '=',
        fonctions: '=?',
        sectionAdresseContacts: '<',
      },

      link: function (scope) {
        scope.Math = window.Math;
        /**
         * Watcher on "representants" collection.
         * Call only when we want add or delete an element of the collection and not when we update an element of the collection.
         */
        scope.$watchCollection('representants', function () {
          if (scope.representants) {
            // Force the representants' address to the tiers address when the 'adresseCommuneTiers' is true
            // Has to be done here, because we have to wait for the representants list to be set
            if (!scope.edit && !scope.readOnly) {
              scope.representants.forEach(function (representant) {
                if (
                  _.get(scope, 'sectionAdresseContacts.active') &&
                  representant.adresseCommuneTiers &&
                  scope.situationTiers.geographique &&
                  representant.situations[0]
                ) {
                  var situation = representant.situations[0];
                  situation.geographique = new Geographique(angular.copy(scope.situationTiers.geographique), scope.mdm);
                  situation.etrangere = situation.geographique.isForeignAddress();
                }
              });
            }
            scope.representantsByRows = groupByRepresentantsByRows(scope.representants, scope.elementsPerLine);
          }
        });

        // Help tooltips
        scope.tooltips = {
          edit: {
            title: 'tiers.representants.actions.edit.title',
            sronly: 'tiers.representants.actions.edit.sronly',
          },

          delete: {
            title: 'tiers.representants.actions.delete.title',
            sronly: 'tiers.representants.actions.delete.sronly',
          },
        };

        /**
         * Validation de la création ou de la modification d'un représentant
         *
         * @param  {object} modal  Fenêre modale contenant le formulaire de modification d'un représentant
         * @param  {number} index  Index du représentant
         * @param  {Representant} representant Représentant
         */
        scope.validRepresentant = function (modal, index, representant) {
          if (index === -1) {
            scope.representants.push(angular.copy(representant));
          } else {
            scope.representants[index] = representant;
            scope.representantsByRows = groupByRepresentantsByRows(scope.representants, scope.elementsPerLine);
          }
          scope.validate();
          modal.$hide();
        };

        /**
         * Affichage de la fenêtre modale de modification d'un représentant
         */
        scope.addRepresentant = function () {
          var scopeModal = scope.$new();
          scopeModal.representant = new Representant();

          if (scope.sectionAdresseContacts.active) {
            if (!scopeModal.representant.situations) {
              scopeModal.representant.situations = [];
            }
            scopeModal.representant.situations.push(new Coordonnees());
          }

          if (scope.sectionAdresseContacts.active && scope.situationTiers && scope.situationTiers.geographique) {
            var situation = scopeModal.representant.situations[0];
            situation.geographique = new Geographique(angular.copy(scope.situationTiers.geographique), scope.mdm);
          }

          scopeModal.validRepresentant = scope.validRepresentant;
          scopeModal.index = -1;
          scopeModal.edit = false;
          scopeModal.action = 'add';
          scopeModal.mdm = scope.mdm;
          scopeModal.masterdata = scope.masterdata;
          scopeModal.viewConfiguration = _.merge({}, scope.viewConfiguration, { remoteValidation: false });
          scopeModal.sectionAdresseContacts = scope.sectionAdresseContacts;
          $modal({
            scope: scopeModal,
            template: 'tiers/tiers-directives/representants/modal/modal-form-representant.html',
            backdrop: 'static',
          });
        };

        /**
         * Affichage de la fenêtre modale de modification ou d'ajout d'un représentant
         *
         * @param  {Representant} representant Instance du représentant que l'on souhaite modifier
         */
        scope.editRepresentant = function (representant) {
          var scopeModal = scope.$new();
          scopeModal.coordonneesGeographiqueTiers = null;
          scopeModal.validRepresentant = scope.validRepresentant;
          if (scope.situationTiers && scope.situationTiers.geographique) {
            scopeModal.coordonneesGeographiqueTiers = new Geographique(
              angular.copy(scope.situationTiers.geographique),
              scope.mdm
            );
          }
          scopeModal.edit = scope.edit;
          scopeModal.action = 'edit';
          scopeModal.representant = new Representant(angular.copy(representant), scope.mdm);
          if (_.get(scope, 'sectionAdresseContacts.active')) {
            if (!scopeModal.representant.situations) {
              scopeModal.representant.situations = [];
            }
            if (!scopeModal.representant.situations[0]) {
              scopeModal.representant.situations.push(new Coordonnees());
            }
          }
          scopeModal.index = _.indexOf(scope.representants, representant);
          scopeModal.mdm = scope.mdm;
          scopeModal.masterdata = scope.masterdata;
          scopeModal.viewConfiguration = _.merge({}, scope.viewConfiguration, { remoteValidation: false });

          $modal({
            scope: scopeModal,
            template: 'tiers/tiers-directives/representants/modal/modal-form-representant.html',
            backdrop: 'static',
          });
        };

        /**
         * Suppression effective d'un représentant
         *
         * @param  {Modal} modal        Fenêtre modale de confirmation de suppression d'un représentant
         * @param  {Representant} representant Représentant à supprimer
         */
        scope.confirmDeleteRepresentant = function (modal, representant) {
          scope.representants.splice(_.indexOf(scope.representants, representant), 1);
          scope.validate();
          modal.$hide();
        };

        /**
         *  Affichage d'une fenêtre modale demandant la confirmation de la suppression du représentant
         *
         * @param  {Representant} representant Instance du représentant que l'on souhaite supprimer
         */
        scope.deleteRepresentant = function (representant) {
          var scopeModal = scope.$new();
          scopeModal.confirmDeleteRepresentant = scope.confirmDeleteRepresentant;
          scopeModal.representant = representant;
          $modal({
            scope: scopeModal,
            template: 'tiers/tiers-directives/representants/modal/modal-form-representant-confirm.html',
            backdrop: 'static',
          });
        };

        /**
         * Indicates missing informations on a representant
         *
         * @param representant
         */
        scope.isRepresentantValid = function (representant) {
          let representantValid =
            _.get(representant, 'civilite.title') &&
            _.get(representant, 'individu.Prenoms.PrenomUsuel') &&
            _.get(representant, 'individu.Noms.NomUsage') &&
            _.get(representant, 'fonction.title');

          representantValid = scope.sectionAdresseContacts.active
            ? representantValid &&
              _.get(representant, 'situations[0]') &&
              _.get(representant, 'situations[0]').isAddressValid()
            : representantValid;
          return representantValid;
        };
      },
    };
  },
]);

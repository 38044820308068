'use strict';
angular.module('tiers.model').factory('Geographique', [
  'Localite',

  function (Localite) {
    'use strict';

    /**
     *
     * @param data
     * @param mdm
     */
    function Geographique(data, mdm) {
      data = data || {};
      this.PointRemise = data.PointRemise;
      this.Complement = data.Complement;
      this.NumeroVoie = data.NumeroVoie;
      this.Extension = data.Extension;
      this.TypeVoie = data.TypeVoie;
      this.NomVoie = data.NomVoie;
      this.LieuDit = data.LieuDit;
      this.MentionDistribution = data.MentionDistribution;
      this.Cedex = data.Cedex;
      this.LibelleBureauCedex = data.LibelleBureauCedex;
      this.CodePostal = data.CodePostal;
      this.Localite = new Localite(data.Localite);
      this.DivisionTerritoriale = data.DivisionTerritoriale;
      if (data.Pays) {
        var pays = _.get(mdm, 'pays.object.' + (data.Pays.code || data.Pays.reference || data.Pays) + '.expand');
        this.Pays = pays ? pays : data.Pays;
      }

      // dans le cas d'une entrée de mdm, il faut retraduire les éléments pour coller à l'entité
      if (this.Pays && this.Pays.reference) {
        this.Pays = {
          code: this.Pays.reference,
          value: this.Pays.libelle.value,
        };
      }
    }

    /**
     * Retourne l'information précisant si l'adresse du tiers est une adresse étrangère.
     *
     * @returns {boolean}
     */
    Geographique.prototype.isForeignAddress = function () {
      var ISFOREIGNADRESS = false;
      if (this.Pays && this.Pays.code) {
        ISFOREIGNADRESS = _.get(this.Pays, 'code', '') !== 'FR';
      }
      return ISFOREIGNADRESS;
    };

    return Geographique;
  },
]);

'use strict';
/**
 * Service de gestions des contributions
 */
angular.module('contributions.services').factory('contributionsService', contributionsService);

contributionsService.$inject = [
  '$http',
  '$q',
  '$log',
  'configuration',
  'aidesService',
  '$rootScope',
  '$translate',
  'ngToast',
  'StoreService',
  'tiersService',
];

/**
 *
 * @param $http
 * @param $q
 * @param $log
 * @param configuration
 * @param aidesService
 * @param $rootScope
 * @param $translate
 * @param ngToast
 * @param StoreService
 * @param tiersService
 */
function contributionsService(
  $http,
  $q,
  $log,
  configuration,
  aidesService,
  $rootScope,
  $translate,
  ngToast,
  StoreService,
  tiersService
) {
  'use strict';
  const DEFAULT_CONTRIBUTION_PER_PAGE = 20;
  // Statuts
  const PENDING = 'PENDING';
  const ANSWERED = 'ANSWERED';
  const SUPPORTED = 'SUPPORTED';
  const WAITING_FOR_CERTIFICATE = 'WAITING_FOR_CERTIFICATE';
  const ASKED = 'ASKED';
  const IN_PROGRESS = 'INPROGRESS';

  const IS_TREATED_STATUS = [ANSWERED, SUPPORTED, WAITING_FOR_CERTIFICATE];
  const IS_NOT_TREATED_STATUS = [ASKED, IN_PROGRESS];

  const DEFAULT_ES_SOURCES = [
    'reference',
    'typeContribution',
    'demande.reference',
    'demande.referenceAdministrative',
    'demande.title',
    'demande.demandeur.title',
    'demande.teleservice.reference',
    'demande.comiteRecevabilite.libelle',
    'demande.comiteRecevabilite.dateDeComite',
    'statut',
    'avis',
    'tiers.title',
    'tiers.href',
    'date',
    'dateEnvoi',
    'dateReponse',
    'cloture',
    'teleservice.reference',
    'pieces',
  ];

  const NON_TRAITEES_ES_SOURCE = [
    'reference',
    'typeContribution',
    'demande.title',
    'demande.libelle',
    'demande.reference',
    'demande.referenceAdministrative',
    'demande.teleservice.reference',
    'dateEnvoi',
    'teleservice.reference',
  ];

  /**
   * get url from configuration
   *
   * @returns {string} contributions url
   */
  function contributionsUrl() {
    return configuration.contributions?.url;
  }

  /**
   * POST search query to referentiel-financement
   *
   * @param {object} filters
   */
  function searchContributions(filters) {
    return $http.post(urljoin(contributionsUrl(), '/search'), filters).then(function (result) {
      const total = _.get(result, 'data.hits.total.value', 0);
      const contributions = _.get(result, 'data.hits.hits', []).map(({ _source }) => _source);
      return { total, contributions };
    });
  }

  /**
   * Add From and Size parameters to Contribution search query
   *
   * @param {object} query
   * @param {number} page
   */
  function addFromSizeToQuery(query, page = 0) {
    query.from = _.isSafeInteger(page) ? page * DEFAULT_CONTRIBUTION_PER_PAGE : 0;
    query.size = DEFAULT_CONTRIBUTION_PER_PAGE;
  }

  /**
   * Get the "teleservice" by his href
   *
   * @param teleserviceHref
   */
  function getTSFinancement(teleserviceHref) {
    return $http.get(teleserviceHref).then(function (teleservice) {
      return _.get(teleservice, 'data', {});
    });
  }

  return {
    /**
     * Search all contributions for current user
     *
     * @param {number} page
     * @param {boolean} isTreated
     * @param {string} type
     * @param {string} search
     * @param {string | object} order Either 'asc' or 'desc'
     * @returns {Promise<object>}
     */
    searchForCurrentUser(page, isTreated, type, search, order) {
      return this.generateFiltersOnCurrentTiers().then((filtersOnCurrentTiers) => {
        const filters = {
          query: search,
          tiers: filtersOnCurrentTiers.in,
          not: {
            tiers: filtersOnCurrentTiers.notIn,
          },
        };

        // If type is null, then the type filter should not be sent
        if (type) filters.type = type;

        addFromSizeToQuery(filters, page);

        if (isTreated) {
          filters.statuts = IS_TREATED_STATUS;
        } else {
          filters.statuts = IS_NOT_TREATED_STATUS;
          filters.cloture = false;
        }

        if (order) {
          filters.sort = [`dateReponse:${order === 'asc' ? 'asc' : 'desc'}`];
        }

        filters.sources = DEFAULT_ES_SOURCES;

        return searchContributions(filters);
      });
    },

    /**
     * Get all the "Contribution de Redirection" matching a references array of demandes
     *
     * @param {Array} demandesReferences references array of demandes
     * @param {boolean} cloture [cloture=false] - if the "Contribution de Redirection" should have status cloture or not
     * @returns {object} { total, contributions }
     */
    getContributionsRedirectionFromDemandes: (demandesReferences, cloture = false) => {
      const filters = {
        type: 'REDIRECTION',
        demande: { references: demandesReferences },
        cloture: cloture,
        sources: DEFAULT_ES_SOURCES,
      };

      addFromSizeToQuery(filters);

      return searchContributions(filters);
    },

    /**
     * Search for "Contibution pour Modification" for a specific demande
     * without the statut "PENDING"
     *
     * @param {*} demandeReference
     * @param {*} page
     */
    searchModificationsForDemande: function (demandeReference, page) {
      const filters = {
        type: 'MODIFICATION',
        demande: { references: [demandeReference] },
        // Not Pending
        not: {
          statuts: [PENDING],
        },

        sort: ['cloture.value:asc', 'date:asc'],
      };

      addFromSizeToQuery(filters, page);

      filters.sources = DEFAULT_ES_SOURCES;

      return searchContributions(filters);
    },

    /**
     * Search for contributions which have not been treated
     */
    searchNonTraiteeForCurrentUser() {
      return this.generateFiltersOnCurrentTiers().then((filtersOnCurrentTiers) => {
        const filters = {
          statuts: IS_NOT_TREATED_STATUS,
          sort: ['date:desc'],
          cloture: false,
          tiers: filtersOnCurrentTiers.in,
          not: {
            tiers: filtersOnCurrentTiers.notIn,
          },
        };

        addFromSizeToQuery(filters);
        filters.sources = NON_TRAITEES_ES_SOURCE;

        return searchContributions(filters);
      });
    },

    /**
     * Get contribution, utterly expanded by default (not the best behaviour...)
     *
     * @param {string} reference contribution reference
     * @param {Array<string>} expands [optional] expands array (default ['*'])
     * @param {Array<string>} select
     * @returns {Promise<object>}
     */
    get: (reference, expands, select) => {
      if (expands && !Array.isArray(expands)) {
        throw new Error('contributions-service - "expands" parameter must be an array');
      } else if (!expands) {
        expands = ['*'];
      }

      const uri = urljoin(contributionsUrl(), reference);
      const options = {
        params: {
          expand: expands.join(','),
        },
      };

      if (Array.isArray(select) && select.length > 0) {
        options.params.$select = select.join(',');
      }

      return $http.get(uri, options).then(({ data }) => data);
    },
    /**
     * Get a contribution for MODIFICATION or REDIRECTION that isn't closed and isn't (ANSWERED, SUPPORTED) on the demande, otherwise return null
     *
     * @param  {string} demandeHref
     * @returns {Promise<object | null>}
     */
    getContributionModificationOrRedirectionNotClosedForDemande: (demandeHref) => {
      const isModificationOrRedirection = "(typeContribution eq 'MODIFICATION' or typeContribution eq 'REDIRECTION')";

      const query = {
        method: 'GET',
        url: contributionsUrl(),
        params: {
          $filter: `demande/href eq '${demandeHref}' and ${isModificationOrRedirection} and (cloture/value eq false or cloture eq nil) and (statut ne 'ANSWERED' and statut ne 'SUPPORTED')`,
          $top: 1,
        },
      };

      return $http(query).then(function (res) {
        return _.get(res, 'data._embedded.items[0]', null);
      });
    },

    /**
     * (legacy code) - Get a contribution from a demande
     *
     * @param {string} hrefDemande reference of the demande
     * @param {string} statut status of the demande
     * @returns http request
     */
    getContributionFromDemande: function (hrefDemande, statut) {
      const url = `${contributionsUrl()}?$filter=demande/href eq '${hrefDemande}' and statut eq '${statut}'`;
      return $http.get(url).then(function (res) {
        return res.data;
      });
    },

    patch: function (reference, patches) {
      const uri = urljoin(contributionsUrl(), reference);
      return $http.patch(uri, patches);
    },

    /**
     * Fonction de suppression de la réponse à une contribution
     *
     * @param contribution
     */
    removeAnswer: function (contribution) {
      const dataToPatch = [];

      // Delete data for answer
      if (_.get(contribution, 'avis')) {
        const answerRemove = {
          op: 'remove',
          path: '/avis',
        };

        dataToPatch.push(answerRemove);
      }

      // Update answer status
      const answerStatut = {
        op: 'add',
        path: '/statut',
        value: 'ASKED',
      };

      dataToPatch.push(answerStatut);

      // Update first step for answer
      const answerFirstStep = {
        op: 'add',
        path: '/history/begin/metadata/step',
        value: 'preambule',
      };

      dataToPatch.push(answerFirstStep);

      const url = urljoin(contributionsUrl(), contribution.reference);
      return $http.patch(url, dataToPatch).then(function (response) {
        return response.data;
      });
    },

    /**
     * Patch the contribution and send the result (the contribution with the last modification to the parent component
     * (contribution.avis.step.component)
     *
     * @param {string} reference
     * @param {Array} patches
     * @param {boolean} blockEvent
     */
    updateContributionAndSendResultToParent: (reference, patches, blockEvent = false) => {
      if (_.isEmpty(patches)) {
        return $q.resolve(true);
      }

      const uri = urljoin(contributionsUrl(), reference);
      return $http.patch(uri, patches).then((result) => {
        const contribution = result.data;

        // broadcast event to update parent contribution
        if (!blockEvent) {
          $rootScope.$broadcast('contributionPatched', contribution);
        }
        return contribution;
      });
    },

    /**
     * Permet de faire un expand de la demande d'une contribution
     *
     * @param contribution
     */
    expandDemandeForContribution: function (contribution) {
      return $http.get(_.get(contribution, 'demande.href'));
    },

    /**
     * Permet de faire un expand du teleservice d'une contribution
     *
     * @param contribution
     */
    expandTeleserviceForContribution: function (contribution) {
      return $http.get(_.get(contribution, 'teleservice.href'));
    },

    /**
     * Permet de faire un expand du tiers d'une contribution
     *
     * @param contribution
     */
    expandTiersForContribution: function (contribution) {
      return $http.get(_.get(contribution, 'tiers.href') + '?expand=pieces.documents');
    },

    /**
     * Permet supprimer les expands d'une entity
     *
     * @param element
     */
    cleanEntity: function (element) {
      if (_.isObject(element)) {
        _.each(element, function (childElement) {
          if (_.isObject(childElement) && childElement.expand) {
            // Expand
            delete childElement.expand;
          }
          contributionsService.cleanEntity(childElement);
        });
      }
      return element;
    },

    /**
     * Permet de récupérer la liste de motifs associé a une contribution ,
     * récupérable par deux clés : motifsAvisFavorable, motifAvisIrrecevable
     *
     * @param contribution
     */
    getMotifsForContribution: function (contribution) {
      return $http
        .get(_.get(contribution, 'teleservice.href'))
        .then(function (response) {
          const motifsAvis = _.get(response, 'data.motifsContributionAvis', []);
          const promiseExpandMotifs = [];
          _.each(motifsAvis, function (motif) {
            promiseExpandMotifs.push($http.get(motif.href));
          });
          return $q.all(promiseExpandMotifs);
        })
        .then(function (motifsAvis) {
          motifsAvis = motifsAvis.map(function (motif) {
            return motif.data;
          });
          const motifsAvisFavorable = _.filter(motifsAvis, function (motif) {
            return _.get(motif, 'kind') === 'FAVORABLE';
          });
          const motifsAvisDefavorable = _.filter(motifsAvis, function (motif) {
            return _.get(motif, 'kind') === 'DEFAVORABLE';
          });

          return {
            motifsAvisFavorable: motifsAvisFavorable,
            motifsAvisDefavorable: motifsAvisDefavorable,
          };
        });
    },

    /**
     * Permet de télécharger le recapitulatif d'une demande
     *
     * @param contribution
     */
    getRecapitulatifFromContribution: function (contribution) {
      return $http({
        method: 'GET',
        url: urljoin(_.get(contribution, 'id'), '/recapitulatif'),
        responseType: 'arraybuffer',
      });
    },

    /**
     * Permet de créer une nouvelle contribution pour avis de financement
     *
     * @param contribution
     */
    createContributionAvisFinancement: function (contribution) {
      const demandeFinancement = _.get(contribution, 'demande.reference');
      const tiers = _.last(_.get(contribution, 'tiers.href').split('/'));
      const url = urljoin(contributionsUrl(), '/avis-financement/', demandeFinancement, tiers);
      return $http.post(url).then(function (response) {
        return response.data;
      });
    },

    /**
     * Récupère la persistence (maxDocumentSize, allowedExtensions, etc) du téléservice des demandes de financement
     *
     * @param teleserviceFinancementHref
     */
    getPersistenceConfigurationTSFinancement: function (teleserviceFinancementHref) {
      return getTSFinancement(teleserviceFinancementHref).then(function (teleservice) {
        return _.get(teleservice, 'persistenceConfiguration.expand');
      });
    },

    /**
     * Display a toast notifying that the contribution is already done
     */
    notifications: {
      /**
       * Display warning message
       *
       * @param {string} messageKey
       * @returns {void}
       */
      warningMessage(messageKey) {
        ngToast.create({
          content: $translate.instant(messageKey),
          className: 'warning',
          timeout: 4000,
          maxNumber: 1,
        });
      },
      /**
       * Already done notification
       *
       * @returns {void}
       */
      alreadyDone() {
        this.warningMessage('contributions.common.alreadyDone');
      },
    },

    /**
     * Save the contributions by calculating "diff" on the api side
     *
     * @param {object} aide "aide" with modification
     * @param {object} contribution the contribution
     * @returns {Promise<void>}
     */
    saveContribution: function (aide, contribution) {
      const aideClean = aide.getCleanEntity();
      const uri = urljoin(contributionsUrl(), contribution.reference, 'diff');
      return $http
        .post(uri, aideClean)
        .then((res) => {
          const aideUpdated = res.data;
          aidesService.postUpdateAide(aide, aideUpdated, false);
          contribution.date = aideUpdated.date;

          // Get expand properties of aide's documents
          aidesService.gererExpandDocumentsAide(aideUpdated, aide);
        })
        .catch(function (err) {
          $log.error("Une erreur est survenue lors de l'enregistrement de la contribution ", err);
        })
        .finally(function () {
          $rootScope.isPFLoading = false;
        });
    },

    /**
     * Function used to sort contributions by date
     *
     * @param {object} contribA
     * @param {object} contribB
     */
    sortByDateFunction: function (contribA, contribB) {
      return contribA.date > contribB.date ? 1 : -1;
    },

    /**
     * Returns on one side treated contributions and on the other side untreated contributions
     *
     * @param {Array<object>} contributions the list of unfiltered contributions
     * @returns {{ treatedContributions: Array<object>, untreatedContributions: Array<object> }} untreated and treated contributions
     */
    differenciateTreatedFromUntreated(contributions) {
      const contribT = [];
      const contribNT = [];

      contributions?.forEach((contribution) => {
        if (
          contribution.cloture?.value ||
          !contribution.statut ||
          !IS_NOT_TREATED_STATUS.includes(contribution.statut.toUpperCase())
        ) {
          contribT.push(contribution);
        } else {
          contribNT.push(contribution);
        }
      });

      return { treatedContributions: contribT, untreatedContributions: contribNT };
    },

    /**
     * Filter demande contributions for current user and untreated contributions
     *
     * @param {Array<object>} contributions the list of unfiltered contributions
     * @param {string} userId the user id
     * @returns {Array<object>} The filtered list for current user and untreated contribs
     */
    filterContributionsForUser(contributions, userId) {
      if (!userId || !contributions) return [];
      return contributions?.filter((contribution) => contribution?.contact?.href === userId) ?? [];
    },

    /**
     * Generate the filters on the current tiers of the user
     *
     * If the user has selected a tiers we should only return the contributions for
     * this tiers. Otherwise we should return all the contributions for these user
     * that are not on one of the known tiers he is attached to
     *
     * @returns {Promise<object>} filters on the echange tiers
     */
    generateFiltersOnCurrentTiers() {
      const currentTiersReference = StoreService.currentTiersRef.get();
      const filters = {};

      return $q
        .resolve()
        .then(() => {
          if (currentTiersReference) {
            filters.in = [tiersService.buildIdFromReference(currentTiersReference)];
          } else {
            return tiersService.getAllCurrentUserTiers();
          }
        })
        .then((tiersToExclude) => {
          if (tiersToExclude) {
            filters.notIn = tiersToExclude.map((tiers) => tiers.id);
          }

          return filters;
        });
    },

    /**
     * Update avis situation data on the contribution (external data)
     *
     * @param {string} contributionReference reference of the contribution
     * @param {object} options
     * @param {string} options.numeroAllocataire numero allocataire
     * @param {string} options.codePostal code postal
     * @returns {Promise<object>} update result
     */
    updateAvisSituation(contributionReference, { numeroAllocataire, codePostal }) {
      return $http
        .post(`${contributionsUrl()}/${contributionReference}/update-composition-familiale`, {
          numeroAllocataire,
          codePostal,
        })
        .then(({ data }) => data);
    },
  };
}

'use strict';
angular.module('common.services').factory('documentsPubliesService', [
  '$http',
  'configuration',
  function ($http, configuration) {
    'use strict';

    const _tenantId = configuration.tenant.id;

    return {
      /**
       * get unread documents
       */
      getStats: function () {
        return $http
          .get('/referentiel-publication-documents/api/tenants/' + _tenantId + '/documents-publies/stats/my', {
            headers: { 'x-referer': 'portail-depot-demande-aides' },
          })
          .then(function (response) {
            return response.data;
          });
      },
      /**
       * get documents for demande
       *
       * @param {string} demandeHref
       */
      getDocumentsByDemandeHref: function (demandeHref) {
        return $http
          .get(
            `/referentiel-publication-documents/api/tenants/${_tenantId}/documents-publies/my?demandeFinancementHref=${demandeHref}&isPublished=true`,
            {
              headers: { 'x-referer': 'portail-depot-demande-aides' },
            }
          )
          .then(function (response) {
            return response.data;
          });
      },
    };
  },
]);

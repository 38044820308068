'use strict';
angular.module('comitesRecevabilite.services').factory('comitesRecevabiliteService', [
  '$http',
  function ($http) {
    'use strict';

    return {
      /**
       * Get a comiteRecevabilite
       *
       * @param href
       * @returns {*}
       */
      getByHref: function (href) {
        return $http.get(href);
      },
    };
  },
]);

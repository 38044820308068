'use strict';
RecapitulatifDocumentComptableController.$inject = ['aidesService', 'planFinancementService'];
angular.module('aides.recapitulatif').component('recapitulatifDocumentComptable', {
  templateUrl:
    'aides/aides-components/recapitulatif/document-comptable/recapitulatif-document-comptable.component.html',
  bindings: {
    aide: '<',
    teleservice: '<',
  },

  controller: /* @ngInject */ RecapitulatifDocumentComptableController,
});

/**
 *
 * @param aidesService
 * @param planFinancementService
 */
function RecapitulatifDocumentComptableController(aidesService, planFinancementService) {
  var ctrl = this;

  ctrl.$onInit = function () {
    ctrl.viewConfiguration = {
      ns: 'teleservice.document-comptable',
    };

    ctrl.pageOptions = ctrl.teleservice.workflow.simple.pageDocumentComptable;

    // Si on ne retrouve pas le type de montant dans le document comptable
    // alors on récupère le type paramétré sur le téléservice
    const firstPlanFinancement = aidesService.getFirstPlanFinancement(ctrl.aide);
    ctrl.typeMontant = (
      firstPlanFinancement.typeMontant || planFinancementService.getTypeMontant(firstPlanFinancement)
    ).toLowerCase();
    if (!ctrl.typeMontant && !['CHOIX_UTILISATEUR', 'MIXTE'].includes(ctrl.pageOptions.typeMontant)) {
      // Récupération du type de montant (ht ou ttc)
      ctrl.typeMontant = ctrl.pageOptions.typeMontant ? ctrl.pageOptions.typeMontant.toLowerCase() : 'ht';
    }

    ctrl.planFinancements = ctrl.aide.planFinancement;
    ctrl.teleserviceConfiguration = initTeleserviceConfiguration();
  };

  /**
   * Returns true if the given planFinancement is the first
   *
   * @param {object} planFinancement
   * @returns {boolean}
   */
  ctrl.isFirstYear = function (planFinancement) {
    const firstYear = aidesService.getFirstYearOfPf(ctrl.aide.planFinancement);
    return _.get(planFinancement, 'periode.exercice', 0) === firstYear;
  };

  /**
   *
   */
  function initTeleserviceConfiguration() {
    var type = _.get(ctrl, 'teleservice.workflow.type');
    var tsConfig = _.cloneDeep(ctrl.teleservice);
    tsConfig.workflow = _.get(ctrl, `teleservice.workflow.${type}`);
    return tsConfig;
  }
}

('use strict');
/**
 *
 * @param $scope
 * @param $rootScope
 * @param $state
 * @param tiersService
 * @param $stateParams
 * @param $http
 * @param $log
 * @param alertsService
 * @param planFinancementService
 */
function JustificationPrintController(
  $scope,
  $rootScope,
  $state,
  tiersService,
  $stateParams,
  $http,
  $log,
  alertsService,
  planFinancementService
) {
  var ctrl = this;
  this.$onInit = () => {
    this.print = true;
    this.displayTitle = this.displayTitle ?? true;
    this.alerts = [];

    this.setWorkflow();
    this.setStateTitle();
    this.setCustomHeader();
    this.setCustomFooter();
    this.setDemandeur();
    this.setRecap();
    this.setRecapPage();
    this.setDateJustifTransmitted();

    // Get type montant only if there is a tableau or workflow synthese to begin with
    if (this.workflowSynthese != null) {
      // Get type montant from justification plan financement HT or TTC
      this.typeMontant = this.justification?.tableau?.typeMontant
        ? this.justification.tableau.typeMontant
        : planFinancementService.getTypeMontant(this.justification?.tableau);
      if (this.typeMontant == null) {
        this.alerts = alertsService.getAlertError('justification.common.alerts.error.typeMontant');
        $log("Can't find type montant of planFinancement");
      }
      // Get plan financement model from demande financement telervice
      this.planFinancementModel =
        this.demandeFinancement.teleservice?.expand?.workflow?.simple?.pageDocumentComptable?.typeDocumentComptable?.planFinancement;
    }
  };

  ctrl.$doCheck = function () {
    ctrl.setReadonlyInputs();
  };

  ctrl.setRecapPage = function () {
    ctrl.recapPage = $stateParams.recap === 'true';
  };

  /**
   * Set all workflows
   */
  ctrl.setWorkflow = function () {
    ctrl.workflowInformationsGenerales = _.get(ctrl.teleserviceJustification, 'workflow.pageStandard');
    ctrl.workflowDonneesChiffrees = _.get(ctrl.teleserviceJustification, 'workflow.pageDonneesChiffrees');
    ctrl.workflowMoyensHumains = _.get(ctrl.teleserviceJustification, 'workflow.pageMoyensHumains');
    ctrl.workflowSynthese = _.get(ctrl.teleserviceJustification, 'workflow.pageSynthese');
    const pageSpecifique = _.get(ctrl.teleserviceJustification, 'workflow.pageSpecifique');
    ctrl.workflowInformationsComplementaires =
      pageSpecifique && _.get(pageSpecifique, 'actif', true) ? pageSpecifique : undefined;
    ctrl.workflowPieces = _.get(ctrl.teleserviceJustification, 'workflow.pagePieces');
    ctrl.workflowRecapitulatif = _.get(ctrl.teleserviceJustification, 'workflow.pageRecapitulatif');
  };

  /**
   * Set screen title with step
   *
   * @returns {object} state
   */
  ctrl.setStateTitle = function () {
    if ($state.$current?.data?.title) {
      // Change state title to allow proper display from page-title fragment
      $state.$current.data.title = 'justification.pageRecapitulatif.title';
    }

    var state = $state.$current;
    state.data = $state.$current.data || {};
    return state;
  };

  /**
   * Set readonly attributes on all inputs
   */
  ctrl.setReadonlyInputs = function () {
    var inputs = document.querySelectorAll('input[type="radio"], input[type="text"], select, textarea');

    _.each(inputs, function (el) {
      el.setAttribute('readonly', true);
    });
  };

  /**
   * Set custom header with teleservice workflow configuration
   */
  ctrl.setCustomHeader = function () {
    var workflowHeader = _.get(ctrl.teleserviceJustification, 'workflow.header');

    if (!workflowHeader) return;
    // check if custom header is set un workflow
    $rootScope.$broadcast('printJustification', workflowHeader);
  };

  /**
   * Set custom footer with teleservice workflow configuration
   */
  ctrl.setCustomFooter = function () {
    ctrl.footer = _.get(ctrl.teleserviceJustification, 'workflow.footer');
  };

  /**
   * Set demandeur datas
   */
  ctrl.setDemandeur = function () {
    // Get user name
    var demandeurHref = _.get(ctrl, 'justification.user.href');
    if (!demandeurHref) return;
    var userName = demandeurHref.split('/').pop();

    // Get user data
    tiersService.getTiersByUserName(userName).then(function (tiers) {
      var user = tiers[0];

      // Set title
      var title =
        _.has(user, 'civilite.title') &&
        _.has(user, 'individu.Prenoms.PrenomUsuel') &&
        _.has(user, 'individu.Noms.NomUsage')
          ? _.get(user, 'civilite.title') +
            ' ' +
            _.get(user, 'individu.Prenoms.PrenomUsuel') +
            ' ' +
            _.get(user, 'individu.Noms.NomUsage')
          : _.get(ctrl, 'justification.history.begin.user.title', '');

      ctrl.demandeur = {
        title,
        email: _.get(user, 'courriels[0].value', ''),
      };
    });
  };

  ctrl.isActiveWorkflow = function () {
    return (
      ctrl.workflowInformationsGenerales ||
      ctrl.workflowDonneesChiffrees ||
      ctrl.workflowMoyensHumains ||
      ctrl.workflowSynthese ||
      ctrl.workflowPieces ||
      ctrl.workflowRecapitulatif
    );
  };

  ctrl.setRecap = function () {
    // Get the recap
    const recapJustif = _.find(_.get(ctrl.justification, 'pieces', []), { reference: 'recapitulatif' });
    ctrl.recap = _.get(recapJustif, 'documents[0]', false);
  };
  /**
   * Method to download the PDF recapitulatif document
   */
  ctrl.downloadRecap = function () {
    if (!ctrl.recap || !ctrl.recap.id) return;

    // we set a shortcut to access to the recap url
    return Promise.all([$http.get(ctrl.recap.id, { responseType: 'arraybuffer' }), $http.get(ctrl.recap.href)])
      .then((response) => {
        const fileData = response[0].data;
        const expandedProperties = response[1].data.properties;
        var mimeType = ctrl.getMimeType(expandedProperties);
        const blob = new Blob([fileData], { type: mimeType });
        return saveAs(blob, ctrl.getFileName(expandedProperties));
      })
      .catch((err) => {
        ctrl.alerts = alertsService.getAlertError('justification.common.alerts.error.downloadRecap');
        $log('An error append when trying to download. More informations: ' + err);
      });
  };

  ctrl.getFileName = function (expandedProperties) {
    return _.get(expandedProperties, 'entity:document:originalfilename.value', 'recapitulatif.pdf');
  };

  ctrl.getMimeType = function (expandedProperties) {
    return _.get(expandedProperties, 'cmis:contentStreamMimeType.value', 'application/pdf');
  };

  ctrl.setDateJustifTransmitted = function () {
    const events = _.get(ctrl, 'justification.history.events', []);
    // All JUSTIFIED events
    const eventsJustified = _.filter(events, (event) => event.reference === 'JUSTIFIED');
    // Sort by date
    const eventsJustifiedSorted = _.sortBy(eventsJustified, 'date');
    // Get the most recent
    const eventJustified = _.last(eventsJustifiedSorted);
    ctrl.dateJustifTransmitted = _.get(eventJustified, 'date', '');
  };
}

JustificationPrintController.$inject = [
  '$scope',
  '$rootScope',
  '$state',
  'tiersService',
  '$stateParams',
  '$http',
  '$log',
  'alertsService',
  'planFinancementService',
];

// Module declaration
angular.module('portailDepotDemandeAide.depot').component('justificationPrintComponent', {
  templateUrl: 'depot/justification/print/justification-print.component.html',
  controller: JustificationPrintController,
  bindings: {
    justification: '<',
    piecesExpanded: '<',
    teleserviceJustification: '<',
    displayTitle: '<',
    demandeFinancement: '<',
  },
});

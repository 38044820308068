'use strict';
tiersSelection.$inject = [
  '$log',
  '$state',
  '$sce',
  '$timeout',
  '$httpParamSerializer',
  'configuration',
  'jwtSessionService',
  'StoreService',
  'routeUtilsService',
];
angular.module('portailDepotDemandeAide.tiersSelection').component('tiersSelection', {
  bindings: {
    tiersMine: '<',
  },

  templateUrl: 'tiers-selection/tiers-selection.component.html',
  controller: tiersSelection,
});

/* @ngInject */
/**
 *
 * @param $log
 * @param $state
 * @param $sce
 * @param $timeout
 * @param $httpParamSerializer
 * @param configuration
 * @param jwtSessionService
 * @param StoreService
 * @param routeUtilsService
 */
function tiersSelection(
  $log,
  $state,
  $sce,
  $timeout,
  $httpParamSerializer,
  configuration,
  jwtSessionService,
  StoreService,
  routeUtilsService
) {
  const ctrl = this;
  const currentTiersRef = StoreService.currentTiersRef.get();

  const redirectFrom = _.get($state, 'params.redirectFrom');
  const rawpreviousParams = _.get($state, 'params.redirectParams');

  let previousLocation = _.get($state, 'params.redirectTo');
  let previousParams = {};

  try {
    if (rawpreviousParams) {
      previousParams = JSON.parse(rawpreviousParams);
    }
  } catch (err) {
    $log.error(err);
    previousLocation = null;
  }
  ctrl.showBackButton = redirectFrom && previousLocation && currentTiersRef !== null;

  /**
   * @returns {string} trusted url
   */
  const buildIframeUrl = () => {
    const jwtKey = jwtSessionService.getJwtKey();
    const params = $httpParamSerializer({
      jwtKey: jwtKey,
      hideOthersProfiles: ctrl.notParticulierCount === 0,
    });

    const url = `${configuration.tiers.client}user-tiers-selection?${params}`;
    return $sce.trustAsResourceUrl(url);
  };

  // Help to make screen disseapear cleanly on tiers selection.
  // we use available ui-router uiCanExit event hook which is simple, auto-unregister,
  // and cover our basic need.
  ctrl.uiCanExit = function () {
    ctrl.screenLeaving = true;
  };

  /**
   * Leave selection screen
   * We use recorded state history
   */
  ctrl.cancelChoice = () => {
    $state.go(previousLocation, previousParams);
  };

  ctrl.$onInit = function () {
    ctrl.screenLoading = true;
    ctrl.screenLeaving = false;

    // Check if we only have famille tiers particuliers
    const tiersByFamilleCount = StoreService.famillesTiersCount.get();
    ctrl.notParticulierCount = 0;
    for (const famille in tiersByFamilleCount) {
      if (famille !== 'TIERS_PARTICULIER') {
        ctrl.notParticulierCount++;
      }
    }

    const { total, tiers } = ctrl.tiersMine.data;
    // If we don't have any or only one tiers coming from change tiers action,
    // we should skip the selection screen alltogether.
    const shouldSkipSelectionScreen = total === 0 || (total === 1 && !previousLocation);

    if (shouldSkipSelectionScreen) {
      const tiersRefToSelect = total === 1 ? tiers[0].reference : '';
      setCurrentTiers(tiersRefToSelect);
    } else {
      // To avoid some screen flashing, we only show our screen now.
      ctrl.screenLoading = false;
      // load iframe.
      ctrl.tiersSelectionUIUrl = buildIframeUrl();
      // ... and iframe resizer
      $timeout(() => {
        iFrameResize(
          {
            checkOrigin: false,
            inPageLinks: true,
          },

          '#tiers-selection-iframe'
        );
      });
    }
  };
  /**
   * Set the current tiers and redirect outside the selection screen
   *
   * @param {string} reference selected tiers reference
   * @returns {void}
   */
  function setCurrentTiers(reference) {
    StoreService.currentTiersRef.set(reference, true);
    routeUtilsService.handleRedirectionOrGoTo('app.connected.dashboard.accueil', { reload: true });
  }
}

'use strict';
angular.module('common.directives').directive('fieldDisplay', [
  '$translate',
  '$rootScope',
  'formUtils',
  function ($translate, $rootScope, formUtils) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'common/common-directives/field-display/field-display.html',
      require: ['?^specificInformationsDisplay'],
      scope: {
        namespace: '=',
        field: '=',
        showHelp: '=',
        personnalizedLabel: '<',
      },

      link: function (scope, element, attributes, controllers) {
        scope.dataType = _.get(scope.field, 'schema.expand.dataType') || _.get(scope.field, 'dataType') || 'string';
        scope.dataType = scope.dataType.replace(/^xs:/, '');
        scope.subType = _.get(scope.field, 'schema.expand.subType') || _.get(scope.field, 'subType');
        scope.reference = _.get(scope.field, 'reference') || _.get(scope.field, 'schema.expand.reference');

        // To adapt the column with the content of the labels, we have to translate into the controller
        var translateFieldLabel = function (fieldConfiguration, reference, key) {
          var lbKey = scope.namespace + '.' + reference + '.' + key;

          // Initialize the value with the configuraiton field;
          var lb = fieldConfiguration[key];
          if (!scope.personnalizedLabel) {
            if ($translate.instant(lbKey) !== lbKey) {
              lb = $translate.instant(lbKey);
            }

            if (lb === lbKey || lb === null || lb === undefined) {
              var keyDefault = 'form.field.' + key;
              var lbDefault = $translate.instant(keyDefault);
              if (lbDefault !== keyDefault) {
                lb = lbDefault;
              }
            }
          }

          return lb;
        };

        /**
         * Initializes labels and the field's display
         */
        var writeField = function () {
          // Complete field configuration with standard labels
          _.each(['label', 'unit', 'help'], function (key) {
            var lb = translateFieldLabel(scope.field, scope.reference, key);
            _.set(scope.field, key, lb);
          });

          // Managing widths
          // Set width attributes
          scope.widths = {
            label: scope.field.labelWidth || 4,
            field: scope.field.fieldWidth || 4,
            help: scope.field.helpWidth || 4,
            colType: scope.field.colType || 'xs',
          };

          if (!scope.showHelp) {
            scope.widths.label += scope.widths.help / 2;
            scope.widths.field += scope.widths.help / 2;
          }
        };

        $rootScope.$on('$translateChangeSuccess', writeField);
        writeField();

        // Access "scopeFields" element from directive "dynamic-form-group"
        var scopeFields;
        if (controllers && controllers[0] && controllers[0].scopeFields) {
          scopeFields = controllers[0].scopeFields;
        }

        //  List of properties to analyse when we use angular expression on configuration's values properties.
        var properties = ['hidden'];

        // Clone the configuration for not override angular expression contains on some configuration's properties
        scope.dynamicFieldConfiguration = angular.copy(scope.field);

        // Check if we have access to property "scopeFields" of scope of the directive "dynamic-form-groups"
        if (scopeFields && scope.field) {
          _.each(properties, function (property) {
            // Evaluate property
            if (scope.field[property] && formUtils.checkIsAngularExpression(scope.field[property])) {
              scopeFields.$watch(scope.field[property], function (newValue) {
                if (newValue !== undefined) {
                  scope.dynamicFieldConfiguration[property] = newValue;
                }
              });
            }
          });
        }

        scope.isObject = function (object) {
          return angular.isObject(object);
        };
      },
    };
  },
]);

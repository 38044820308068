'use strict';
angular.module('common.services').factory('configurationService', [
  '$location',
  'configuration',

  function ($location, configuration) {
    'use strict';

    /**
     *
     * @param obj
     * @param tenant
     */
    function recursiveTenantReplacement(obj, tenant) {
      if (!tenant) return;
      let result = obj;
      if (_.isString(obj)) {
        result = obj.replace('{{tenantId}}', tenant);
      } else if (_.isObject(obj)) {
        _.forEach(obj, (value, key) => {
          obj[key] = recursiveTenantReplacement(value, tenant);
        });
      }
      return result;
    }

    return {
      fillConfigurationWithTenantInformation: (tenantId) => {
        if (!tenantId) {
          const url = $location.url().split('/');
          tenantId = _.get(configuration, 'tenant.id') || url[1];
        }
        recursiveTenantReplacement(configuration, tenantId);
      },
    };
  },
]);

'use strict';
angular.module('common.directives').directive('buttonRole', buttonRole);

// Gives the usual role and attributes of a button.
// - beeing focusable
// - have the role of a button
// - respond to keyboard "Space" by sending a click event

/* @ngInject */
/**
 *
 */
function buttonRole() {
  return {
    restrict: 'A',
    link: (scope, element, attr) => {
      attr.$set('tabindex', 0);
      attr.$set('role', 'button');

      element.on('keydown', (e) => {
        if (e.key === 'Space') {
          element.trigger('click');
        }
      });
    },
  };
}

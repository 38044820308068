'use strict';
angular.module('tiers.model').factory('Coordonnees', [
  'adresseService',
  'Geographique',
  'Telephone',
  function (adresseService, Geographique, Telephone) {
    'use strict';

    /**
     *
     * @param data
     * @param mdm
     */
    function Coordonnees(data, mdm) {
      data = data || {};
      this.typeAdresse = data.typeAdresse || 'ADM';
      this.principale = _.get(data, 'principale', true);
      this.position = data.position;
      this.codeInsee = data.codeInsee;
      this.regionInsee = data.regionInsee;
      this.departementInsee = data.departementInsee;
      this.communeInsee = data.communeInsee;
      this.cantonInsee = data.cantonInsee;
      this.arrondissementInsee = data.arrondissementInsee;
      this.epciInsee = data.epciInsee;
      this.afnor = data.afnor;
      this.geographique = new Geographique(data.geographique, mdm);
      this.courriels = data.courriels || [];
      this.sitesWeb = data.sitesWeb || [];
      this.telephones = data.telephones || [];
      this.id = data.id;

      this.complements = data.complements;

      // Champs complémentaires UX
      this.etrangere = data.etrangere || this.isForeignAddress();
      this.previousCodePostalVille = '';
      this.codePostalVille = this.getCodePostalVille();
    }

    /**
     * Retourne l'information précisant si l'adresse du tiers est une adresse étrangère.
     *
     * @returns {boolean}
     */
    Coordonnees.prototype.isForeignAddress = function () {
      var isForeignAddress = false;
      if (this.geographique.Pays && this.geographique.Pays.code) {
        isForeignAddress = this.geographique.Pays.code !== 'FR';
      }
      return isForeignAddress;
    };

    /**
     * Retourne le code postal, la ville et le cedex formaté comme désiré
     *
     * @returns {string}
     */
    Coordonnees.prototype.getCodePostalVille = function () {
      if (!this.isForeignAddress() && _.get(this.afnor, 'LigneSix')) {
        return this.afnor.LigneSix;
      }

      let codePostalVille = '';

      if (this.geographique.Localite.value) {
        codePostalVille = this.geographique.Localite.value;

        if (this.geographique.CodePostal) {
          codePostalVille = `${this.geographique.CodePostal} ${codePostalVille}`;
        }

        if (this.geographique.Cedex === 'P') {
          codePostalVille = _.get(this, 'afnor.LigneSix') || codePostalVille;
        }
      }

      return codePostalVille;
    };

    /**
     * Réinitialise le code postal et la localité de l'adresse géographique
     */
    Coordonnees.prototype.reinitCodePostalVille = function () {
      if (this.codePostalVille !== this.previousCodePostalVille) {
        this.regionInsee = undefined;
        this.departementInsee = undefined;
        this.communeInsee = undefined;
        this.cantonInsee = undefined;
        this.arrondissementInsee = undefined;
        this.epciInsee = undefined;
        this.geographique.CodePostal = undefined;
        this.geographique.Cedex = 'V';
        this.geographique.LibelleBureauCedex = undefined;
        this.geographique.Localite.code = undefined;
        this.geographique.Localite.value = undefined;
        this.afnor = undefined;
      }
    };

    /**
     * Affecte les coordonnées passées en paramètre à l'adresse géographique des coordonnées courantes
     *
     * @param {string} itemCodePostalVille Element codePostalVille
     */
    Coordonnees.prototype.defineCodePostalVille = function (itemCodePostalVille) {
      if (itemCodePostalVille.inseeregion) {
        this.regionInsee = {
          code: itemCodePostalVille.inseeregion,
          value: itemCodePostalVille.region,
        };
      }
      if (itemCodePostalVille.inseedepartement) {
        this.departementInsee = {
          code: itemCodePostalVille.inseedepartement,
          value: itemCodePostalVille.departement,
        };
      }
      if (itemCodePostalVille.codeinsee) {
        this.communeInsee = {
          code: itemCodePostalVille.codeinsee,
          value: itemCodePostalVille.commune,
        };
      }
      if (itemCodePostalVille.inseecanton) {
        this.cantonInsee = {
          code: itemCodePostalVille.inseecanton,
          value: itemCodePostalVille.canton,
        };
      }
      if (itemCodePostalVille.inseeepci) {
        this.epciInsee = {
          code: itemCodePostalVille.inseeepci,
          value: itemCodePostalVille.epci,
        };
      }
      this.codeInsee = itemCodePostalVille.codeinsee;
      this.afnor = this.afnor || {};
      this.afnor.LigneCinq = itemCodePostalVille.lbligne5;
      this.afnor.LigneSix = itemCodePostalVille.lbligne6;
      this.geographique.CodePostal = itemCodePostalVille.codepostal;
      this.geographique.Localite.code = itemCodePostalVille.codeinsee;
      this.geographique.Localite.value = itemCodePostalVille.communemaj;
      this.geographique.MentionDistribution = itemCodePostalVille.lbligne5;
      if (itemCodePostalVille.typecp === 'C') {
        this.geographique.Cedex = 'P';
        this.geographique.LibelleBureauCedex = '';
      } else {
        this.geographique.Cedex = 'V';
        this.geographique.LibelleBureauCedex = '';
      }
      this.codePostalVille = itemCodePostalVille.lbligne6;
      this.previousCodePostalVille = this.codePostalVille;
    };

    Coordonnees.prototype.checkAdresseEtrangere = function (etrangere) {
      this.codePostalVille = undefined;
      this.geographique.LieuDit = '';
      this.geographique.CodePostal = undefined;
      this.geographique.Cedex = undefined;
      this.geographique.Pays = undefined;
      this.geographique.LibelleBureauCedex = undefined;
      this.geographique.Localite.code = undefined;
      this.geographique.Localite.value = undefined;
      this.geographique.MentionDistribution = undefined;
      this.afnor = undefined;
      if (!etrangere) {
        this.geographique.Pays = {};
        this.geographique.Pays.code = 'FR';
        this.geographique.Pays.value = 'FRANCE';
      }
    };

    /**
     * Méthode exécutée lors du changement de pays.
     */
    Coordonnees.prototype.changePays = function () {
      this.geographique.Pays = {
        code: this.geographique.Pays.reference,
        value: this.geographique.Pays.libelle,
      };
    };

    Coordonnees.prototype.searchCodePostalVille = adresseService.searchCodePostalVille;

    var cleanTelephones = function (telephones) {
      if (_.isArray(telephones)) {
        return _.map(telephones, function (telephone) {
          if (typeof telephone === 'string') {
            return {
              value: telephone,
            };
          } else if (typeof telephone === 'object') {
            return telephone;
          }
        });
      }
    };

    Coordonnees.prototype.getCleanEntity = function () {
      var situation = angular.copy(this);
      if (situation) {
        // Lors du dépot d'une 1ere demande, si l'usager à sélectionné "A l'étranger"
        //  et qu'il sort du workflow avant d'avoir saisit un pays, il faut pouvoir
        //  repositionner le bouton radio sur "A l'étranger".
        // Pour cela, on ajoute un pays fictif dans la situation.
        if (situation.etrangere && _.get(situation, 'geographique.Pays.code', '') === '') {
          _.set(situation, 'geographique.Pays.code', '!FR');
        }

        delete situation.etrangere;
        delete situation.codePostalVille;
        delete situation.previousCodePostalVille;

        // Telephones
        if (situation.telephones && situation.telephones.length > 0) {
          situation.telephones = cleanTelephones(situation.telephones);
        }

        // if defined, the contract requires that field value exist
        if (!_.get(situation, 'geographique.Localite.value')) {
          delete situation.geographique.Localite;
        }
      }

      return situation;
    };

    Coordonnees.prototype.isAddressValid = function () {
      return (
        (this.geographique.PointRemise ||
          this.geographique.Complement ||
          this.geographique.NomVoie ||
          this.geographique.MentionDistribution) &&
        ((this.isForeignAddress() && this.geographique.Pays) || (!this.isForeignAddress() && this.getCodePostalVille()))
      );
    };

    return Coordonnees;
  },
]);

'use strict';
angular.module('portailDepotDemandeAide.dashboard').controller('dashboardCentreEchangesController', [
  '$scope',
  '$sce',
  '$stateParams',
  '$state',
  '$httpParamSerializer',
  'isEchangesLicenceActive',
  'alertsService',
  'jwtSessionService',
  '$rootScope',
  'StoreService',
  'IFrameCommunicationManager',
  'routeUtilsService',
  function (
    $scope,
    $sce,
    $stateParams,
    $state,
    $httpParamSerializer,
    isEchangesLicenceActive,
    alertsService,
    jwtSessionService,
    $rootScope,
    StoreService,
    IFrameCommunicationManager,
    routeUtilsService
  ) {
    this.$onInit = function $onInit() {
      $scope.isEchangesLicenceActive = isEchangesLicenceActive;

      const iframeCentreEchangesParams = $httpParamSerializer({
        jwtKey: jwtSessionService.getJwtKey(),
        theme: 'demandeur',
        currentTiers: StoreService.currentTiersRef.get(),
      });

      $scope.iframeCentreEchangesUrl = $sce.trustAsResourceUrl(
        '/referentiel-echanges/#/' + $stateParams.tenantId + '/centreEchangesUsager?' + iframeCentreEchangesParams
      );

      this.iFrameCommunicationManager = new IFrameCommunicationManager('#centreEchanges');
      this.iFrameCommunicationManager.manageEvent(({ data }) => {
        const action = data?.action;

        if (action === 'updateIframeCentreEchanges') {
          resizeIframe();
        } else if (action === 'goToWorkflowDemandeFinancement') {
          routeUtilsService.goToDepotSimple(data.teleserviceRef, data.demandeRef, {
            fromEchange: data.echangeRef,
          });
        } else if (action === 'goToRecapDemandeFinancement') {
          routeUtilsService.goToRecapDemandeFinancement(data.demandeRef, { fromEchange: data.echangeRef });
        } else if (action === 'unAuthorizedUserOnAide') {
          $rootScope.$broadcast(
            'alerts',
            alertsService.getAlertError('connected.dashboard.alerteur.echanges.error'),
            'general-error-alert'
          );
        } else if (action === 'mark-as-read') {
          // We set timeout to ensure that readed echanges count is updated
          setTimeout(() => {
            $rootScope.$broadcast('refresh-menu');
          }, 1000);
        }
      });
    };

    $scope.$on('$destroy', () => {
      this.iFrameCommunicationManager.close();
    });

    /**
     * Resize iframe
     *
     * @returns {void}
     */
    function resizeIframe() {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
          scrolling: true,
        },

        '#centreEchanges'
      );
    }
  },
]);

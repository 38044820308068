'use strict';
recapitulatifDocuments.$inject = [
  'jwtSessionService',
  'configuration',
  '$scope',
  '$httpParamSerializer',
  'StoreService',
];
angular.module('aides.recapitulatif').directive('recapitulatifDocuments', function () {
  return {
    templateUrl: 'aides/aides-directives/recapitulatif/recapitulatif-documents/recapitulatif-documents.component.html',
    controller: recapitulatifDocuments,
    controllerAs: '$ctrl',
  };
});

/**
 *
 * @param jwtSessionService
 * @param configuration
 * @param $scope
 * @param $httpParamSerializer
 * @param StoreService
 */
function recapitulatifDocuments(jwtSessionService, configuration, $scope, $httpParamSerializer, StoreService) {
  this.$onInit = function $onInit() {
    // generating publication URL iframe
    const publicationIframeQueryParam = $httpParamSerializer({
      jwtKey: jwtSessionService.getJwtKey(),
      currentTiers: StoreService.currentTiersRef.get(),
    });

    const templatePublicationIframeSrc =
      configuration.referentielPublication.ux +
      '<%= tenantId %>/documents-publies/demande/<%= demandeId %>?<%= queryParams %>';
    const compiledPublicationIframeSrc = _.template(templatePublicationIframeSrc);
    const publicationIframeSrc = compiledPublicationIframeSrc({
      tenantId: configuration.tenant.id,
      demandeId: $scope.aide.reference,
      queryParams: publicationIframeQueryParam,
    });

    this.publicationIframeSrc = publicationIframeSrc;
  };
}

'use strict';
angular.module('common.directives').directive('anchorLink', anchorLink);

// An anchor (<a>) without an href or with an empty href is not counted as a real link.
// This directive aims at replicating a real links behaviour which is :
// - beeing focusable
// - have the role of a link
// - respond to keyboard Enter by sending a click event

/* @ngInject */
/**
 *
 */
function anchorLink() {
  return {
    restrict: 'A',
    link: (scope, element, attr) => {
      attr.$set('tabindex', 0);
      attr.$set('role', 'link');

      element.on('keydown', (e) => {
        if (e.key === 'Enter') {
          element.trigger('click');
        }
      });
    },
  };
}

'use strict'; /*jshint loopfunc: true */
/*jshint bitwise: false */

/**
 * @param $q
 * @param $window
 * @param FileUploader
 * @param $translate
 * @param $log
 * @param $modal
 * @param $state
 * @param $http
 * @param $tooltip
 * @param configuration
 * @param alertsService
 * @param fileService
 * @param Document
 * @param Link
 * @param jwtSessionService
 * @param userSessionService
 * @ngdoc directive
 * @name tiers.directive:pieceForm
 * @restrict EA
 * @description Directive who diplay a piece and this associated documents
 * @element
 * @example
 * <piece-offre-stage-form
 view-configuration="piecesConfiguration"
 ng-model="piece"
 url-documents="urlDocuments"
 file-extensions-allowed="mergedConfiguration.fileExtensionsAllowed"
 file-size-limit="mergedConfiguration.fileSizeLimit"
 icons-set-name="mergedConfiguration.iconsSetName"
 authorize-delete-document-server="true"
 url-file-icons="urlFileIcons"
 display-status="true"
 max-documents-authorized="maxDocumentsAuthorized">
 </piece-offre-stage-form>
 */
angular.module('common.directives').directive('pieceOffreStageForm', [
  '$q',
  '$window',
  'FileUploader',
  '$translate',
  '$log',
  '$modal',
  '$state',
  '$http',
  '$tooltip',
  'configuration',
  'alertsService',
  'fileService',
  'Document',
  'Link',
  'jwtSessionService',
  'userSessionService',
  '$timeout',
  function (
    $q,
    $window,
    FileUploader,
    $translate,
    $log,
    $modal,
    $state,
    $http,
    $tooltip,
    configuration,
    alertsService,
    fileService,
    Document,
    Link,
    jwtSessionService,
    userSessionService,
    $timeout
  ) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      require: ['^form', 'ngModel'],
      templateUrl: 'common/common-directives/piece-offre-stage-form/piece-offre-stage-form.html',
      scope: {
        viewConfiguration: '=',
        fileExtensionsAllowed: '=?',
        piece: '=ngModel',
        buttonText: '=',
        buttonName: '=',
        buttonIcon: '=',
        iconsSetName: '=?',
        offreStage: '=',
        displayStatus: '=',
        fileSizeLimit: '=?',
        urlDocuments: '=',
        urlFileIcons: '=?',
        maxDocumentsAuthorized: '=',
        isActiveOneDocumentByPiece: '=?',
        authorizeDeleteDocumentServer: '=',
        readOnly: '=',
        validate: '&',
        beforeUpload: '&',
        afterUpload: '&',
        isRequired: '=?',
        entity: '=',
        kind: '=',
      },

      link: function (scope, element, attributes, controllers) {
        scope.isActiveOneDocumentByPiece = scope.isActiveOneDocumentByPiece || false;
        scope.tooltipAddButtonTrue = {};
        scope.tooltipAddButtonFalse = {};
        scope.form = controllers[0];
        scope.model = controllers[1];

        // Logged-in user
        scope.user = userSessionService.getUser();

        // Uploader is in the scope for we can iterate on it and display all documents
        scope.uploader = null;

        // Maintaining Settings
        // If there are no values to set then we will search in configuration for the default values.
        scope.fileExtensionsAllowed =
          scope.fileExtensionsAllowed || _.get(configuration, 'persistenceConfiguration.aide.allowedExtensions');
        scope.maxDocumentsAuthorized =
          scope.maxDocumentsAuthorized ||
          _.get(configuration, 'persistenceConfiguration.aide.maxNumberDocumentsPerPersistence');
        scope.fileSizeLimit =
          scope.fileSizeLimit || _.get(configuration, 'persistenceConfiguration.aide.maxDocumentSize');

        // Icons set name
        scope.iconsSetName = scope.iconsSetName || 'standard';

        // Url directory file icons
        scope.urlFileIcons = scope.urlFileIcons || '/module/tiers-resources/images/file-icons/';

        /**
         * setStateClasseOnbtnAddPiece add or remove  class btn-desabled on button add piece
         */
        var setStateClasseOnbtnAddPiece = function () {
          if (scope.isActiveOneDocumentByPiece && _.get(scope, 'piece.documents.length')) {
            angular.element(element).find('.bootstrap-filestyle').addClass('desabled-btn');
            angular.element(element).find('.bootstrap-filestyle').removeClass('actived-btn');
            scope.tooltipAddButtonFalse.setEnabled(true);
            scope.tooltipAddButtonTrue.setEnabled(false);
          } else {
            angular.element(element).find('.bootstrap-filestyle').find('label').removeAttr('disabled');
            angular.element(element).find('.bootstrap-filestyle').removeClass('desabled-btn');
            angular.element(element).find('.bootstrap-filestyle').addClass('actived-btn');
            scope.tooltipAddButtonFalse.setEnabled(false);
            scope.tooltipAddButtonTrue.setEnabled(true);
          }
        };

        // Text to be displayed in tooltips
        $translate.refresh().then(function () {
          scope.tooltip = {
            add: {
              title: $translate.instant(scope.viewConfiguration.ns + '.addButtonTooltip'),
            },

            briefcase: {
              title: $translate.instant(scope.viewConfiguration.ns + '.briefcaseButtonTooltip'),
            },

            deleteDocument: {
              title: $translate.instant(scope.viewConfiguration.ns + '.deleteDocumentButtonTooltip'),
            },

            postal: {
              title: $translate.instant(scope.viewConfiguration.ns + '.unableToSendElectronicDocument'),
            },
          };
        });

        /**
         * Function who return the url to access to the directory file icons
         *
         * @returns {string} Url to access file icons
         */
        scope.getUrlFileIcons = function () {
          if (scope.urlFileIcons.slice(-1) !== '/') {
            scope.urlFileIcons += '/';
          }

          return scope.urlFileIcons;
        };

        /**
         * Method for validating whether a document list is valid
         *
         * @param documents
         * @returns {boolean}
         */
        const areDocumentsValid = (documents) => {
          // We're checking to make sure the list isn't empty.
          if (_.isEmpty(documents)) {
            return false;
          }
          let isValid = true;
          _.forEach(documents, (document) => {
            // We check that for each document the href and the id are not null.
            isValid =
              isValid &&
              !angular.isUndefined(document) &&
              !angular.isUndefined(document.id) &&
              !angular.isUndefined(document.href);
          });
          return isValid;
        };

        const checkValidity = () => {
          var validity = true;
          if (
            scope.isRequired &&
            !areDocumentsValid(scope.piece.documents) &&
            scope.piece.modeTransmission === 'DEPOSEE'
          ) {
            validity = false;
          }
          return validity;
        };

        /**
         * Filter for fileExtensionsAllowed - Item can be a file object if the browser handles or a html input file element
         *
         * @param  {File} item File
         */
        var filterFileExtensionsAllowed = function (item) {
          if (
            scope.fileExtensionsAllowed &&
            angular.isArray(scope.fileExtensionsAllowed) &&
            scope.fileExtensionsAllowed.length > 0
          ) {
            var isExtensionAuthorized = fileService.isExtensionAuthorized(item, scope.fileExtensionsAllowed);
            if (!isExtensionAuthorized) {
              scope.$broadcast(
                'alerts',
                alertsService.getAlertError(scope.viewConfiguration.ns + '.error.extensionUnauthorized', {
                  extensions: scope.fileExtensionsAllowed.join(', '),
                }),

                'piece-form'
              );
            }

            return isExtensionAuthorized;
          }

          return true;
        };

        var filterMaxDocumentsAuthorized = function () {
          var isUploadAuthorized = true;
          if (scope.piece && scope.piece.documents && scope.piece.documents.length + 1 > scope.maxDocumentsAuthorized) {
            scope.$broadcast(
              'alerts',
              alertsService.getAlertError(scope.viewConfiguration.ns + '.error.maxDocumentsAuthorized', {
                maxDocumentsAuthorized: scope.maxDocumentsAuthorized,
              }),

              'piece-form'
            );
          }

          return isUploadAuthorized;
        };

        /**
         * Filter for the check the size of the file
         *
         * @param  {object} file File before upload
         */
        var filterFileSize = function (file) {
          var isSizeOk = true;

          // Size to 0
          if (file && file.size === 0) {
            scope.$broadcast(
              'alerts',
              alertsService.getAlertError(scope.viewConfiguration.ns + '.error.fileSizeZero', {
                fileSizeLimit: scope.fileSizeLimit,
              }),

              'piece-form'
            );

            isSizeOk = false;
          }

          // File's size limit
          if (scope.fileSizeLimit && scope.fileSizeLimit > 0 && file && file.size && file.size > 0) {
            var sizeFileMo = file.size / 1024 / 1024;
            if (sizeFileMo > scope.fileSizeLimit) {
              scope.$broadcast(
                'alerts',
                alertsService.getAlertError(scope.viewConfiguration.ns + '.error.fileSizeLimitExceeded', {
                  fileSizeLimit: scope.fileSizeLimit,
                }),

                'piece-form'
              );

              isSizeOk = false;
            }
          }

          return isSizeOk;
        };

        var generateUUID = function () {
          /**
           *
           */
          function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
          }

          var guid = (
            S4() +
            S4() +
            '-' +
            S4() +
            '-4' +
            S4().substr(0, 3) +
            '-' +
            S4() +
            '-' +
            S4() +
            S4() +
            S4()
          ).toLowerCase();
          return guid;
        };

        var getUrlDocuments = function () {
          var urlDocuments = scope.urlDocuments;
          urlDocuments = _.first(urlDocuments.split('/root'));

          return urlDocuments + '/root';
        };

        var onBeforeUpload = function (fileItem) {
          // Add a value associted with the document
          var entityKind = _.get(scope, 'entity.kind', scope.kind);

          // Pass cmis parameters
          var guid = generateUUID();
          var params = {
            cmisaction: 'createDocument',
            'propertyId[0]': 'cmis:name',
            'propertyValue[0]': guid + '_' + fileItem.file.name,
            'propertyId[1]': 'cmis:objectTypeId',
            'propertyValue[1]': 'cmis:document',
            'propertyId[2]': 'cmis:contentStreamLength',
            'propertyValue[2]': fileItem.file.size,
            'propertyId[3]': 'cmis:contentStreamMimeType',
            'propertyValue[3]': fileItem.file.type,
            'propertyId[4]': 'cmis:secondaryObjectTypeIds',
            'propertyValue[4]': 'entity:metadata',
            'propertyId[5]': 'entity:uri',
            'propertyValue[5]': _.get(scope, 'entity.id', ''),
            'propertyId[6]': 'entity:reference',
            'propertyValue[6]': _.get(scope, 'entity.reference', ''),
            'propertyId[7]': 'entity:referenceAdministrative',
            'propertyValue[7]': _.get(scope, 'entity.referenceAdministrative', ''),
            'propertyId[8]': 'entity:document:originalfilename',
            'propertyValue[8]': fileItem.file.name,
            'propertyId[9]': 'entity:kind',
            'propertyValue[9]': entityKind,
            'propertyId[10]': 'entity:author',
            'propertyValue[10]': _.get(scope.user, 'displayName', ''),
            'propertyId[11]': 'entity:document:date',
            'propertyValue[11]': new Date().toISOString(),
            'propertyId[12]': 'entity:document:category',
            'propertyValue[12]': _.get(scope, 'piece.reference', ''),
            'propertyId[13]': 'entity:document:nature',
            'propertyValue[13]': _.get(scope, 'piece.nature', ''),
            // Keys retained for upward compatibility
            'propertyId[14]': 'entity:originalfilename',
            'propertyValue[14]': fileItem.file.name,
            'propertyId[15]': 'entity:category',
            'propertyValue[15]': _.get(scope, 'piece.reference', ''),
          };

          fileItem.url += '?' + $.param(params);

          // Hide error message if exist
          scope.hideAlertMessage();
          var documentPiece = new Link({
            expand: new Document(),
          });

          documentPiece.expand.properties['cmis:name'] = {};
          documentPiece.expand.properties['cmis:name'].value = fileItem.file.name;
          documentPiece.expand.properties['cmis:description'] = {};
          documentPiece.expand.properties['cmis:description'] = {
            value: null,
          };

          documentPiece.expand.properties['cmis:contentStreamLength'] = {};
          documentPiece.expand.properties['cmis:contentStreamLength'].value = fileItem.file.size;
          documentPiece.expand.properties['cmis:contentStreamMimeType'] = {};
          documentPiece.expand.properties['cmis:contentStreamMimeType'].value = fileItem.file.type;
          scope.piece.documents = scope.piece.documents || [];
          scope.piece.documents.push(documentPiece);
          fileItem.documentPiece = documentPiece;

          if (scope.beforeUpload) {
            scope.beforeUpload();
          }
        };

        var onProgress = function (fileItem, progress) {
          scope.piece.hasDocumentOnProgress = true;
          fileItem.documentPiece.expand.isUploading = true;
          fileItem.documentPiece.expand.progress = progress;
        };

        var onSuccess = function (fileItem, response) {
          var documentPiece = fileItem.documentPiece;
          documentPiece.expand.properties['cmis:objectId'] = {};
          documentPiece.expand.properties['cmis:objectId'].value =
            (response.properties &&
              response.properties['cmis:objectId'] &&
              response.properties['cmis:objectId'].value) ||
            response._id;

          if (
            response.properties &&
            response.properties['cmis:name'] &&
            response.properties['entity:originalfilename']
          ) {
            _.set(documentPiece.expand.properties, 'cmis:name.value', _.get(response, 'properties.cmis:name.value'));
            _.set(
              documentPiece.expand.properties,
              'entity:originalfilename.value',
              _.get(response, 'properties.entity:originalfilename.value')
            );
          }

          documentPiece.rel = 'cmis';

          var objectId = documentPiece.expand.properties['cmis:objectId'].value;
          documentPiece.id = getUrlDocuments() + '?objectId=' + objectId;
          if (response.properties && response.properties['entity:originalfilename']) {
            documentPiece.title = response.properties['entity:originalfilename'].value;
          }
          documentPiece.href = documentPiece.id + '&cmisselector=object';
          documentPiece.expand.properties['cmis:creationDate'] = {};
          documentPiece.expand.properties['cmis:creationDate'].value = new Date().toISOString();
          documentPiece.expand.isUploaded = true;
          documentPiece.expand.isUploading = false;
          documentPiece.expand.progress = 100;
          delete scope.piece.hasDocumentOnProgress;

          setStateClasseOnbtnAddPiece();
          if (scope.afterUpload) {
            scope.afterUpload();
          }
          if (scope.validate) {
            scope.validate();
          }
        };

        var onError = function (fileItem, response, status) {
          // Document already exist on the server
          if (status === 409) {
            scope.$broadcast(
              'alerts',
              alertsService.getAlertError(scope.viewConfiguration.ns + '.error.conflict'),
              'piece-form'
            );
          }
          // File too big for the server
          else if (status === 413) {
            scope.$broadcast(
              'alerts',
              alertsService.getAlertError(scope.viewConfiguration.ns + '.error.fileSizeLimitExceededServer'),
              'piece-form'
            );
          }
          // File corrupted
          else if (response.status === 400.1) {
            scope.$broadcast(
              'alerts',
              alertsService.getAlertError(scope.viewConfiguration.ns + '.error.corrupted'),
              'piece-form'
            );
          } else if (status === 401) {
            $state.go('app.home');
          } else {
            scope.$broadcast(
              'alerts',
              alertsService.getAlertError(scope.viewConfiguration.ns + '.error.unknow'),
              'piece-form'
            );
          }

          // Delete item from the list of documents
          scope.piece.documents.splice(_.indexOf(scope.piece.documents, fileItem.documentPiece), 1);

          if (scope.afterUpload) {
            scope.afterUpload();
          }
        };

        /**
         * Download a document
         *
         * @param  {object} pieceDocument Document of the piece
         */
        scope.downloadDocument = function (pieceDocument) {
          var url = pieceDocument.id;
          $http
            .get(url, {
              responseType: 'arraybuffer',
            })
            .then(function (reponse) {
              var mimeType = pieceDocument.expand.properties['cmis:contentStreamMimeType'].value;
              var blob = new Blob([reponse.data], {
                type: mimeType,
              });

              var documentName =
                _.get(pieceDocument, 'expand.properties.entity:originalfilename.value') ||
                _.get(pieceDocument, 'expand.properties.cmis:name.value');
              saveAs(blob, documentName);
            });
        };

        /**
         * Function who hide alert message
         */
        scope.hideAlertMessage = function () {
          scope.$broadcast('alerts', [], 'piece-form');
        };

        /**
         * Return the path of the icon.
         *
         * @param  {string} fileName File's name
         * @returns {string}          File's icon
         */
        scope.getIconDocument = function (fileName) {
          var iconExtension = 'default';
          var iconsExtensions = [
            'ai',
            'doc',
            'docx',
            'eps',
            'gif',
            'html',
            'jpeg',
            'jpg',
            'mp3',
            'mp4',
            'pdf',
            'png',
            'ppt',
            'pptx',
            'psd',
            'rar',
            'txt',
            'xls',
            'xlsx',
            'xml',
            'zip',
          ];

          var extensionFile = fileService.getExtension(fileName);
          if (extensionFile && _.includes(iconsExtensions, extensionFile)) {
            iconExtension = extensionFile;
          }

          return iconExtension;
        };

        /**
         * Function who display or hide the document's list of a piece
         *
         * @returns {boolean} Display document's list
         */
        scope.displayListDocuments = function () {
          if (scope.piece) {
            scope.piece.displayListDocuments = scope.piece.displayListDocuments === true ? false : true;
          }
        };

        /**
         * Function who format bytes
         *
         * @param  {number} bytes Number of bytes
         * @returns {string}       Bytes formatted
         */
        scope.formatSizeFile = fileService.formatSizeFile;

        /**
         * Remove document from the persistence
         *
         * @param  {modal} modal Modal to close when ok
         * @param  {object} documentPiece Document to remove
         */
        scope.removeDocument = function (modal, documentPiece) {
          // Hide error message if exist
          scope.hideAlertMessage();

          var deleteDocumentOnEntityPiece = function () {
            // Delete item from the list of documents
            scope.piece.documents.splice(_.indexOf(scope.piece.documents, documentPiece), 1);

            modal.$hide();

            setStateClasseOnbtnAddPiece();

            if (scope.validate) {
              scope.validate();
            }
          };

          // if this is invalid, the document has been remove form the GED
          const isExpandValid = !_.isEmpty(_.get(documentPiece, 'expand.properties'));

          // La condition sur entity:uri permet de savoir si le document est référencé comme pièce de type structure.
          if (scope.authorizeDeleteDocumentServer === true && isExpandValid) {
            var query = {};
            switch (documentPiece.rel) {
              case 'cmis':
                query = {
                  method: 'POST',
                  url: documentPiece.id,
                  params: {
                    cmisaction: 'delete',
                  },
                };

                break;
              default:
                query = {
                  method: 'POST',
                  url: scope.urlDocuments,
                  params: {
                    cmisaction: 'delete',
                    objectId: _.get(documentPiece, "expand.properties['cmis:objectId'].value", ''),
                  },
                };

                break;
            }

            $http(query)
              .then(function () {
                deleteDocumentOnEntityPiece();
              })
              .catch(function (error) {
                $log.error(error);
                if (error && error.status === 401) {
                  modal.$broadcast(
                    'alerts',
                    alertsService.getAlertError(scope.viewConfiguration.ns + '.confirmRemoveDocument.unauthorized'),
                    'remove-account-confirm'
                  );
                } else {
                  modal.$broadcast(
                    'alerts',
                    alertsService.getAlertError(scope.viewConfiguration.ns + '.confirmRemoveDocument.error'),
                    'remove-account-confirm'
                  );
                }
              });
          } else {
            deleteDocumentOnEntityPiece();
          }
        };

        /**
         * Update description's document
         *
         * @param  {object} documentPiece Document
         */
        scope.updateDescriptionDocument = function (documentPiece) {
          if (documentPiece.rel === 'cmis') {
            if (scope.beforeUpload) {
              scope.beforeUpload({ updatingDescription: true });
            }

            // using a timeout here is necessary because it allows to detect the click on "follow" button if it's done simultaneously to the blur of the description field
            $timeout(() => {
              let success = false;

              const query = {
                method: 'POST',
                params: {
                  cmisaction: 'update',
                  'propertyId[1]': 'cmis:description',
                  'propertyValue[1]': documentPiece.expand.properties['cmis:description'].value,
                },

                url: documentPiece.id,
              };

              $http(query)
                .then(function () {
                  if (scope.validate) {
                    scope.validate();
                  }
                  success = true;
                })
                .catch(function (err) {
                  $log.error(err);
                  scope.$broadcast(
                    'alerts',
                    alertsService.getAlertError('connected.dashboard.offresStage.pieces.error.miseAJourDocument'),
                    'piece-form'
                  );
                })
                .finally(function () {
                  if (scope.afterUpload) {
                    scope.afterUpload({ updatingDescription: true, success });
                  }
                });
            }, 200);
          }
        };

        /**
         * Display a modal for confirm remove document
         *
         * @param documentPiece
         */
        scope.confirmRemoveDocument = function (documentPiece) {
          var scopeModal = scope.$new();
          scopeModal.documentPiece = documentPiece;
          scopeModal.removeDocument = scope.removeDocument;
          $modal({
            scope: scopeModal,
            viewConfiguration: scope.viewConfiguration,
            template: 'common/common-directives/piece-form/modal/confirm-remove-document.html',
            backdrop: 'static',
          });
        };

        scope.$watch('uploader', function (newValue) {
          if (newValue) {
            // Component who style the upload button - http://markusslima.github.io/bootstrap-filestyle/#Downloads
            $translate.refresh().then(function () {
              angular
                .element(element)
                .find('.piece-form-file')
                .filestyle({
                  input: false,
                  buttonText: scope.buttonText || $translate.instant(scope.viewConfiguration.ns + '.buttonAddLabel'),
                  buttonName: scope.buttonName || 'btn-primary',
                  iconName: scope.buttonIcon || 'fa fa-plus-circle',
                });

              scope.tooltipAddButtonTrue = $tooltip(element.find('.bootstrap-filestyle'), {
                title: $translate.instant(scope.viewConfiguration.ns + '.addButtonTooltip'),
                placement: 'left',
              });

              scope.tooltipAddButtonFalse = $tooltip(element.find('.bootstrap-filestyle'), {
                title: $translate.instant('aides.depotpieces.addButtonTooltipForOneOnlyPiece'),
                placement: 'left',
              });

              setStateClasseOnbtnAddPiece();
            });
          }
        });

        // Configuration component upload file - angular-file-upload  - https://github.com/nervgh/angular-file-upload
        scope.$watch('urlDocuments', function (newValue) {
          if (newValue) {
            // Definition of the uploader
            scope.uploader = new FileUploader({
              scope: scope,
              url: scope.urlDocuments,
              autoUpload: true,
              method: 'POST',
              headers: {
                Authorization: 'Bearer ' + jwtSessionService.jwt(),
                'mg-authentication': true,
              },
            });

            // Filter for fileExtensionsAllowed - Item can be a file object if the browser handles or a html input file element
            scope.uploader.filters.push({
              name: 'filterfileExtensionsAllowed',
              fn: filterFileExtensionsAllowed,
            });

            // Filter for check the size of a file
            scope.uploader.filters.push({
              name: 'filterFileSize',
              fn: filterFileSize,
            });

            // Max files upload
            scope.uploader.filters.push({
              name: 'filterMaxDocumentsAuthorized',
              fn: filterMaxDocumentsAuthorized,
            });

            scope.uploader.onBeforeUploadItem = onBeforeUpload;

            // Recover the list of documents after a document has been uploaded
            scope.uploader.onSuccessItem = onSuccess;

            // Error handling
            scope.uploader.onErrorItem = onError;

            // Progress handling
            scope.uploader.onProgressItem = onProgress;
          }
        });

        scope.$watch('form.attempted', function (newValue) {
          if (newValue === true && !checkValidity()) {
            scope.$broadcast(
              'alerts',
              alertsService.getAlertError(scope.viewConfiguration.ns + '.error.required'),
              'piece-form'
            );
          }
        });

        // Check status of the pieces and display error when the form is send
        scope.$watch(
          'piece',
          function () {
            if (scope.piece) {
              // Define default's values
              scope.piece.obligatoire = scope.piece.obligatoire || false;
              scope.piece.envoiPostal = scope.piece.envoiPostal || false;
              $translate.refresh().then(function () {
                scope.piece.libelle = scope.piece.libelle || {
                  value: $translate.instant(scope.viewConfiguration.ns + '.labels.' + scope.piece.reference),
                };

                scope.piece.description = scope.piece.description || {
                  value: $translate.instant(scope.viewConfiguration.ns + '.descriptions.' + scope.piece.reference),
                };
              });
              scope.piece.displayListDocuments = angular.isDefined(scope.piece.displayListDocuments)
                ? scope.piece.displayListDocuments
                : true;

              scope.model.$setValidity('required', checkValidity());
            }
          },
          true
        );
      },
    };
  },
]);

'use strict';
JustificationInformationsComplementairesController.$inject = ['viewsService', '$scope', '$q'];
angular.module('portailDepotDemandeAide.depot').component('justificationInformationsComplementairesComponent', {
  templateUrl:
    'depot/justification/informations-complementaires/justification-informations-complementaires.component.html',
  controller: /* @ngInject */ JustificationInformationsComplementairesController,
  bindings: {
    workflow: '<',
    justification: '=',
    print: '<',
    validStep: '<',
    disableNavigation: '<',
    saveJustification: '<',
    stepIndex: '<',
  },
});

/**
 *
 * @param viewsService
 * @param $scope
 * @param $q
 */
function JustificationInformationsComplementairesController(viewsService, $scope, $q) {
  var ctrl = this;

  var listeners = [];

  ctrl.$onInit = function () {
    ctrl.readOnly = ctrl.print || false;

    ctrl.iframeSrc = viewsService.getPageViewsIframeUrl({
      entity: ctrl.justification,
      page: 'pageInformationsComplementaires',
      theme: 'demandeur',
      readOnly: ctrl.readOnly,
      topTitleLevel: 5,
      fieldsSelectionParameterName: 'select',
      contextHeaders: {
        'referentiel-tiers': [
          'Accept:application/vnd.mgdis.tiers-3.19.0+json',
          'Content-Type:application/vnd.mgdis.tiers-3.19.0+json',
        ],
      },
    });

    window.addEventListener('message', iframeEventListener);
  };

  /**
   * Method for the justification-step-wizard-navigation to go to the previous step
   */
  ctrl.previousStep = function () {
    ctrl.validViews(true).then(() => ctrl.validStep(-1));
  };

  /**
   * Method for the justification-step-wizard-navigation to go to the next step
   */
  ctrl.nextStep = function () {
    ctrl.validViews().then(ctrl.validStep);
  };

  /**
   * Method to check if the form is valid
   */
  ctrl.isFormValid = function () {
    return $scope.justificationInformationsGeneralesForm && $scope.justificationInformationsGeneralesForm.$valid;
  };

  /**
   * Method to save justification and display success alerts (for validView callback in saveStep)
   */
  ctrl.saveJustificationAndDisplaySuccess = function () {
    ctrl.saveJustification();
  };

  /**
   * Method for the justification-step-wizard-navigation to save this step
   */
  ctrl.saveStep = function () {
    ctrl.validViews(true).then(ctrl.saveJustificationAndDisplaySuccess);
  };

  ctrl.$onDestroy = function () {
    _.forEach(listeners, function (listener) {
      window.removeEventListener('message', listener);
    });
  };

  /**
   *
   * @param msg
   */
  function iframeEventListener(msg) {
    var action = _.get(msg, 'data.action');
    var source = _.get(msg, 'data.source');

    // Update view's datas on current justification
    if (action === 'viewsUpdated') {
      var index = _.get(msg, 'data.index');
      var values = _.get(msg, 'data.values');
      _.set(ctrl, 'justification.views.' + index + '.values', values);
    }

    // Send context to evaluate display's condition
    if (source === 'data-schemas.views' && action === 'ready') {
      // Get iframe
      const viewsIframeInit = ctrl.getViewsIframe();
      if (viewsIframeInit) {
        viewsIframeInit.contentWindow.postMessage(
          {
            action: 'updateContextExpressions',
            contextExpressions: JSON.parse(JSON.stringify(ctrl.justification)),
          },

          '*'
        );

        // resize iframe when ready
        iFrameResize(
          {
            heightCalculationMethod: 'taggedElement',
            checkOrigin: false,
            inPageLinks: true,
          },

          '#iframeInfoComp'
        );
      }
      // add listener in list to be remove on destroy
      listeners.push(iframeEventListener);
    }
  }

  /**
   * Valid data-schema views with events
   *
   * @param {boolean} [ignoreAllFields] option to ignore errors on fields
   * @returns {Promise<void>}
   */
  ctrl.validViews = function (ignoreAllFields = false) {
    const deferred = $q.defer();
    const viewsIframe = ctrl.getViewsIframe();
    if (viewsIframe) {
      // set action
      ctrl.action = () => deferred.resolve();
      // post message
      viewsIframe.contentWindow.postMessage(
        {
          action: 'validViews',
          options: { skipRequiredErrors: ignoreAllFields, showAllErrors: !ignoreAllFields },
        },

        '*'
      );

      // add event listener
      window.addEventListener('message', updateStateViews, false);
      listeners.push(updateStateViews);
    } else {
      deferred.resolve();
    }
    return deferred.promise;
  };

  /**
   * Get views iframe component
   *
   * @returns {object}
   */
  ctrl.getViewsIframe = function () {
    return _.get(angular.element('#iframeInfoComp'), '0', null);
  };

  /**
   *
   * @param msg
   */
  function updateStateViews(msg) {
    viewsService.updateStateViewsJustification($scope, msg, ctrl.action);
  }
}

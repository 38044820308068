('use strict');
angular.module('portailDepotDemandeAide.dashboard').controller('aideRecapitulatifController', [
  '$scope',
  '$rootScope',
  '$modal',
  '$state',
  '$log',
  '$q',
  '$location',
  '$anchorScroll',
  '$timeout',
  '$stateParams',
  '$translate',
  'aide',
  'teleservice',
  'mdm',
  'avisPrealablesService',
  'alertsService',
  'tiers',
  'contributionsService',
  'contributionsFactory',
  'aidesService',
  'contributionsConstants',
  'demandesPaiement',
  'demandesPaiementService',
  'teleservicePaiement',
  'decisions',
  'suiviFinancementService',
  'demandesAidesService',
  'contribution',
  'documentsPubliesService',
  'StoreService',
  'AidesConstant',
  'JustificationsConstant',
  'justificationService',
  'types',
  'documentComptableService',
  'teleservicesService',
  function (
    $scope,
    $rootScope,
    $modal,
    $state,
    $log,
    $q,
    $location,
    $anchorScroll,
    $timeout,
    $stateParams,
    $translate,
    aide,
    teleservice,
    mdm,
    avisPrealablesService,
    alertsService,
    tiers,
    contributionsService,
    contributionsFactory,
    aidesService,
    contributionsConstants,
    demandesPaiement,
    demandesPaiementService,
    teleservicePaiement,
    decisions,
    suiviFinancementService,
    demandesAidesService,
    contribution,
    documentsPubliesService,
    StoreService,
    AidesConstant,
    JustificationsConstant,
    justificationService,
    types,
    documentComptableService,
    teleservicesService
  ) {
    /**
     * Init component
     */
    this.$onInit = () => {
      // Set page title
      const state = $state.$current;
      state.data = $state.$current.data || {};
      state.data.title = aide.teleservice?.title;
      if (aide.referenceAdministrative) state.data.title += ` (${aide.referenceAdministrative})`;

      // With REQUESTED demandes, there is no reference nor PDF recap, so first tab is empty and useless
      if (aide.status === AidesConstant.demandeStatuts.REQUESTED) {
        $scope.hideTabSynthese = true;
        $scope.activeTabIndex = 1;
      } else {
        $scope.hideTabSynthese = false;
        $scope.activeTabIndex = 0;
      }

      $scope.hideTabDocuments = false;
      if ($scope.fromAvisPrealables || $scope.fromAvisCompleted) {
        $scope.hideTabSynthese = true;
        $scope.activeTabIndex = 1;

        $scope.hideTabDocuments = true;
      }

      $scope.isAccessedThroughSharing = aidesService.isAccessedThroughSharing($scope.aide);

      demandesAidesService.getDemandeFromAideAndTiers($scope.aide, $scope.tiers).then((demande) => {
        $scope.demande = demande;

        $scope.showJustificationSection = $scope.aide?.justification?.expand?.history?.events?.some(({ reference }) => {
          return reference === JustificationsConstant.justificationSubStatuts.JUSTIFIED;
        });
      });

      $scope.teleserviceForJustification = justificationService.formatTeleserviceForJustifications(teleservice);

      $scope.processedDCP = _.map(aide.demandesComplementPieces, 'expand').filter(
        ({ avancement }) => avancement !== 'TRANSMIS' // Processed DCP == Not "TRANSMIS"
      );

      $scope.SollicitationsTypes = types.SollicitationsTypes;
    };

    // Modify typeMontant on plan financement if bad due to migration (PLAID-80086)
    const typeMontantTeleservice = _.get(teleservice, 'workflow.simple.pageDocumentComptable.typeMontant');
    const typeMontantPlanFin = _.get(aide, 'planFinancement.0.typeMontant');
    if (typeMontantTeleservice !== typeMontantPlanFin && ['HT', 'TTC'].includes(typeMontantTeleservice)) {
      documentComptableService.changeTypeMontant(aide.planFinancement?.[0], typeMontantTeleservice.toLowerCase());
    }

    var statutsContribution = contributionsConstants.status;
    const { tenantId } = $stateParams;
    $scope.aide = aide;

    const lignePF = suiviFinancementService.getFinancialLine(aide);
    $scope.financementStatut = _.get(lignePF, 'financement.statut');

    if (!_.has(lignePF, 'financement.dispositif.href')) {
      $scope.autorisationDemandesPaiementDemandeur =
        _.get(lignePF, 'financement.teleservicePaiement') &&
        _.get(lignePF, 'financement.autorisationDemandesPaiementDemandeur', false);
    } else {
      demandesPaiementService
        .canCreatePaiement(_.get(lignePF, 'financement.dispositif.href'))
        .then(({ ouvertureDemandePaiementSurDecisionDemandeur, teleservicePaiement }) => {
          $scope.teleservicePaiement = teleservicePaiement;
          $scope.autorisationDemandesPaiementDemandeur =
            _.get(lignePF, 'financement.teleservicePaiement') && ouvertureDemandePaiementSurDecisionDemandeur;
          return demandesPaiementService.canCreateDemandePaiement({
            action: 'POST',
            resource: `/referentiel-financement/api/tenants/${tenantId}/demandes-paiement`,
            environment: {
              body: {
                teleservicePaiement: teleservicePaiement,
                demandeFinancement: {
                  href: $scope.aide.id,
                },
              },
            },
          });
        })
        .then(function (response) {
          $scope.canCreateDemandePaiement = response;
          $scope.displayPaiementBtn =
            $scope.financementStatut === 'VOTE' &&
            $scope.autorisationDemandesPaiementDemandeur &&
            !_.get($scope, 'aide.cloture.value') &&
            $scope.configurationId &&
            $scope.canCreateDemandePaiement &&
            isConnectedWithTiersDemandeur() &&
            teleservicesService.isTSPaiementPublie($scope.teleservicePaiement);
        });
    }

    const ligneFinancement = demandesAidesService.findLigneFinancementDispositifEligible(aide);
    $scope.aide.teleservicePaiement = ligneFinancement?.financement?.teleservicePaiement?.href;
    if ($scope.aide.teleservicePaiement) {
      demandesAidesService.fillTeleservicePaiementInDemande($scope.aide).then(function (result) {
        $scope.aide = result;
      });
    } else {
      $scope.aide = aide;
    }

    // Recovering of the number of documents for demande
    $scope.demandeDocuments = { count: 0, heading: $translate.instant('teleservice.reacapitulatifTabs.documents') };
    documentsPubliesService
      .getDocumentsByDemandeHref(_.get($scope, 'demande.id'))
      .then((data) => {
        $scope.demandeDocuments.count = data.total;
        if (data.total > 0) {
          $scope.demandeDocuments.heading += ' (' + data.total + ')';
        }
      })
      .catch(() => {
        $scope.alertePublication = alertsService.getAlertError('connected.dashboard.documentsReçus.erreur');
      });

    /**
     * Keep the contributions that aren't closed and not answered
     *
     * @param  {Array} contributions
     * @returns {Array}
     */
    const filterOpenedContribution = (contributions = []) =>
      contributions.filter(({ href }) => href === contribution.id);

    const initAideData = () => {
      // The contribution retrieved here is not closed
      $scope.contributionsRedirectionSize = 0;
      $scope.contributionsPourModificationSize = 0;

      if (contribution && contribution.statut !== statutsContribution.ANSWERED) {
        if (contribution.typeContribution === 'REDIRECTION') {
          $scope.contributionsRedirectionSize = filterOpenedContribution($scope.aide.contributionsRedirection).length;
        } else {
          $scope.contributionsPourModificationSize = filterOpenedContribution(
            $scope.aide.contributionsPourModification
          ).length;
        }
      }

      $scope.aide.hasComplements = false;
      $scope.demandeListCollapsed = false;
      $scope.aide.nbComplements = '';
    };

    $scope.tiers = tiers;
    $scope.teleservice = teleservice;
    $scope.mdm = mdm;
    $scope.namespace = 'connected.dashboard.recapitulatif';
    $scope.aideConfiguration = _.get($scope.teleservice, 'aide.informations-generales');
    initAideData();
    $scope.fromAttestations = $stateParams.fromAttestations;
    $scope.fromContribution = $stateParams.fromContribution;
    $scope.fromAvisPrealables = $stateParams.fromAvisPrealables;
    $scope.fromAvis = $stateParams.fromAvis;
    $scope.fromAvisCompleted = $stateParams.fromAvisCompleted;
    $scope.fromAides =
      !$state.params.typeAvis &&
      !$scope.fromContribution &&
      !$scope.fromAttestations &&
      !$scope.fromAvisPrealables &&
      !$scope.fromAvis;
    $scope.statutContribution = $stateParams.statutContribution;
    $scope.demandesPaiement = demandesPaiement || [];
    $scope.demandesLiquide = $scope.demandesPaiement.filter(function (e) {
      return [
        'MANDATE',
        'MANDATE_PARTIELLEMENT',
        'PAYE',
        'PAYE_PARTIELLEMENT',
        'LIQUIDE',
        'LIQUIDE_PARTIELLEMENT',
      ].includes(e.statut);
    });

    // Do not display EN_COURS demandes from other demande or beneficiaire
    $scope.demandesPaiement = _.reduce(
      $scope.demandesPaiement,
      (acc, demande) => {
        if (_.get($rootScope, 'currentUser.self') !== _.get(demande, '_user.href') && demande.statut === 'EN_COURS') {
          return acc;
        }
        demande.tiersAuthorized = _.get($rootScope, 'currentUser.self') === _.get(demande, '_user.href');
        return acc.concat(demande);
      },
      []
    );

    /**
     * Get demandes paiement to display
     *
     * @returns {object[]} Demandes paiement to display
     */
    $scope.displayedDemandesPaiement = function () {
      return demandesPaiementService.getVisiblesDemandesPaiement($scope.demandesPaiement);
    };

    // format demandes paiement to display it with the correct informations
    $scope.paiements = $scope.demandesPaiement.reduce((acc, demandePaiement) => {
      acc[demandePaiement.reference] = {
        montant: demandesPaiementService.getMontantDemande(demandePaiement),
        dateCreation: demandePaiement?.history?.begin?.date,
        liquidations: demandePaiement?.paiement?.liquidations?.filter((liquidation) => !liquidation.dateRejetFinancier),
      };
      return acc;
    }, {});

    // check if can create paiement request
    demandesPaiementService
      .canCreateDemandePaiement({
        action: 'POST',
        resource: '/referentiel-financement/api/tenants/' + $stateParams.tenantId + '/demandes-paiement',
        environment: {
          body: {
            teleservicePaiement: {
              href: $scope.aide.teleservicePaiement,
            },

            demandeFinancement: {
              href: $scope.aide.id,
            },
          },
        },
      })
      .then(function (response) {
        $scope.canCreateDemandePaiement = response;
      });

    $scope.displayBtnDemandesPaiements = function () {
      return (
        $scope.financementStatut === 'VOTE' &&
        $scope.autorisationDemandesPaiementDemandeur &&
        !_.get($scope, 'aide.cloture.value') &&
        $scope.configurationId &&
        $scope.canCreateDemandePaiement
      );
    };

    /**
     * Return the remaining to be asked
     *
     * @returns {number} remaining amount to ask
     */
    $scope.getMontantRestantADemander = function () {
      return demandesPaiementService.getMontantRestantADemander($scope.demandesPaiement, $scope.decisions);
    };

    /**
     * Return the remaining to be paid on the voted amount
     *
     * @returns {number} remaining montant to pay
     */
    $scope.getMontantRestantAPayer = function () {
      return demandesPaiementService.getMontantRestantAPayer($scope.demandesLiquide, $scope.decisions);
    };

    /**
     * Get autorisation payment demand from plan financement
     *
     * @param demande
     */
    $scope.autorisationDemandePaiement = function (demande) {
      const lignePF = suiviFinancementService.getFinancialLine(demande);
      return _.get(lignePF, 'financement.autorisationDemandesPaiement', false);
    };

    // Pagination
    $scope.pagerConfig = {
      totalItems: $scope.demandesPaiement.length,
      itemsPerPage: 10,
      maxSize: 5, //Number of pager buttons to show
      currentPage: 1,
    };

    $scope.decisions = decisions;

    /**
     * calculate montant for display
     */
    $scope.montantRestantADemander = $scope.getMontantRestantADemander();
    $scope.displayMontantRestantADemander = typeof $scope.montantRestantADemander === 'number';
    $scope.montantRestantAPayer = $scope.getMontantRestantAPayer();
    $scope.displayMontantRestantAPayer = typeof $scope.montantRestantAPayer === 'number';

    /**
     * Checks if there is still an amount to request on the voted amount
     *
     * @returns {boolean} true when there's an amount to request
     */
    $scope.hasARemainingAmountToAsk = function () {
      return $scope.montantRestantADemander > 0;
    };

    if ($scope.aide.multiFinanceur) {
      $scope.financeur = _.find($scope.aide.multiFinancement.financeurs, ['href', $stateParams.financeur]);
    }
    $scope.configurationId = teleservicePaiement ? teleservicePaiement.reference : null;
    $scope.teleservicePaiement = teleservicePaiement;
    if (_.get(aide, 'demandesComplementPieces.length') > 0) {
      $scope.aide.hasComplements = true;
      $scope.aide.nbComplements = _.get(aide, 'demandesComplementPieces.length');
    }
    if ($stateParams.fromEchange) {
      $timeout(function () {
        $rootScope.$broadcast('showAsideEchange');
      }, 2000);
    }

    // Attestation over contribution MODIFICATION and REDIRECTION
    if ($scope.fromAttestations) {
      $scope.attestationContribution = {
        active: !!($scope.contributionsPourModificationSize || $scope.contributionsRedirectionSize),
      };

      if ($scope.attestationContribution.active) {
        if (contribution && ['MODIFICATION', 'REDIRECTION'].includes(contribution.typeContribution)) {
          $scope.attestationContribution.contribution = contribution;
        }
      }
    }

    /********************************************************************************************************
     * ENUMS *                                                                       *                      *
     * We write them into the code because they doesn't exists (only in the contract)                        *
     * http://gitlab.mgdis.fr/contract/referentiel-aide/blob/master/aide-noAdditionalProperties.json    *
     ********************************************************************************************************/
    var avisEnum = {
      FAVORABLE: 'FAVORABLE',
      DEFAVORABLE: 'DEFAVORABLE',
    };

    //Avis - Cast to select-field format
    //We code the values, cause we don't have it in a referentiel.
    $scope.listeTypeAvis = [
      {
        expand: {
          reference: avisEnum.FAVORABLE,
          libelle: {
            value: 'Favorable',
          },
        },
      },

      {
        expand: {
          reference: avisEnum.DEFAVORABLE,
          libelle: {
            value: 'Défavorable',
          },
        },
      },
    ];

    // Vérification que notre tiers demandeur est lié à un avis sur l'aide en cours.
    // (et donc que notre tiers est désigné comme "Contributeur pour avis préalable sur la demande")
    // Cette vérification nous permettra d'afficher ou non le bouton
    // indiquant que l'on souhaite déposer un avis
    // on test aussi le fait qu'un avis préalable n'a pas encore été donné
    $scope.displayBtnGiveOpinion =
      !_.isEmpty(avisPrealablesService.getAvisPrealableTiers(aide.avisPrealables, tiers)) &&
      _.isEmpty(_.get(aide, 'avisPrealables[0].avis'));

    // Pop-up 'Donner un avis'
    $scope.giveOpinion = function () {
      var scopeModal = $scope.$new();
      scopeModal.item = {};
      scopeModal.validOpinion = $scope.validOpinion;
      scopeModal.viewConfiguration = {
        ns: 'connected.dashboard.contributionsAvisPrealable.avis.recapitulatif.modal',
        fields: {
          typeAvis: {
            required: true,
            labelWidth: 3,
          },

          commentaire: {
            required: true,
            labelWidth: 3,
          },
        },
      };

      $modal({
        scope: scopeModal,
        template: 'dashboard/recapitulatif/modal/modal-give-opinion.html',
      });
    };

    $scope.validOpinion = function (modal, item) {
      $scope.avis = item.avis.expand.reference;

      $scope.avisPrealables = avisPrealablesService.getAvisPrealableTiers(aide.avisPrealables, tiers);
      const index = avisPrealablesService.getIndexAvisPrealableTiers(aide.avisPrealables, tiers);

      $scope.avisPrealables.avis = $scope.avis;
      $scope.avisPrealables.date = new Date().toISOString();
      $scope.avisPrealables.commentaire = modal.commentaire;
      $scope.avisPrealables.user = {
        href: $rootScope.currentUser.self,
        title: $rootScope.currentUser.displayName,
      };

      const demandeur = _.get(aide, 'demandeur.expand');
      const teleservice = $scope.teleservice;

      return (
        aidesService
          // if needed, we make the prise-en-charge-auto
          .priseEnChargeAuto(demandeur, teleservice, tenantId)
          .then(() => avisPrealablesService.patchAvisPrealable($scope.aide, $scope.avisPrealables, index))
          .then(() => {
            // Enregistrement OK
            $scope.displayBtnGiveOpinion = _.isEmpty(_.get(aide, 'avisPrealables[0].avis'));
            $scope.displayOpinion = !_.isEmpty(_.get(aide, 'avisPrealables[0].avis'));
          })
          .catch((error) => {
            if (error && error.status === 401) {
              // Redirect to to login screen
              $state.go('app.home', {
                loggedOut: true,
              });
            } else {
              if (error.data.message) {
                $log.error(error.data.message);
                $scope.$broadcast('alerts', alertsService.getAlertError('Une erreur est survenue. ' + error.data));
              }
            }
          })
          .finally(() => {
            modal.$hide();
            $rootScope.$broadcast('refresh-menu');
          })
      );
    };

    const isAideContainsAvisPrealable = aide?.avisPrealables?.some((elt) => elt.avis?.length > 0);
    $scope.displayOpinion = $scope.fromAvisPrealables || $scope.fromAvisCompleted || isAideContainsAvisPrealable;

    // Contribution
    $scope.replyToContribution = function () {
      if ($scope.fromContribution) {
        contributionsService.get($scope.fromContribution).then(function (contribution) {
          var teleserviceHref = _.last(_.get(contribution, 'teleservice.href', '').split('/'));

          if (teleserviceHref) {
            $state.go('app.connected.config.depot.contributions', {
              configurationId: teleserviceHref, // téléservice de contribution
              contributionRef: $scope.fromContribution,
            });
          }
        });
      }
    };

    /**
     * Check display condition of decision screen
     *
     * @returns {boolean} true if all conditions are met
     */
    $scope.displayDecisionScreen = function () {
      // Fast return if the decisions are empty
      if (!$scope.decisions.length) {
        return false;
      }
      // If one decision is favorable and has not been canceled, display the screen
      const hasOneDecisionFavorableAndNotCanceled = $scope.decisions.some(
        (e) => !e?.cancelation && e.avis === 'FAVORABLE'
      );

      return hasOneDecisionFavorableAndNotCanceled;
    };

    /**
     * Check display condition of paiement list
     *
     * @returns {boolean} true if all conditions are met
     */

    $scope.displayPaiementList = function () {
      // When there is an endorsment or not, if one decision exists the total amount to pay is displayed
      // Fast return if the decisions are empty
      if ($scope.decisions.length === 0) return false;

      // When the decision is voted and canceled, check if all decisions are canceled
      // If all decisions are canceled the total amount to pay is hidden
      const isDecisionCanceled = $scope.decisions.every((decision) => decision.cancelation);

      return (
        $scope.aide.status === 'ACCEPTABLE' &&
        ['SOLDE', 'VOTE', 'INSTRUCTION', 'FININSTRUCTION'].includes($scope.financementStatut) &&
        // Hide if multi-dossiers
        !(($scope.aide.dossiersFinancement || []).length > 1) &&
        !isDecisionCanceled
      );
    };

    //Méthode de modification de la demande : elle lancera un PATCH sur la demande de financement
    // pour modifier le user en mettant à la place l'utilisateur courant et repasser le statut à
    // 'REQUESTED' ensuite il se rendra sur le preambule de la demande de financement
    $scope.modifyAide = function () {
      var patches = [
        {
          op: 'replace',
          path: '/status',
          value: 'REQUESTED',
        },

        {
          op: 'replace',
          path: '/history/begin/metadata/step',
          value: 'preambule',
        },

        {
          op: 'replace',
          path: '/user',
          value: {
            href: $rootScope.currentUser.self,
            title: $rootScope.currentUser.displayName,
            rel: 'user',
            organization: $stateParams.tenantId + '-demandeurs',
            organizationLabel: $stateParams.tenantId + '-demandeurs',
          },
        },
      ];

      if ($scope.aide.status === statutsContribution.WAITING_FOR_CERTIFICATE) {
        // Dans le cas des demandes au statut WAITING_FOR_CERTIFICATE on modifie le créateur de la demande
        var newCreator = {
          href: $rootScope.currentUser.self,
          method: 'GET',
          title: $rootScope.currentUser.displayName,
          rel: 'mgs:creator',
        };

        patches.push({
          op: 'add',
          path: '/history/begin/user',
          value: newCreator,
        });
      }
      aidesService.patchAide($scope.aide, patches).then(function () {
        $state.go('app.connected.config.depot.simple', {
          p: $scope.aide.reference,
          configurationId: $scope.aide.teleservice.href.split('/').pop(),
        });
      });
    };

    // Modification de la contribution pour modification
    $scope.modifyContribution = function () {
      var pagesARevoir = contributionsFactory.pagesARevoirToHistoryStep(
        _.get($scope.attestationContribution, 'contribution.pagesARevoir', [])
      );

      // Patch contribution
      var patchesContributions = [
        {
          op: 'replace',
          path: '/statut',
          value: 'INPROGRESS',
        },

        {
          op: 'replace',
          path: '/contact',
          value: {
            href: $rootScope.currentUser.self,
            title: $rootScope.currentUser.displayName,
          },
        },

        {
          op: 'replace',
          path: '/history/begin/metadata',
          value: {
            step: pagesARevoir[0] || 'preambule',
            stepsStack: [],
            stepsVisited: [],
          },
        },
      ];

      const runningRequest = [
        contributionsService.patch(
          _.get($scope.attestationContribution, 'contribution.reference'),
          patchesContributions
        ),

        // Patch aide (status goes back to supported)
        aidesService.patchAide($scope.aide, [
          {
            op: 'replace',
            path: '/status',
            value: 'SUPPORTED',
          },
        ]),
      ];

      $q.all(runningRequest).then(() => {
        // Go to depot contribution page
        const options = {
          reload: true,
        };

        if (contribution.typeContribution === 'MODIFICATION') {
          this.$state.go(
            'app.connected.config.depot.contributionModification',
            {
              configurationId: $scope.aide.teleservice.href.split('/').pop(),
              p: $scope.aide.reference,
              c: $scope.attestationContribution.contribution.reference,
            },

            options
          );
        } else if (contribution.typeContribution === 'REDIRECTION') {
          this.$state.go(
            'app.connected.config.depot.contributionRedirection',
            {
              configurationId: contribution.teleservice.href.split('/').pop(),
              p: $scope.aide.reference,
              c: $scope.attestationContribution.contribution.reference,
            },

            options
          );
        }
      });
    };

    if (_.get($scope, 'aide.demandesReport.length')) {
      $scope.demandesReportIframeSrc = aidesService.generateDemandeReportTableIframeSrc($scope.aide.reference);

      $timeout(() => {
        iFrameResize(
          {
            heightCalculationMethod: 'taggedElement',
            checkOrigin: false,
            inPageLinks: true,
          },

          '#demandesReportIframe'
        );
      }, 0);
    }

    $scope.displayBtnCreateContributionAvisFinancement = function () {
      return (
        $scope.fromContribution &&
        !$scope.fromAvisPrealables &&
        !$scope.fromAvisCompleted &&
        $scope.statutContribution !== 'ANSWERED'
      );
    };

    /**
     * Affichage d'une fenêtre modale demandant la confirmation de la suppression d'une demande
     *
     * @param demandePaiement
     */
    $scope.deleteDemandePaiement = function (demandePaiement) {
      var scopeModal = $scope.$new();
      scopeModal.demandePaiement = demandePaiement;

      $modal({
        scope: scopeModal,
        template: 'dashboard/aides/demandes-paiement/modal/modal-delete-demande.html',
      });
    };

    /**
     * Suppression d'une demande
     *
     * @param  {object} modal       Modal to hide after the persistence has been deleted
     * @param  {object} demandePaiement Demande paiement
     */
    $scope.confirmDeleteDemande = function (modal, demandePaiement) {
      modal.$hide();

      demandesPaiementService
        .removeDemandePaiement(demandePaiement)
        .then(function () {
          // Supprimer l'élément de la liste sans recharger pour autant.
          $scope.demandesPaiement = _.filter($scope.demandesPaiement, function (p) {
            return demandePaiement.reference !== p.reference;
          });

          $scope.alerts = alertsService.getAlertSuccess(
            $translate.instant('connected.dashboard.aides.demandesAides.deleted')
          );
        })
        .catch(function (error) {
          $scope.alerts = alertsService.getAlertError(`${$translate.instant('common.error.happened')} ${error.data}`);
        });
    };

    $scope.goToAvisPrealable = function () {
      $scope.activeTabIndex = 1;
      $timeout(function () {
        $location.hash('tcRecapAvisPrealable');
        $anchorScroll();
      }, 0);
    };

    $scope.goToAvis = function () {
      $state.go('app.connected.dashboard.contributions.recapitulatif', {
        referenceContribution: $scope.fromContribution,
      });
    };

    /**
     * Check if actions requises should be displayed
     *
     * @returns {boolean}
     */
    $scope.displayActionsRequises = () => {
      const mayDisplayActionsRequises = isConnectedWithTiersDemandeur();
      const hasActionsRequises =
        $scope.aide.hasComplements || $scope.contributionsPourModificationSize || $scope.contributionsRedirectionSize;
      return mayDisplayActionsRequises && hasActionsRequises;
    };

    /**
     * Check if the user is connected with the tiers demandeur of the demande
     *
     * @returns {boolean} true if the selected tiers is the demandeur of the demande
     */
    function isConnectedWithTiersDemandeur() {
      const currentTiersReference = StoreService.currentTiersRef.get();
      const tiersDemandeurReference = aide?.demandeur?.expand?.reference;
      return currentTiersReference === tiersDemandeurReference;
    }

    /**
     * Display date dcp depending on status
     *
     * @param {object} demandeComplement
     * @returns {string} date
     */
    $scope.getDateAffichage = function (demandeComplement = {}) {
      const { avancement, dateReponse, dateCloture, dateDemande } = demandeComplement;

      if (avancement === AidesConstant.demandeComplementPieces.avancement.REPONSE_DEMANDEUR) {
        return dateReponse;
      }
      if (avancement === AidesConstant.demandeComplementPieces.avancement.CLOTURE) {
        return dateCloture;
      }

      return dateDemande;
    };
  },
]);

'use strict';
abonnementsController.$inject = ['$scope', '$rootScope', 'abonnementService'];
/**
 * @ngdoc component
 * @module components.abonnements
 * @name abonnementsComponent
 * @description
 *
 *   Display a list of agreements (abonnement)
 *
 *   The criteres parameter will be completed with a 'value' key for each item.
 * @param {object} tenantId - tenantId
 * @param {list} listeAbonnement - list of abonnement to display
 * @param {boolean} withValidateUserLines - also displays the user's lines with a validation date
 *              true: displays all the user's lines (even with a validation date)
 *              false: displays only the user's lines without a validation date (after CGU modal)
 * @example
 *
 *    <abonnements-component tenant-id="tenantId" liste-abonnement="listeAbonnement" with-validate-user-lines="true">
 *    </abonnements-component>
 */
angular.module('components.abonnements').component('abonnementsComponent', {
  templateUrl: 'agreements/abonnements/abonnements.html',
  controller: abonnementsController,
  bindings: {
    tenantId: '<',
    listeAbonnement: '=',
    listeAbonnementStatic: '=?',
    withValidateUserLines: '<',
  },
});

abonnementsController.$inject = ['$scope', '$rootScope', 'abonnementService'];

/**
 *
 * @param $scope
 * @param $rootScope
 * @param abonnementService
 */
function abonnementsController($scope, $rootScope, abonnementService) {
  'use strict';

  var ctrl = this;

  ctrl.$onInit = function () {
    abonnementService.getList(ctrl.tenantId).then(function (list) {
      // Récupération des abonnements 'actif' et date d'activation antérieure ou égale à la date du jour
      ctrl.listeAbonnement = _.filter(list, function (abonnement) {
        return abonnement.actif && moment(abonnement.dateActivation).isSameOrBefore(new Date(), 'day');
      });

      // Récupération des abonnements du user ayant une date de validation
      var listAgreementUser = _.get($rootScope, 'currentUser.agreements');
      var listUserAbonnement = _.filter(listAgreementUser, function (abonnement) {
        return abonnement.type === 'ABONNEMENT' && abonnement.dateValidation;
      });

      if (!_.isNil(listUserAbonnement)) {
        // Mise à jour de la liste avec le paramètre 'checked' des abonnements du user
        if (ctrl.withValidateUserLines) {
          _.forEach(ctrl.listeAbonnement, function (abonnement) {
            _.forEach(listUserAbonnement, function (userAbonnement) {
              if (abonnement.id === userAbonnement.id) {
                abonnement.checked = userAbonnement.checked;
              }
            });
          });
        }
        // Exclusion des abonnements déjà validés par le user
        else {
          ctrl.listeAbonnement = _.filter(ctrl.listeAbonnement, function (abonnement) {
            return !_.find(listUserAbonnement, function (userAbonnement) {
              return userAbonnement.id === abonnement.id && userAbonnement.dateValidation;
            });
          });
        }
        // Copie de la liste des abonnements pour ne pas la modifier lorsqu'on valide le changement des autorisations
        if (ctrl.listeAbonnement.length > 0) {
          ctrl.listeAbonnementStatic = angular.copy(ctrl.listeAbonnement);
        }
      }
    });
  };
}

'use strict';
DepotSimpleDocumentComptableController.$inject = [
  '$modal',
  '$scope',
  '$rootScope',
  '$log',
  '$translate',
  'aidesService',
  'planFinancementUtils',
  'planFinancementService',
  'contributionsFactory',
];
angular
  .module('portailDepotDemandeAide.depot')
  .controller('depotSimpleDocumentComptableController', DepotSimpleDocumentComptableController);

DepotSimpleDocumentComptableController.$inject = [
  '$modal',
  '$scope',
  '$rootScope',
  '$log',
  '$translate',
  'aidesService',
  'planFinancementUtils',
  'planFinancementService',
  'contributionsFactory',
  'documentComptableService',
];

/**
 *
 * @param $modal
 * @param $scope
 * @param $rootScope
 * @param $log
 * @param $translate
 * @param aidesService
 * @param planFinancementUtils
 * @param planFinancementService
 * @param contributionsFactory
 * @param documentComptableService
 */
function DepotSimpleDocumentComptableController(
  $modal,
  $scope,
  $rootScope,
  $log,
  $translate,
  aidesService,
  planFinancementUtils,
  planFinancementService,
  contributionsFactory,
  documentComptableService
) {
  'use strict';

  $scope.cleanNavigate();
  $scope.navigate.ns = $scope.documentComptableConfiguration.ns;
  $scope.documentComptableConfiguration.showErrors = $scope.showErrorsOnNavigate();
  $scope.navigate.metadata = _.get($scope, 'aide.history.begin.metadata.stepMetadata');
  // PF is required if at least one poste is required
  this.isPlanFinancementRequired = !!JSONPath(
    '$..lignes[?(@.obligatoire == true)]',
    _.get($scope.teleserviceConfiguration, 'workflow.pageDocumentComptable.typeDocumentComptable.planFinancement', [])
  ).length;
  // Sur un changement de type de montant (cas du CHOIX_UTILISATEUR)
  $scope.$watch('$ctrl.typeMontant', (newValue, oldValue) => {
    // Change type montant on all plan de financement
    if (newValue !== oldValue) {
      // When the user selects a type of montant : we remove the montants that haven't the selected type
      this.cleanContributionRedirectionMontants(oldValue, newValue);

      $scope.aide.planFinancement.forEach((planFinancement) => {
        documentComptableService.changeTypeMontant(planFinancement, newValue);
      });
    }
  });

  // set required property for typeMontant field
  _.set($scope.documentComptableConfiguration, 'fields.typeMontant.required', this.isPlanFinancementRequired);
  this.configuration = _.cloneDeep($scope.documentComptableConfiguration);
  this.isInContribution = contributionsFactory.getContribution();
  this.teleserviceConfiguration = $scope.teleserviceConfiguration; //? auto inherited from the parent scope by angular
  this.pageOptions = this.teleserviceConfiguration.workflow.pageDocumentComptable;

  this.postesPlanFinancement = JSONPath({
    json: _.get(this.teleserviceConfiguration, 'workflow.pageDocumentComptable.typeDocumentComptable.planFinancement'),
    path: '$..lignes[*]',
  });

  // Recherche du type de montant déjà saisi (ht ou ttc) sur le plan de financement

  const firstPlanFinancement = aidesService.getFirstPlanFinancement($scope.aide);

  /**
   * check if it's the first year of planFinancement
   *
   * @param {object} planFinancement
   * @returns {boolean} true if we're on the first year
   */
  this.isPfFirstYear = function (planFinancement) {
    const firstYear = aidesService.getFirstYearOfPf($scope.aide.planFinancement);
    const currentYear = _.get(planFinancement, 'periode.exercice', 0);
    return firstYear === currentYear;
  };

  // Type montant based on teleservice setting
  if (this.pageOptions.typeMontant && ['HT', 'TTC'].includes(this.pageOptions.typeMontant.toUpperCase())) {
    this.typeMontant = this.pageOptions.typeMontant;
  }
  // Type montant based on planFinancement user choice only if any montant is set
  else if (
    firstPlanFinancement.typeMontant &&
    (_.has(firstPlanFinancement, 'depense.montant.total') || _.has(firstPlanFinancement, 'recette.montant.total'))
  ) {
    this.typeMontant = firstPlanFinancement.typeMontant;
  }
  // backward compatibility
  else {
    this.typeMontant = planFinancementService.getTypeMontant(firstPlanFinancement);
  }

  if (this.typeMontant) {
    this.typeMontant = this.typeMontant.toLowerCase();
    this.typeMontantUI = this.typeMontant;
  }

  /**
   * check planFinancement's multi-financeur rules
   *
   * @param {object} planFinancement current plan financement
   * @returns {boolean} true if there's an error on planFinancement
   */
  this.checkPlanFinancement = function checkPlanFinancement(planFinancement) {
    const MULTI_FIN_PARTAGE = 'PARTAGE';
    const errorMessageMultipleFinanceur = $translate.instant(
      'teleservice.document-comptable.recette.controleMontant.errorMultipleFinanceur'
    );

    const errorMessageTs = this.teleserviceConfiguration.workflow.pageDocumentComptable.avertissementMontant;

    // get plans financement options
    const isFirstYear = this.isPfFirstYear(planFinancement);
    const allowedNegativeAmounts = _.get(
      $scope,
      'teleserviceConfiguration.workflow.pageDocumentComptable.autoriserMontantNegatif'
    );

    // retrieve multifinanceur configurations
    const typeMultiFin = $scope.aide.multiFinanceur && $scope.aide.optionsMultiFinanceur.modePreInstruction;
    const isFinanceurPrincipal = _.get(
      this.teleserviceConfiguration,
      'multiFinanceur.options.financeurPrincipal',
      false
    );

    // get recette's postes
    const postes = planFinancement.recette && planFinancement.recette.postes;

    // don't check this rule if we're in contribution or if we're not on aide's first year
    if (!this.isInContribution && isFirstYear) {
      const hasEmptyMultipleFinanceurLine = planFinancementUtils.checkPostesMultipleFinanceur(
        postes,
        allowedNegativeAmounts
      );

      if (hasEmptyMultipleFinanceurLine) {
        this.showErrorModal(errorMessageMultipleFinanceur);

        // don't need to check other rules if there's already an error here
        return true;
      }
    }

    // now we check multi-fin rules
    if (typeMultiFin) {
      // get all lines with a financeur
      const lignesWithFinanceur = planFinancementUtils.extractFinanceursFromPostes(postes);

      const isMultiPartage = typeMultiFin === MULTI_FIN_PARTAGE;

      const typeFinanceurToCheck = isMultiPartage ? 'FINANCEURPRIVILEGIE' : 'ORGANISATION';

      // first check if at least one ligne with financeur is filled with an amount > 0
      const hasEmptyFinanceurs = planFinancementUtils.checkFinanceur(
        lignesWithFinanceur,
        allowedNegativeAmounts,
        typeFinanceurToCheck
      );

      // show error if no financeur filled
      if (hasEmptyFinanceurs) {
        this.showErrorModal(errorMessageTs);
        return true;
      }

      // mode PARTAGE
      if (isMultiPartage && isFinanceurPrincipal) {
        const financeurPrincipalReference = _.get(this.teleserviceConfiguration, 'workflow.pageFinanceur.posteRecette');

        const [sousPosteFinanceurPrincipal] = JSONPath(
          `$.[?(@.sousPostes)].sousPostes[?(@.reference == "${financeurPrincipalReference}")]`,
          postes
        );

        // fast return if there's no financeurPrincipal
        if (!sousPosteFinanceurPrincipal || _.isEmpty(sousPosteFinanceurPrincipal.lignes)) {
          this.showErrorModal(errorMessageTs);
          return true;
        }

        const hasEmptyFinanceurPrincipal = planFinancementUtils.checkFinanceur(
          sousPosteFinanceurPrincipal.lignes,
          allowedNegativeAmounts
        );

        // throw error if financeurPrincipal is not filled
        if (hasEmptyFinanceurPrincipal) {
          this.showErrorModal(errorMessageTs);
          return true;
        }
      }
    }

    return false;
  };

  this.showErrorModal = function showErrorModal(message) {
    if (!message || typeof message !== 'string') throw new Error('showErrorModal - message must be a non empty string');
    $scope.modalMessage = message;
    $modal({
      scope: $scope,
      backdrop: 'static',
      template: 'depot/simple/document-comptable/modals/error-modal.html',
    });
  };

  /**
   * Permet de passer à l'etape suivante,
   * si la demande contient une durée de plan de financement on ne change
   * pas vraiment d'étape, on change simplement les metadonnées de l'etape
   * pour aller sur le plan de financement suivant.
   *
   * @param {boolean} forget Indique que l'on supprime cette étapge de l'historique
   * si la page n'est pas actif.
   */
  const confirmNext = (forget) => {
    const workflowMetadata = $scope.aide.history.begin.metadata;
    let newStepMetadata;
    if (_.get($scope, 'aide.duree') > 0) {
      if (
        !workflowMetadata.stepMetadata ||
        workflowMetadata.stepMetadata.exercice < _.get($scope, 'aide.exerciceBudgetaire') + $scope.aide.duree - 1
      ) {
        if (!workflowMetadata.stepMetadata) {
          newStepMetadata = {
            exercice: $scope.aide.exerciceBudgetaire + 1,
          };
        } else {
          newStepMetadata = {
            exercice: workflowMetadata.stepMetadata.exercice + 1,
          };
        }
        $scope.goToStepFn('document-comptable', newStepMetadata)();
        $rootScope.isPFLoading = true;
        $scope.navigate.metadata = workflowMetadata.stepMetadata;
      } else {
        _.unset($scope, 'navigate.metadata');
        $scope.goToStepFn('minimis')(forget);
      }
    } else {
      _.unset($scope, 'navigate.metadata');
      $scope.goToStepFn('minimis')(forget);
    }
  };

  const checkBalanceAndConfirmNext = (depense, recette) => {
    if (!this.pageOptions.equilibre || depense === recette) {
      confirmNext();
    } else {
      $scope.confirmNext = confirmNext;
      $modal({
        scope: $scope,
        template: 'depot/simple/document-comptable/modals/depot-simple-document-comptable-equilibre-nok.html',
      });
    }
  };

  $scope.navigate.next = () => {
    let planFinancement;
    if (aidesService.isCPO($scope.aide)) {
      // if multiyear, get the currently edited funding plan
      // (navigate.metadata.exercice is null on first funding plan then contains the financial year
      // of the currently edited funding plan)
      planFinancement = aidesService.getCurrentPlanFinancement($scope.aide);
    } else {
      planFinancement = _.get($scope.aide, 'planFinancement[0]');
    }
    const depense = _.get(planFinancement, 'depense.montant.total');
    const recette = _.get(planFinancement, 'recette.montant.total');
    if (!planFinancement || _.get(planFinancement, 'periode.invalid')) {
      if (_.get(planFinancement, 'periode.noData') === true) {
        _.set(planFinancement, 'periode.invalidSubmitted', true);
      }
      return;
    }

    try {
      const hasError = this.checkPlanFinancement(planFinancement);
      if (hasError) return; //! if an error modal was displayed while checking the plan de financement there is no need to continue
      checkBalanceAndConfirmNext(depense, recette); // Balance is checked only if no error occurred before
    } catch (error) {
      $log.error(error);
      const errorMessage = this.teleserviceConfiguration.workflow.pageDocumentComptable.avertissementMontant;
      this.showErrorModal(errorMessage);
    }
  };

  /**
   * Clean the montants of the planFinancement if contributionType is REDIRECTION,
   * previous typeMontant was MIXTE and is now CHOIX_UTILISATEUR
   *
   * @param  {string} oldType
   * @param  {string} newType
   * @returns {void}
   */
  this.cleanContributionRedirectionMontants = (oldType, newType) => {
    const isInContributionRedirection = _.get(this.isInContribution, 'typeContribution');
    if (isInContributionRedirection !== 'REDIRECTION') return;

    // special case of contribution REDIRECTION, MIXTE was enable, but CHOIX_UTILISATEUR is enabled on the new telervice
    const mixteOnPreviousTeleservice = oldType === 'mixte' && this.pageOptions.typeMontant === 'CHOIX_UTILISATEUR';
    if (!mixteOnPreviousTeleservice) return;

    const lignesMontants =
      JSONPath({
        json: $scope.aide.planFinancement,
        path: '$..lignes[?(@.montant || @.montantDemande || @.montantSubventionnable)]',
      }) || [];

    lignesMontants.forEach(({ montant, montantDemande, montantSubventionnable }) => {
      if (!['ht', 'ttc'].includes(newType)) return;
      const typeToDelete = newType === 'ht' ? 'ttc' : 'ht';

      if (montant) delete montant[typeToDelete];
      if (montantDemande) delete montantDemande[typeToDelete];
      if (montantSubventionnable) delete montantSubventionnable[typeToDelete];
    });
  };

  /**
   * Triggered when user changes the type of montant
   */
  this.handleTypeMontantChange = function () {
    const oldValue = this.typeMontant ? this.typeMontant.toLowerCase() : null;
    const newValue = this.typeMontantUI ? this.typeMontantUI.toLowerCase() : null;

    const onConfirm = () => {
      this.typeMontantUI = newValue;
      this.typeMontant = newValue;
    };

    // If we change from MIXTE to other, we display a modal
    // to alert the user that he will lose his changes
    if (oldValue === 'mixte') {
      // Reset the typeMontantUI to the old value
      // until the modal is not confirmed
      // If it's not in a setTimeout, the UI is not udpated
      setTimeout(() => (this.typeMontantUI = oldValue));

      const scopeModal = $scope.$new();
      scopeModal.onConfirm = onConfirm;
      $modal({
        scope: scopeModal,
        template: 'depot/simple/document-comptable/modals/warning-type-montant.html',
      });
    } else {
      onConfirm();
    }
  };

  $scope.$watch('aide.history.begin.metadata.stepMetadata.exercice', () => {
    _.set(this.configuration, 'fields.typeMontant.required', this.isRequired());
    this.configuration = _.cloneDeep(this.configuration);
  });

  this.isReadOnly = function () {
    return !_.isNil(_.get($scope, 'navigate.metadata.exercice'));
  };

  this.isRequired = function () {
    return (
      !$scope.documentComptableConfiguration.fields.typeMontant.hidden &&
      !$scope.documentComptableConfiguration.remoteValidation &&
      $scope.documentComptableConfiguration.fields.typeMontant.required &&
      !this.isReadOnly()
    );
  };

  // If the page is hidden directly navigate to next page and forget this one in the history
  if (this.pageOptions.actif) {
    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'dossier';
  } else {
    confirmNext(true);
  }
}

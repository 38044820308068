'use strict';

/**
 * @param {any} Tiers
 * @param {any} Coordonnees
 * @param {any} tiersService
 * @param {any} tiersFacts
 * @param {any} $log
 * @ngdoc directive
 * @name tiers.directive:identificationForm
 * @restrict EA
 * @description Directive who display a form for create a tiers
 * @element
 * @example
 * <identification-form
    controle-siret-actif="false"
    controle-siret-parametre-blocage="controleSiretParametreBlocage"
    mdm="mdm"
    tiers="demande.demandeur.expand"
    edit="false"
    user="currentUser"
    view-configuration="identificationDemandeurConfiguration"
    restriction-famille-tiers="mergedConfiguration.options.restrictionFamilleTiers">
  </identification-form>
 * @returns {void}
 */
angular.module('tiers').directive('identificationForm', [
  'Tiers',
  'Coordonnees',
  'tiersService',
  'tiersFacts',
  '$log',
  function (Tiers, Coordonnees, tiersService, tiersFacts, $log) {
    'use strict';

    return {
      restrict: 'EA',
      transclude: true,
      templateUrl: 'tiers/tiers-directives/identification-form/identification-form.html',
      scope: {
        mdm: '=',
        masterdata: '=',
        edit: '=',
        tiers: '=',
        user: '=',
        controleSiretActif: '<',
        viewConfiguration: '=',
        forbiddenSiret: '=',
        restrictionFamilleTiers: '=',
        adresseEtrangere: '=',
        civilites: '=',
        fullName: '=',
        isBeneficiaire: '<',
        isAccessedThroughSharing: '<',
      },

      link: function (scope) {
        // some facts about tiers
        scope.familleFacts = { ...tiersFacts.famille(scope.tiers) };

        // Familles of tiers
        if (!_.isEmpty(scope.restrictionFamilleTiers)) {
          scope.famillesTiers = _.filter(scope.mdm.famillestiers.array, function (familleTiers) {
            return _.includes(scope.restrictionFamilleTiers, familleTiers.href);
          });
        } else {
          scope.famillesTiers = scope.mdm.famillestiers.array;
        }

        // Automatic creation of a coordonnees if none exists for the tiers
        if (!scope.tiers.situations || scope.tiers.situations.length === 0) {
          scope.tiers.situations.push(new Coordonnees());
        }

        // If there is restriction on the foreign state, it cannot be edited
        scope.adresseEtrangere = scope.adresseEtrangere || 'CHOIX_UTILISATEUR';
        if (scope.adresseEtrangere !== 'CHOIX_UTILISATEUR') {
          scope.tiers.situations[0].etrangere = scope.adresseEtrangere === 'ETRANGERE';
        }

        // Création d'un nouveau tiers lors du changement de personnalité juridique
        scope.$watch('tiers.famille', function (newFamille, oldFamille) {
          if (newFamille) {
            if (newFamille.expand) {
              // Use expanded formesjuridiques if possible
              const formesJuridiquesMdm = _.get(scope.mdm, 'formesjuridiques.array', []);
              scope.filteredFormesJuridiques = _.map(newFamille.expand.formesJuridiques, (formeJuridique) => {
                const result = _.find(formesJuridiquesMdm, ['href', formeJuridique.href]);
                return result || formeJuridique;
              });
            }
            if (newFamille !== oldFamille || scope.famillesTiers.length === 1) {
              tiersService.changeFamilleTiers(scope.tiers, scope.user, scope.mdm, scope.masterdata);
              scope.familleFacts = { ...tiersFacts.famille(scope.tiers) };
            }
          }

          // By default "famille" is an empty object, in this case we need to transform it to undefined
          // on this way, the select control can detect that is invalid (an empty object is considered valid)
          // https://jira.mgdis.fr/browse/PLAID-1544
          // https://jira.mgdis.fr/browse/PLAID-2245
          if (_.isEmpty(scope.tiers.famille)) {
            scope.tiers.famille = undefined;
          }
        });

        const updateLegend = () => {
          // display the legend once
          scope.shouldDisplayLegend = !(scope.familleFacts.isTiersMoral && scope.isBeneficiaire);
        };

        updateLegend();

        /**
         * Allow to control famille value and delete if it's not valued
         */
        scope.onFamilleChange = function () {
          if (_.isEmpty(scope.tiers.famille)) {
            delete scope.tiers.famille;
          }
          updateLegend();
        };

        // If there is only one single famille available, it is selected by default
        if (scope.famillesTiers.length === 1 && _.isEmpty(_.get(scope, 'tiers.famille.href'))) {
          scope.tiers.famille = scope.famillesTiers[0];
          tiersService.changeFamilleTiers(scope.tiers, scope.user, scope.mdm, scope.masterdata);
          scope.familleFacts = { ...tiersFacts.famille(scope.tiers) };
        }
      },
    };
  },
]);

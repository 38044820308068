'use strict'; /*jshint bitwise: false */

/**
 * @ngdoc service
 * @name common.services.fileService
 * @param $window
 * @param numberService
 * @requires $window
 * @description Manages files
 */
angular.module('common.services').factory('fileService', [
  '$window',
  'numberService',
  function ($window, numberService) {
    'use strict';

    return {
      /**
       * Generate an UUID for
       *
       * @returns {string}
       */
      generateUUID: function () {
        /**
         *
         */
        function S4() {
          return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }

        var guid = (
          S4() +
          S4() +
          '-' +
          S4() +
          '-4' +
          S4().substr(0, 3) +
          '-' +
          S4() +
          '-' +
          S4() +
          S4() +
          S4()
        ).toLowerCase();
        return guid;
      },

      /**
       * Function who format the size of a file in bytes
       *
       * @param  {number} size Size of a document
       * @returns {string} Bytes formatted
       */
      formatSizeFile: function (size) {
        if (size === 0) {
          return '0 octets';
        }
        var k = 1024;
        var sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];
        var i = Math.floor(Math.log(size) / Math.log(k));
        const sizeNumber = size / Math.pow(k, i);
        return numberService.round(sizeNumber, 2) + ' ' + sizes[i];
      },

      /**
       * Return the extension of a file. Read the property 'name' of a javascript  file object
       *
       * @param  {string} fileName  File's name
       * @returns {string}     Extension of the file
       */
      getExtension: function (fileName) {
        var extension = null;
        if (fileName) {
          extension = fileName.toLowerCase().substring(fileName.lastIndexOf('.') + 1);
        }
        return extension;
      },

      /**
       * Function who determine if the file is authorized to be upload
       *
       * @param  {File}  file       [description]
       * @param  {Array}  extensions Extensions
       * @returns {boolean} Indicates whether the extension is allowed
       */
      isExtensionAuthorized: function (file, extensions) {
        var authorized = false;

        // If there are no extensions given, check with default values
        if (!extensions || !angular.isArray(extensions) || extensions.length === 0) {
          extensions = ['pdf', 'png', 'jpg', 'jpeg', 'doc', 'docx'];
        }
        if (file) {
          var extension = this.getExtension(file.name);
          authorized = _.includes(extensions, extension);
        }
        return authorized;
      },

      /**
       * Function who determine if the item is an instance of javascript File
       *
       * @param  {object}  item Item
       * @returns {boolean}      Indicates if the item is a file or not
       */
      isFile: function (item) {
        return angular.isObject(item) && item instanceof $window.File;
      },
    };
  },
]);

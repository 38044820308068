'use strict';
class AidePaiementAuthorizationService {
  /* @ngInject */
  constructor($log, $q, aidesService, demandesAidesService, demandesPaiementService, suiviFinancementService) {
    this.$log = $log;
    this.$q = $q;
    this.aidesService = aidesService;
    this.demandesAidesService = demandesAidesService;
    this.demandesPaiementService = demandesPaiementService;
    this.suiviFinancementService = suiviFinancementService;
  }

  /**
   * Paiement informations
   *
   * @typedef { canCreatePaiement: boolean, teleservicePaiement?: string } PaiementInformations
   */

  /**
   * Can create "demande paiement"
   *
   * @param {object} aide
   * @param {string} teleservicePaiementId
   * @returns {Promise<PaiementInformations>} paiement informations
   */
  canCreateDemandePaiementMultiFinanceur(aide, teleservicePaiementId) {
    return this.demandesPaiementService
      .canCreateDemandePaiement({
        action: 'POST',
        resource: `/referentiel-financement/api/tenants/${aide.tenant}/demandes-paiement`,
        environment: {
          body: {
            teleservicePaiement: {
              href: teleservicePaiementId,
            },

            demandeFinancement: {
              href: aide.id,
            },
          },
        },
      })
      .then((response) => ({ canCreatePaiement: response }));
  }

  /**
   * Get aide "demande de paiement" list
   *
   * @param {object} aide aide
   * @param {string} dossierId dossier
   * @returns {Array<object>} demande de paiement list
   */
  getDemandesPaiements(aide, dossierId) {
    return this.demandesPaiementService.getDemandesPaiementFromDossier(aide, dossierId).catch((error) => {
      this.$log.warn('DemandePaiements unreachable', error);
      return [];
    });
  }

  /**
   * Can create paiement for "plan de financement" line
   *
   * @param {object} lignePF plan de financement line
   * @returns {Promise<{ autorisationDemandePaiement: boolean, teleservicePaiement?: string }>} paiement informations
   */
  canCreatePaiement(lignePF) {
    const defaultCanCreatePaiement = {
      autorisationDemandePaiement: _.get(lignePF, 'financement.autorisationDemandesPaiementDemandeur', false),
    };

    if (!_.has(lignePF, 'financement.dispositif.href')) {
      return this.$q.resolve(defaultCanCreatePaiement);
    }

    return this.demandesPaiementService
      .canCreatePaiement(lignePF.financement.dispositif.href)
      .then(({ ouvertureDemandePaiementSurDecisionDemandeur, teleservicePaiement }) => ({
        autorisationDemandePaiement: ouvertureDemandePaiementSurDecisionDemandeur,
        // V8V9 dispositif paiement settings can be returned without teleservicePaiement
        teleservicePaiement: _.get(teleservicePaiement, 'href'),
      }))
      .catch((error) => {
        this.$log.warn('Dispositif unreachable', error);
        return defaultCanCreatePaiement;
      });
  }

  /**
   * Get paiement authorization informations for mono financeur
   *
   * @param {object} aide
   * @param {object} lignePF ligne de plan de financement
   * @returns {Promise<PaiementInformations>} paiement informations
   */
  canCreateDemandePaiementMonoFinanceur(aide, lignePF) {
    return this.canCreatePaiement(lignePF).then(({ autorisationDemandePaiement, teleservicePaiement }) => {
      const canCreatePaiement =
        autorisationDemandePaiement && this.aidesService.isAideHaveAtLeastOneActiveDecisionFavorable(lignePF);
      if (canCreatePaiement) {
        // Early return to avoid launch async demandes paiement list extra call
        return {
          canCreatePaiement,
          teleservicePaiement,
        };
      }
      // Check if demande has already demandePaiements since authorization have to be truthy if it has some
      return this.getDemandesPaiements(aide, _.get(lignePF, 'financement.source.href')).then((demandesPaiements) => ({
        canCreatePaiement: demandesPaiements.length > 0,
        teleservicePaiement,
      }));
    });
  }
}
AidePaiementAuthorizationService.$inject = [
  '$log',
  '$q',
  'aidesService',
  'demandesAidesService',
  'demandesPaiementService',
  'suiviFinancementService',
];

/**
 * @name aidePaiementAuthorizationService
 * @class AidePaiementAuthorizationService
 * @memberOf angular_module.aides.services
 * @description Helpers for paiement authorization and informations on demande
 */
angular.module('aides.services').service('aidePaiementAuthorizationService', AidePaiementAuthorizationService);

'use strict';

infosComplTroisCtrl.$inject = [
  '$scope',
  'configuration',
  'viewsService',
  '$timeout',
  'IFrameCommunicationManager',
  'StoreService',
  'alertsService',
  '$log',
];

angular
  .module('portailDepotDemandeAide.depot')
  .controller('depotSimpleInformationsGeneralesTroisController', infosComplTroisCtrl);

/**
 *
 * @param $scope
 * @param configuration
 * @param viewsService
 * @param $timeout
 * @param IFrameCommunicationManager
 * @param StoreService
 * @param alertsService
 * @param $log
 */
function infosComplTroisCtrl(
  $scope,
  configuration,
  viewsService,
  $timeout,
  IFrameCommunicationManager,
  StoreService,
  alertsService,
  $log
) {
  $scope.loaded = false;

  let goToNextPage;
  const iFrameCommunicationManagers = [];
  const viewsCommunicationManager = new IFrameCommunicationManager('#viewsIframe3');
  iFrameCommunicationManagers.push(viewsCommunicationManager);

  const controleCompletude = _.get($scope, 'teleserviceConfiguration.controleCompletudeDepot', false);

  $scope.pageOptions = _.get($scope, 'teleserviceConfiguration.workflow.pageInformationsGenerales3', {});
  $scope.config = configuration;

  $scope.cleanNavigate();
  $scope.navigate.ns = $scope.informationsComplementaires3Configuration.ns;
  $scope.showErrors = $scope.showErrorsOnNavigate();
  $scope.stepsWizard.active = 'dossier';

  let viewsPage = [];

  const updateStateViewsId = 'updateStateViews';

  /**
   * Update views of the aide
   *
   * @param {object} msg
   * @returns {void}
   */
  function updateViewsAide(msg) {
    // hide spinner on first iframe message
    if (!$scope.loaded) $scope.loaded = true;

    viewsService.updateViewsEntity($scope, msg, $scope.aide, '#viewsIframe3', {
      showAllErrors: $scope.showErrors,
      skipRequiredErrors: controleCompletude,
    });
  }

  $scope.navigate.beforePrevious = () => {
    return validateViewsAndWaitForUpdate(true);
  };

  /**
   * Handle msg for context expression
   *
   * @param {object} msg message event
   */
  function updateContextExpressions(msg) {
    if (msg?.data?.action === 'error') {
      $scope.navigate.block = true;
    }
  }

  $scope.navigate.next = function (forget) {
    if (goToNextPage) {
      $scope.goToStep('indicateurs', forget);
    } else {
      // Envoi du message permettant de valider la saisie des Liste/Fiche / Formulaires
      validateViewsAndWaitForUpdate(controleCompletude)
        .then(() => {
          $scope.goToStep('indicateurs', forget);
        })
        .catch(() => {
          // iframe validation / user rejected the goToStep
          viewsCommunicationManager.closeUniqueEvent({ type: updateStateViewsId });
        });
    }
  };

  /**
   * Check if the data set on the demande views are valid and wait for them to be updated on the demande in memory
   *
   * @param {boolean} ignoreAllFields option to ignore errors on fields
   * @returns {Promise} promise that resolves when the views are updated
   */
  function validateViewsAndWaitForUpdate(ignoreAllFields) {
    viewsCommunicationManager.sendEvent({
      action: 'validViews',
      options: { skipRequiredErrors: ignoreAllFields, showAllErrors: !ignoreAllFields },
    });

    return viewsCommunicationManager.manageUniqueEventWithPromise({
      type: updateStateViewsId,
      callback: (msg, resolve, reject) => {
        viewsService.updateStateViews($scope, msg, resolve, reject, $scope.teleserviceConfiguration);
      },
    });
  }

  /**
   * Initialize the controller
   *
   * @param {Array<object>} viewsPage
   * @returns {void}
   */
  function initialize(viewsPage) {
    if (viewsPage?.length > 0) {
      $scope.viewsIframeSrc = viewsService.getPageViewsIframeUrl({
        entity: $scope.aide,
        page: 'pageInformationsGenerales3',
        theme: 'demandeur',
        readOnly: false,
        topTitleLevel: 5,
        contextHeaders: {
          'referentiel-tiers': [
            'Accept:application/vnd.mgdis.tiers-3.19.0+json',
            'Content-Type:application/vnd.mgdis.tiers-3.19.0+json',
          ],
        },
      });

      viewsCommunicationManager.manageEvent(updateViewsAide);
      viewsCommunicationManager.manageEvent(updateContextExpressions);
    }

    // Wait for iframes to be loaded
    $timeout(() => {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
        },

        '#viewsIframe3'
      );
    }, 0);
  }

  this.$onInit = () => {
    // is page active
    const isActif = $scope.pageOptions?.actif ?? false;

    // list form or master-details of the page
    if ($scope.aide.views?.length > 0) {
      viewsPage = $scope.aide.views?.filter(({ page }) => page === 'pageInformationsGenerales3');
    }

    goToNextPage = !isActif || viewsPage.length === 0;

    if (goToNextPage) {
      $scope.navigate.next(true);
    }

    // ! we wait for pending promises in depot workflow before displaying anything
    StoreService.depot.pendingPromises
      .promise()
      .then(() => initialize(viewsPage))
      .catch((err) => {
        $scope.alerts = alertsService.getAlertError('connected.config.depot.errors.save');
        $log.error(`[depotSimpleInformationsGeneralesTroisController] StoreService pendingPromises: `, err);
        $scope.loaded = true;
      });

    // Remove listeners
    $scope.$on('$destroy', () => {
      iFrameCommunicationManagers.forEach((manager) => manager.close());
    });
  };
}

'use strict';
angular.module('portailDepotDemandeAide.tiers', ['ui.router']);

angular.module('portailDepotDemandeAide.tiers').config([
  '$stateProvider',
  function ($stateProvider) {
    'use strict';

    var resolveConfiguration = function (viewManagerService, $rootScope, module, view) {
      return $rootScope.configurationPromise.then(function (configuration) {
        return viewManagerService.getViewConfiguration(module, view);
      });
    };

    $stateProvider.state('app.connected.tiers', {
      url: '/tiers/:reference',
      controller: 'tiersEditController',
      templateUrl: 'tiers/tiers-edit.html',
      resolve: {
        tiers: [
          '$stateParams',
          'tiersService',
          'tiersAccountsService',
          'mdm',
          function ($stateParams, tiersService, tiersAccountsService, mdm) {
            return tiersService
              .getTiersByReference($stateParams.reference, mdm, 'pieces.documents,famille,formeJuridique,civilite')
              .then(function (tiers) {
                return tiersAccountsService.getLinkedUsers(tiers).then(function (response) {
                  tiers.linkedUsers = response.data;
                  return tiers;
                });
              });
          },
        ],

        tiersEditConfiguration: [
          'viewManagerService',
          '$rootScope',
          '$q',
          function (viewManagerService, $rootScope, $q) {
            var tiersEditConfigurations = [
              resolveConfiguration(viewManagerService, $rootScope, 'tiers', 'identification'),
              resolveConfiguration(viewManagerService, $rootScope, 'tiers', 'adresse'),
              resolveConfiguration(viewManagerService, $rootScope, 'tiers', 'representants'),
              resolveConfiguration(viewManagerService, $rootScope, 'tiers', 'accounts-tiers'),
              resolveConfiguration(viewManagerService, $rootScope, 'tiers', 'piece-form'),
            ];

            return $q.all(tiersEditConfigurations).then(function (results) {
              return {
                identificationConfiguration: results[0],
                adresseConfiguration: results[1],
                representantsConfiguration: results[2],
                accountTiersConfiguration: results[3],
                pieceFormConfiguration: results[4],
              };
            });
          },
        ],
      },
    });
  },
]);

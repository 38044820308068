'use strict';
/**
 * @ngdoc component
 * @module aides
 * @name listDemandes
 * @description
 *
 *   Display a list of demandes
 * @property {object} pagination - pagination object (used to redirect to current page in demandes list)
 * @example
 *
 *  `<list-demandes
 *    pagination="pagination">
 *  </list-demandes>`
 */
class ListDemandesController {
  constructor(
    $scope,
    $sce,
    $httpParamSerializer,
    $state,
    configuration,
    jwtSessionService,
    $timeout,
    routeUtilsService
  ) {
    this.$scope = $scope;
    this.$sce = $sce;
    this.$state = $state;
    this.$httpParamSerializer = $httpParamSerializer;
    this.configuration = configuration;
    this.jwtSessionService = jwtSessionService;
    this.$timeout = $timeout;
    this.routeUtilsService = routeUtilsService;
    this.iframeResize = null;
  }

  /**
   * Initialize component
   *
   * @returns {void}
   */
  $onInit() {
    const isShared = this.$state.$current.name.split('.').pop() === 'sharedAides';
    const demandesFinancementIframeQueryParams = this.$httpParamSerializer({
      jwtKey: this.jwtSessionService.getJwtKey(),
      tiersKey: 'current-tiers-ref',
      isShared,
      page: this.pagination,
    });

    const templateDemandesFinancementIframeSrc = `${this.configuration.ux}<%= tenantId %>/demandes-financement-view?<%= queryParams %>`;
    const compiledDemandesFinancementIframeSrc = _.template(templateDemandesFinancementIframeSrc);
    const demandesFinancementIframeSrc = compiledDemandesFinancementIframeSrc({
      tenantId: this.configuration.tenant.id,
      queryParams: demandesFinancementIframeQueryParams,
    });

    this.demandesFinancementIframeSrc = this.$sce.trustAsResourceUrl(demandesFinancementIframeSrc);
  }
}

angular.module('aides').component('listDemandes', {
  templateUrl: 'aides/aides-components/list-demandes/list-demandes.component.html',
  bindings: {
    pagination: '<',
  },

  controller: ListDemandesController,
});

ListDemandesController.$inject = [
  '$scope',
  '$sce',
  '$httpParamSerializer',
  '$state',
  'configuration',
  'jwtSessionService',
  '$timeout',
  'routeUtilsService',
];

'use strict';
angular
  .module('portailDepotDemandeAide.depot')
  .factory('justificationTableauSyntheseDepensesRecettesService', justificationTableauSyntheseDepensesRecettesService);

/**
 *
 */
function justificationTableauSyntheseDepensesRecettesService() {
  /**
   * Format the line with the fields reference, libelle, sousPostes
   * as well as montant and montantRealise initialized to the right type
   * (when typeMontant is MIXTE, init with HT)
   *
   * @param {object} ligne the line to format
   * @param {string} posteReference
   * @param {string} typeMontant
   * @returns {object}
   */
  function formatLine(ligne, posteReference, typeMontant) {
    const { reference, libelle } = ligne;
    const pfLine = { reference, libelle };
    pfLine.poste = posteReference;
    // Init montants
    if (typeMontant === 'MIXTE') {
      pfLine.montant = { ht: 0 };
      pfLine.montantRealise = { ht: 0 };
    } else {
      pfLine.montant = { [typeMontant.toLowerCase()]: 0 };
      pfLine.montantRealise = { [typeMontant.toLowerCase()]: 0 };
    }
    return pfLine;
  }

  /**
   * Dispatch lines between display and optional groups
   * and init config of each line (to store its typeMontant)
   *
   * Modifies poste params in place
   *
   * @param {object} poste (output param) the poste to format
   * @param {object} planFinancementPostes the definitions from the demande's teleservice
   * @param {string} typeMontant either 'MIXTE', 'HT' or 'TTC'
   * @param {object} optionalLines (output param) the lines that are optional
   * @param {object} excludedLines the lines excluded defined in the justification's teleservice
   * @param {object} lignesFormConfiguration (output param) the typeMontant of each line
   * @returns {void}
   */
  function formatPoste(
    poste,
    planFinancementPostes,
    typeMontant,
    optionalLines,
    excludedLines,
    lignesFormConfiguration
  ) {
    // Reset the lines
    const sortedLines = [];
    // Find the poste on planFinancement
    const planFinancementPoste = planFinancementPostes.find((pfPoste) => pfPoste.reference === poste.reference);
    if (!planFinancementPoste) {
      return;
    }
    // Add non optionnal missing lines from planFinancement on justification table
    (planFinancementPoste.lignes || []).forEach((line) => {
      // Check if line should be excluded
      if (excludedLines.includes(line.reference) || line.agentOnly) return;
      const existingLine = (poste.lignes ?? []).find((l) => l.reference === line.reference);
      if (
        existingLine &&
        (existingLine.montantRealise.ht !== undefined || existingLine.montantRealise.ttc !== undefined)
      ) {
        // If we already have this line, we copy it.
        sortedLines.push(existingLine);
      } else if (line.optionnel && !line.isUsed) {
        const optionalLine = formatLine(line, poste.reference, typeMontant);
        optionalLine.poste = {
          libelle: poste.libelle,
          reference: poste.reference,
        };

        optionalLines.push(optionalLine);
      } else {
        sortedLines.push(formatLine(line, poste.reference, typeMontant));
      }
    });
    // Add the lines added by the user (that are not in the workflow)
    const linesInPlanFinancementPoste = (planFinancementPoste.lignes ?? []).map((ligne) => ligne.reference);
    const linesAddedByUser = (poste.lignes ?? [])
      .filter((ligne) => !linesInPlanFinancementPoste.includes(ligne.reference))
      .map((line) => {
        if (line.montantRealise.ttc === undefined && line.montantRealise.ht === undefined) {
          if (typeMontant === 'MIXTE') {
            line.montant = { ht: 0 };
            line.montantRealise = { ht: 0 };
          } else {
            line.montant = { [typeMontant.toLowerCase()]: 0 };
            line.montantRealise = { [typeMontant.toLowerCase()]: 0 };
          }
          return line;
        } else {
          return line;
        }
      });

    // Override lines with ordered lines
    poste.lignes = [...sortedLines, ...linesAddedByUser];
    poste.lignes.forEach((ligne) => {
      lignesFormConfiguration[ligne.reference] = {
        typeMontant: ligne.montant.ttc !== undefined ? 'ttc' : 'ht',
      };
    });

    // Format sousPostes
    (poste.sousPostes || []).forEach((sp) => {
      formatPoste(
        sp,
        planFinancementPoste.sousPostes || [],
        typeMontant,
        optionalLines,
        excludedLines,
        lignesFormConfiguration
      );
    });
  }

  return {
    /**
     * Gets the lines to display, the typeMontant of each line and the optionnal lines
     *
     * @param {object} justification
     * @param {object} resource either 'depense' or 'recette'
     * @param {object} teleservice justification teleservice for the excluded lines
     * @param {Array} planFinancement
     * @param {object} typeMontant either 'MIXTE', 'TTC' or 'HT'
     * @returns {object} returns { activeLinePostes, optionalLines, lignesFormConfiguration }
     */
    getLinesToDisplay: (justification, resource, teleservice, planFinancement, typeMontant = 'TTC') => {
      const activeLinePostes = _.get(justification, `tableau.${resource}.postes`, []);
      const planFinancementPostes = _.get(planFinancement, `${resource}.postes`, []);
      const excludedLines = _.get(teleservice, 'documentComptable.options.posteExclusions', []).map(
        (poste) => poste.reference
      );

      const optionalLines = [];
      const lignesFormConfiguration = {};
      activeLinePostes.forEach((poste) => {
        formatPoste(poste, planFinancementPostes, typeMontant, optionalLines, excludedLines, lignesFormConfiguration);
      });
      return { activeLinePostes, optionalLines, lignesFormConfiguration };
    },
    /**
     * Gets the line from the planFinancement
     *
     * @param {object} lineReference
     * @param {object} posteReference
     * @param {object} planFinancement
     * @param {object} resource either 'depense' or 'recette'
     * @returns {object}
     */
    getPlanFinancementLine: (lineReference, posteReference, planFinancement, resource) => {
      if (!resource) return;
      const planFinancementPostes = _.get(planFinancement, `${resource}.postes`, []);
      const planFinancementPoste = planFinancementPostes.find((pfPoste) => pfPoste.reference === posteReference) || {
        lignes: [],
      };

      return planFinancementPoste.lignes?.find((line) => line.reference === lineReference);
    },
    /**
     * Returns an array of the financeurs' href
     *
     * @param {object} justification
     * @param {object} resource either 'depense' or 'recette'
     * @returns {Array<string>}
     */
    getFinanceurs: (justification, resource) => {
      const postes = _.get(justification, `tableau.${resource}.postes`, []);
      return postes
        .filter(({ sousPostes }) => sousPostes.length)
        .flatMap(({ sousPostes }) => sousPostes)
        .filter(({ lignes }) => lignes && lignes.length)
        .flatMap(({ lignes }) => lignes)
        .filter(({ financeur }) => financeur && financeur.href)
        .map(({ financeur }) => financeur.href);
    },
    /**
     * Tells if line is cumulable
     * If the option is not set for this poste and line, defaults to true
     *
     * @param {object} lineRef
     * @param {object} posteRef
     * @param {object} planFinancement
     * @param {object} resource either 'depense' or 'recette'
     * @returns {boolean}
     */
    isLineCumulable(lineRef, posteRef, planFinancement, resource) {
      const postes = planFinancement?.[resource]?.postes || [];
      const poste = postes.find((poste) => poste?.reference === posteRef) || {};
      const lines = poste.lignes || [];
      const line = lines.find((line) => line?.reference === lineRef) || {};
      return _.get(line, 'cumulable', true);
    },
  };
}

'use strict';
JustificationTableauSyntheseController.$inject = [
  '$modal',
  '$scope',
  '$translate',
  'justificationTableauSyntheseService',
  'planFinancementService',
]; // Module declaration
angular.module('portailDepotDemandeAide.depot').component('justificationTableauSyntheseComponent', {
  templateUrl: 'depot/justification/tableau-synthese/justification-tableau-synthese.component.html',
  controller: /* @ngInject */ JustificationTableauSyntheseController,
  bindings: {
    workflow: '<',
    justification: '=',
    teleservice: '<',
    planFinancementModel: '<',
    validStep: '<',
    disableNavigation: '<',
    saveJustification: '<',
    throwError: '&',
    stepIndex: '<',
  },
});

/**
 *
 * @param $modal
 * @param $scope
 * @param $translate
 * @param justificationTableauSyntheseService
 * @param planFinancementService
 */
function JustificationTableauSyntheseController(
  $modal,
  $scope,
  $translate,
  justificationTableauSyntheseService,
  planFinancementService
) {
  let ctrl = this;

  ctrl.$onInit = () => {
    ctrl.initLabels();

    // Get type montant from justification plan financement HT or TTC
    ctrl.typeMontant = _.has(ctrl, 'justification.tableau.typeMontant')
      ? ctrl.justification.tableau.typeMontant
      : planFinancementService.getTypeMontant(_.get(ctrl, 'justification.tableau'));
    if (_.isNil(ctrl.typeMontant)) {
      ctrl.throwError({
        error: "Can't find type montant of planFinancement",
        message: 'justification.common.alerts.error.typeMontant',
      });
    }
  };

  /**
   * Method for the justification-step-wizard-navigation to go to the previous step
   */
  ctrl.previousStep = function () {
    ctrl.validStep(-1);
  };

  ctrl.openCheckMontantRealiseModal = function (controleEquilibre) {
    const scope = $scope.$new();
    scope.controleEquilibre = controleEquilibre;
    $modal({
      template: 'depot/justification/tableau-synthese/modals/depot-justification-synthese-checkMontantRealise.html',
      scope,
    });
  };

  /**
   * Method for the justification-step-wizard-navigation to go to the next step
   */
  ctrl.nextStep = () => {
    const isDepenseSet = ctrl.getIfSet('depense');
    const isRecetteSet = ctrl.getIfSet('recette');
    if (!(isDepenseSet && isRecetteSet)) {
      ctrl.openCheckMontantRealiseModal();
      return;
    }
    // Balance control check
    const controleEquilibre = _.get(ctrl.teleservice, 'documentComptable.options.controleEquilibre');
    if (
      justificationTableauSyntheseService.canBalanceControl(controleEquilibre) &&
      !justificationTableauSyntheseService.balanceControl(controleEquilibre.postes, ctrl.justification.tableau)
    ) {
      ctrl.openCheckMontantRealiseModal(controleEquilibre);
      return;
    }
    ctrl.validStep();
  };

  /**
   * Method to check if montantRealise is > 0
   *
   * @param {string} type either recette or depense
   */
  ctrl.getIfSet = function (type) {
    const postes = _.get(ctrl.justification, `tableau.${type}.postes`, []);
    let isMontantSet = false;
    _.forEach(postes, (poste) => {
      const montantRealise = _.get(poste, 'montantRealise.ttc', _.get(poste, 'montantRealise.ht', 0));
      if (montantRealise > 0) {
        isMontantSet = true;
      }
    });
    return isMontantSet;
  };

  /**
   * Method for the justification-step-wizard-navigation to save this step
   */
  ctrl.saveStep = function () {
    ctrl.saveJustification();
  };

  /**
   * Method for displaying error on screen
   *
   * @param {object} error error to log
   * @param {string} message user message to display
   */
  ctrl.displayError = function (error, message) {
    // Throw error to parent component
    ctrl.throwError({ error, message });
  };

  /**
   * Initialize custom labels and use default if not found
   *
   * @returns {void}
   */
  ctrl.initLabels = function () {
    const fields = ctrl.workflow.fields || {};

    /**
     * Translate a label for tableau synthese
     *
     * @param {string} key
     * @returns {string}
     */
    const translate = (key) => $translate.instant(`justification.tableauSynthese.${key}`);

    /**
     * Get custom text, returns null if empty
     *
     * @param {string} key
     * @returns {string | null}
     */
    const getCustomTextIfExists = (key) => {
      const customText = _.get(fields, key, '');
      return customText === '' ? null : customText;
    };

    /**
     * Check if custom labels are defined because none of those should be empty strings
     * If not defined (or empty strings), use default labels
     */
    ctrl.labels = {
      depense: { label: getCustomTextIfExists('depense.label') ?? translate('depense.title') },
      recette: { label: getCustomTextIfExists('recette.label') ?? translate('recette.title') },
      synthese: { label: getCustomTextIfExists('synthese.label') ?? translate('synthese.title') },
      message: { nonNull: getCustomTextIfExists('messages.nonNull') ?? translate('checkMontantRealise.message') },
    };
  };
}

'use strict';
rechercheMultifinanceurs.$inject = ['$http', '$translate', '$httpParamSerializer'];
/**
 * @description Champ de recherche un financeur sur son libellé court
 */
angular.module('common.components').component('rechercheMultifinanceurs', {
  templateUrl: 'common/common-components/recherche-multifinanceurs/recherche-multifinanceurs.html',
  controller: /* @ngInject */ rechercheMultifinanceurs,
  bindings: {
    name: '@',
    model: '<',
    poste: '<',
    viewConfiguration: '=',
    readOnly: '<',
    requete: '<',
    callbackSelection: '&',
  },
});

/**
 *
 * @param $http
 * @param $translate
 * @param $httpParamSerializer
 */
function rechercheMultifinanceurs($http, $translate, $httpParamSerializer) {
  this.tiers = '';
  this.$onInit = () => {
    this.configuration = {
      placeholder: $translate.instant(this.viewConfiguration.ns + '.addFinanceur.button'),
    };
  };

  /**
   * Called every time the user choose a tiers
   *
   * @param  {[Object]} item    the item selected
   */
  this.onChangeTiers = (item) => {
    this.callbackSelection({ tiers: item, sousPoste: this.model });
  };

  /**
   * Building url in odata format and escape char
   *
   * @param {string} base Base of the url
   * @param {string} searchKey The keywords space separed
   */
  function buildUrl(base, searchKey) {
    let [url, filter] = decodeURI(base).split('?');
    url += '?';

    const hasFilter = filter;

    if (filter) {
      filter = filter.replace(/\$filter=/g, '');
      filter = `(${filter}) and (`;
    } else {
      filter = '';
    }

    //! escape special char and escape quote to avoid breaking the query
    const encodedSearchKey = searchKey.replace(/'/g, "''");

    filter += `substringof(thematiquesLiees/financeur/organisation/libelleCourt, '${encodedSearchKey}') or `;
    filter +=
      '(thematiquesLiees/financeur/financeurPrivilegie/groupeGestion/href ne undefined and ' +
      `substringof(thematiquesLiees/financeur/financeurPrivilegie/libelleCourt, '${encodedSearchKey}')) or `;
    filter += `substringof(thematiquesLiees/financeur/partenaireFinancier/libelleCourt, '${encodedSearchKey}') or `;
    filter += `substringof(thematiquesLiees/financeur/autreFinanceur/libelleCourt, '${encodedSearchKey}')`;
    filter += hasFilter ? ')' : '';

    const queryParams = $httpParamSerializer({
      $filter: filter,
      $orderby: 'libelleCourt asc',
      $top: 30,
    });

    url += queryParams;

    return url;
  }

  /**
   * Get the tiers based on search keywords
   *
   * @param  {string} searchKey The keywords space separed
   */
  this.getTiers = (searchKey) => {
    //Construct query
    if (searchKey && this.requete) {
      const url = buildUrl(this.requete, searchKey);

      this.loadingTiers = true;

      return $http.get(url, { noLogOn404: true }).then(
        function (response) {
          this.loadingTiers = false;
          const data = response.data;
          _.remove(
            data,
            function (tiers) {
              return isTiersPlanFinancement.apply(this, [tiers.id]);
            }.bind(this)
          );

          return data;
        }.bind(this)
      );
    }
  };

  /**
   *
   * @param referenceTiers
   */
  function isTiersPlanFinancement(referenceTiers) {
    let found = false;
    _.each(this.poste.sousPostes, (sousPoste) => {
      if (sousPoste.lignes) {
        _.each(sousPoste.lignes, (ligne) => {
          if ((_.get(ligne, 'financement.financeur.href') || _.get(ligne, 'financeur.href')) === referenceTiers) {
            found = true;
          }
        });
      }
    });
    return found;
  }
}

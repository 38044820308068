'use strict';
autorisationsController.$inject = ['$scope', '$rootScope', 'autorisationService'];
/**
 * @ngdoc component
 * @module components.autorisations
 * @name autorisationsComponent
 * @description
 *
 *   Display a list of agreements (autorisation)
 *
 *   The criteres parameter will be completed with a 'value' key for each item.
 * @param {object} tenantId - tenantId
 * @param {list} listeAutorisation - list of autorisation to display
 * @param {boolean} withValidateUserLines - also displays the user's lines with a validation date
 * @example
 *
 *    <autorisations-component tenant-id="tenantId" liste-autorisation="listeAutorisation" with-validate-user-lines="true">
 *    </autorisations-component>
 */
angular.module('components.autorisations').component('autorisationsComponent', {
  templateUrl: 'agreements/autorisations/autorisations.html',
  controller: autorisationsController,
  bindings: {
    tenantId: '<',
    listeAutorisation: '=',
    listeAutorisationStatic: '=?',
    withValidateUserLines: '<',
  },
});

autorisationsController.$inject = ['$scope', '$rootScope', 'autorisationService'];

/**
 *
 * @param $scope
 * @param $rootScope
 * @param autorisationService
 */
function autorisationsController($scope, $rootScope, autorisationService) {
  var ctrl = this;

  ctrl.$onInit = function () {
    autorisationService.getList(ctrl.tenantId).then(function (list) {
      // Récupération des autorisations 'actif' date d'activation antérieure ou égale à la date du jour
      ctrl.listeAutorisation = _.filter(list, function (autorisation) {
        return autorisation.actif && moment(autorisation.dateActivation).isSameOrBefore(new Date(), 'day');
      });

      // Récupération des autorisations du user ayant une date de validation
      var listAgreementUser = _.get($rootScope, 'currentUser.agreements');
      var listUserAutorisation = _.filter(listAgreementUser, function (autorisation) {
        return autorisation.type === 'AUTORISATION' && autorisation.dateValidation;
      });

      if (!_.isNil(listUserAutorisation)) {
        // Mise à jour de la liste avec le paramètre 'checked' des autorisations du user
        if (ctrl.withValidateUserLines) {
          _.forEach(ctrl.listeAutorisation, function (autorisation) {
            _.forEach(listUserAutorisation, function (userAutorisation) {
              if (autorisation.id === userAutorisation.id) {
                autorisation.checked = userAutorisation.checked;
              }
            });
          });
        }
        // Exclusion des autorisations déjà validés par le user
        else {
          ctrl.listeAutorisation = _.filter(ctrl.listeAutorisation, function (autorisation) {
            return !_.find(listUserAutorisation, function (userAutorisation) {
              return userAutorisation.id === autorisation.id && userAutorisation.dateValidation;
            });
          });
        }
        // Copie de la liste des autorisations pour ne pas la modifier lorsqu'on valide le changement des abonnements
        if (ctrl.listeAutorisation.length > 0) {
          ctrl.listeAutorisationStatic = angular.copy(ctrl.listeAutorisation);
        }
      }
    });
  };
}

'use strict';
angular.module('portailDepotDemandeAide').controller('portalController', [
  '$log',
  '$rootScope',
  '$scope',
  '$state',
  '$stateParams',
  '$translate',
  '$location',
  'configuration',
  'AUTHENTICATION_EVENTS',
  'USER_EVENTS',
  'userSessionService',
  'accountManagementService',
  'routeUtilsService',
  'jwtUtilService',
  'configurationService',
  'jwtSessionStorage',
  '$transitions',
  '$timeout',
  function PortalController(
    $log,
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $translate,
    $location,
    configuration,
    AUTHENTICATION_EVENTS,
    USER_EVENTS,
    userSessionService,
    accountManagementService,
    routeUtilsService,
    jwtUtilService,
    configurationService,
    jwtSessionStorage,
    $transitions,
    $timeout
  ) {
    jwtUtilService.defineJwtKey();
    configurationService.fillConfigurationWithTenantInformation();

    // Get css from referentiel-theme
    const refThemeMainUrl = configuration.referentielThemes.mainCss;
    const mainCsslink = document.createElement('link');
    mainCsslink.setAttribute('rel', 'stylesheet');
    mainCsslink.setAttribute('type', 'text/css');
    mainCsslink.setAttribute('href', refThemeMainUrl);
    document.head.appendChild(mainCsslink);

    // Add configuration to know if there is FAQ
    $scope.configuration = configuration;

    $rootScope.currentUser = userSessionService.getUser();

    /**
     * Ecoute de la transition vers la page de liste des contributions
     * pour mettre à jour le file d'ariane avec le numero de la demande
     */
    $transitions.onSuccess(
      {
        to: 'app.connected.dashboard.contributionsModificationList',
      },

      function (trans) {
        const state = trans.to().$$state();
        const demande = trans.injector().get('demande');

        state.data = {
          breadcrumb:
            $translate.instant('connected.dashboard.contributionsModificationList.breadcrumb') +
            demande.referenceAdministrative,
        };

        state.tooltip = demande.libelle.value + ' (' + demande.referenceAdministrative + ')';
        state.show_parent = {
          name: 'app.connected.dashboard.aides.demandesAides',
          breadcrumb: 'connected.dashboard.aides.breadcrumb',
        };
      }
    );

    $transitions.onSuccess({}, function () {
      // Define the root state
      if ($rootScope.currentUser) {
        // Add some metadata to all outgoing log messages
        $scope.rootState = 'app.connected.dashboard.accueil';
      } else {
        $scope.rootState = 'app.home';
      }
    });

    // After successful authentication redirect the user to the 'connected.dashboard' state
    $scope.$on(AUTHENTICATION_EVENTS.loginSuccess, function () {
      $rootScope.currentUser = userSessionService.getUser();
      // If the footer echange menu is displayed or not
      $rootScope.displayEchangeMenu = { value: false };

      $scope.rootState = 'app.connected.dashboard.accueil';
      if (_.isEmpty($state.params.redirectTo)) {
        $state.go('app.connected.dashboard.accueil');
      } else {
        routeUtilsService.manageRedirection();
      }
    });

    // After disconnect redirect the user to the 'home' state
    $scope.$on(AUTHENTICATION_EVENTS.logoutSuccess, async function (event, { token, successKey = '' }) {
      // We need to pass a redirect URL, the jwt key in the query parameters when we are in the case of OIDC or FranceConnect
      const baseUrl = $location.absUrl().split('#')[0];

      // Handle special case when this event is thrown after a user confirmed a email update
      const path = successKey ? $state.href('app.home', { success: successKey }) : `${$state.href('app')}/`;
      const redirectURL = `${baseUrl}${path}`;
      const jwtKey = jwtSessionStorage.getJwtKey();
      let redirection;

      try {
        // Logout the user
        const tenantId = `${$state.params.tenantId}-demandeurs`;
        const { data } = await accountManagementService.logout(tenantId, token, redirectURL, jwtKey);
        ({ redirection } = data);
      } finally {
        // Clear local storage after user logout but keep theme and favicon
        const theme = window.localStorage.getItem('theme');
        const favicon = window.localStorage.getItem('favicon');

        // we want a fresh session Storage regarding etags
        window.sessionStorage.removeItem('etags');

        window.localStorage.clear();
        window.localStorage.setItem('theme', theme);
        window.localStorage.setItem('favicon', favicon);

        // Clean
        $rootScope.currentUser = null;
        window.location.assign(redirection);
      }
    });

    $scope.$on(AUTHENTICATION_EVENTS.notAuthorized, () => {
      // Execute disconnection actions only if there is a connected user
      // to avoid duplicate disconnection actions
      if ($rootScope.currentUser) {
        jwtSessionStorage.removeJWTInfo();
        $rootScope.currentUser = null;
        $scope.rootState = 'app.home';

        // go root to get tenant if is undefined
        if (!_.has($state.params.tenantId)) {
          $state.go('root');
        } else {
          $state.go(
            'app.home',
            { redirectTo: $state.current.name, redirectParams: JSON.stringify($state.params) },
            { reload: true }
          );
        }
      }
    });

    // After editing the user, currentUser in the scope is updated
    $scope.$on(USER_EVENTS.userUpdated, function () {
      accountManagementService.getAccount($rootScope.currentUser.id).then(function (user) {
        $rootScope.currentUser = _.merge($rootScope.currentUser, user);
      });
    });

    // Parameter for the i18n language selection directive
    $scope.languages = [
      {
        label: '<span class="flag-icon flag-icon-fr"></span>&nbsp;&nbsp;French',
        value: 'fr',
      },

      {
        label: '<span class="flag-icon flag-icon-gb"></span>&nbsp;&nbsp;English',
        value: 'en',
      },
    ];

    $scope.languageSelection = configuration['i18n']['language-selection'];
  },
]);

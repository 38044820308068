'use strict';
angular.module('portailDepotDemandeAide.dashboard').controller('dashboardNotificationOffreStageController', [
  '$scope',
  '$sce',
  '$translate',
  'offresStageService',
  'offre',
  'jsonpatch',
  '$state',
  function ($scope, $sce, $translate, offresStageService, offre, jsonpatch, $state) {
    $scope.patchObserver = jsonpatch.observe(offre);
    $scope.helpTopHtmlContent = $translate.instant('connected.dashboard.offresStage.notification.helpTop');

    $scope.helpBottomHtmlContent = $translate.instant('connected.dashboard.offresStage.notification.helpBottom');

    $scope.ages = ['1625', '25ETPLUS'];
    $scope.genres = ['HOMME', 'FEMME'];

    $scope.niveauxQualificationRequis = offresStageService.referentiels.niveauxQualificationRequis;
    $scope.offre = offre;

    $scope.viewConfiguration = {
      ns: 'offres-stage',
      displayCharactersLeft: true,
      fields: {
        commune: {
          label: 'Commune de résidence',
          required: true,
          maxlength: 200,
          displayCharactersLeft: true,
        },
      },
    };

    $scope.submitForm = function () {
      $scope.submitted = true;
      if ($scope.notificationForm.$valid) {
        offre.statut = 'FILLED';
        var dateDemarrage = kendo.parseDate($scope.kendoDateDemarrage, 'dd/MM/yyyy');
        offre.dateDemarrage = kendo.toString(dateDemarrage, 'yyyy-MM-dd');
        var patches = jsonpatch.generate($scope.patchObserver);

        offresStageService.patch(offre.reference, patches).then(function (data) {
          $state.go('app.connected.dashboard.offresStage.mesOffres');
        });
      }
    };
  },
]);

'use strict';
class JwtSessionService {
  constructor(jwtSessionService) {
    this._jwtSessionService = jwtSessionService;

    this.LICENSES = Object.freeze({
      API_PARTICULIER: 'products/pilotage-aides/modules/pp_ctapipa',
    });
  }
  /**
   * Verifie qu'une licence est stockée dans le JWT,
   * que le module y est présent
   * et que sa date d'expiration n'est pas dépassée.
   *
   * @param module
   */
  getPresenceLicence(module) {
    const jwt = this._jwtSessionService.info();
    // si on a save une licence pour le tenant
    if (_.has(jwt, 'payload.license.modules')) {
      const licenceModule = _.find(jwt.payload.license.modules, function (mod) {
        return _.endsWith(mod.href, module);
      });

      // a licence without expiration date is valid
      return licenceModule && (!licenceModule.expiration || new Date(licenceModule.expiration) >= new Date());
    }
    return false;
  }
}
JwtSessionService.$inject = ['jwtSessionService'];

JwtSessionService.$inject = ['jwtSessionService'];

angular.module('common.services').service('licenseService', JwtSessionService);

'use strict';
angular.module('aides.recapitulatif').directive('recapitulatifAvisPrealable', [
  '$translate',
  '$rootScope',
  'avisPrealablesService',
  function ($translate) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'aides/aides-directives/recapitulatif/avis-prealable/recapitulatif-avis-prealable.html',
      link: function (scope) {
        // Gestion affichage title tiers pour une avis Prealable
        if (_.get(scope, 'aide.multiFinanceur', false)) {
          var avisPrealables = _.get(scope, 'aide.avisPrealables');
          _.forEach(avisPrealables, function (avisPrealable) {
            var tiersTitle = _.get(avisPrealable, 'tiers.title', '');
            if (!_.isEmpty(tiersTitle)) {
              var tab = tiersTitle.split(' ');
              tab.splice(tab.length - 3, 3);
              tiersTitle = tab.join(' ');
              avisPrealable.tiers.title = tiersTitle;
            }
          });
        }
      },
    };
  },
]);

'use strict';
agrementsFormController.$inject = ['$scope', '$element'];
angular.module('tiers').component('agrementForm', {
  bindings: {
    agrement: '=',
    namespace: '=?',
    viewConfiguration: '=',
    niveauxAgrement: '<',
  },

  templateUrl: 'tiers/tiers-directives/agrements-demandeurs/agrement-form/agrement-form.html',
  controller: agrementsFormController,
});

agrementsFormController.$inject = ['$scope', '$element'];

/**
 *
 * @param $scope
 * @param $element
 */
function agrementsFormController($scope, $element) {
  var vm = this;

  vm.$onInit = function () {
    $scope.viewConfiguration = this.viewConfiguration;
    $scope.namespace = $scope.namespace || $scope.viewConfiguration.ns || 'agrement.form';
    // Bind de the select options with the saved one
    if (_.get(vm, 'agrement.niveau')) {
      vm.niveauxAgrement = [vm.agrement.niveau];
    }
  };

  // Append kendo datePicker to this element (useful in modal)
  vm.datePickerConfig = {
    popup: {
      appendTo: angular.element($element),
    },
  };

  vm.onNiveauChange = function () {
    if (_.isEmpty(this.agrement.niveau)) {
      delete this.agrement.niveau;
    }
  };
}

'use strict';
depotSimpleBeneficiaireFamilleController.$inject = [
  '$scope',
  '$httpParamSerializer',
  'Tiers',
  'tiersService',
  'configuration',
  'jwtSessionService',
  '$sce',
  'StoreService',
  '$q',
  'alertsService',
  '$log',
];
angular
  .module('portailDepotDemandeAide.depot')
  .controller('depotSimpleBeneficiaireFamilleController', depotSimpleBeneficiaireFamilleController);
depotSimpleBeneficiaireFamilleController.$inject = [
  '$scope',
  '$httpParamSerializer',
  'Tiers',
  'tiersService',
  'configuration',
  'jwtSessionService',
  '$sce',
  'StoreService',
  '$q',
  'alertsService',
  '$log',
];

/**
 *
 * @param $scope
 * @param $httpParamSerializer
 * @param Tiers
 * @param tiersService
 * @param configuration
 * @param jwtSessionService
 * @param $sce
 * @param StoreService
 * @param $q
 * @param alertsService
 * @param $log
 */
function depotSimpleBeneficiaireFamilleController(
  $scope,
  $httpParamSerializer,
  Tiers,
  tiersService,
  configuration,
  jwtSessionService,
  $sce,
  StoreService,
  $q,
  alertsService,
  $log
) {
  'use strict';

  $scope.loaded = false;
  $scope.pageOptions = $scope.teleserviceConfiguration.workflow.pageInformationsBeneficiaire || {};
  $scope.cleanNavigate();
  // Manually set $error to form to disable save button at init
  // If we already have a valid tiers we should receive a message from iframe to clear $error
  $scope.depotForm.$error = { invalidTiers: {} };

  // Get the list of familles references in a string
  const restrictionFamillesTiers = $scope.pageOptions.familles
    ?.map((famille) => famille.famille.href.split('/').pop())
    .join(',');

  let publicSettingsTiers;
  tiersService.getPublicSettingsTiers().then((publicSettings) => {
    publicSettingsTiers = publicSettings;
  });

  let currentBeneficiaire = StoreService.beneficiaire.get();

  /**
   * Get metadata of the demande
   *
   * @returns {object}
   */
  function getDemandeMetadata() {
    return $scope.aide?.history?.begin?.metadata ?? null;
  }

  /**
   * Returns the referentiel-tiers iframe url for siret verification
   *
   * @param familles
   */
  function getVerificationSiretIframeUrl(familles) {
    const demandeur = StoreService.demandeur.get();
    const teleservice = _.get($scope, 'aide.teleservice.expand.reference');
    const jwtKey = jwtSessionService.getJwtKey();
    const queryParams = {
      theme: 'demandeur',
      usagers: true,
      ref: currentBeneficiaire?.reference,
      boutonSuivant: false,
      jwtKey,
      familles,
      gestionRattachementCompte: false,
      teleservice,
      adresseEtrangere: true,
      nsResources: 'beneficiaire-famille',
      searchType: getDemandeMetadata().searchTypeBeneficiaire,
    };

    if (demandeur.SIRET && demandeur.SIRET.SIREN && demandeur.SIRET.NIC) {
      queryParams.forbiddenSIRET = `${demandeur.SIRET.SIREN}${demandeur.SIRET.NIC}`;
    }
    const url = `${configuration.tiers.ux}#/verificationsiret?${$httpParamSerializer(queryParams)}`;
    return $sce.trustAsResourceUrl(url);
  }

  /**
   * Get iframe element
   *
   * @returns {object}
   */
  function getIframeElement() {
    return angular.element('#verification-siret-iframe')?.[0]?.contentWindow;
  }

  /**
   * Manage the messages we receive from child frames
   *
   * @param {object} msgEvent
   * @returns {void}
   */
  function receiveMessageEvent(msgEvent) {
    if (!$scope.loaded) {
      // controller is loaded, we can hide the spinner
      $scope.loaded = true;
      // Resize iframe
      iFrameResize(
        {
          heightCalculationMethod: 'lowestElement',
          checkOrigin: false,
          inPageLinks: true,
        },

        '#verification-siret-iframe'
      );
    }

    const data = msgEvent.data;
    if (data.action !== 'tiersValidation') {
      return;
    }

    $scope.tiersValidation = data;

    // add/reset error in the form to disable/enable the save button
    $scope.depotForm.$error = data.valid ? {} : { invalidTiers: {} };

    // add search type
    getDemandeMetadata().searchTypeBeneficiaire = data.searchType;

    // override beneficiaire data if the iframe returns a valid tiers
    // (may return valid message without a tiers to avoid overriding beneficiaire on load)
    if (data.valid && data.tiers) {
      $scope.tiersFound = data.tiers;

      const apiEntrepriseActive = _.get(publicSettingsTiers, 'apiEntreprise.active');

      const updatedBeneficiaireFields = { SIRET: data.tiers.SIRET };

      if (apiEntrepriseActive) {
        const thematiquesLiees = currentBeneficiaire?.thematiquesLiees ?? {};
        const newThematiqueAsso = _.get(data, 'tiers.thematiquesLiees.association', {});

        if (!thematiquesLiees.association) {
          _.set(thematiquesLiees, 'association', {});
        }

        const newAssoInfos = newThematiqueAsso.expand || {};
        const oldAssoInfos = thematiquesLiees.association;

        // do not set fields as undefined if they did not change
        // otherwise the thematique would not be empty (it would have the key with an undefined value) and we would try to update it
        if (oldAssoInfos.codeRNA || newAssoInfos.codeRNA) {
          oldAssoInfos.codeRNA = newAssoInfos.codeRNA;
        }
        if (oldAssoInfos.dateModificationRNA || newAssoInfos.dateModificationRNA) {
          oldAssoInfos.dateModificationRNA = newAssoInfos.dateModificationRNA;
        }
        if (oldAssoInfos.datePublicationJO || newAssoInfos.datePublicationJO) {
          oldAssoInfos.datePublicationJO = newAssoInfos.datePublicationJO;
        }
        if (oldAssoInfos.descriptionActivites || newAssoInfos.descriptionActivites) {
          oldAssoInfos.descriptionActivites = newAssoInfos.descriptionActivites;
        }

        _.assign(updatedBeneficiaireFields, {
          raisonSociale: data.tiers.raisonSociale,
          referenceAdministrative: data.tiers.referenceAdministrative,
          situations: data.tiers.situations,
          NAF: data.tiers.NAF,
          NAFNiv732: data.tiers.NAFNiv732,
          externalData: data.tiers.externalData,
          enseigne: data.tiers.enseigne,
          civilite: data.tiers.civilite,
          individu: data.tiers.individu,
          thematiquesLiees,
        });
      } else {
        const situations = currentBeneficiaire?.situations;
        const situationPrincipale = situations?.find(({ principale }) => principale);
        const currentSituationPaysCode = _.get(situationPrincipale, 'geographique.Pays.code');
        const updatedSituationPaysCode = _.get(data.tiers, 'situations[0].geographique.Pays.code');

        // reset situations when domiciliation changes if api entreprise is not active
        if (currentSituationPaysCode !== updatedSituationPaysCode) {
          updatedBeneficiaireFields.situations = data.tiers.situations;
        }
      }

      updatedBeneficiaireFields.sansSIRET = _.get(data.tiers, 'sansSIRET');

      // get the expanded famille from mdm
      // (the expanded version from iframe is missing some informations needed for the depot)
      updatedBeneficiaireFields.famille = $scope.mdm.famillestiers.array?.find(
        (famille) => famille.href === data.tiers?.famille?.href
      );

      // add famille pieces to the tiers
      // (not done by the referentiel-tiers middleware because the tiers is created without any famille)
      tiersService.addFamillePiecesToTiers(updatedBeneficiaireFields);

      const newBeneficiaire = new Tiers(_.assign({}, currentBeneficiaire, updatedBeneficiaireFields));

      // call the function of famille change to update the tiers
      tiersService.changeFamilleTiers(newBeneficiaire, null, $scope.mdm, $scope.masterdata);
      currentBeneficiaire = StoreService.beneficiaire.set(newBeneficiaire);
      $scope.aide.beneficiaires[0].expand = currentBeneficiaire;
    }

    // Manually set $error to form to disable/enable save button and then use $scope.$apply()
    // to force angular to trigger watcher because this function isn't called from angularjs
    $scope.$apply();
  }

  // The promises created during the controller life cycle,
  const promises = [];

  /**
   * Initialize the controller
   *
   * @returns {void}
   */
  function initialize() {
    currentBeneficiaire = StoreService.beneficiaire.get();
    $scope.referentielTiersIframeUrl = getVerificationSiretIframeUrl(restrictionFamillesTiers);

    // create the beneficiaire if it does not already exist
    if (!currentBeneficiaire) {
      const newBeneficiaire = new Tiers({
        status: 'TEMPORARY',
        user: $scope.aide.user,
      }).getCleanEntity();

      // It should be updated again on creation success. But it needs to be up to date to permit retry if creation fails
      currentBeneficiaire = StoreService.beneficiaire.set(newBeneficiaire);

      const promise = tiersService
        .saveTiers(newBeneficiaire, $scope.mdm)
        .then((tiers) => {
          currentBeneficiaire = StoreService.beneficiaire.set(tiers);
          $scope.aide.beneficiaires = [
            {
              href: currentBeneficiaire.id,
              title: currentBeneficiaire.title,
              expand: currentBeneficiaire,
            },
          ];

          return $scope.saveAide();
        })
        .catch((err) => {
          $scope.alerts = alertsService.getAlertError('connected.config.depot.errors.save');
          $log.error(`[depotSimpleBeneficiaireFamilleController] saveTiers`, err);
        });

      promises.push(promise);
    }

    $scope.navigate.ns = $scope.familleBeneficiaireConfiguration.ns;

    $scope.viewConfiguration = $scope.familleBeneficiaireConfiguration;
    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'beneficiaire';
    window.addEventListener('message', receiveMessageEvent);

    $scope.$on('$destroy', function () {
      window.removeEventListener('message', receiveMessageEvent);
      $scope.depotForm.$error = {};
    });
  }

  $scope.navigate.next = function () {
    // Next step
    const isValid = $scope.tiersValidation?.valid;
    const adressePrincipale = currentBeneficiaire.situations?.find(({ principale }) => principale);
    const adresseEtrangere = adressePrincipale?.etrangere;

    const rnaFilled = getDemandeMetadata().searchTypeBeneficiaire === 'RNA';

    const SIREN = ($scope.tiersFound ?? StoreService.beneficiaire.get())?.SIRET?.SIREN;
    const metadatas = {
      sirenReadOnly: !adresseEtrangere && !!SIREN,
      nicEditable: adresseEtrangere || !SIREN || rnaFilled,
      codeRnaReadOnly: rnaFilled,
    };

    if (isValid) {
      $q.all(promises)
        .then(() => $scope.saveBeneficiaire({ refreshExternalData: true }))
        .then(() => $scope.goToStep('beneficiaire-identification', undefined, undefined, undefined, metadatas));
    } else {
      getIframeElement().postMessage({ action: 'click-next' }, '*');
    }
  };

  // !  we wait for pending promises in depot workflow before displaying anything
  this.$onInit = () =>
    StoreService.depot.pendingPromises
      .promise()
      .then(() => initialize())
      .catch((err) => {
        $scope.alerts = alertsService.getAlertError('connected.config.depot.errors.save');
        $log.error(`[depotSimpleBeneficiaireFamilleController] StoreService pendingPromises: `, err);
        $scope.loaded = true;
      });
}

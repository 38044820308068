'use strict';
ContributionAvisRecapComponent.$inject = [
  '$scope',
  '$sce',
  '$translate',
  'jsonpatch',
  'contributionsService',
  '$q',
  '$rootScope',
]; // Module declaration
angular.module('portailDepotDemandeAide.depot').component('contributionAvisRecapComponent', {
  templateUrl: 'depot/contributions-avis/recapitulatif/contributions-avis-recapitulatif.component.html',
  controller: /* @ngInject */ ContributionAvisRecapComponent,
  bindings: {
    contribution: '<',
    navigationHelper: '<',
    workflow: '<',
    viewConfiguration: '<',
  },
});

/**
 * Controller
 *
 * @param $scope
 * @param $sce
 * @param $translate
 * @param jsonpatch
 * @param contributionsService
 * @param $q
 * @param $rootScope
 */
function ContributionAvisRecapComponent($scope, $sce, $translate, jsonpatch, contributionsService, $q, $rootScope) {
  var ctrl = this;

  ctrl.$onInit = function () {
    ctrl.cguAcceptation = false;
    ctrl.navigationHeaderDelegate = {
      previousButton: {
        title: $translate.instant('contributions.common.previous'),
        action: ctrl.previousStep,
      },
    };

    ctrl.disableNavigation = false;
    ctrl.navigationFooterDelegate = {
      finishButton: {
        title: $translate.instant('contributions.common.transmit'),
        action: ctrl.nextStep,
      },

      previousButton: {
        title: $translate.instant('contributions.common.previous'),
        action: ctrl.previousStep,
      },
    };

    ctrl.ns = 'contributions';
    ctrl.viewConfiguration.ns = 'teleservice.recapitulatif';
    ctrl.compatibilityError = (bowser.ios || bowser.safari) && (bowser.mobile || bowser.tablet);

    // Extract value from workflow
    // Récuperation du title de la page via "title"
    ctrl.navigationHelper.title = _.get(ctrl.workflow, 'title', ctrl.navigationHelper.title);
    ctrl.recapitulatifHTMLContent = _.get(ctrl.workflow, 'content');
  };

  // Navigation delegate
  ctrl.previousStep = function () {
    ctrl.navigationHelper.goToPreviousStep();
  };

  /**
   * Save the contribution if the cgu have been accepted
   *
   * @returns {Promise}
   */
  ctrl.saveContrib = function () {
    ctrl.attempted = true;
    if (ctrl.cguAcceptation) {
      var patches = [
        {
          op: 'replace',
          path: '/statut',
          value: 'ANSWERED',
        },

        {
          op: 'add',
          path: '/dateReponse',
          value: new Date(),
        },
      ];

      return contributionsService.updateContributionAndSendResultToParent(ctrl.contribution.reference, patches);
    } else {
      ctrl.disableNavigation = false;
      return $q.resolve();
    }
  };

  ctrl.nextStep = function () {
    // we stop process if loading in progress
    if (ctrl.disableNavigation) return;
    ctrl.disableNavigation = true;

    // we use debounce to render immediatly loading value in the template and disable the submit button
    _.debounce(() => {
      ctrl
        .saveContrib()
        .then(() => {
          $rootScope.$broadcast('refresh-menu');
          if (ctrl.cguAcceptation) ctrl.navigationHelper.goToNextStep();
        })
        .catch((err) => {
          ctrl.disableNavigation = false;
          throw err;
        });
    }, 250)();
  };

  ctrl.downloadDocument = function () {
    contributionsService.getRecapitulatifFromContribution(ctrl.contribution).then(function (response) {
      // Blob
      var fileName =
        response.headers('content-disposition') && response.headers('content-disposition').split('=').length > 0
          ? response.headers('content-disposition').split('=')[1]
          : 'download_file';
      fileName = fileName.replace(/"/g, '');
      var blob = new Blob([response.data], {
        type: 'application/pdf',
      });

      saveAs(blob, fileName);
    });
  };

  ctrl.shouldDisplayContenuHtml = function () {
    var content = $translate.instant(ctrl.viewConfiguration.ns + '.contenuHtml');
    return content !== ctrl.viewConfiguration.ns + '.contenuHtml' && content !== '';
  };
}

'use strict';
angular.module('tiers').controller('rattachementUserTiersController', [
  '$scope',
  '$q',
  '$sce',
  '$translate',
  'Tiers',
  'teleservicesService',
  'tiersService',
  'tiersRattachementService',
  'piecesService',
  'alertsService',
  'adresseService',
  'accountManagementService',
  function (
    $scope,
    $q,
    $sce,
    $translate,
    Tiers,
    teleservicesService,
    tiersService,
    tiersRattachementService,
    piecesService,
    alertsService,
    adresseService,
    accountManagementService
  ) {
    'use strict';

    $scope.dateNaissancePickerConfig = {
      min: '01/01/1900',
      max: new Date(),
      format: 'd',
      start: 'month',
      depth: 'month',
    };

    $scope.tiersRattachement = new Tiers();
    $scope.configurationId = 'rattachement';
    $scope.demandeRattachementSent = false;

    $scope.blockedRattachement = false;
    $scope.blockingErrorForGivenSiret = null;
    $scope.rattachementDetailsHidden = false;
    $scope.lockActions = false;
    $scope.currentlyClickingNextBtn = false;

    let tiersOfRattachementHasAdministrator = false;

    accountManagementService.getPublicSettings().then((accountManagementPublicSettings) => {
      $scope.accountManagementPublicSettings = accountManagementPublicSettings;
    });

    // Get all tiers linked to current user and store them for future checks
    tiersService.getAllCurrentUserTiers(true).then((allTiers) => {
      $scope.currentUserTiers = {
        all: allTiers,
        moraux: allTiers.filter((tiers) => tiers.famille.expand.personnaliteJuridique === 'MORALE'),
        physiques: allTiers.filter((tiers) => tiers.famille.expand.personnaliteJuridique === 'PHYSIQUE'),
      };
    });

    // Initialize the view
    $scope.persistence = {};

    $q.all([
      tiersRattachementService.getRattachementsConfig(),
      teleservicesService.getTeleService($scope.configurationId),
    ]).then(([config, teleservice]) => {
      // Configuration
      $scope.configuration = config;
      const pieces = teleservice?.workflow?.rattachement?.PageRattachement?.modelesPieces;

      // Persistence
      $scope.persistence.content = $scope.persistence.content || {};
      $scope.persistence.content.pieces = piecesService.initializePiecesPersistence(
        pieces,
        $scope.persistence.content.pieces
      );

      $scope.piece = $scope.persistence.content.pieces[0];

      // Url for upload documents
      $scope.urlDocuments = tiersRattachementService.getDocumentUploadUrl();

      // Url file icons
      $scope.urlFileIcons = './resources/images/file-icons/';
    });

    /**
     * Actions on famille change
     *
     * @returns {Promise}
     */
    $scope.onFamilleChange = () => {
      const typeFamille = $scope.tiersRattachement?.famille?.expand?.typeFamille;

      $scope.rattachementBySiret = typeFamille !== 'TIERS_PARTICULIER';

      tiersService.changeFamilleTiers($scope.tiersRattachement, $scope.user, $scope.mdm, $scope.masterdata);

      if (typeFamille === 'TIERS_PARTICULIER') {
        initBirthInformations();
        disableFieldsIfGivenByProvider();
      }

      return checkIfRattachementIsPossibleAndUpdateInternalProperties();
    };

    /**
     * Actions on SIRET change
     *
     * @returns {Promise}
     */
    $scope.onSiretChange = () => {
      return checkIfRattachementIsPossibleAndUpdateInternalProperties();
    };

    /**
     * Check if the rattachement is possible given what was filled and display
     * errors if it is not
     *
     * Also update some internal properties depending on the tiers the user asks
     * to be attached to
     *
     * @returns {Promise}
     */
    function checkIfRattachementIsPossibleAndUpdateInternalProperties() {
      const checksAndUpdatePipeline = $q
        .resolve()
        .then(() => resetAllErrors())
        .then(ifNoCheckError(() => checkIfRattachementIsPossibleForFamilleType()))
        .then(ifNoCheckError(() => checkIfRattachementIsPossibleForGivenSiret()))
        .then(ifNoCheckError(() => fetchTiersToAttachTo()))
        .then(
          ifNoCheckError((foundTiers) => {
            tiersOfRattachementHasAdministrator = foundTiers?.some((tiers) => tiers.accountAdministrator);
            $scope.piece.obligatoire = !tiersOfRattachementHasAdministrator;

            return checkIfRattachementIsPossibleForExistingTiersMatchingSiret(foundTiers);
          })
        );

      return checksAndUpdatePipeline.then((result) => handleRattachementChecksErrors(result?.error));
    }

    /**
     * Reset all the rattachement errors that might have been set
     *
     * @returns {void}
     */
    function resetAllErrors() {
      $scope.blockingErrorForGivenSiret = null;
      $scope.blockedRattachement = false;
      $scope.rattachementDetailsHidden = false;
      $scope.$broadcast('alerts', [], 'forbidden-rattachement');
    }

    /**
     * Fetch the tiers that match the informations the user has filled
     *
     * @returns {Promise<object[]>} partial tiers that match the filled infos
     */
    function fetchTiersToAttachTo() {
      if (!shouldControlSiret()) {
        return $q.resolve([]);
      }

      const famille = $scope.tiersRattachement?.famille?.href;
      const siren = $scope.tiersRattachement?.SIRET?.SIREN;
      const nic = $scope.tiersRattachement?.SIRET?.NIC;

      return tiersService.controlerUniciteSIRET(siren, nic, famille);
    }

    /**
     * Check if the user can ask for rattachement for the selected famille type
     *
     * @returns {object} object with ok/error property
     */
    function checkIfRattachementIsPossibleForFamilleType() {
      const { personnaliteJuridique, typeFamille } = $scope.tiersRattachement?.famille?.expand || {};

      if (personnaliteJuridique !== 'PHYSIQUE') {
        return checkOk();
      }

      const alreadyLinkedToTiersOfSameType = $scope.currentUserTiers.physiques.some(
        (tiers) => tiers.famille.expand.typeFamille === typeFamille
      );

      if (alreadyLinkedToTiersOfSameType) {
        return checkError({ impossibleForFamilleType: typeFamille });
      }

      return checkOk();
    }

    /**
     * Check that the user can ask to be attached to a tiers for the given SIRET
     *
     * @returns {object} object with ok/error property
     */
    function checkIfRattachementIsPossibleForGivenSiret() {
      if (!shouldControlSiret()) {
        return checkOk();
      }

      const siren = $scope.tiersRattachement?.SIRET?.SIREN;
      const nic = $scope.tiersRattachement?.SIRET?.NIC;

      const isAlreadyLinkedToTiers = $scope.currentUserTiers.all.some((tiers) => {
        return tiers.SIRET?.SIREN === siren && tiers.SIRET?.NIC === nic;
      });

      if (isAlreadyLinkedToTiers) {
        return checkError({ forGivenSiret: 'tiersAlreadyLinked' });
      }

      return checkOk();
    }

    /**
     * Check if the user can ask to be attached to the tiers existing tiers
     * that have been found given the informations he has filled
     *
     * @param {object[]} existingTiersWithGivenSiret partial found tiers
     * @returns {object} object with ok/error property
     */
    function checkIfRattachementIsPossibleForExistingTiersMatchingSiret(existingTiersWithGivenSiret) {
      if (!shouldControlSiret()) {
        return checkOk();
      }

      const hasExistingTiers = existingTiersWithGivenSiret?.length > 0;

      if (!hasExistingTiers) {
        return checkError({ forGivenSiret: 'tiersNotFound' });
      }

      const typeFamille = $scope.tiersRattachement?.famille?.expand?.typeFamille;
      const tiersHasLinkedUsers = existingTiersWithGivenSiret?.some((tiers) => tiers.hasLinkedUsers);

      if (typeFamille === 'TIERS_ENTREPRENEUR_INDIVIDUEL' && tiersHasLinkedUsers) {
        return checkError({ forGivenSiret: 'tiersHasLinkedUsers' });
      }

      return checkOk();
    }

    /**
     * Handle the check errors that the different checks might have returned
     * and update the state for error display
     *
     * @param {object?} error error returned by a check
     * @returns {void}
     */
    function handleRattachementChecksErrors(error) {
      if (!error) {
        return;
      }

      $scope.blockedRattachement = true;

      if (error.forGivenSiret) {
        $scope.blockingErrorForGivenSiret = getHtmlErrorMessageForKey(error.forGivenSiret);
      }

      if (error.impossibleForFamilleType) {
        hideDetailsAndDisplayError(`alreadyLinkedToTiersOfType.${error.impossibleForFamilleType}`);
      }
    }

    /**
     * Build the value returned by a check if it is OK
     *
     * @returns {object} object with ok property
     */
    function checkOk() {
      return { ok: true };
    }

    /**
     * Build the value returned by a check if it is not OK with details for the
     * reason
     *
     * @param {object} error details of why the check fails
     * @returns {object} object with error property
     */
    function checkError(error) {
      return { error };
    }

    /**
     * Propagate the check error if there is one or call the given function
     * otherwise
     *
     * @param {Function} fn function to call if there is no check error
     * @returns {object} error received or result of the given function
     */
    function ifNoCheckError(fn) {
      return (previousStepResult) => {
        return previousStepResult?.error ? previousStepResult : fn(previousStepResult);
      };
    }

    /**
     * Return if the control on SIRET should be done
     *
     * @returns {boolean} if controls on SIRET should be done
     */
    function shouldControlSiret() {
      const famille = $scope.tiersRattachement?.famille?.href;
      const siren = $scope.tiersRattachement?.SIRET?.SIREN;
      const nic = $scope.tiersRattachement?.SIRET?.NIC;
      const siretValid =
        siren && nic && $scope.formAttach['SIRET-SIREN'].$valid && $scope.formAttach['SIRET-NIC'].$valid;

      return $scope.rattachementBySiret && famille && siretValid;
    }

    /**
     * Hide the rattachement form (except famille select) and display an error
     *
     * @param {string} errorKey error message key
     */
    function hideDetailsAndDisplayError(errorKey) {
      $scope.rattachementDetailsHidden = true;

      $scope.$broadcast(
        'alerts',
        alertsService.getAlertWarning(`${$scope.viewConfiguration.ns}.error.${errorKey}`),
        'forbidden-rattachement'
      );
    }

    /**
     * Initialize birth fields informations
     *
     * @returns {void}
     */
    function initBirthInformations() {
      if ($scope.user.birthdate) {
        const dn = moment($scope.user.birthdate);
        $scope.tiersRattachement.individu.Naissance.DateNaissance = new Date($scope.user.birthdate);
        $scope.tiersRattachement.individu.Naissance.DateNaissanceDisplay = dn.format('DD/MM/YYYY');
      }
      if ($scope.user.birthplaceName) {
        $scope.tiersRattachement.individu.Naissance.LieuNaissance.Localite.value = $scope.user.birthplaceName;
      } else if ($scope.user.birthplace) {
        adresseService.searchCodeInseeVille($scope.user.birthplace).then((ville) => {
          $scope.tiersRattachement.individu.Naissance.LieuNaissance.Localite.value = ville;
        });
      }
    }

    /**
     * check if provider user exist in list oidcProviders
     * if exist set readOnly value in the scope for birthdate and birthplace
     *
     * @returns {void}
     */
    function disableFieldsIfGivenByProvider() {
      const userProviderId = $scope.user?.providerId;
      const oidcProviders = $scope.accountManagementPublicSettings?.data?.oidcProviders || [];
      const provider = oidcProviders.filter((oidcProvider) => oidcProvider.providerId === userProviderId);
      const providedByTrustedProvider = provider && provider.length > 0 && provider[0].isTrusted;

      $scope.isBirthplaceReadOnly = providedByTrustedProvider && !_.isEmpty($scope.user.birthplaceName);
      $scope.isBirthdateReadOnly = providedByTrustedProvider && !_.isEmpty($scope.user.birthdate);
    }

    /**
     * Get the value of the error with the given key as trusted HTML for
     * insertion in the DOM
     *
     * @param {string} errorKey error message key
     * @returns {*} trusted HTML to be inserted in DOM
     */
    function getHtmlErrorMessageForKey(errorKey) {
      return $translate.instant(`${$scope.viewConfiguration.ns}.error.${errorKey}`);
    }

    /**
     * Création d'une demande de rattachement
     *
     * @param  {[type]} user        Utilisateur connecté
     * @param  {[type]} individu    Individu
     * @param  {[type]} coordonnees Coordonnées
     * @param  {[type]} siret       Siret
     * @param  {[type]} pieces      Pièces
     * @param  {[type]} familleTiers    Famille de tiers
     */
    function demandeRattachement(user, individu, coordonnees, siret, pieces, familleTiers) {
      // Url for accept or reject the rattachement
      const actionUrl = tiersRattachementService.getUrlConfirmationRattachement();

      $scope.demandeRattachementSent = false;
      $scope.alerts = [];

      // Creation of rattachement
      tiersRattachementService
        .addDemandeRattachement(user, individu, coordonnees, siret, pieces, actionUrl, familleTiers)
        .then(() => {
          const destination = !tiersOfRattachementHasAdministrator ? 'region' : 'admin';
          $scope.alerts = alertsService.getAlertSuccess(
            `${$scope.viewConfiguration.ns}.success.rattachement-${destination}`
          );

          $scope.tiersRattachement = new Tiers();
          $scope.formAttach.$setPristine();
          $scope.demandeRattachementSent = true;
        })
        .catch((err) => {
          if (err.status === 403) {
            $scope.demandeRattachementSent = true;
            $scope.alerts = alertsService.getAlertError($scope.viewConfiguration.ns + '.error.forbidden');
          }

          if (err.status === 406) {
            $scope.demandeRattachementSent = true;
            $scope.alerts = alertsService.getAlertError($scope.viewConfiguration.ns + '.error.alreadySend');
          }

          if (!$scope.demandeRattachementSent) {
            $scope.alerts = alertsService.getAlertError($scope.viewConfiguration.ns + '.error.generic');
          }
        });
    }

    /**
     * Creation of rattachement for a tiers morale
     *
     * @param  {User} 	user  User
     * @param  {Siret} 	siret Siret
     * @param pieces
     * @param familleTiers
     */
    function demandeRattachementTiersMorale(user, siret, pieces, familleTiers) {
      tiersService.controlerUniciteSIRET(siret.SIREN, siret.NIC).then((uniquenessSiret) => {
        if (uniquenessSiret.length > 0) {
          demandeRattachement(user, null, null, siret, pieces, familleTiers);
        } else {
          $scope.alerts = alertsService.getAlertError($scope.viewConfiguration.ns + '.error.tiersNotFound');
        }
      });
    }

    /**
     * Création d'une demande de rattachement pour un tiers physique
     *
     * @param  {[type]} user        Utilisateur connecté
     * @param  {[type]} individu    Individu
     * @param  {[type]} coordonnees Coordonnées
     * @param  {[type]} pieces      Pièces
     * @param  {[type]} familleTiers    Famille de tiers
     */
    function demandeRattachementTiersPhysique(user, individu, coordonnees, pieces, familleTiers) {
      tiersOfRattachementHasAdministrator = false;
      demandeRattachement(user, individu, coordonnees, null, pieces, familleTiers);
    }

    /**
     * Before upload piece
     *
     * @param  {boolean} updatingDescription to inform if description updating or not
     * @returns {void}
     */
    $scope.onBeforeUploadPiece = (updatingDescription = false) => {
      $scope.pieceUploadInProgress = true;
      if (!updatingDescription) {
        $scope.lockActions = true;
      }
    };

    /**
     * After upload piece
     *
     * @param  {boolean} updatingDescription to inform if description updating or not
     * @param {boolean} success to inform if piece updated with success or not
     * @returns {void}
     */
    $scope.onAfterUploadPiece = (updatingDescription = false, success = false) => {
      $scope.pieceUploadInProgress = false;
      if (!updatingDescription) {
        $scope.lockActions = false;
      }
      if ($scope.currentlyClickingNextBtn) {
        $scope.currentlyClickingNextBtn = false;
        if (success) {
          $scope.demandeRattachement();
        }
      }
    };

    /**
     * Creation of rattachement.
     */
    $scope.demandeRattachement = function () {
      $scope.currentlyClickingNextBtn = false;
      if ($scope.pieceUploadInProgress) {
        $scope.currentlyClickingNextBtn = true;
      } else {
        const user = $scope.user.getCleanEntity();
        const tiers = $scope.tiersRattachement.getCleanEntity();
        const pieces = [$scope.piece];
        const familleTiers = $scope.tiersRattachement.famille.expand.reference;

        if (tiers && tiers.SIRET) {
          demandeRattachementTiersMorale(user, tiers.SIRET, pieces, familleTiers);
        } else {
          demandeRattachementTiersPhysique(user, tiers.individu, tiers.situations[0], pieces, familleTiers);
        }
      }
    };
  },
]);

'use strict';
printRecapPaiementthis.$inject = [
  '$rootScope',
  '$window',
  'configuration',
  'piecesService',
  'viewsService',
  'dataSchemasService',
  'demandesPaiementService',
  'indicateursService',
  'suiviFinancementService',
  'anonymizationService',
  '$http',
  'Tiers',
  '$timeout',
  '$filter',
  '$location',
];
angular.module('portailDepotDemandeAide').component('printRecapPaiementComponent', {
  bindings: {
    reference: '<',
    demandePaiement: '<',
    publicSettingsFinancement: '<',
    teleservice: '<',
    aide: '<',
    tiers: '<',
    mdm: '<',
  },

  templateUrl: 'print-recap-paiement/print-recap-paiement.component.html',
  controller: printRecapPaiementthis,
});

/**
 *
 * @param $rootScope
 * @param $window
 * @param configuration
 * @param piecesService
 * @param viewsService
 * @param dataSchemasService
 * @param demandesPaiementService
 * @param indicateursService
 * @param suiviFinancementService
 * @param anonymizationService
 * @param anonymizationService
 * @param $http
 * @param Tiers
 * @param $timeout
 * @param $location
 * @param $filter
 * @param $location
 */
function printRecapPaiementthis(
  $rootScope,
  $window,
  configuration,
  piecesService,
  viewsService,
  dataSchemasService,
  demandesPaiementService,
  indicateursService,
  suiviFinancementService,
  anonymizationService,
  $http,
  Tiers,
  $timeout,
  $filter,
  $location
) {
  this.$onInit = () => {
    this.initDossierFinancement(_.get(this.demandePaiement, 'demandeFinancement.expand'), this.demandePaiement);
    // Logo and labels from referentiel-financement
    this.configStandard = _.get(this.publicSettingsFinancement, 'recapitulatifStandardPaiement', {});
    this.teleserviceConfiguration = this.teleservice.configuration;
    this.demandeAideConfiguration = {};
    this.demandeAideConfiguration.ns = this.teleserviceConfiguration.ns + '.informations-generales';
    this.demandeur = _.get(this, 'demandePaiement.history.begin.user');
    this.pieceConfiguration = {};
    this.pieceConfiguration.ns = this.teleserviceConfiguration.ns + '.pieces';

    configuration.i18n.teleservice.id = this.teleservice.reference;
    configuration.i18n.teleservice.workflow = this.teleservice.workflow.type;
    // PLAID-71558: we want to anonymize IBAN when recap pdf is generated
    // if uri has anonymize in query (meaning invoked by ref-fi)
    if ($location.search().anonymize && this.demandePaiement?.domiciliationBancaire) {
      this.anonymize();
    }
    $rootScope.demandePaiement = _.cloneDeep(this.demandePaiement);
    // PLAID-71558 RG 4-01 : we want to anonymize IBAN
    this.pieces = (this.demandePaiement.pieces || []).map((piece) => {
      piece.isVisible = piecesService.evalConditionPiece($rootScope, piece, 'conditionAffichage');
      return piece;
    });

    // always display time zone
    this.dateTransmission = $filter('date')(this.demandePaiement.date, 'short');
    if (!this.dateTransmission.includes(configuration.timeZone)) {
      this.dateTransmission += ` (${configuration.timeZone})`;
    }

    this.urlFileIcons = './resources/images/file-icons/';

    // Translate fieldsName into path in demande
    const fieldsProperties = {
      montantDemande: {
        path: 'montant.ttc',
        dataType: 'decimal',
        subType: 'currency',
      },

      commentaire: {
        path: 'commentaire',
        dataType: 'string',
      },

      typePaiement: {
        path: 'typePaiement.title',
        dataType: 'string',
      },

      operationTerminee: {
        path: 'operationTerminee',
        dataType: 'boolean',
      },

      dateReelleFinOperation: {
        path: 'dateReelleFinOperation',
        dataType: 'date',
      },

      montantDepensesJustifiees: {
        path: 'montantDepensesJustifiees.ttc',
        dataType: 'decimal',
        subType: 'currency',
      },
    };

    const fieldsHidden = [];

    // If dispositif is present in typesPaiementAvecDepensesJustifiees, hide the field
    if (
      _.get(this.teleserviceConfiguration, 'workflow.pageInformationsGenerales.typesPaiementAvecDepensesJustifiees')
    ) {
      fieldsHidden['montantDepensesJustifiees'] = !_.includes(
        _.get(this.teleserviceConfiguration, 'workflow.pageInformationsGenerales.typesPaiementAvecDepensesJustifiees'),
        _.get(this.demandePaiement, 'typePaiement.title')
      );

      // Check if a value is set, else, set the field hidden
      if (
        !fieldsHidden['montantDepensesJustifiees'] &&
        _.isEmpty(_.get(this.demandePaiement, 'montantDepensesJustifiees'))
      ) {
        fieldsHidden['montantDepensesJustifiees'] = true;
      }
    }

    // Complete a field's configuration with teleservice's configuration, it's reference and value
    const writeField = (field, reference, path) => {
      const fieldConfig =
        _.find(this.teleserviceConfiguration.demandePaiement.fields, {
          reference: reference,
        }) || {};
      field.hidden = fieldsHidden[reference] || fieldConfig.hidden;
      field.reference = reference;
      field.dataType = _.get(fieldsProperties, reference + '.dataType', 'string');
      field.subType = _.get(fieldsProperties, reference + '.subType');
      field.value = _.get(this.demandePaiement, path);
    };

    // Update informationsGeneralesConfiguration from teleservice definition
    (this.teleserviceConfiguration.demandePaiement.fields || []).forEach((field) => {
      const reference = field.reference.replace('informations-generales.', '');
      _.set(this, 'informationsGeneralesConfiguration.fields.' + reference + '.required', field.required);
      _.set(this, 'informationsGeneralesConfiguration.fields.' + reference + '.hidden', field.hidden);
      _.set(this, 'informationsGeneralesConfiguration.fields.' + reference + '.dataType', field.dataType);
    });
    this.informationsGeneralesFields = _.transform(
      this.informationsGeneralesConfiguration.fields,
      (fieldsList, field, reference) => {
        if (fieldsProperties[reference]) {
          writeField(field, reference, fieldsProperties[reference].path);
          fieldsList.push(field);
        }
      },
      []
    );

    const infosCompHrefList = JSONPath(
      "$.[?(@.page == 'pageInformationsComplementaires')].schema.href",
      _.get(this.demandePaiement, 'views')
    );

    dataSchemasService.getDataSchemasList(infosCompHrefList).then((infosComp) => {
      this.displayInfosComplementaires = demandesPaiementService.mustDisplayInformationsComplementaires(infosComp);
    });

    this.iframeInformationsComplementairesPaiementSrc = viewsService.getPageViewsIframeUrl({
      entity: this.demandePaiement,
      page: 'pageInformationsComplementaires',
      theme: 'demandeur',
      readOnly: true,
      hideExplanation: true,
      topTitleLevel: 5,
      contextHeaders: {
        'referentiel-tiers': [
          'Accept:application/vnd.mgdis.tiers-3.19.0+json',
          'Content-Type:application/vnd.mgdis.tiers-3.19.0+json',
        ],
      },
    });

    // Check if all indicators are visible only for agent
    const allIndicatorsAreVisibleOnlyForAgent = _.every(
      _.get(this.aide, 'indicateursSaisis', []),
      (indicateurSaisi) => {
        return _.get(indicateurSaisi, 'definition.agentSeulement', false);
      }
    );

    // Check if the admin configuration asks to display the indicators and if there is at least one indicator to display
    // Check also if all indicators are not only visible for Agent
    this.displayIndicateursRealisationRecapPdf =
      demandesPaiementService.mustDisplayIndicateursRealisation(_.get(this, 'aide.indicateursSaisis', [])) &&
      _.get(this.teleserviceConfiguration, 'workflow.pageIndicateurs.actif') &&
      !allIndicatorsAreVisibleOnlyForAgent;

    this.iframeIndicateursRealisationPaiementRecapPdf = null;
    // Build the url for "indicateurs" in mode "readOnly" and "realized"
    if (this.displayIndicateursRealisationRecapPdf) {
      this.iframeIndicateursRealisationPaiementRecapPdf = indicateursService.getIndicateursIframeUrl(
        { id: this.demandePaiement.demandeFinancement.href },
        true,
        'realized',
        true,
        null,
        this.demandePaiement.id
      );
    }
    this.displayDocumentComptable = !!demandesPaiementService.getPlanFinancementDepose(this.demandePaiement);
    this.iframeDocumentComptablePaiementSrc = demandesPaiementService.getPlanFinancementIframeUrl(
      this.demandePaiement,
      true
    );
  };

  this.$onDestroy = () => {
    $rootScope.hideFooter = false;
  };

  this.initDossierFinancement = (aide, demandePaiement) => {
    const isMultiDossiers = _.get(aide, 'dossiersFinancement.length', 0) > 1;
    if (isMultiDossiers) {
      const dossierReference = _.get(demandePaiement, 'dossierFinancement.href', '').split('/').pop();
      const ligneDossier = suiviFinancementService.getLigneWithDossier(aide, dossierReference);
      this.dossierFinancement = {
        href: _.get(ligneDossier, 'financement.source.href'),
        referenceAdministrative: _.get(ligneDossier, 'financement.source.referenceAdministrative'),
        title: _.get(ligneDossier, 'financement.source.title'),
        montantVote:
          _.get(ligneDossier, 'financement.montantVote.ttc') || _.get(ligneDossier, 'financement.montantVote.ht') || 0,
      };
    }
  };

  /**
   * anonymize demandePaiement.domiciliationBancaire object through anonymizationService
   */
  this.anonymize = () => {
    this.demandePaiement.domiciliationBancaire = anonymizationService.anonymizeDomiciliationBancaire(
      this.demandePaiement.domiciliationBancaire
    );
  };

  this.print = () => {
    $window.print();
  };
  $rootScope.hideFooter = true;

  $timeout(() => {
    iFrameResize(
      {
        heightCalculationMethod: 'lowestElement',
        checkOrigin: false,
        inPageLinks: true,
      },

      '#iframeIndicateursRealisationPaiementRecapPdf'
    );

    iFrameResize(
      {
        heightCalculationMethod: 'taggedElement',
        checkOrigin: false,
        inPageLinks: true,
      },

      '#iframeDocumentComptablePaiement'
    );
  }, 0);

  this.informationsComplementairesIframeInitialization = () => {
    $timeout(() => {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
        },
        '#iframeInformationsComplementairesPaiementSrc'
      );
    }, 0);
  };
}

printRecapPaiementthis.$inject = [
  '$rootScope',
  '$window',
  'configuration',
  'piecesService',
  'viewsService',
  'dataSchemasService',
  'demandesPaiementService',
  'indicateursService',
  'suiviFinancementService',
  'anonymizationService',
  '$http',
  'Tiers',
  '$timeout',
  '$filter',
  '$location',
];

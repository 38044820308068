'use strict';
caracteristiquesController.$inject = ['$scope', 'aidesService'];
/**
 * @description Composant permettant de saisir des caractéristiques sociales
 */
angular.module('aides').component('caracteristiquesSociales', {
  require: {
    model: 'ngModel',
  },

  bindings: {
    name: '@',
    typeCaracteristique: '<',
  },

  templateUrl:
    'aides/aides-directives/types-caracteristiques-sociales/caracteristiques-sociales/caracteristiques-sociales.html',
  controller: caracteristiquesController,
});

caracteristiquesController.$inject = ['$scope', 'aidesService'];

/**
 *
 * @param $scope
 * @param aidesService
 */
function caracteristiquesController($scope, aidesService) {
  var vm = this;
  vm.caracteristiques;
  $scope.viewConfiguration = this.viewConfiguration;

  vm.listValeurs = [];

  vm.$onInit = function () {
    vm.model.$render = function () {
      vm.caracteristiques = vm.model.$viewValue;
      if (!vm.caracteristiques) {
        vm.caracteristiques = [];
        vm.model.$setViewValue(vm.caracteristiques);
      }
    };
  };

  vm.loadCaracteristiques = function (searchKey) {
    if (_.isEmpty(vm.listValeurs)) {
      return aidesService.getCaracteristiquesSociales(vm.typeCaracteristique).then(function (result) {
        vm.listValeurs = _.orderBy(result, 'title');
        return vm.filterValues(vm.listValeurs, searchKey);
      });
    }
    return vm.filterValues(vm.listValeurs, searchKey);
  };

  vm.filterValues = function (listValeurs, searchKey) {
    return _.filter(listValeurs, function (value) {
      return _.includes(value.title.toLowerCase(), searchKey.toLowerCase());
    });
  };

  vm.tagChanged = function () {
    vm.model.$setViewValue([].concat(vm.caracteristiques));
  };

  vm.validTag = function (tag) {
    if (tag.href) {
      return (
        _.findIndex(vm.listValeurs, function (valeur) {
          return valeur.href === tag.href;
        }) !== -1
      );
    }
    return false;
  };
}

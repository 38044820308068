'use strict';
/**
 * @ngdoc directive
 * @module aides
 * @name criteresAideForm
 * @restrict EA
 * @description
 *
 *   Validate eligibility of a user to an aide
 *
 *   The criteres parameter will be completed with a 'value' key for each item.
 *
 *   The status parameter will be updated with a 'attestationOk' boolean and a 'criteresOk' boolean
 * @param {object} viewConfiguration - view configuration
 * @param {list} criteres - list of criteria to display
 * @param {object} status - validity of the form
 * @param {boolean} attestation - defines if the form contains the attestation checkbox
 * @example
 *
 *  <criteres-aide-form
 *     view-configuration="criteresConfiguration"
 *     criteres="persistence.content.criteres"
 *     status="persistence.content.criteresStatus"
 *     attestation="mergedConfiguration.attestationHonneur">
 *   </criteres-aide-form>
 */

angular.module('aides').directive('criteresAideForm', function () {
  'use strict';

  return {
    restrict: 'EA',
    replace: 'true',
    transclude: false,
    templateUrl: 'aides/aides-directives/criteres-aide-form/criteres-aide-form.html',
    scope: {
      viewConfiguration: '=',
      criteresEligibilite: '=',
      status: '=?',
      attestation: '=',
      readOnly: '=?',
      isCriteresLocked: '<',
    },

    link: function (scope, element, attrs, formController) {
      scope.form = formController;

      scope.status = scope.status || {};
      scope.readOnly = scope.readOnly || false;

      // Check status of the criterias and update model accordingly
      scope.$watch(
        'criteresEligibilite',
        function () {
          _.set(scope, 'status.criteres', true);
          _.set(scope, 'status.formValid', true);

          _.each(scope.criteresEligibilite, function (critere) {
            // All questions must be answered to validate the form
            if (_.isNil(critere.reponseSaisie)) {
              scope.status.formValid = false;
            }
            // Checking if the answers match expectations
            if (critere.type !== 'STRING' && critere.reponseSaisie !== critere.reponseAttendue) {
              scope.status.criteres = false;
            }
          });
        },
        true
      );
    },
  };
});

'use strict';
JustificationPiecesController.$inject = [
  '$rootScope',
  '$scope',
  '$log',
  '$http',
  'justificationService',
  'piecesService',
  'alertsService',
  'configuration',
]; // Module declaration
angular.module('portailDepotDemandeAide.depot').component('justificationPiecesComponent', {
  templateUrl: 'depot/justification/pieces/justification-pieces.component.html',
  controller: /* @ngInject */ JustificationPiecesController,
  bindings: {
    workflow: '<',
    justification: '=',
    persistenceConfiguration: '<',
    print: '<',
    validStep: '<',
    disableNavigation: '<',
    saveJustification: '<',
    piecesExpanded: '=',
    stepIndex: '<',
  },
});

/**
 *
 * @param $rootScope
 * @param $scope
 * @param $log
 * @param $http
 * @param justificationService
 * @param piecesService
 * @param alertsService
 * @param configuration
 */
function JustificationPiecesController(
  $rootScope,
  $scope,
  $log,
  $http,
  justificationService,
  piecesService,
  alertsService,
  configuration
) {
  var ctrl = this;

  ctrl.$onInit = function () {
    ctrl.uploadInProgress = false;
    ctrl.disableNavigation = true;
    return justificationService
      .getPieceComputedRules(ctrl.justification.reference)
      .catch((error) => {
        $log.error('problem with getPieceComputedRules()', error);
        return [];
      })
      .then((pieceComputedRules) => {
        ctrl.pieceComputedRules = pieceComputedRules;
      })
      .then(() => {
        ctrl.setPiecesFormConfiguration();
      })
      .finally(() => {
        ctrl.disableNavigation = false;
      });
  };

  /**
   * Method for the justification-step-wizard-navigation to go to the previous step
   */
  ctrl.previousStep = function () {
    ctrl.save();
    ctrl.validStep(-1);
  };

  /**
   * Method for the justification-step-wizard-navigation to go to the next step
   */
  ctrl.nextStep = function () {
    if (ctrl.isFormValid()) {
      if (!ctrl.uploadInProgress && ctrl.isPiecesRequiredUploaded()) {
        ctrl.save();
        ctrl.validStep();
      } else {
        $log.error('Required pieces missing to go to the next step');
      }
    }
  };

  /**
   * Method to check if the form is valid
   */
  ctrl.isFormValid = function () {
    return $scope.justificationPiecesForm && $scope.justificationPiecesForm.$valid;
  };

  /**
   * Method for the justification-step-wizard-navigation to save this step
   */
  ctrl.saveStep = function () {
    ctrl.save();
    ctrl.saveJustification();
  };

  /**
   * Method to save justification
   */
  ctrl.save = function () {
    // we fusion current screen pieces with persistance pièces before send to the service
    ctrl.justification.pieces = _.union(ctrl.pieces, ctrl.piecesPersistance);
    // we use a correct piece mapping pefore patch
    ctrl.justification.pieces = ctrl.piecesMapping();
  };

  /**
   * Method to set input pieces config
   */
  ctrl.setPiecesFormConfiguration = function () {
    // Shorcut for access to pieces
    ctrl.pieces = ctrl.setPieces();

    // We set a persistance for not used pieces in this screen
    ctrl.piecesPersistance = piecesService.setPiecesPersistance(ctrl.pieces, _.get(ctrl, 'piecesExpanded', []));

    // Pieces's configuration
    ctrl.piecesConfiguration = {
      ns: 'justification.pagePieces.pieces',
      displayCharactersLeft: true,
      remoteValidation: false,
      showErrors: false,
    };

    // Prevent form validation when keyboard "enter" is press
    ctrl.preventValidationOnEnter = function (event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    };

    // Directory of file's icons
    ctrl.urlFileIcons = './resources/images/file-icons/';

    // Url where to upload files
    ctrl.urlDocuments = _.get(ctrl.justification, "_links['mgs:documents'].href");

    // set read only for print screen
    ctrl.readOnly = ctrl.print || false;
  };

  /**
   * Method to set pieces content
   *
   * @returns {Array}
   */
  ctrl.setPieces = function () {
    const stepPieces = ctrl.workflow.pieces
      .filter(({ reference }) => {
        const pieceRule = ctrl.pieceComputedRules[reference];
        return _.get(pieceRule, 'conditionAffichage', true);
      })
      .map(({ reference, title, description, obligatoire = false, envoiPostal = false }) => {
        return {
          libelle: { value: title },
          reference,
          description: { value: description },
          obligatoire,
          envoiPostal,
          documents: [],
          modeTransmission: 'DEPOSEE',
          conditionObligatoire: _.get(ctrl, `pieceComputedRules.${reference}.conditionObligatoire`),
        };
      });

    // we use justification datas if exists
    if (!_.get(ctrl, 'piecesExpanded', false)) {
      return stepPieces;
    }
    var mergeStepPieceAndJustifPiece = _.map(stepPieces, function (piece) {
      let newPiece =
        _.find(ctrl.piecesExpanded, function (justifPiece) {
          return piece.reference === justifPiece.reference;
        }) || piece;
      newPiece.envoiPostal = piece.envoiPostal;
      return newPiece;
    });

    // we return a cloneDeep to prevent justification piece mapping sideEffect when PATCH is sent
    return _.cloneDeep(mergeStepPieceAndJustifPiece);
  };

  /**
   * Method to set all justification pieces needed fields before save
   *
   * @returns {Array}
   */
  ctrl.piecesMapping = function () {
    const documentsMapping = (documents, mapping) => _.map(documents, (document) => _.pick(document, mapping));

    const piecesExpanded = _.map(ctrl.justification.pieces, function (piece) {
      return {
        description: piece.description,
        documents: documentsMapping(piece.documents, ['href', 'id', 'rel', 'title', 'expand', 'error']),
        envoiPostal: piece.envoiPostal,
        libelle: piece.libelle,
        obligatoire: piece.obligatoire,
        reference: piece.reference,
        modeTransmission: piece.modeTransmission,
      };
    });

    const justificationPieces = _.map(ctrl.justification.pieces, function (piece) {
      return {
        description: piece.description,
        documents: documentsMapping(piece.documents, ['href', 'id', 'rel', 'title']),
        envoiPostal: piece.envoiPostal,
        libelle: piece.libelle,
        obligatoire: piece.obligatoire,
        reference: piece.reference,
        modeTransmission: piece.modeTransmission,
      };
    });

    ctrl.piecesExpanded = piecesExpanded.filter(
      (piece) => piece.modeTransmission === 'ENVOIEPOSTAL' || !_.isEmpty(piece.documents)
    );

    return justificationPieces.filter(
      (piece) => piece.modeTransmission === 'ENVOIEPOSTAL' || !_.isEmpty(piece.documents)
    );
  };

  /**
   * Method to check if all required pieces are upload
   *
   * @returns {boolean}
   */
  ctrl.isPiecesRequiredUploaded = function () {
    // we check if we have required pieces
    var requiredPieces = _.reduce(
      ctrl.justification.pieces,
      function (acc, piece) {
        if (piece.obligatoire) acc.push(piece);
        return acc;
      },
      []
    );

    if (requiredPieces.length < 1) return true;

    // we check if all required pièce are uploaded
    var requiredPiecesValidation = _.reduce(
      requiredPieces,
      function (acc, piece) {
        if (piece.modeTransmission === 'ENVOIEPOSTAL' || !_.isEmpty(piece.documents)) return acc + 1;
        return acc;
      },
      0
    );

    return requiredPieces.length === requiredPiecesValidation;
  };

  /**
   * Method to say if piece is visible
   *
   * @param piece
   * @returns {boolean}
   */
  ctrl.isPieceVisible = function (piece) {
    if (!piece) return false;
    return piecesService.evalConditionPiece($scope, piece, 'conditionAffichage');
  };

  /**
   * Method to lock navigation
   */
  ctrl.lockNavigation = function () {
    ctrl.uploadInProgress = true;
  };

  /**
   * Method to unlock navigation
   */
  ctrl.unlockNavigation = function () {
    ctrl.uploadInProgress = false;
  };

  /**
   * Method to say if piece is required
   *
   * @param piece
   * @returns {boolean}
   */
  $scope.isPieceRequired = (piece) =>
    Boolean(piece && piece.obligatoire && (_.isNil(piece.conditionObligatoire) || piece.conditionObligatoire));
}

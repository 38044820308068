('use strict');
ContributionAvisConfirmationComponent.$inject = ['$translate', '$state', '$rootScope', 'types']; // Module declaration
angular.module('portailDepotDemandeAide.depot').component('contributionAvisConfirmationComponent', {
  templateUrl: 'depot/contributions-avis/confirmation/contributions-avis-confirmation.component.html',
  controller: /* @ngInject */ ContributionAvisConfirmationComponent,
  bindings: {
    navigationHelper: '<',
    viewConfiguration: '<',
    workflow: '<',
  },
});

/**
 * Controller
 *
 * @param $translate
 * @param $state
 * @param $rootScope
 * @param {object} types
 */
function ContributionAvisConfirmationComponent($translate, $state, $rootScope, types) {
  var ctrl = this;

  ctrl.$onInit = function () {
    // Setup navigation delegate
    ctrl.navigationFooterDelegate = {
      finishButton: {
        title: $translate.instant('contributions.common.finish'),
        action: ctrl.nextStep,
      },
    };

    // Extract info from workflow
    ctrl.viewConfiguration.ns = 'teleservice.confirmation';
    _.merge(ctrl.viewConfiguration.fields, ctrl.workflow);
  };

  // Navigation delegate
  ctrl.nextStep = function () {
    $rootScope.$broadcast('refresh-alerteur-contributions');
    $state.go('app.connected.dashboard.sollicitations', { activeTab: types.SollicitationsTypes.AVIS });
  };

  ctrl.shouldDisplayRecap = function () {
    var recapLabel = $translate.instant('teleservice.confirmation.recapitulatif');
    return recapLabel !== 'teleservice.confirmation.recapitulatif' && recapLabel !== '';
  };
}

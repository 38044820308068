'use strict';
angular.module('offresStage.services').factory('offresStageService', [
  '$http',
  '$q',
  '$log',
  'OffreStage',
  'configuration',
  '$state',
  /**
   * @param {object} $http
   * @param {object} $q
   * @param {object} $log
   * @param {object} OffreStage
   * @param {object} configuration
   * @param {object} $state
   * @returns {void}
   */
  function ($http, $q, $log, OffreStage, configuration, $state) {
    'use strict';

    /**
     * get teleservices url from configuration
     *
     * @returns {string} teleservices url
     */
    function teleservicesUrl() {
      return configuration.teleservices?.url;
    }

    /**
     * get ressource path from configuration
     *
     * @returns {string} ressource path
     */
    function ressourcePath() {
      return configuration.offresStage?.ressourcePath || 'offres-stage';
    }

    /**
     *
     * @param res
     */
    function extractLinkType(res) {
      const items = _.get(res, 'data._embedded.items', []);
      return items.map(function (item) {
        return {
          title: item.title,
          href: item.id || item._links.self.href,
        };
      });
    }
    const referentiels = {
      cadresDepot: null,
      secteursActiviteStructure: null,
      effectifsStructure: null,
      typesStage: null,
      typesContrat: null,
      dureesStageMarche: null,
      dureesStageSubvention: null,
      niveauxQualificationRequis: null,
    };

    return {
      referentiels: referentiels,
      /**
       * Valid an offre-stage
       *
       * @param newOffreStage
       * @returns {HttpPromise}
       */
      validOffreStage: function (newOffreStage) {
        newOffreStage.statut = 'TRANSMITTED';
        const patches = [
          {
            op: 'replace',
            path: '/statut',
            value: 'TRANSMITTED',
          },
        ];

        return $http
          .patch(`${teleservicesUrl()}/${ressourcePath()}/${newOffreStage.reference}`, patches)
          .then(({ data }) => data);
      },
      /**
       * Get an offre-stage
       *
       * @param {string} reference
       * @param {string} expand
       * @param {string} after
       * @returns {HttpPromise}
       */
      get: function (reference, expand, after) {
        const config = {
          params: {},
        };

        if (expand) {
          config.params.expand = expand;
        }
        if (after) {
          config.params.after = after;
        }

        return $http.get(`${teleservicesUrl()}/${ressourcePath()}/${reference}`, config).then(({ data }) => {
          return new OffreStage(data);
        });
      },
      /**
       * Get an offre-stage by its reference
       *
       * @param {string} reference
       */
      getOffreStage: function (reference) {
        return $http.get(`${teleservicesUrl()}/${ressourcePath()}/${reference}`).then(({ data }) => data);
      },
      /**
       * Get the offres-stage associated with the current user
       *
       * @param {number} skip
       * @param {number} top
       * @returns {HttpPromise}
       */
      getOffresStage: function (skip, top) {
        // use backendForFrontEnd proxy endpoint

        const options = {
          params: {
            $top: top,
            $skip: skip,
          },
        };

        return $http
          .get(`/aides/api/tenants/${_.get(configuration, 'tenant.id')}/${ressourcePath()}`, options)
          .then(({ data }) => {
            return {
              total: data.hits.total.value,
              data: data.hits.hits.map((hit) => hit._source),
            };
          });
      },
      /**
       * Patch an offre-stage by its reference
       *
       * @param {string} reference
       * @param {Array<object>} data
       * @returns {HttpPromise}
       */
      patch: function (reference, data) {
        return $http.patch(`${teleservicesUrl()}/${ressourcePath()}/${reference}`, data).then(({ data }) => data);
      },
      /**
       * Remove an offre-stage
       *
       * @param {object} offre
       * @returns {HttpPromise}
       */
      remove: function (offre) {
        return $http.delete(`${teleservicesUrl()}/${ressourcePath()}/${offre.reference}`);
      },
      /**
       * Create an offre-stage
       *
       * @param {object} offreStage
       * @returns {HttpPromise}
       */
      create: function (offreStage) {
        return $http
          .post(`${teleservicesUrl()}/${ressourcePath()}`, offreStage)
          .then(({ data }) => {
            return new OffreStage(data);
          })
          .catch((err) => {
            if (err.status === 403 && _.get(err, 'data.code') === 403.1) {
              $state.go('app.connected.dashboard.accueil', {
                maximumOffresStageReached: true,
              });
            } else {
              if (err.data.message) {
                $log.error(err.data.message);
              }
            }
          });
      },
      /**
       * Update an offre-stage
       *
       * @param {object} offreStage
       * @param {object} mdm
       * @returns {HttpPromise}
       */
      update: function (offreStage) {
        const offreStageJson = angular.toJson(offreStage);
        return $http
          .put(`${teleservicesUrl()}/${ressourcePath()}/${offreStage.reference}`, offreStageJson)
          .then(({ data }) => {
            return new OffreStage(data);
          })
          .catch((err) => {
            if (err.status === 403 && _.get(err, 'data.code') === 403.1) {
              $state.go('app.connected.dashboard.accueil', {
                maximumOffresStageReached: true,
              });
            } else {
              if (err.data.message) {
                $log.error(err.data.message);
                throw err;
              }
            }
          });
      },
      /**
       * Initialize cadres-depot
       *
       * @returns {HttpPromise}
       */
      initCadresDepot: function () {
        return $http.get(`${teleservicesUrl()}/cadres-depot`).then(({ data }) => {
          referentiels.cadresDepot = _.get(data, '_embedded.items', []);
        });
      },
      /**
       * Initialize secteurs-activite-structure
       *
       * @returns {Promise<Array<object>>}
       */
      initSecteursActiviteStructure() {
        return $http
          .get(`${teleservicesUrl()}/secteurs-activite-structure`, {
            params: {
              $top: 100,
            },
          })
          .then(extractLinkType)
          .then((secteursActiviteStructure) => {
            referentiels.secteursActiviteStructure = secteursActiviteStructure;
            return secteursActiviteStructure;
          });
      },
      /**
       * Initialize effectifs-structure
       *
       * @returns {HttpPromise}
       */
      initEffectifStructure: function () {
        return $http
          .get(`${teleservicesUrl()}/effectifs-structure`)
          .then(extractLinkType)
          .then((effectifsStructure) => {
            referentiels.effectifsStructure = effectifsStructure;
          });
      },
      /**
       * Initialize types-stage
       *
       * @returns {HttpPromise}
       */
      initTypesStage: function () {
        return $http
          .get(`${teleservicesUrl()}/types-stage`)
          .then(extractLinkType)
          .then((typesStage) => {
            referentiels.typesStage = typesStage;
          });
      },
      /**
       * Initialize types-contrat
       *
       * @returns {HttpPromise}
       */
      initTypesContrat: function () {
        return $http
          .get(`${teleservicesUrl()}/types-contrat`)
          .then(extractLinkType)
          .then((typesContrat) => {
            referentiels.typesContrat = typesContrat;
          });
      },
      /**
       * Initialize durees-stage
       *
       * @returns {HttpPromise}
       */
      initDureesStage: function () {
        return $q.all([
          $http
            .get(`${teleservicesUrl()}/durees-stage-subvention`)
            .then(extractLinkType)
            .then((dureesStageSubvention) => {
              referentiels.dureesStageSubvention = dureesStageSubvention;
            }),
          $http
            .get(`${teleservicesUrl()}/durees-stage-marche`)
            .then(extractLinkType)
            .then((dureesStageMarche) => {
              referentiels.dureesStageMarche = dureesStageMarche;
            }),
        ]);
      },
      /**
       * Get durees-stage by cadre-depot
       *
       * @param {string} referenceCadreDepot
       */
      getDureesStage: function (referenceCadreDepot) {
        if (referenceCadreDepot === 'SUBVENTION') {
          return referentiels.dureesStageSubvention;
        } else {
          return referentiels.dureesStageMarche;
        }
      },
      /**
       * Initialize niveaux-qualification-requis
       *
       * @returns {HttpPromise}
       */
      initNiveauxQualificationRequis: function () {
        return $http
          .get(`${teleservicesUrl()}/niveaux-qualification-requis`)
          .then(extractLinkType)
          .then((niveauxQualificationRequis) => {
            referentiels.niveauxQualificationRequis = niveauxQualificationRequis;
          });
      },
    };
  },
]);

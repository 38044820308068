'use strict';
angular.module('common.model').factory('LocalizedText', function () {
  'use strict';

  /**
   *
   * @param data
   */
  function LocalizedText(data) {
    data = data || {};
    this.value = data.value || '';
  }

  return LocalizedText;
});

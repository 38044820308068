/**
 * This component is intended to be a wrapper around inputs of the mg-components
 * lib to make them easier to use in the portal.
 *
 * The goal is to match the behavior of other fields without having to handle
 * everything manually.
 *
 * It handles:
 *   * the style of the components to match other fields (with mg-component-wrapper class)
 *   * the update of AngularJS form with the field validity
 *   * the two-way binding of a value
 *   * the trigger of field errors display when the form is submitted
 *
 * (This is not the cleanest way possible. Feel free to update or remove this
 * if the lib can be integrated in a better way in the future.)
 */
class MgComponentWrapper {
  constructor($scope) {
    this.$scope = $scope;

    this.loaded = false;
  }

  $postLink() {
    // get the wrapped component and register listeners once the component
    // has been rendered
    this.wrappedComponent = this.wrapper.children().first();

    this.registerListeners();

    this.loaded = true;

    // consider the fields as valid on load
    // it will change either on field value change or on click on "Next" if necessary
    this.valid = true;
  }

  $onDestroy() {
    this.unregisterListeners();
  }

  /**
   * Register listeners on the wrapped component and on the integration
   * context to update when necessary
   *
   * @returns {void}
   */
  registerListeners() {
    this.validityChangeListener = this.onValidityChange.bind(this);
    this.valueChangeListener = this.onValueChange.bind(this);

    this.wrapper.on('input-valid', this.validityChangeListener);
    this.wrapper.on('value-change', this.valueChangeListener);

    this.$scope.$watch('$ctrl.form.$submitted', (submitted) => {
      if (submitted) {
        this.wrappedComponent[0].displayError();
      }
    });

    this.$scope.$watch('$ctrl.ngModel.$viewValue', (newValue) => {
      this.wrappedComponent.val(newValue);
    });
  }

  /**
   * Unregister listeners
   *
   * @returns {void}
   */
  unregisterListeners() {
    this.wrapper.off('input-valid', this.validityChangeListener);
    this.wrapper.off('value-change', this.valueChangeListener);
  }

  /**
   * Update the field validity in AngularJS context when the wrapped component
   * validity changes
   *
   * @param {object} event event
   * @returns {void}
   */
  onValidityChange(event) {
    this.valid = event.target.valid;

    // Distinguish errors because required input is empty and other errors
    // (required errors can be non-blocking if control is set at dépôt end)
    if (this.valid) {
      this.ngModel.$setValidity('required', true);
      this.ngModel.$setValidity('valid', true);
    } else {
      const isRequiredError = !!this.wrappedComponent[0].required && !this.wrappedComponent[0].value;
      this.ngModel.$setValidity('required', this.ignoreRequiredErrors || !isRequiredError);
      this.ngModel.$setValidity('valid', isRequiredError);
    }
  }

  /**
   * Update the field value in AngularJS context when the wrapped component
   * value changes
   *
   * @param {object} event event
   * @returns {void}
   */
  onValueChange(event) {
    this.ngModel.$setViewValue(event.target.value);
  }
}

MgComponentWrapper.$inject = ['$scope'];

angular.module('common.components').component('mgComponentWrapper', {
  transclude: true,
  template: '<div ng-ref="$ctrl.wrapper" class="mg-component-wrapper" ng-transclude></div>',
  require: {
    form: '^form',
    ngModel: 'ngModel',
  },
  bindings: {
    ignoreRequiredErrors: '<',
    valid: '=?',
  },
  controller: MgComponentWrapper,
});

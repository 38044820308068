'use strict';
echangesController.$inject = [
  '$scope',
  '$rootScope',
  '_$sce',
  '$stateParams',
  'jwtSessionService',
  '$aside',
  'echangesService',
];
/**
 * @ngdoc component
 * @module components.echanges
 * @name echangesComponent
 * @description
 *
 * Display the menu of echanges as an aside menu
 * Use $rootScope.displayEchangeMenu = {value: true, demandeId: <id>}
 * to display the menu from anywhere
 *
 * To show the aside popin manually, use:
 * $rootScope.$broadcast('showAsideEchange');
 *
 * And to close is manually:
 * $rootScope.$broadcast('closeAsideEchange');
 * @example
 *    <echanges-component display="<condition to display the menu"></echanges-component>
 */
angular.module('components.echanges').component('echangesComponent', {
  templateUrl: 'echanges/echanges.html',
  controller: echangesController,
  bindings: {
    display: '<',
  },
});

echangesController.$inject = [
  '$scope',
  '$rootScope',
  '$sce',
  '$stateParams',
  'jwtSessionService',
  '$aside',
  'echangesService',
];

/**
 *
 * @param $scope
 * @param $rootScope
 * @param _$sce
 * @param $stateParams
 * @param jwtSessionService
 * @param $aside
 * @param echangesService
 */
function echangesController($scope, $rootScope, _$sce, $stateParams, jwtSessionService, $aside, echangesService) {
  'use strict';

  var ctrl = this;

  var myAside = $aside({
    scope: $scope,
    template: 'echanges/aside/aside.html',
    container: '.container-tchat',
    show: false,
  });

  /**
   * Show / hide aside
   */
  $scope.showAside = function () {
    myAside.$promise.then(function () {
      if (myAside.$isShown) {
        myAside.hide();
      } else {
        myAside.show();
      }
    });
  };

  $scope.$on('showAsideEchange', function () {
    $scope.showAside();
  });

  $scope.$on('closeAsideEchange', function () {
    if (myAside.$isShown) {
      myAside.hide();
    }
  });

  var getNbEchangesNonLus = function (demandeId) {
    echangesService.countUnreadEchangesByDemande($stateParams.tenantId, demandeId).then(function (result) {
      $scope.unreadEchanges = result;
    });
    echangesService.socketUnreadEchanges($stateParams.tenantId, $rootScope.currentUser, demandeId, function (result) {
      $scope.unreadEchanges = result;
    });
  };

  $scope.$watch('$ctrl.display.value', function (newDisplay) {
    var displayCurrent = newDisplay;
    // Become false -> hidding
    if (!displayCurrent) {
      $scope.displayFooter = displayCurrent;
      myAside.hide();
    } else {
      // Become true -> showing
      if (ctrl.isEchangesActif) {
        $scope.displayFooter = displayCurrent;
        getNbEchangesNonLus(ctrl.display.demandeId);
      }
    }
  });

  ctrl.$onInit = function () {
    var jwt = jwtSessionService.info();
    ctrl.isEchangesActif = _.get(jwt, 'payload.echangesActif');
    $scope.unreadEchanges = -1;
  };

  ctrl.$onDestroy = function () {
    // Close the socket when the component is destroyed
    if ($rootScope.socketEchange) {
      $rootScope.socketEchange.close();
      delete $rootScope.socketEchange;
    }
  };
}

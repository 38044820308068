'use strict';
/**
 * @ngdoc component
 * @module aides
 * @name demandeAide
 * @description
 *
 *   Demande d'une aide
 * @param {object} demandeAide - document's model
 * @param {object} viewConfiguraton - view configuration
 * @example
 *
 *   <demande-aide demande="$ctrl.demande" view-configuration="$ctrl.viewConfiguraton"></demande-aide>
 */
class DemandeAideController {
  constructor(demandesAidesService) {
    this.demandesAidesService = demandesAidesService;
  }

  /**
   * Initialize component
   *
   * @returns {void}
   */
  $onInit() {
    this.namespace = this.viewConfiguration.ns + '.demande-aide';
    // Compute montant voté - Backward compatibility
    this.montantVote = this.demandesAidesService.getVotedAmount(this.demande);
  }
}
DemandeAideController.$inject = ['demandesAidesService'];

DemandeAideController.$inject = ['demandesAidesService'];

angular.module('aides').component('demandeAide', {
  templateUrl: 'aides/aides-components/demande-aide/demande-aide.html',
  bindings: {
    demande: '<',
    viewConfiguration: '<',
  },

  controller: DemandeAideController,
});

'use strict';
comiteRecevabilite.$inject = ['comitesRecevabiliteService', 'COMITE_STATUS'];
angular.module('aides').component('comiteRecevabilite', {
  templateUrl: 'aides/aides-components/recapitulatif/comite-recevabilite/comite-recevabilite.component.html',
  bindings: {
    aide: '<',
    tiers: '<',
  },

  controller: comiteRecevabilite,
});

/**
 *
 * @param comitesRecevabiliteService
 * @param COMITE_STATUS
 * @param tiersService
 */
function comiteRecevabilite(comitesRecevabiliteService, COMITE_STATUS) {
  this.$onInit = () => {
    const financeur = this.aide.multiFinancement?.financeurs?.find((financeur) => {
      return financeur.href === this.tiers.id;
    });
    if (financeur) {
      this.COMITE_STATUS = COMITE_STATUS;
      const comiteHref = this.aide.comiteRecevabilite?.href;
      if (comiteHref) {
        comitesRecevabiliteService.getByHref(comiteHref).then((res) => {
          this.comiteRecevabilite = res.data;
        });
      }
    }
  };
}

'use strict';
ContributionsAvisPreambuleController.$inject = [
  '$sce',
  '$translate',
  'contributionsService',
  'piecesService',
  'jsonpatch',
]; // Module declaration
angular.module('portailDepotDemandeAide.depot').component('contributionAvisPreambuleComponent', {
  templateUrl: 'depot/contributions-avis/preambule/contributions-avis-preambule.component.html',
  controller: /* @ngInject */ ContributionsAvisPreambuleController,
  bindings: {
    contribution: '=',
    navigationHelper: '=',
    workflow: '<',
    workflowPieces: '<',
    contributionDelegate: '<',
  },
});

/**
 * Controller
 *
 * @param $sce
 * @param $translate
 * @param contributionsService
 * @param piecesService
 * @param jsonpatch
 */
function ContributionsAvisPreambuleController($sce, $translate, contributionsService, piecesService, jsonpatch) {
  var ctrl = this;

  ctrl.$onInit = function () {
    // Define navigation delegate
    ctrl.navigationDelegate = {
      nextButton: {
        title: $translate.instant('contributions.common.next'),
        action: ctrl.nextStep,
      },
    };
  };

  ctrl.nextStep = function () {
    var observer = jsonpatch.observe(ctrl.contribution);
    if (_.get(ctrl.contribution, 'statut') === 'INPROGRESS') {
      ctrl.navigationHelper.goToNextStep();
    } else {
      // Update status
      ctrl.contribution.statut = 'INPROGRESS';
      // Setup form values for pieces
      var pieces = _.get(ctrl.contribution, 'avis.pieces');
      _.set(
        ctrl.contribution,
        'avis.pieces',
        piecesService.initializePiecesPersistence(ctrl.workflowPieces.modelesPieces, pieces)
      );

      // Filter the pieces by family
      contributionsService
        .expandTiersForContribution(ctrl.contribution)
        .then(function (tiers) {
          var tiersReference = _.get(tiers, 'data');
          _.set(ctrl.contribution, 'tiers.title', tiersReference.title);

          if (_.get(tiersReference, 'famille.href')) {
            ctrl.contribution.avis.pieces = _.filter(ctrl.contribution.avis.pieces, function (piece) {
              return (
                piece.familles === undefined ||
                _.find(piece.familles, function (famille) {
                  return _.includes(tiersReference.famille.href, famille.href);
                })
              );
            });
          }

          var patches = jsonpatch.generate(observer);
          return contributionsService.updateContributionAndSendResultToParent(ctrl.contribution.reference, patches);
        })
        .then(function () {
          ctrl.navigationHelper.goToNextStep();
        });
    }
  };

  ctrl.shouldDisplayContent = function () {
    var content = $translate.instant('teleservice.preambule.content');
    return content !== 'teleservice.preambule.content' && content !== '';
  };
}

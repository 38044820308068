'use strict';
angular.module('portailDepotDemandeAide.dashboard').controller('dashboardMesPublicationsController', [
  'configuration',
  'jwtSessionService',
  '$rootScope',
  '$scope',
  '$state',
  '$httpParamSerializer',
  'StoreService',
  'IFrameCommunicationManager',
  function (
    configuration,
    jwtSessionService,
    $rootScope,
    $scope,
    $state,
    $httpParamSerializer,
    StoreService,
    IFrameCommunicationManager
  ) {
    this.$onInit = function $onInit() {
      const publicationIframeQueryParams = $httpParamSerializer({
        jwtKey: jwtSessionService.getJwtKey(),
        currentTiers: StoreService.currentTiersRef.get(),
      });

      const templatePublicationIframeSrc =
        configuration.referentielPublication.ux + '<%= tenantId %>/documents-publies/my?<%= queryParams %>';
      const compiledPublicationIframeSrc = _.template(templatePublicationIframeSrc);
      const publicationIframeSrc = compiledPublicationIframeSrc({
        tenantId: configuration.tenant.id,
        queryParams: publicationIframeQueryParams,
      });

      this.publicationIframeSrc = publicationIframeSrc;

      this.iFrameCommunicationManager = new IFrameCommunicationManager('#publicationIframe');
      this.iFrameCommunicationManager.manageEvent(({ data }) => {
        const { route, action, aide } = data;

        if (route === 'documents-publies.my.ux') {
          switch (action) {
            case 'go-to-aide':
              $state.go('app.connected.dashboard.recapitulatif', { aide, from: 'mesPublications' });
              break;
            case 'downloaded':
              $rootScope.$broadcast('refresh-menu');
              break;
          }
        }
      });
    };

    /**
     * Resize iframe
     *
     * @returns {void}
     */
    function resizeIframe() {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
        },

        '#publicationIframe'
      );
    }

    $scope.$on('$destroy', () => {
      this.iFrameCommunicationManager.close();
    });
  },
]);

'use strict';
angular.module('aides.recapitulatif').directive('suiviOrganismesSollicites', [
  'aidesService',
  function (aidesService) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'aides/aides-directives/recapitulatif/organismes-sollicites/suivi-organismes-sollicites.html',
      scope: {
        aide: '=',
        namespace: '=',
      },

      link: function (scope) {
        // organismes financeurs list
        scope.listeOrganismesSollicites = [];
        // pick the current planFinancement
        const currentPlanFinancement = aidesService.getCurrentPlanFinancement(scope.aide);
        const listeLignesPlanFinancementFinanceur = getLignesPlanFinancementWithFinanceur(currentPlanFinancement);
        const organismesFinanceurs = getOrganismesFinanceurs(scope.aide);

        organismesFinanceurs.forEach((organismesSollicites = {}) => {
          const ligneFinanceur = _.find(listeLignesPlanFinancementFinanceur, function (lignePlanFinancementFinanceur) {
            return _.get(lignePlanFinancementFinanceur, 'financement.financeur.href') === organismesSollicites.href;
          });
          if (!ligneFinanceur) return;

          const suiviOrganismeSollicite = {};
          suiviOrganismeSollicite.tiers = {
            href: organismesSollicites.href,
            title: organismesSollicites.title,
          };

          // add groupeGestion
          suiviOrganismeSollicite.groupesGestion = organismesSollicites.groupesGestion;
          // add type financeur
          suiviOrganismeSollicite.typeFinanceur = organismesSollicites.typeFinanceur;
          suiviOrganismeSollicite.titleFinanceur = organismesSollicites.title;

          if ('montant' in ligneFinanceur) {
            suiviOrganismeSollicite.montantDemande = _.get(ligneFinanceur, 'montant');
          }

          // check if an "avis de prise en charge" was already sent
          if ('avisPriseEnCharge' in ligneFinanceur) {
            suiviOrganismeSollicite.hasAvisPriseEnCharge = true;
          }
          // add financement statut
          if (_.has(ligneFinanceur, 'financement.statut')) {
            suiviOrganismeSollicite.statutLigneFinancement = _.get(ligneFinanceur, 'financement.statut');
          }
          // add avis recevabilite
          if ('avisRecevabilite' in ligneFinanceur) {
            suiviOrganismeSollicite.avisRecevabilite = ligneFinanceur.avisRecevabilite;
          }

          scope.listeOrganismesSollicites.push(suiviOrganismeSollicite);
        });

        /**
         * Get organismes financers
         *
         * @param {object} aide
         * @returns {Array<object>}
         */
        function getOrganismesFinanceurs(aide) {
          return _.get(aide, 'multiFinancement.financeurs', []).filter((organismeFinanceur = {}) => {
            const typeFinanceur = organismeFinanceur.typeFinanceur;
            const montantValide =
              _.get(organismeFinanceur, 'montant.ht') > 0 || _.get(organismeFinanceur, 'montant.ttc') > 0;
            const financeurValide =
              organismeFinanceur.href && ['FINANCEURPRIVILEGIE', 'PARTENAIREFINANCIER'].includes(typeFinanceur);

            return montantValide && financeurValide;
          });
        }

        /**
         * Get lignes plan financement with financeur
         *
         * @param {object} planFinancement
         * @returns {Array<object>}
         */
        function getLignesPlanFinancementWithFinanceur(planFinancement) {
          if (!planFinancement) return [];

          const listeLignesPlanFinancementFinanceur = JSONPath('$.recette..ligne[?(@.financement)]', planFinancement);
          // Other request for sousPoste
          return listeLignesPlanFinancementFinanceur.concat(
            JSONPath('$.recette...lignes[?(@.financement)]', planFinancement)
          );
        }

        // Affichage de l'organisme financeur dans le tableau
        scope.displayOrganismeFinanceur = function (organismeSollicite) {
          return _.get(
            organismeSollicite,
            'titleFinanceur',
            _.get(organismeSollicite, 'groupesGestion.0.title', _.get(organismeSollicite, 'tiers.title'))
          );
        };

        // Affichage de l'etat dans le tableau
        scope.displayEtat = function (organismeSollicite) {
          // Dans le cas d'un financeur privilégié qui a donné un avis de refus de prise en charge
          if (
            _.get(organismeSollicite, 'hasAvisPriseEnCharge') &&
            _.get(organismeSollicite, 'typeFinanceur') === 'FINANCEURPRIVILEGIE' &&
            _.get(organismeSollicite, 'statutLigneFinancement') === 'RETURNED'
          ) {
            return 'Rejetée';
          } else if (
            !_.get(organismeSollicite, 'hasAvisPriseEnCharge') &&
            _.get(organismeSollicite, 'typeFinanceur') !== 'PARTENAIREFINANCIER'
          ) {
            return 'Non pris en charge';
          }

          return _.has(organismeSollicite, 'avisRecevabilite.avis')
            ? _.get(organismeSollicite, 'avisRecevabilite.avis') === 'RECEVABLE'
              ? 'Recevable'
              : 'Irrecevable'
            : "En attente d'avis";
        };

        // Affichage du montant demande
        scope.displayMontantDemande = function (organismeSollicite) {
          return _.get(organismeSollicite, 'montantDemande.ht', _.get(organismeSollicite, 'montantDemande.ttc'));
        };

        // Affichage du montant proposé
        scope.displayMontantPropose = function (organismeSollicite) {
          return _.get(
            organismeSollicite,
            'avisRecevabilite.montantPropose.ht',
            _.get(organismeSollicite, 'avisRecevabilite.montantPropose.ttc')
          );
        };
      },
    };
  },
]);

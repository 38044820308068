'use strict';
/**
 * @param $modal
 * @ngdoc directive
 * @name tiers.directive:rattachementUserTiers
 * @restrict EA
 * @description Directive allowing to attach an account on tiers
 * @example
 *
 * <rattachement-user-tiers
 *   user="currentUser"
 *   mdm="mdm"
 *   url-file-icons="urlFileIcons"
 *   view-configuration="rattachementUserConfiguration">
 * </rattachement-user-tiers>
 */
angular.module('tiers').directive('rattachementUserTiers', [
  '$modal',
  function ($modal) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      templateUrl: 'tiers/tiers-directives/rattachement-user-tiers/rattachement-user-tiers.html',
      scope: {
        viewConfiguration: '=',
        configurationId: '=?',
        persistenceId: '=',
        user: '=',
        mdm: '=',
        urlFileIcons: '=',
      },

      link: function ($scope) {
        $scope.configurationId = $scope.configurationId || 'rattachement';

        /**
         * Attach account on tiers
         */
        $scope.attachAccountOnTiers = function () {
          var scopeModal = $scope.$new();
          scopeModal.user = $scope.user;
          scopeModal.viewConfiguration = $scope.viewConfiguration;
          scopeModal.urlFileIcons = $scope.urlFileIcons;
          scopeModal.mdm = $scope.mdm;

          $modal({
            scope: scopeModal,
            template: 'tiers/tiers-directives/rattachement-user-tiers/modal/rattachement.html',
            backdrop: 'static',
          });
        };
      },
    };
  },
]);

'use strict';
class PartageAidesService {
  /* @ngInject */
  constructor($http, $log, $q, aidesService) {
    this.$http = $http;
    this.$log = $log;
    this.$q = $q;
    this.aidesService = aidesService;
  }

  /**
   * Get sharings of given demande
   *
   * @param {object} aide
   * @returns {Promise<string[]>} email list
   */
  getSharings(aide) {
    if (!aide?.id) return this.$q.resolve([]);

    return this.$http
      .get(`${aide.id}/shared-with`)
      .then(({ data }) => {
        return data || [];
      })
      .catch((err) => {
        const message = `An error occurred trying to fetch sharings on demande ${aide.reference}`;
        this.$log.error(message, err);
        throw new Error(message);
      });
  }

  /**
   * Update sharings of demande
   *
   * @param {object} aide
   * @param {string[]} emails
   * @returns {Promise<void>}
   */
  saveSharings(aide, emails) {
    if (!aide?.id) return this.$q.reject(new Error('saveSharings - id must be defined'));

    return this.$http
      .put(`${aide.id}/shared-with`, emails)
      .then(({ data }) => {
        return data || [];
      })
      .catch((err) => {
        const message = `An error occurred trying to save sharings on demande ${aide.reference}`;
        this.$log.error(message, err);
        throw new Error(message);
      });
  }

  /**
   * Check if demande is shared with some users
   *
   * @param {object} aide
   * @returns {Promise<boolean>} true if shared
   */
  isShared(aide) {
    if (!aide?.id) return this.$q.resolve(false);

    return this.$http
      .get(`${aide.id}/shared`)
      .then(({ data }) => {
        return data === 'true';
      })
      .catch((err) => {
        const message = `An error occurred trying to fetch shared state of demande ${aide.reference}`;
        this.$log.error(message, err);
        throw new Error(message);
      });
  }

  /**
   * Check if demande can be shared
   *
   * @param {object} aide
   * @returns {boolean} true if can be shared
   */
  isSharable(aide) {
    return this.aidesService.isRequested(aide);
  }
}
PartageAidesService.$inject = ['$http', '$log', '$q', 'aidesService'];

/**
 * @name partageAidesService
 * @class PartageAidesService
 * @memberOf angular_module.aides.services
 * @description Helpers for sharing a demande
 */
angular.module('aides.services').service('partageAidesService', PartageAidesService);

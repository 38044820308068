'use strict';
JustificationTableauSyntheseConclusionController.$inject = [
  '$filter',
  '$translate',
  'justificationTableauSyntheseDepensesRecettesService',
  'planFinancementUtils',
]; // Module declaration
angular.module('portailDepotDemandeAide.depot').component('justificationTableauSyntheseConclusionComponent', {
  templateUrl:
    'depot/justification/tableau-synthese-conclusion/justification-tableau-synthese-conclusion.component.html',
  controller: /* @ngInject */ JustificationTableauSyntheseConclusionController,
  bindings: {
    navigationDelegate: '<',
    workflow: '<',
    justification: '=',
    print: '<',
    planFinancementModel: '<',
  },
});

/**
 * Controller of JustificationTableauSyntheseConclusion
 *
 * @param {object} $filter Filter
 * @param {object} $translate Translate
 * @param {object} justificationTableauSyntheseDepensesRecettesService Service of justificationTableauSyntheseDepensesRecettes
 * @param {object} planFinancementUtils Utils of planFinancement
 * @returns {void}
 */
function JustificationTableauSyntheseConclusionController(
  $filter,
  $translate,
  justificationTableauSyntheseDepensesRecettesService,
  planFinancementUtils
) {
  var ctrl = this;

  ctrl.$onInit = function () {
    ctrl.initLabels();

    ctrl.viewConfiguration = {
      fields: {
        syntheseDepenseCommentaire: { label: ctrl.labels.complementsDepense },
        syntheseRecetteCommentaire: { label: ctrl.labels.complementsRecette },
      },

      ns: 'justification.tableauSynthese',
    };
  };

  /**
   * The sum of the expected resource
   *
   * @param {string} resource either 'recette' or 'depense'
   * @returns {number} sum expected
   */
  ctrl.sumExpected = function (resource) {
    const resDatas = ctrl.justification?.tableau[resource];
    if (!resDatas) return 0;

    const sum = (resDatas.montant?.ht || 0) + (resDatas.montant?.ttc || 0);
    return planFinancementUtils.parseFloat2Dec(sum);
  };

  /**
   * The sum of the resource
   *
   * @param {string} resource either 'recette' or 'depense'
   * @returns {number} sum of the resource
   */
  ctrl.sumRessources = function (resource) {
    const resDatas = ctrl.justification?.tableau[resource];
    if (!resDatas) return 0;

    const sum = _.sumBy(resDatas.postes, (poste) => {
      let posteSum = 0;
      if (poste.lignes.length) {
        posteSum +=
          _.sumBy(poste.lignes, (ligne) => {
            const isCumulable = justificationTableauSyntheseDepensesRecettesService.isLineCumulable(
              ligne.reference,
              poste.reference,
              ctrl.planFinancementModel,
              resource
            );

            return isCumulable ? _.get(ligne, 'montantRealise.ht') || _.get(ligne, 'montantRealise.ttc', 0) : 0;
          }) || 0;
      }
      if (poste.sousPostes.length) {
        let sousPosteSum = 0;
        _.forEach(poste.sousPostes, (sousPoste) => {
          sousPosteSum +=
            _.sumBy(sousPoste.lignes, (ligne) => {
              return _.get(ligne, 'montantRealise.ht') || _.get(ligne, 'montantRealise.ttc', 0);
            }) || 0;
        });
        posteSum += sousPosteSum;
      }
      return posteSum;
    });

    return planFinancementUtils.parseFloat2Dec(sum);
  };

  /**
   *
   * @param operator
   */
  function calcDiff(operator = 1) {
    let diff = operator * (ctrl.sumRessources('depense') - ctrl.sumRessources('recette'));
    diff = planFinancementUtils.parseFloat2Dec(diff);
    if (diff > 0) return $filter('currency')(diff);
    else return false;
  }

  /**
   * Calculate deficit
   *
   * @returns {number} diff number
   */
  ctrl.deficit = function () {
    return calcDiff(1);
  };
  /**
   * Calculate exceeding
   *
   * @returns {number}
   */
  ctrl.exceeding = function () {
    return calcDiff(-1);
  };

  /**
   * Compute deficit text
   *
   * @returns {string}
   */
  ctrl.deficitText = function () {
    const customText = _.get(ctrl.workflow, 'fields.messages.deficit', '').replace('{{montant}}', ctrl.deficit());
    // if no custom text, use default text
    if (customText === '') {
      return $translate.instant('justification.tableauSynthese.synthese.depense.alerte.deficit', {
        montant: ctrl.deficit(),
      });
    }
    return customText;
  };
  /**
   * Compute exceeding text
   *
   * @returns {string}
   */
  ctrl.exceedingText = function () {
    const customText = _.get(ctrl.workflow, 'fields.messages.excedent', '').replace('{{montant}}', ctrl.exceeding());
    // if no custom text, use default text
    if (customText === '') {
      return $translate.instant('justification.tableauSynthese.synthese.depense.alerte.exceeding', {
        montant: ctrl.exceeding(),
      });
    }
    return customText;
  };

  /**
   * Initialize custom labels and use default if not found
   *
   * @returns {void}
   */
  ctrl.initLabels = function () {
    const fields = _.get(ctrl.workflow, 'fields.synthese', {});

    /**
     * Translate a label for tableau synthese
     *
     * @param {string} key
     * @returns {string}
     */
    const translate = (key) => $translate.instant(`justification.tableauSynthese.synthese.${key}`);

    ctrl.labels = {
      label: fields.label || translate('title'),
      totalDepense: fields.totalDepense || translate('depense.title'),
      expectedDepense: fields.expectedDepense || translate('depense.prevu'),
      realDepense: fields.realDepense || translate('depense.realise'),
      complementsDepense: fields.complementsDepense || translate('depense.commentaire'),
      totalRecette: fields.totalRecette || translate('recette.title'),
      expectedRecette: fields.expectedRecette || translate('recette.prevu'),
      realRecette: fields.realRecette || translate('recette.realise'),
      complementsRecette: fields.complementsRecette || translate('recette.commentaire'),
    };
  };
}

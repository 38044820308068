'use strict';
/**
 * Select on a tree of two levels.
 *
 * @param model: list of items (LinkType)
 * @param categories: list of categories in which selectable items are displayed
 * @params source: list of selectable items referencing their respective category
 * @param categoryKey: string key to access category's reference from an expanded item (item.expand[categoryKey])
 * @param max (optional): maximum selection allowed
 */
angular.module('common.directives').directive('multipleSelectField', multipleSelectField);

/**
 *
 */
function multipleSelectField() {
  'use strict';

  return {
    restrict: 'EA',
    replace: true,
    templateUrl: 'common/common-directives/multiple-select-field/multiple-select-field.html',
    scope: {
      viewConfiguration: '=?',
      configuration: '=?',
      model: '=',
      name: '@',
      categories: '=',
      categoryKey: '@',
      source: '=',
      required: '=?',
      readOnly: '=?',
      max: '=?',
      remoteValidation: '=?',
    },

    require: '^form',
    link: function (scope, elements, attr, formController) {
      scope.model = scope.model || [];
      scope.configuration = scope.configuration || _.get(scope.viewConfiguration, 'fields.' + scope.name) || {};
      scope.required = scope.required || scope.configuration.required;
      scope.form = formController;

      // Read remote validation from directive, configuration or viewConfiguration
      if (scope.remoteValidation === undefined) {
        if (scope.configuration.remoteValidation !== undefined) {
          scope.remoteValidation = scope.configuration.remoteValidation;
        } else if (_.get(scope, 'viewConfiguration.remoteValidation') !== undefined) {
          scope.remoteValidation = _.get(scope, 'viewConfiguration.remoteValidation');
        } else {
          scope.remoteValidation = false;
        }
      }

      // UX tree state
      scope.showCategories = false;
      scope.openedCategory = {};
      // Keep track of selected checkboxes
      scope.selected = {};

      // Build selectables and folders list
      scope.selectables = _.map(scope.source, function (item) {
        return item.expand ? item.expand : item;
      });
      scope.folders = _.map(scope.categories, function (category) {
        return category.expand ? category.expand : category;
      });

      // Initialise displayed model
      scope.$watch(
        'model',
        function () {
          // String used to validate or not the field (if required)
          scope.itemSelected = scope.model.length > 0 ? 'selected' : '';
          // Check already selected items
          _.each(scope.model, function (link) {
            scope.selected[link.href] = true;
          });
        },
        true
      );

      /**
       * Close / open a category
       *
       * @param categoryRef
       */
      scope.switchCategory = function (categoryRef) {
        // Change reference of currently opened category
        scope.openedCategory.reference = scope.openedCategory.reference !== categoryRef ? categoryRef : '';
        // Load items belonging to that category
        scope.openedCategory.items = _.filter(scope.selectables, function (item) {
          return _.get(item, scope.categoryKey) === categoryRef;
        });
      };

      scope.getCategoryTitle = function (reference) {
        return _.get(_.find(scope.folders, { reference: reference }), 'title') || '';
      };

      scope.getCategoryFromItemHref = function (itemHref) {
        var item = _.find(scope.selectables, { id: itemHref });
        return _.find(scope.folders, { reference: _.get(item, scope.categoryKey) });
      };

      /**
       * Select or unselect an item
       *
       * @param item
       */
      scope.selectItem = function (item) {
        // Find item in model
        var index = _.findIndex(scope.model, { href: item.id });
        if (index > -1) {
          // If already selected, remove it
          scope.removeItem(index);
        } else {
          // Else, add it to model
          scope.model.push({
            href: item.id,
            title: item.title,
          });
        }
      };

      /**
       * Remove an item from model
       *
       * @param index
       */
      scope.removeItem = function (index) {
        if (index < scope.model.length) {
          scope.selected[scope.model[index].href] = false;
          scope.model.splice(index, 1);
        }
      };

      /**
       * Search through categories and items with search field
       *
       * @param searchValue
       */
      scope.searchItem = function (searchValue) {
        searchValue = searchValue.toLowerCase();
        return _.filter(scope.selectables, function (item) {
          // Only show not selected items
          return (
            !scope.selected[item.id] &&
            (_.includes(item.title.toLowerCase(), searchValue) ||
              _.includes(scope.getCategoryTitle(item[scope.categoryKey]).toLowerCase(), searchValue))
          );
        });
      };
    },
  };
}

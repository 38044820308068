'use strict';
/**
 * @param $modal
 * @param $timeout
 * @param adresseService
 * @param licenseService
 * @param alertsService
 * @param $translate
 * @ngdoc directive
 * @name tiers.directive: coordonneesForm
 * @restrict EA
 * @description Directive for create a tiers's address.
 */
angular.module('tiers').directive('coordonneesForm', [
  '$modal',
  '$timeout',
  'adresseService',
  'licenseService',
  'alertsService',
  '$translate',
  function ($modal, $timeout, adresseService, licenseService, alertsService, $translate) {
    'use strict';

    return {
      restrict: 'EA',
      replace: 'true',
      transclude: true,
      templateUrl: 'tiers/tiers-directives/coordonnees-form/coordonnees-form.html',
      require: '^form',
      scope: {
        coordonnees: '=',
        tiers: '=',
        representant: '=',
        viewConfiguration: '=',
        adresseEtrangereFieldDisabled: '=',
        mdm: '=',
        moral: '=?',
      },

      link: function (scope, element, attrs, formController) {
        _.set(
          scope,
          'viewConfiguration.fields.PointRemise.label',
          $translate.instant(`${scope.viewConfiguration.ns}.PointRemise.label`)
        );

        _.set(
          scope,
          'viewConfiguration.fields.PointRemise.help',
          $translate.instant(`${scope.viewConfiguration.ns}.PointRemise.help`)
        );

        scope.form = formController;

        // moral is true by default
        scope.moral = scope.moral !== false;

        // Prepare placeholders for transation
        const typeTiers = scope.moral ? 'tiersMoral' : 'tiersPhysique';
        scope.fieldsPlaceholders = {
          PointRemise: `${scope.viewConfiguration.ns}.PointRemise.placeholder.${typeTiers}`,
          Complement: `${scope.viewConfiguration.ns}.Complement.placeholder.${typeTiers}`,
          NomVoie: `${scope.viewConfiguration.ns}.NomVoie.placeholder.${typeTiers}`,
          MentionDistribution: `${scope.viewConfiguration.ns}.MentionDistribution.placeholder.${typeTiers}`,
        };

        // Les tests unitaires ne possède pas de mdm, il faut tester la nullité de l'objet
        if (scope.mdm) {
          scope.pays =
            scope.mdm.pays.array
              ?.filter((pays) => pays.expand.reference !== 'FR')
              ?.map((pays) => ({
                code: pays.expand.reference,
                value: pays.expand.libelle.value,
              })) || [];
        }

        // Search field is shown if apiBan option is active
        scope.apiBanConfiguration = {
          active: adresseService.isApiBanActive(),
          logo: adresseService.isDefaultApiUsed(),
          field: '',
        };

        // Check licence apiban
        scope.apibanAlerts = [];
        if (scope.apiBanConfiguration.active && !licenseService.getPresenceLicence('connecteur-api-ban')) {
          // Display alert if not present
          scope.apibanAlerts.push(
            alertsService.getAlertWarning(scope.viewConfiguration.ns + '.apiBanSearch.licence')[0]
          );
        }

        /**
         * Faux champs concaténant l'adresse entière
         */
        function concatenateAdresse() {
          var pointRemise = _.get(scope.coordonnees, 'geographique.PointRemise', '');
          var complement = _.get(scope.coordonnees, 'geographique.Complement', '');
          var nomVoie = _.get(scope.coordonnees, 'geographique.NomVoie', '');
          var mentionDistribution = _.get(scope.coordonnees, 'geographique.MentionDistribution', '');
          return pointRemise + complement + nomVoie + mentionDistribution;
        }
        scope.pointRemiseHide = concatenateAdresse();
        // copie de la confid de Pointremise dans PointRemiseHide
        scope.viewConfiguration.fields.PointRemiseHide = _.cloneDeep(scope.viewConfiguration.fields.PointRemise);

        // Si pas entrangère, il faut se mettre à FRANCE par défaut
        scope.$watch('coordonnees.etrangere', function (value) {
          if (!value) {
            _.set(scope.coordonnees, 'geographique.Pays', {
              code: 'FR',
              value: 'FRANCE',
            });
          }
        });

        /**
         *
         */
        function setCodePostalValidity() {
          scope.form.codePostalVille.$setValidity('pattern', true);
          // trigger the address validation in case address changed (for example cedex)
          scope.validateAddress();
        }

        scope.defineCodePostalVille = function (item) {
          if (scope.coordonnees.geographique.MentionDistribution) {
            if (!item.lbligne5 || (item.lbligne5 && item.lbligne5.length === 0)) {
              item.lbligne5 = scope.coordonnees.geographique.MentionDistribution;
              scope.coordonnees.defineCodePostalVille(item);
              setCodePostalValidity();
            } else {
              var scopeModal = scope.$new();
              scopeModal.newMentionDistribution = item.lbligne5;
              scopeModal.oldMentionDistribution = scope.coordonnees.geographique.MentionDistribution;

              scopeModal.defineCodePostalVille = function (eraseZone4) {
                if (!eraseZone4) {
                  item.lbligne5 = scope.coordonnees.geographique.MentionDistribution;
                }
                scope.coordonnees.defineCodePostalVille(item);
                setCodePostalValidity();
              };

              $modal({
                scope: scopeModal,
                template:
                  'tiers/tiers-directives/coordonnees-form/modal/modal-coordonnees-erase-mentionDistribution.html',
                backdrop: 'static',
              });
            }
          } else {
            scope.coordonnees.defineCodePostalVille(item);
            setCodePostalValidity();
          }
        };

        // At least one line of the adresse should be filled to validate the adress
        // Foreign tiers has to fill pays
        scope.validateAddress = () => {
          scope.pointRemiseHide = concatenateAdresse();
          if (scope.pointRemiseHide || scope.viewConfiguration.remoteValidation) {
            scope.form.PointRemiseHide.$setValidity('required', true);
          } else {
            scope.form.PointRemiseHide.$setValidity('required', false);
          }

          if (!scope.viewConfiguration.remoteValidation) {
            const isEtranger = _.get(scope, 'coordonnees.etrangere');
            const paysValue = _.get(scope, 'coordonnees.geographique.Pays.value');

            if (isEtranger && _.isEmpty(paysValue)) {
              // if foreign with empty country, we don't validate
              scope.form.Pays.$setValidity('required', false);
            }
          }
        };

        scope.isPaysValid = () => {
          const payscode = _.get(scope, 'coordonnees.geographique.Pays.code');
          const wasPreviouslyLoadedWithErrors = scope.viewConfiguration.showErrors || formController.$submitted;

          return wasPreviouslyLoadedWithErrors && !payscode;
        };

        // Search assistant (with API BAN)
        scope.searchApiBan = function (searchValue) {
          return adresseService.searchApiBan(searchValue).catch(function (err) {
            if (_.includes([-1, 404], err.status) && !_.find(scope.apibanAlerts, ['type', 'error'])) {
              scope.apibanAlerts.push(
                alertsService.getAlertError(scope.viewConfiguration.ns + '.apiBanSearch.unreachable', {
                  url: _.get(err, 'config.url'),
                })[0]
              );
            }
            return [];
          });
        };

        // Validate a selection from search
        scope.fillAddressFromBan = function (item) {
          const nomVoie = item.properties.name;
          // if nomVoie is larger than input's maxlength we set the the maxlength at the nowVoie length
          _.set(
            scope,
            'viewConfiguration.fields.NomVoie.maxlength',
            nomVoie && nomVoie.length > scope.defaultMaxLength.NomVoie ? nomVoie.length : scope.defaultMaxLength.NomVoie
          );

          _.set(scope.coordonnees, 'geographique.NomVoie', nomVoie);
          _.set(scope.coordonnees, 'codePostalVille', item.properties.postcode + ' ' + item.properties.city);
          // Empty adresse
          _.set(scope.coordonnees, 'geographique.PointRemise', '');
          _.set(scope.coordonnees, 'geographique.Complement', '');
          _.set(scope.coordonnees, 'geographique.MentionDistribution', '');

          // Validate in a timeout to let scope update PointRemise validity
          $timeout(scope.validateAddress);
          // Use adresseService to get codePostal
          adresseService
            .getCodePostalVilleFromCodes(item.properties.postcode, item.properties.citycode)
            .then(function (codePostalItem) {
              _.set(
                scope,
                'viewConfiguration.fields.MentionDistribution.maxlength',
                codePostalItem.lbligne5 && codePostalItem.lbligne5.length > scope.defaultMaxLength.MentionDistribution
                  ? codePostalItem.lbligne5.length
                  : scope.defaultMaxLength.MentionDistribution
              );

              scope.defineCodePostalVille(codePostalItem);
            });
          scope.apiBanConfiguration.field = '';
        };

        /**
         * Allow to control pays value and delete if it's not valued
         */
        scope.onPaysChange = function () {
          if (_.isEmpty(scope.coordonnees.geographique.Pays)) {
            delete scope.coordonnees.geographique.Pays;
          }
        };

        // Initialisation du formulaire
        $timeout(function () {
          scope.validateAddress();
        });

        scope.defaultMaxLength = {};
        ['PointRemise', 'Complement', 'NomVoie', 'MentionDistribution'].forEach((field) => {
          _.set(scope, `viewConfiguration.fields.${field}.maxlength`, fieldMaxLength(field));
        });

        /**
         * Get maxLength of field
         * Return data length if it's larger than default maxLength otherwise returns default maxLength from viewConfiguration
         *
         * @param {*} fieldName field to get maxLength from
         */
        function fieldMaxLength(fieldName) {
          const defaultMaxLength = _.get(scope, `viewConfiguration.fields.${fieldName}.maxlength`, 38);
          _.set(scope, `defaultMaxLength.${fieldName}`, defaultMaxLength);
          const data = _.get(scope, `coordonnee.geographique.${fieldName}`, '');
          return data.length > defaultMaxLength ? data.length : defaultMaxLength;
        }
      },
    };
  },
]);

'use strict';
angular.module('aides.services').factory('avisPrealablesService', [
  '$http',
  'configuration',
  'Aide',
  function ($http, configuration, Aide) {
    'use strict';

    return {
      /**
       * Récupération de l'index de l'avis préalable du tiers passé en paramètre
       *
       * @param avisPrealables
       * @param tiers
       */
      getIndexAvisPrealableTiers: function (avisPrealables, tiers) {
        return _.findIndex(avisPrealables, ['tiers.href', tiers.id || tiers.href]);
      },
      /**
       * Récupération de l'avis préalable du tiers passé en paramètre
       *
       * @param avisPrealables
       * @param tiers
       */
      getAvisPrealableTiers: function (avisPrealables, tiers) {
        var avisPrealableTiers;
        if (tiers) {
          avisPrealableTiers = _.find(avisPrealables, ['tiers.href', tiers.id || tiers.href]);
        }

        return avisPrealableTiers;
      },
      /**
       * Dépôt d'un avis préalable sur une demande de financement
       * et modification du statut (la demande passe à l'état TRANSMITTED)
       *
       * @param aide
       * @param avisPrealables
       * @param index
       */
      patchAvisPrealable: (aide, avisPrealables, index) => {
        const patches = [];

        if (!aide.avisPrealables) {
          patches.push({
            op: 'add',
            path: '/avisPrealables',
            value: [],
          });
        }

        patches.push({
          op: 'replace',
          path: `/avisPrealables/${index}`,
          value: avisPrealables,
        });

        patches.push({
          op: 'replace',
          path: '/status',
          value: 'TRANSMITTED',
        });

        const tenantId = _.get(configuration, 'tenant.id');
        const url = `/aides/api/tenants/${tenantId}/contributions-avis-prealable/${aide.reference}`;

        return $http.patch(url, patches).then(({ data }) => data);
      },

      /**
       * Récupération de la liste des demandes de financement
       * pour lesquelles je dois déposer ou ai déposé un avis préalables
       *
       * @param expand Expand
       */
      getContributionsPourAvisPrealables: function (expand) {
        expand = expand || [];

        var config = {
          params: {},
        };

        if (!_.isEmpty(expand)) {
          config.params.$expand = expand.join(',');
        }

        return $http
          .get('/aides/api/tenants/' + _.get(configuration, 'tenant.id') + '/contributions-avis-prealable', config)
          .then(function (response) {
            return response.data;
          });
      },

      /**
       * Récupération de la liste des demandes de financement avec juste comme données 'reference' et 'avisPrealables'
       * pour pouvoir les compter dans le dasboard
       */
      getDemandesFinancementWithJustAvisPrealablesForCount: function () {
        return $http
          .get(
            '/aides/api/tenants/' +
              _.get(configuration, 'tenant.id') +
              '/contributions-avis-prealable?$top=10000&$select=reference,avisPrealables'
          )
          .then(function (response) {
            return response.data;
          });
      },

      /**
       * Récupération d'une aide sur laquelle je dois déposer ou j'ai déposé un avis préalable
       *
       * @param reference Référence de la demande de financement
       * @param expands Expand
       * @param mdm Mdm
       */
      getContributionPourAvisPrealables: function (reference, mdm, expands) {
        expands = expands || [];

        var config = {
          params: {},
        };

        if (!_.isEmpty(expands)) {
          config.params.$expand = expands;
        }

        return $http
          .get(
            '/aides/api/tenants/' + _.get(configuration, 'tenant.id') + '/contributions-avis-prealable/' + reference,
            config
          )
          .then(function (response) {
            return new Aide(response.data, mdm);
          });
      },
    };
  },
]);

'use strict';
ContributionAvisPiecesComponent.$inject = [
  '$scope',
  '$translate',
  'piecesService',
  '$http',
  'contributionsService',
  'jsonpatch',
]; // Module declaration
angular.module('portailDepotDemandeAide.depot').component('contributionAvisPiecesComponent', {
  templateUrl: 'depot/contributions-avis/pieces/contributions-avis-pieces.component.html',
  controller: /* @ngInject */ ContributionAvisPiecesComponent,
  bindings: {
    contribution: '=',
    navigationHelper: '<',
    workflow: '<',
    viewConfiguration: '<',
    publicSettingsFinancement: '<',
    persistence: '<',
  },
});

/**
 * Controller
 *
 * @param $scope
 * @param $translate
 * @param piecesService
 * @param $http
 * @param contributionsService
 * @param jsonpatch
 */
function ContributionAvisPiecesComponent($scope, $translate, piecesService, $http, contributionsService, jsonpatch) {
  var ctrl = this;

  ctrl.$onInit = function () {
    ctrl.navigationDelegate = {
      nextButton: {
        title: $translate.instant('contributions.common.next'),
        action: ctrl.nextStep,
      },

      previousButton: {
        title: $translate.instant('contributions.common.previous'),
        action: ctrl.previousStep,
      },
    };

    ctrl.piecesViewConfiguration = {
      ns: 'teleservice.pieces',
    };

    contributionsService.expandTiersForContribution(ctrl.contribution).then(function (tiers) {
      _.set(ctrl, 'contribution.tiers', _.get(tiers, 'data'));
      _.set(ctrl, 'contribution.tiers.href', _.get(tiers, 'data.id'));
    });

    // Url where to upload files
    ctrl.urlDocuments = _.get(ctrl.contribution, "demande._links['mgs:documents'].href");
    if (!ctrl.urlDocuments) {
      //Si on ne trouve pas le _links (pas d'expand de fait),
      //alors on va requeter la demand pour pouvoir le récupérer
      contributionsService.expandDemandeForContribution(ctrl.contribution).then(function (result) {
        ctrl.urlDocuments = _.get(result.data, "_links['mgs:documents'].href");
      });
    }
    // Directory of file's icons
    ctrl.urlFileIcons = './resources/images/file-icons/';
    // set setting parameter for 1 document by piece
    ctrl.unDocumentParPiece = _.get(ctrl.publicSettingsFinancement, 'unDocumentParPiece', false);

    $scope.isPieceRequired = function (piece) {
      return piecesService.evalConditionPiece($scope, piece, 'conditionObligatoire');
    };
  };

  ctrl.save = () => {
    if (_.get(ctrl.contribution, 'avis.pieces')) {
      const patch = [
        {
          op: 'add',
          path: '/avis/pieces',
          value: ctrl.contribution.avis.pieces,
        },
      ];

      // 'contributionPatched' event broadcasting is blocked, it's not needed when uploading
      // and it could update the contribution during pending promises which would cause an instable state
      const blockEvent = true;

      return contributionsService.updateContributionAndSendResultToParent(
        ctrl.contribution.reference,
        patch,
        blockEvent
      );
    }
  };

  ctrl.previousStep = function () {
    if (!ctrl.uploadInProgress) {
      ctrl.save();
      ctrl.navigationHelper.goToPreviousStep();
    }
  };

  ctrl.nextStep = function () {
    if (!ctrl.uploadInProgress && $scope.contributionPiecesForm.$valid) {
      ctrl.save();
      ctrl.navigationHelper.goToNextStep();
    }
  };

  // Pieces
  ctrl.isPieceVisible = function (piece) {
    return piecesService.evalConditionPiece($scope, piece, 'conditionAffichage');
  };

  ctrl.lockNavigation = function () {
    ctrl.uploadInProgress = true;
  };

  ctrl.unlockNavigation = function () {
    ctrl.uploadInProgress = false;
  };
}

'use strict';
angular
  .module('common.directives')

  /**
   * Directive used to scroll when form input elements are masked by footer
   */ .directive('scrollBehindFooter', [
    '$timeout',
    function ($timeout) {
      'use strict';

      return {
        restrict: 'A',
        link: function (scope, element) {
          /**
           * (Re-)initialize the listeners for the current content
           * (uses a debounce because it can be called multiple times when some steps are disabled)
           */
          const init = _.debounce(() => {
            const footer = document.querySelector('.portal-footer');

            // init fields that are not in an iframe
            initFieldsListeners(element[0], footer);

            // Special request for iframe dom elements because we need to get
            // the iframe offset to compute the element offset
            const iframes = element[0].getElementsByTagName('iframe');
            if (!_.isEmpty(iframes)) {
              for (const iframe of iframes) {
                // init iframe fields focus listener after it has been loaded
                iframe.addEventListener('load', () => {
                  $timeout(() => {
                    const iframeContentAccessible = iframe.contentWindow && iframe.contentWindow.document;

                    // iframe content might not be accessible
                    // for example if the callback is called for an iframe of a
                    // previous page that is not loaded anymore
                    if (iframeContentAccessible) {
                      initFieldsListeners(iframe.contentWindow.document, footer, iframe);
                    }
                  }, 1000);
                });
              }
            }
          }, 500);

          // init fields listeners when content change event is broadcasted (e.g. step changed)
          scope.$on('contentChanged', init);

          // init when the view is loaded if "contentChanged" event is not broadcasted (else will be ignored by debounce)
          $timeout(init);
        },
      };
    },
  ]);

const MARGIN = 5;
const WATCHED_ELEMENTS = ['input', 'textarea', 'select', 'button'];

/**
 *
 * @param parent
 * @param footer
 * @param iframe
 */
function initFieldsListeners(parent, footer, iframe) {
  const inputs = getElementByTagNames(parent, WATCHED_ELEMENTS);

  _.forEach(inputs, function (input) {
    angular.element(input).on('focus', () => scrollToDisplayElementIfBelowFooter(input, footer, iframe));
  });
}

/**
 * Check if element is below footer and scroll to display it if it is
 *
 * @param {*} element element to check
 * @param {*} footer app footer
 * @param iframeElement
 */
function scrollToDisplayElementIfBelowFooter(element, footer, iframeElement) {
  if (element && footer) {
    const elementBounds = element.getBoundingClientRect();
    const footerBounds = footer.getBoundingClientRect();

    const offsetToFocusedElementBottom = offsetToElementBottom(elementBounds, iframeElement);
    const offsetToFooterTop = footerBounds.top;
    const focusedElementBelowFooter = offsetToFocusedElementBottom > offsetToFooterTop;

    if (focusedElementBelowFooter) {
      const elementHeightBiggerThanWindow = elementBounds.height > window.innerHeight;

      let newYScroll;
      if (elementHeightBiggerThanWindow) {
        // scroll to have the top of the field as screen top if it is bigger than window
        newYScroll = window.scrollY + offsetToElementTop(elementBounds, iframeElement) - MARGIN;
      } else {
        // else scroll to have the bottom of the field just over the footer
        newYScroll = window.scrollY + offsetToFocusedElementBottom - window.innerHeight + footerBounds.height + MARGIN;
      }

      window.scroll(window.scrollX, newYScroll);
    }
  }
}

/**
 * Returns the position of the element bottom relative to the top of what is displayed on screen
 *
 * @param {*} elementBounds bounds of the element
 * @param {*} iframe iframe if the element is in an iframe
 */
function offsetToElementBottom(elementBounds, iframe) {
  let result = elementBounds.bottom;

  if (iframe) {
    result += iframe.getBoundingClientRect().top;
  }

  return result;
}

/**
 * Returns the position of the element top relative to the top of what is displayed on screen
 *
 * @param {*} elementBounds bounds of the element
 * @param {*} iframe iframe if the element is in an iframe
 */
function offsetToElementTop(elementBounds, iframe) {
  let result = elementBounds.top;

  if (iframe) {
    result += iframe.getBoundingClientRect().top;
  }

  return result;
}

/**
 * Get Html elements by tag in parameters
 *
 * @param {*} element
 * @param {*} tags
 */
function getElementByTagNames(element, tags) {
  var inputs = [];
  _.forEach(tags, function (tag) {
    inputs = _.concat(inputs, Array.from(element.getElementsByTagName(tag)));
  });
  return inputs;
}

'use strict';
angular.module('aides.recapitulatif').directive('recapitulatifMinimis', [
  function () {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'aides/aides-directives/recapitulatif/minimis/recapitulatif-minimis.html',
      scope: {
        aide: '=',
      },

      link: function (scope, element, attributes, controllers) {
        scope.viewConfiguration = {
          ns: 'teleservice.minimis',
        };
      },
    };
  },
]);

'use strict';
EtablissementController.$inject = ['$http'];
/**
 * Select field with full text search on etablissement
 * name: The fieldname as String
 * model: Ng-model
 * teleservice: The selected teleservice
 * viewConfiguration: viewConfiration
 */
angular.module('aides').component('etablissement', {
  templateUrl: 'aides/aides-components/etablissement/etablissement.html',
  bindings: {
    name: '@',
    model: '=',
    teleservice: '<',
    viewConfiguration: '<',
    readOnly: '<',
  },

  controller: EtablissementController,
});

EtablissementController.$inject = ['$http'];

/**
 *
 * @param $http
 */
function EtablissementController($http) {
  this.$onInit = () => {
    // get the current version of the teleservice
    // (the version given in bindings is the revision from when the aide was created)
    const teleserviceHref = _.get(this.teleservice, 'id');
    if (teleserviceHref) {
      $http.get(teleserviceHref).then((res) => {
        this.filterEtablissementsUrl = _.get(
          res,
          'data.workflow.simple.pageInformationsGenerales.etablissementQuery.href'
        );
      });
    }

    if (this.viewConfiguration && !_.get(this.viewConfiguration, 'fields.' + this.name)) {
      _.setWith(this.viewConfiguration, 'fields.' + this.name, {});
    }
    this.configuration = _.get(this.viewConfiguration, 'fields.' + this.name);
  };

  /**
   * Called every time the user choose a etablissement
   *
   * @param  {[Object]} item    the item selected
   * @param  {[String]} element The name of the element that fired the event
   */
  this.onChangeEtablissement = (item) => {
    this.model = item;
  };

  /**
   * Called when the search field emit its blur event, and unset the model value if it is null
   * (a null value may cause errors, for example when saving the aide if aide.etablissement is set on the model)
   */
  this.onBlur = () => {
    if (this.model === null) {
      delete this.model;
    }
  };

  /**
   * Filter on a subset of tiers tiers by title according to the query set on the teleservice
   * (use the current version of the teleservice and not a revision)
   *
   * @param search
   */
  this.filterEtablissements = (search) => {
    if (this.filterEtablissementsUrl) {
      const queryParamSeparator = this.filterEtablissementsUrl.indexOf('?') === -1 ? '?' : '&';
      const searchedTerm = encodeURI(search).replace(/'/g, "''");
      const filter = `$filter=(status eq 'SUPPORTED' or status eq 'BLOCKED') and substringof(title,'${searchedTerm}')`;
      return $http.get(urljoin(this.filterEtablissementsUrl, `${queryParamSeparator}${filter}`)).then((response) => {
        return _.sortBy(
          _.map(response.data, (tiers) => {
            const ville = tiers.ville ? ` - ${tiers.ville}` : '';
            const siret =
              tiers.SIRET && tiers.SIRET.SIREN && tiers.SIRET.NIC ? ` (${tiers.SIRET.SIREN} ${tiers.SIRET.NIC})` : '';
            return {
              href: tiers.id,
              title: `${tiers.title}${ville}${siret}`,
            };
          }),
          ['title']
        );
      });
    } else {
      return [];
    }
  };
}

'use strict';
angular.module('tiers.model').factory('Courriel', [
  function () {
    'use strict';

    /**
     *
     * @param data
     */
    function Courriel(data) {
      data = data || {};
      this.OTHERTYPE = data.OTHERTYPE;
      this.TYPE = data.TYPE;
      this.lang = data.lang;
      this.value = data.value;
    }

    return Courriel;
  },
]);

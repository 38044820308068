'use strict';
angular.module('aides').component('recapitulatifMoyensHumains', {
  templateUrl: 'aides/aides-components/recapitulatif/moyens-humains/recapitulatif-moyens-humains.component.html',
  bindings: {
    aide: '<',
    contribution: '<',
    tiers: '<',
    teleservice: '<',
    mdm: '<',
    namespace: '<',
    user: '<?',
  },

  controller: recapitulatifMoyensHumains,
});

/**
 *
 */
function recapitulatifMoyensHumains() {
  var vm = this;

  this.$onInit = function () {
    vm.moyensHumainsConfiguration = _.get(
      vm.teleservice,
      'workflow.simple.pageInformationsGenerales.moyensHumains',
      {}
    );

    vm.moyensHumainsTeleservice = _.get(vm.moyensHumainsConfiguration, 'moyensHumains', {});
    vm.moyensHumains = vm.aide.moyensHumains || {};

    // Récupération des libellé personnalisés
    vm.nbEtptTeleservice = getElementByReference(vm.moyensHumainsConfiguration.fields, 'nbEtpt');
    vm.nbPersonnesTeleservice = getElementByReference(vm.moyensHumainsConfiguration.fields, 'nbPersonnes');
    vm.etptTotalTeleservice = getElementByReference(vm.moyensHumainsConfiguration.fields, 'etptTotal');
    vm.descriptionTeleservice = getElementByReference(vm.moyensHumainsConfiguration.fields, 'description');
    vm.recrutementTeleservice = _.get(vm.moyensHumainsConfiguration, 'recrutement', {});

    // Affichage du flag booléen
    vm.moyensHumains.recrutementQuestionValue = vm.moyensHumains.recrutementQuestion ? 'Oui' : 'Non';

    // Construction de l'élément permettant la construction du tableau
    vm.moyensHumainsValues = [];
    _.forEach(vm.moyensHumainsTeleservice, function (moyenHumainTeleservice) {
      var moyenHumain = {
        premierNiveau: {
          title: moyenHumainTeleservice.premierNiveau.title,
          href: moyenHumainTeleservice.premierNiveau.href,
          nbPersonnesTotal: 0,
          nbEtptTotal: 0,
        },

        deuxiemesNiveaux: initDeuxiemeNiveau(moyenHumainTeleservice),
      };

      if (_.isEmpty(moyenHumain.deuxiemesNiveaux)) {
        moyenHumain.premierNiveau.nbPersonnesTotal =
          getNbValueByHref(vm.moyensHumains.content, moyenHumainTeleservice.premierNiveau.href, 'nbPersonnes') || 0;
        moyenHumain.premierNiveau.nbEtptTotal =
          getNbValueByHref(vm.moyensHumains.content, moyenHumainTeleservice.premierNiveau.href, 'nbETPT') || 0;
      }

      vm.moyensHumainsValues.push(moyenHumain);
    });

    // Mise à jour des totaux
    _.forEach(vm.moyensHumainsValues, function (moyenHumain) {
      _.forEach(moyenHumain.deuxiemesNiveaux, function (moyenHumainNiv2) {
        moyenHumain.premierNiveau.nbPersonnesTotal += moyenHumainNiv2.nbPersonnes;
        moyenHumain.premierNiveau.nbEtptTotal += moyenHumainNiv2.nbETPT;
      });
    });
  };

  /**
   * Retourne l'élément correspondant à la référence passée en paramètre
   *
   * @param {*} fields
   * @param {*} reference
   * @returns {object}
   */
  function getElementByReference(fields, reference) {
    return _.find(fields, function (element) {
      if (element.reference === reference) {
        return element;
      }
    });
  }

  /**
   * Retourne le moyen humain au bon format
   *
   * @param {*} moyenHumain
   * @returns {object}
   */
  function initDeuxiemeNiveau(moyenHumain) {
    var deuxiemesNiveaux = [];

    _.forEach(moyenHumain.deuxiemesNiveaux, function (deuxiemeNiveau) {
      deuxiemesNiveaux.push({
        title: deuxiemeNiveau.title,
        href: deuxiemeNiveau.href,
        nbPersonnes: getNbValueByHref(vm.moyensHumains.content, deuxiemeNiveau.href, 'nbPersonnes') || 0,
        nbETPT: getNbValueByHref(vm.moyensHumains.content, deuxiemeNiveau.href, 'nbETPT') || 0,
      });
    });

    return deuxiemesNiveaux;
  }

  /**
   * Retourne la valeur passée en paramètre du moyen humain
   *
   * @param {*} moyensHumainsValues
   * @param {*} href
   * @param {*} element
   * @returns {integer}
   */
  function getNbValueByHref(moyensHumainsValues, href, element) {
    var value = 0;

    _.forEach(moyensHumainsValues, function (moyenHumain) {
      if (moyenHumain.href === href) {
        if (element === 'nbETPT') {
          value = moyenHumain.nbETPT;
        }
        if (element === 'nbPersonnes') {
          value = moyenHumain.nbPersonnes;
        }
      }
    });

    return value;
  }
}

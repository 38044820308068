'use strict';
contributionsModificationsController.$inject = ['$state', '$stateParams', 'contributionsService'];
angular.module('portailDepotDemandeAide.dashboard').component('contributionsModifications', {
  templateUrl: 'dashboard/contributions/contributions-modifications/contributions-modifications.html',
  controller: contributionsModificationsController,
  bindings: {
    contributionsDepart: '<',
  },
});

contributionsModificationsController.$inject = ['$state', '$stateParams', 'contributionsService'];

/**
 *
 * @param $state
 * @param $stateParams
 * @param contributionsService
 */
function contributionsModificationsController($state, $stateParams, contributionsService) {
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.contributions = ctrl.contributionsDepart.contributions;
    ctrl.totalContributions = ctrl.contributionsDepart.totalContributions;
    ctrl.page = 1;
    ctrl.itemsPerPage = 20;
  };

  /**
   * Recherche les contributions
   *
   * @param {*} resetPage - si vrai remet le numéro de page à 1
   */
  ctrl.searchContributions = function (resetPage) {
    if (resetPage) {
      ctrl.page = 1;
    }
    contributionsService
      .searchModificationsForDemande($stateParams.demande, ctrl.page - 1)
      .then(({ contributions, total }) => {
        const { treatedContributions, untreatedContributions } =
          contributionsService.differenciateTreatedFromUntreated(contributions);

        ctrl.contributions = untreatedContributions
          .sort(contributionsService.sortByDateFunction)
          .concat(treatedContributions.sort(contributionsService.sortByDateFunction));
        ctrl.totalContributions = total;
      });
  };

  ctrl.pageDebut = function (numPage) {
    return (numPage - 1) * ctrl.itemsPerPage + 1;
  };

  ctrl.pageFin = function (numPage, contributions) {
    return (numPage - 1) * ctrl.itemsPerPage + contributions.length;
  };
}

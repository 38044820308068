'use strict';
angular.module('portailDepotDemandeAide.depot').controller('demandePaiementDomiciliationBancaireController', [
  '$scope',
  '$q',
  '$modal',
  'aidesService',
  'demandesPaiementService',
  'configuration',
  'cmisService',
  '$log',
  function ($scope, $q, $modal, aidesService, demandesPaiementService, configuration, cmisService, $log) {
    'use strict';

    $scope.currentlyClickingNextBtn = false;
    $scope.pieceUploadInProgress = false;

    // Steps Wizard
    $scope.stepsWizard.steps = $scope.getDemandePaiementSteps();
    $scope.stepsWizard.active = 'domiciliationBancaire';

    // Navigation
    $scope.cleanNavigate();
    $scope.navigate.noform = false;
    $scope.navigate.ns = $scope.domiciliationBancaireConfiguration.ns;

    $scope.navigate.next = function (forget) {
      $scope.currentlyClickingNextBtn = false;
      if ($scope.pieceUploadInProgress) {
        $scope.currentlyClickingNextBtn = true;
      } else {
        // Copy domiciliation bancaire document
        if (!$scope.createDom && $scope.selectedDom >= 0) {
          // gestion en promesse pour que l'on push les modifications du documents suite a la copie
          var promisesCopyDocumentDomiciliationBancaireTiers = [];
          _.each($scope.demandePaiement.domiciliationBancaire.pieces, function (piece) {
            _.each(piece.documents, function (documentPiece) {
              promisesCopyDocumentDomiciliationBancaireTiers.push(
                aidesService.copyDocumentDomiciliationBancaireTiers(
                  $scope.urlDocuments,
                  documentPiece,
                  'demandePaiement',
                  $scope.demandePaiement
                )
              );
            });
          });
          $q.all(promisesCopyDocumentDomiciliationBancaireTiers).then(function () {
            demandesPaiementService.saveDemandePaiement($scope.demandePaiement);
          });
        }
        // The user created a new domiciliation but had selection displayed
        else if ($scope.showDomiciliations) {
          // Search for a correlation in tier's domiciliations
          var compareTo = $scope.demandePaiement.domiciliationBancaire.horsSEPA ? 'horsSEPA[0].valeur' : 'IBAN.numero';
          var correlationFound = _.findIndex(
            _.filter($scope.tiers.domiciliationsBancaires, { actif: true }),
            function (domiciliation) {
              return _.get(domiciliation, compareTo) === _.get($scope.demandePaiement.domiciliationBancaire, compareTo);
            }
          );

          // If a correlation is found, alert user that tiers' already has this domiciliation
          if (correlationFound > -1) {
            // displays confirmation Modal
            var scopeModal = $scope.$new();

            scopeModal.namespace = $scope.navigate.ns;
            // On validation, select the tiers' domiciliation
            scopeModal.validate = function (modal) {
              modal.$hide();
            };

            $modal({
              scope: scopeModal,
              template: 'depot/demande-paiement/domiciliation-bancaire/modal/correlation-confirm.html',
            });

            // Display the modal without going to next page
            return;
          }
        }

        // Go to next page
        $scope.goToStep('pieces', forget);
      }
    };

    // Page Options
    $scope.pageOptions = _.get($scope.teleserviceConfiguration, 'workflow.pageDomiciliationBancaire', {});
    if (!$scope.pageOptions.actif) {
      $scope.navigate.next(true);
    } else {
      $scope.activePage();
    }

    // Url documents
    $scope.urlDocuments = $scope.demandePaiement._links['mgs:documents'].href;

    // Demande de paiement - Save the demande when we add a new document on piece or update a document
    $scope.saveDemandePaiement = function () {
      demandesPaiementService.saveDemandePaiement($scope.demandePaiement);
    };

    /**
     * Manage the action after the upload of pieces
     */
    $scope.saveDemandePaiementOnDomiciliationPiecesUpload = function () {
      const domiciliationBancaire = $scope.demandePaiement.domiciliationBancaire;
      // If no data in form do not save it and keep only the pieces
      if (
        !domiciliationBancaire?.IBAN?.numero &&
        !domiciliationBancaire?.horsSEPA?.find(({ cle }) => cle === 'numero')?.valeur
      ) {
        const patches = [
          {
            op: 'add',
            path: '/domiciliationBancaire',
            value: { pieces: $scope.demandePaiement.domiciliationBancaire.pieces },
          },
        ];
        demandesPaiementService.patchDemandePaiement($scope.demandePaiement.reference, patches);
      } else {
        $scope.saveDemandePaiement();
      }
    };

    // Validation Banque de France
    $scope.bdfFile = _.get(configuration, 'demande-paiement.banqueDeFrance');
    $scope.validationIbanFr = $scope.bdfFile && _.get(configuration, 'demande-paiement.validationIbanFr');

    // Domiciliation defined by user
    var newDomiciliation;

    // Add persistenceConfiguration to allowedExtensions
    $scope.persistenceConfiguration = _.get($scope.teleserviceConfiguration, 'persistenceConfiguration.expand') || {};
    // Sort and filter domiciliations
    $scope.domiciliations = _.orderBy(
      _.filter(_.get($scope, 'tiers.domiciliationsBancaires', []), function (domi) {
        return domi.actif;
      }),
      function (e) {
        return _.get(e, 'principale', false);
      },
      'desc'
    );

    /**
     * Select domiciliation from existing one
     *
     * @param  {number} index Index of domiciliations array
     */
    $scope.selectDomiciliation = function (index) {
      // If we leave the creation form, we keep the data somewhere
      if (
        $scope.selectedDom === -1 &&
        $scope.demandePaiement.domiciliationBancaire &&
        !$scope.demandePaiement.domiciliationBancaire.id
      ) {
        newDomiciliation = $scope.demandePaiement.domiciliationBancaire;
      }
      $scope.demandePaiement.domiciliationBancaire = $scope.domiciliations[index];
      $scope.selectedDom = index;
      $scope.createDom = false;
    };

    $scope.createDomiciliation = function () {
      $scope.demandePaiement.domiciliationBancaire = newDomiciliation;
      $scope.selectedDom = -1;
      $scope.createDom = true;
    };

    // If beneficiaire is user's tiers, and it has domiciliations, the user can pick one of them
    $scope.showDomiciliations =
      $scope.pageOptions.actif &&
      _.get($scope.demandePaiement, 'attributaire.href') === $scope.tiers.id &&
      !!$scope.tiers.domiciliationsBancaires &&
      $scope.tiers.domiciliationsBancaires.length > 0;

    // By default, we're in "creation mode"
    $scope.createDom = true;
    $scope.selectedDom = -1;

    // If there are domiciliations, we search the selected one
    if ($scope.showDomiciliations) {
      var selectedIndex = $scope.demandePaiement.domiciliationBancaire
        ? _.findIndex($scope.domiciliations, function (domiciliation) {
            return domiciliation.id === _.get($scope.demandePaiement, 'domiciliationBancaire.id');
          })
        : 0;

      // The first time the page is loaded, the first domiciliation (0) is selected
      if (selectedIndex > -1) {
        $scope.selectDomiciliation(selectedIndex);
      }
    }

    // Principale domiciliation has a status that show a label
    $scope.principaleStatus = { label: $scope.navigate.ns + '.list.item.principal', class: 'success' };

    $scope.onBeforeUploadPiece = function (updatingDescription = false) {
      $scope.pieceUploadInProgress = true;
      if (!updatingDescription) {
        $scope.navigate.lockNavigation();
      }
    };

    $scope.onAfterUploadPiece = function (updatingDescription = false, success = false) {
      $scope.pieceUploadInProgress = false;
      if (!updatingDescription) {
        $scope.navigate.unlockNavigation();
      }
      if ($scope.currentlyClickingNextBtn) {
        $scope.currentlyClickingNextBtn = false;
        if (success) {
          $scope.navigate.next();
        }
      }
    };
  },
]);

'use strict';
angular.module('aides.recapitulatif').directive('recapitulatifCriteres', [
  function () {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'aides/aides-directives/recapitulatif/criteres/recapitulatif-criteres.html',
      scope: {
        aide: '=',
        namespace: '=',
      },

      link: function (scope, element, attributes, controllers) {
        scope.criteres = angular.copy(_.get(scope, 'aide.criteresEligibilite', []));

        scope.eligible = _.get(scope, 'aide.eligible', false);

        scope.criteresConfiguration = {
          ns: 'teleservice.criteres',
        };
      },
    };
  },
]);

'use strict';
angular.module('aides').component('moyensHumainsDescription', {
  templateUrl: 'aides/aides-components/moyens-humains/moyens-humains.component.html',
  require: {
    form: '?^^form',
  },

  bindings: {
    moyensHumains: '=',
    pageOptions: '<',
    teleservice: '<',
    viewConfiguration: '<',
  },

  controller: moyensHumainsCtrl,
});

/**
 * Controlleur
 */
function moyensHumainsCtrl() {
  var vm = this;
  vm.moyensHumainsValues = [];
  vm.moyensHumains = vm.moyensHumains || {};

  // Initialisation
  this.$onInit = function () {
    vm.moyensHumainsConfiguration = _.get(vm.pageOptions, 'moyensHumains', {});
    vm.moyensHumainsTeleservice = _.get(vm.moyensHumainsConfiguration, 'moyensHumains', {});

    // Récupération des libellé personnalisés
    vm.nbEtptTeleservice = getElementByReference(vm.moyensHumainsConfiguration.fields, 'nbEtpt');
    vm.nbPersonnesTeleservice = getElementByReference(vm.moyensHumainsConfiguration.fields, 'nbPersonnes');
    vm.etptTotalTeleservice = getElementByReference(vm.moyensHumainsConfiguration.fields, 'etptTotal');
    vm.descriptionTeleservice = getElementByReference(vm.moyensHumainsConfiguration.fields, 'description');
    vm.recrutementTeleservice = _.get(vm.moyensHumainsConfiguration, 'recrutement', {});
    vm.htmlHautTeleservice = _.get(vm.moyensHumainsConfiguration, 'htmlHaut', '');
    vm.htmlBasTeleservice = _.get(vm.moyensHumainsConfiguration, 'htmlBas', '');
    vm.titleTeleservice = _.get(vm.moyensHumainsConfiguration, 'title', '');

    vm.viewConfiguration.fields.moyensHumains = {
      label: vm.descriptionTeleservice.label,
      required: vm.descriptionTeleservice.required,
      maxlength: 32500,
      displayCharactersLeft: true,
      hidden: vm.descriptionTeleservice.hidden,
    };

    _.forEach(vm.moyensHumainsTeleservice, function (moyenHumainTeleservice) {
      var moyenHumain = {
        premierNiveau: {
          title: moyenHumainTeleservice.premierNiveau.title,
          href: moyenHumainTeleservice.premierNiveau.href,
          nbPersonnesTotal: null,
          nbEtptTotal: null,
        },

        deuxiemesNiveaux: initDeuxiemeNiveau(moyenHumainTeleservice),
      };

      if (_.isEmpty(moyenHumain.deuxiemesNiveaux)) {
        moyenHumain.premierNiveau.nbPersonnes = getNbValueByHref(
          vm.moyensHumains.content,
          moyenHumainTeleservice.premierNiveau.href,
          'nbPersonnes'
        );

        moyenHumain.premierNiveau.nbETPT = getNbValueByHref(
          vm.moyensHumains.content,
          moyenHumainTeleservice.premierNiveau.href,
          'nbETPT'
        );
      }

      vm.moyensHumainsValues.push(moyenHumain);
    });

    // Ajout des valeurs du tableau dans le modèle
    vm.moyensHumains.content = [];
    _.forEach(vm.moyensHumainsValues, function (moyenHumainValues) {
      if (!_.isEmpty(moyenHumainValues.deuxiemesNiveaux)) {
        vm.updateNbEtptTotal(moyenHumainValues);
        vm.updateNbPersonnesTotal(moyenHumainValues);
        _.forEach(moyenHumainValues.deuxiemesNiveaux, function (moyenHumain2) {
          vm.moyensHumains.content.push(moyenHumain2);
        });
      } else {
        vm.moyensHumains.content.push(moyenHumainValues.premierNiveau);
      }
    });

    // The legend is displayed if there is at least one field to display after it
    vm.displayLegend =
      vm.titleTeleservice !== '' &&
      (!vm.descriptionTeleservice.hidden || vm.moyensHumainsValues.length > 0 || vm.recrutementTeleservice.visible);
  };

  /**
   * Retourne le moyen-humain au bon format
   *
   * @param {*} moyenHumain
   * @returns {object}
   */
  function initDeuxiemeNiveau(moyenHumain) {
    var deuxiemesNiveaux = [];

    _.forEach(moyenHumain.deuxiemesNiveaux, function (deuxiemeNiveau) {
      deuxiemesNiveaux.push({
        title: deuxiemeNiveau.title,
        href: deuxiemeNiveau.href,
        nbPersonnes: getNbValueByHref(vm.moyensHumains.content, deuxiemeNiveau.href, 'nbPersonnes'),
        nbETPT: getNbValueByHref(vm.moyensHumains.content, deuxiemeNiveau.href, 'nbETPT'),
      });
    });

    return deuxiemesNiveaux;
  }

  /**
   * Retourne la valeur passée en paramètre du moyen humain
   *
   * @param {*} moyensHumainsValues
   * @param {*} href
   * @param {*} element
   * @returns {integer}
   */
  function getNbValueByHref(moyensHumainsValues, href, element) {
    var value;

    _.forEach(moyensHumainsValues, function (moyenHumain) {
      if (moyenHumain.href === href) {
        if (element === 'nbETPT') {
          value = Number(moyenHumain.nbETPT);
        }
        if (element === 'nbPersonnes') {
          value = Number(moyenHumain.nbPersonnes);
        }
      }
    });

    return value;
  }

  /**
   * Retourne l'élément correspondant à la référence passée en paramètre
   *
   * @param {*} fields
   * @param {*} reference
   * @returns {object}
   */
  function getElementByReference(fields, reference) {
    return _.find(fields, function (element) {
      if (element.reference === reference) {
        return element;
      }
    });
  }

  /**
   * Mise à jour du total de nombre de personnes
   *
   * @param {*} moyenHumain
   * @returns {integer}
   */
  vm.updateNbPersonnesTotal = function (moyenHumain) {
    moyenHumain.premierNiveau.nbPersonnesTotal = _.sumBy(moyenHumain.deuxiemesNiveaux, function (deuxiemeNiveau) {
      return Number(deuxiemeNiveau.nbPersonnes || 0);
    });
  };

  /**
   * Mise à jour du total de nombre de ETPT
   *
   * @param {*} moyenHumain
   * @returns {number}
   */
  vm.updateNbEtptTotal = function (moyenHumain) {
    moyenHumain.premierNiveau.nbEtptTotal = _.sumBy(moyenHumain.deuxiemesNiveaux, function (deuxiemeNiveau) {
      return Number(deuxiemeNiveau.nbETPT || 0);
    });
  };

  /**
   * Mise à jour des pourcentages
   *
   * @param {*} numerateur
   * @param {*} denominateur
   * @returns {number}
   */
  vm.updatePourcentage = function (numerateur, denominateur) {
    numerateur = Number(numerateur);
    return (numerateur * 100) / denominateur;
  };

  /**
   * Initialisation des ETPT
   *
   * @returns {number}
   */
  vm.reinitETPT = function () {
    return (this.moyensHumains.recrutementNbETPT = null);
  };

  /**
   * Get the reference (the last element of the href)
   *
   * @param {string} href
   * @returns {string}
   */
  vm.getReferenceFromHref = (href) => href.split('/').pop();
}

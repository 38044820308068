'use strict';
/**
 * @ngdoc module
 * @module dossiers
 * @name dossiers
 * @description
 *
 *   The main module for all dossiers related services and directives for our portals
 */

angular.module('dossiers', ['common', 'dossiers.services']);

angular.module('dossiers.services', []);

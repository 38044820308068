'use strict';
angular.module('portailDepotDemandeAide.user', ['user.constants', 'ui.router']);

angular.module('portailDepotDemandeAide.user').config([
  '$stateProvider',
  'USER_ROLES',
  function ($stateProvider) {
    'use strict';

    var resolveConfiguration = function (module, view) {
      return [
        'viewManagerService',
        '$rootScope',
        function (viewManagerService, $rootScope) {
          // Wait for configuration from publicSettings
          return $rootScope.configurationPromise.then(function (configuration) {
            return viewManagerService.getViewConfiguration(module, view);
          });
        },
      ];
    };

    $stateProvider
      .state('app.connected.user', {
        url: '/user',
        abstract: true,
        controller: 'userController',
        template: '<div class="view-full" ui-view></div>',
        resolve: {
          mdm: [
            'mdmService',
            'tiersService',
            function (mdmService, tiersService) {
              return mdmService.fill(['pays']).then(function (mdm) {
                return tiersService.getMasterData(['familles', 'titresCivilites']).then(function (masterdata) {
                  _.set(
                    mdm,
                    'famillestiers.array',
                    _.filter(masterdata.familles.items, function (famille) {
                      return famille.expand.actif;
                    })
                  );

                  _.set(mdm, 'civilites.array', masterdata.titresCivilites.items);
                  return mdm;
                });
              });
            },
          ],
        },
      })
      .state('app.connected.user.account-user', {
        url: '/account-user',
        controller: 'accountUserController',
        templateUrl: 'user/account/account-user/account-user.html',
        resolve: {
          userAccountUserConfiguration: resolveConfiguration('user', 'account-user'),
          rattachementUserConfiguration: resolveConfiguration('tiers', [
            'identification',
            'adresse',
            'piece-form',
            'rattachement-user-tiers',
          ]),

          tiers: [
            'tiersService',
            'mdm',
            (tiersService, mdm) => {
              return tiersService.getCurrentTiers(mdm);
            },
          ],

          currentUser: [
            '$rootScope',
            'accountManagementService',
            ($rootScope, accountManagementService) => {
              return accountManagementService.getAccount($rootScope.currentUser.id).then(function (user) {
                // màj du currentUser qui a pu évoluer suite à la validation des "agreements" (lors de la connection)
                $rootScope.currentUser = _.merge($rootScope.currentUser, user);
              });
            },
          ],
        },
      });
  },
]);

class StepWizardDepotController {
  constructor($scope, $translate) {
    this.$scope = $scope;
    this.$translate = $translate;
  }

  $onInit() {
    const watch = this.$scope.$watch(
      'steps',
      () => {
        if (this.$scope.steps) {
          this.$scope.width = 100 / this.$scope.steps.name.length + '%';
        }
      },
      true
    );

    this.$scope.$on('$destroy', function () {
      watch();
    });
  }

  /**
   * Get the translation for an active step
   *
   * @returns string
   */
  stepInProgressLabel() {
    return this.$translate.instant('teleservice.steps.stepInProgress');
  }

  /**
   * Check if given step is active
   *
   * @param {object} step step item
   * @returns {boolean} true if the given is active
   */
  isActiveStep(step) {
    return step.name === this.active;
  }
}

StepWizardDepotController.$inject = ['$scope', '$translate'];

angular.module('common.components').component('stepWizardDepot', {
  templateUrl: 'common/common-components/steps-wizard-depot/steps-wizard-depot.html',
  controller: StepWizardDepotController,
  bindings: {
    steps: '=',
    labelsPrefix: '=',
    active: '=',
    stepLabel: '@',
    activeStepLabel: '@',
  },
});

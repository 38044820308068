'use strict';
recapitulatifInformationsGenerales.$inject = ['$translate', '$interval', 'recapitulatifService'];

angular
  .module('aides.recapitulatif')
  .directive('recapitulatifInformationsGenerales', recapitulatifInformationsGenerales);

recapitulatifInformationsGenerales.$inject = ['$translate', '$interval', 'recapitulatifService'];

/**
 *
 * @param $translate
 * @param $interval
 * @param recapitulatifService
 */
function recapitulatifInformationsGenerales($translate, $interval, recapitulatifService) {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl:
      'aides/aides-directives/recapitulatif/informations-generales/recapitulatif-informations-generales.html',
    scope: {
      aide: '<',
      contribution: '<',
      teleservice: '<',
      configuration: '<',
      namespace: '<',
    },

    link: function (scope) {
      var fields = _.get(scope.teleservice, 'workflow.simple.pageInformationsGenerales.demandeFinancement.fields');
      _.merge(scope.configuration.fields, _.keyBy(fields, 'reference'));

      scope.fields = _.transform(
        scope.configuration.fields,
        function (fieldsList, field, reference) {
          field.reference = reference;
          field.value = _.get(scope.aide, reference);
          if (_.isObject(field.value) && _.get(field.value, 'value') !== undefined) {
            field.value = field.value.value;
          }

          // Avoid to display '{}' for empty thematique field
          // https://jira.mgdis.fr/browse/PLAID-1985
          if (_.isObject(field.value) && _.isEmpty(field.value)) {
            field.value = '';
          }

          // Diplay frequence, nature and period
          var displayValueFrequence = {
            PREMIERE: $translate.instant('aides.informations-generales.frequence.premiere'),
            RENOUVELLEMENT: $translate.instant('aides.informations-generales.frequence.renouvellement'),
          };

          var displayValueNature = {
            FONCTIONNEMENT_GLOBAL: $translate.instant('aides.informations-generales.nature.fonctionnement-global'),
            PROJET_ACTION: $translate.instant('aides.informations-generales.nature.projet-action'),
          };

          var displayValuePeriod = {
            PONCTUELLE: $translate.instant('aides.informations-generales.periode.ponctuelle'),
            PLURIANNUELLE: $translate.instant('aides.informations-generales.periode.pluriannuelle'),
          };

          if (field.reference === 'frequence') {
            field.value = displayValueFrequence[field.value];
          }
          if (field.reference === 'nature') {
            field.value = displayValueNature[field.value];
          }
          if (field.reference === 'periode') {
            field.value = displayValuePeriod[field.value];
          }

          fieldsList.push(field);
        },
        []
      );

      //Supprime tous les champs dont la valeur est '' ou undefined
      //la valeur est stockée dans .value (exerciceBudgetaire, intitule, description, ...) ou .value.title (thematique, contrat, ...)
      //A cause des champs d'un certain type (Date, ... ), on ne peut pas faire de _.isEmpty,_.isObject, ...
      _.remove(scope.fields, function (field) {
        return (
          field.value === '' ||
          _.isUndefined(field.value) ||
          (_.has(field.value, 'title') && (field.value.title === '' || _.isUndefined(field.value.title)))
        );
      });

      scope.displayRecapLoading = false;

      // dataType isn't specified in the configuration, so we do it here for now
      var montant = _.find(scope.fields, ['reference', 'montant']);
      _.set(montant, 'subType', 'currency');

      // gestion d'un tri pour les thematiques, fréquence, nature et période
      var indexFieldThemathique = _.findIndex(scope.fields, ['reference', 'thematique']);
      var indexFieldPeriode = _.findIndex(scope.fields, ['reference', 'periode']);

      if (indexFieldThemathique !== -1 && indexFieldPeriode !== -1) {
        scope.fields.splice(indexFieldThemathique + 1, 0, scope.fields.splice(indexFieldPeriode, 1)[0]);
      }

      indexFieldThemathique = _.findIndex(scope.fields, ['reference', 'thematique']);
      var indexFieldFrequence = _.findIndex(scope.fields, ['reference', 'frequence']);
      if (indexFieldThemathique !== -1 && indexFieldFrequence !== -1) {
        scope.fields.splice(indexFieldThemathique + 1, 0, scope.fields.splice(indexFieldFrequence, 1)[0]);
      }

      indexFieldThemathique = _.findIndex(scope.fields, ['reference', 'thematique']);
      var indexFieldNature = _.findIndex(scope.fields, ['reference', 'nature']);
      if (indexFieldThemathique !== -1 && indexFieldNature !== -1) {
        scope.fields.splice(indexFieldThemathique + 1, 0, scope.fields.splice(indexFieldNature, 1)[0]);
      }

      indexFieldThemathique = _.findIndex(scope.fields, ['reference', 'thematique']);
      var indexFieldSousThemathique = _.findIndex(scope.fields, ['reference', 'sousThematique']);
      if (indexFieldThemathique !== -1 && indexFieldSousThemathique !== -1) {
        scope.fields.splice(indexFieldThemathique + 1, 0, scope.fields.splice(indexFieldSousThemathique, 1)[0]);
      }

      // Booléen indiquant qu'une requête http a déjà été lancée dans $interval
      var lockInterval = false;
      var intervalRecapitulatif;

      // Look if recaptitulatif already exists
      scope.pieceRecapitulatifPresent = true;
      var pieceRecap = _.find(scope.aide.pieces, ['reference', 'recapitulatif']);
      if (pieceRecap) {
        if (pieceRecap.documents && pieceRecap.documents.length > 0) {
          scope.aide.documentRecapitulatif = _.head(pieceRecap.documents);
          scope.displayRecapLoading =
            !scope.aide.documentRecapitulatif &&
            scope.aide.status !== 'REQUESTED' &&
            scope.aide.status !== 'WAITING_FOR_CERTIFICATE';
        } else if (scope.aide.status !== 'REQUESTED' && scope.aide.status !== 'WAITING_FOR_CERTIFICATE') {
          scope.displayRecapLoading = true;
          // If the recap it doesnt exists, means that worker-pdf is still working
          // we query the server every 3 seconds to verify
          // Note: If in the future we have perf problems with this method, we can migrate to web sockets
          intervalRecapitulatif = $interval(function () {
            if (lockInterval) {
              return;
            }
            // on initialise le "lock"
            lockInterval = true;
            recapitulatifService
              .isReady(scope.aide.id)
              .then(function (recap) {
                if (recap.result === true) {
                  scope.aide.documentRecapitulatif = recap.value;
                  scope.displayRecapLoading =
                    !scope.aide.documentRecapitulatif &&
                    scope.aide.status !== 'REQUESTED' &&
                    scope.aide.status !== 'WAITING_FOR_CERTIFICATE';
                  $interval.cancel(intervalRecapitulatif);
                }
                // on libère le "lock"
                lockInterval = false;
              })
              .catch(function () {
                lockInterval = false;
              });
          }, 3000);
        }
      } else {
        scope.pieceRecapitulatifPresent = false;
      }

      if (scope.contribution) {
        var pieceContribRecap = _.find(scope.contribution.pieces, ['reference', 'recapitulatif_modifie']);
        if (pieceContribRecap && pieceContribRecap.documents && pieceContribRecap.documents.length > 0) {
          scope.contribution.documentRecapitulatif = _.head(pieceContribRecap.documents);
        } else if (scope.contribution.statut !== 'INPROGRESS') {
          intervalRecapitulatif = $interval(function () {
            if (lockInterval) {
              return;
            }
            // on initialise le "lock"
            lockInterval = true;
            recapitulatifService
              .isReady(scope.aide.id, _.get(scope, 'contribution.id'))
              .then(function (recap) {
                if (recap.result === true) {
                  scope.contribution.documentRecapitulatif = recap.value;
                  $interval.cancel(intervalRecapitulatif);
                }
                // on libère le "lock"
                lockInterval = false;
              })
              .catch(function () {
                lockInterval = false;
              });
          }, 3000);
        }
      }

      // Look if attestation sur l'honneur exists
      var pieceSignataire = _.find(scope.aide.pieces, ['reference', 'attestationDeclarationHonneur']);
      if (pieceSignataire && pieceSignataire.documents && pieceSignataire.documents.length > 0) {
        scope.aide.documentSignataire = _.head(pieceSignataire.documents);
      }

      scope.contribInProgress = () => {
        return ['INPROGRESS', 'ASKED', 'WAITING_FOR_CERTIFICATE'].includes(scope.contribution.statut);
      };

      // Cancel interval on scope destroy
      scope.$on('$destroy', function () {
        if (intervalRecapitulatif) {
          $interval.cancel(intervalRecapitulatif);
        }
      });
    },
  };
}

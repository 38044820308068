import { AppLoader } from '@mgdis/eu-loader';

angular
  .module('domain', [])
  .constant('menuAPI', AppLoader.getMenuInstance())
  .constant('sessionServiceAPI', AppLoader.getSessionServiceInstance())
  .constant('tiersServiceAPI', AppLoader.getTiersServiceInstance())
  .constant('accountManagementAPI', AppLoader.getAccountManagementInstance())
  .constant('publicSettingsService', AppLoader.getPublicSettingsInstance())
  .constant('types', AppLoader.getTypes());

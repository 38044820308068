'use strict';
angular.module('common.services').factory('domiciliationService', [
  'configuration',
  '$http',

  function (configuration, $http) {
    'use strict';

    /**
     *
     */
    function _getUrlDomiciliation() {
      return configuration.domiciliationBancaire.urlVerification;
    }

    return {
      verifyIban: function (guichet, etablissement) {
        return $http
          .post(_getUrlDomiciliation(), {
            guichet: guichet,
            etablissement: etablissement,
          })
          .then(function (response) {
            return response;
          });
      },
    };
  },
]);

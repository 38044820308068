import './avis-situation-section/avis-situation-section.component';

('use strict');
angular.module('portailDepotDemandeAide.depot').controller('depotSimpleInformationsGeneralesController', [
  '$scope',
  '$timeout',
  '$location',
  '$anchorScroll',
  'configuration',
  'viewsService',
  'localisationsService',
  'bourseService',
  'IFrameCommunicationManager',
  '$q',
  'StoreService',
  'alertsService',
  '$log',
  function (
    $scope,
    $timeout,
    $location,
    $anchorScroll,
    configuration,
    viewsService,
    localisationsService,
    bourseService,
    IFrameCommunicationManager,
    $q,
    StoreService,
    alertsService,
    $log
  ) {
    'use strict';
    const controleCompletude = $scope.teleserviceConfiguration?.controleCompletudeDepot ?? false;

    $scope.loaded = false;
    $scope.pageOptions =
      // First try to get the teleservice at the right revision
      $scope.aide.teleservice?.expand?.workflow?.simple?.pageInformationsGenerales ??
      $scope.teleserviceConfiguration?.workflow?.pageInformationsGenerales ??
      {};
    $scope.config = configuration;
    $scope.realisationEvaluation = 'realisationEvaluation';

    $scope.cleanNavigate();
    $scope.navigate.ns = $scope.informationsGeneralesConfiguration.ns;
    $scope.informationsGeneralesConfiguration.showErrors = $scope.showErrorsOnNavigate();

    $scope.sectionAvisSituationValid = false;

    $scope.isViewsIframeActive = $scope.pageOptions?.informationsComplementairesActif;

    // Génération de l'url pour charger les views.
    // Permet de savoir si l'on doit afficher l'iframe ou non
    let viewsPage = [];

    let forgetNextStep = false;
    let formationCommunicationManager;
    let viewsCommunicationManager;

    const updateStateViewsId = 'updateStateViews';

    /**
     *
     * @param msg
     */
    function updateViewsAide(msg) {
      viewsService.updateViewsEntity($scope, msg, $scope.aide, '#viewsIframe1', {
        showAllErrors: $scope.informationsGeneralesConfiguration.showErrors,
        skipRequiredErrors: controleCompletude,
      });
    }

    /**
     *
     * @param msg
     */
    function updateContextExpressions(msg) {
      if (msg?.data?.action === 'ready') {
        // Sends the iframe an up to date context when the 'Aide' is modified.
        // This allows expressions to be evaluated against 'Aide' data which is modified on the same screen
        $scope.$watch(
          'aide',
          function (newValue, oldValue) {
            if (newValue !== oldValue) {
              const viewsIframe = angular.element('#viewsIframe1');
              if (viewsIframe && viewsIframe[0]) {
                viewsIframe[0].contentWindow.postMessage(
                  {
                    action: 'updateContextExpressions',
                    contextExpressions: JSON.parse(JSON.stringify($scope.aide)),
                  },

                  '*'
                );
              }
            }
          },
          true
        );
      }

      if (msg?.data?.action === 'error') {
        $scope.navigate.block = true;
      }
    }

    /**
     * Méthode qui écoute les messages provenant des différentes Iframes
     *
     * @param msg Message de l'iframe
     */
    function receiveMessageEvent(msg) {
      const action = msg.data.action;

      if (action) {
        $scope.navigate.block = false;
      }
      if (action === 'fetchCustomHierarchies') {
        const customHierarchies = _.get($scope, 'pageOptions.informationsLocalisation.hierarchies', null);
        if (customHierarchies && !_.isEmpty(customHierarchies)) {
          msg.source.postMessage(
            {
              action: 'sendCustomHierarchies',
              payload: customHierarchies,
              showErrorsOnPage: $scope.informationsGeneralesConfiguration.showErrors,
              hasLocalisations: _.some($scope.aide.localisations),
            },

            event.origin
          );
        }
      }

      // Adding a localisation
      if (action === 'localisationAdded') {
        const localisationToAdd = _.get(msg, 'data.localisation');
        localisationToAdd.href = localisationToAdd.id;
        delete localisationToAdd.id;

        if ($scope.aide && Array.isArray($scope.aide.localisations)) {
          const localisationsHref = $scope.aide.localisations.map(({ href }) => href);

          const isLocalisationPresente = _.includes(localisationsHref, localisationToAdd.href);
          if (!isLocalisationPresente) {
            $scope.aide.localisations.push(localisationToAdd);
          }
        } else {
          _.set($scope, 'aide.localisations', [localisationToAdd]);
        }
      }

      // Suppression d'une localisation
      if (action === 'localisationDeleted') {
        const localisationToRemove = _.get(msg, 'data.localisation');
        if (_.get($scope, 'aide.localisations')) {
          _.remove($scope.aide.localisations, function (localisation) {
            return localisation.title === localisationToRemove.title;
          });
        }
      }
    }

    $scope.navigate.beforePrevious = () => {
      if (!_.isEmpty(viewsPage) && $scope.isViewsIframeActive) {
        return this.validateViewsAndWaitForUpdate({
          showAllErrors: false,
          skipAllErrors: true,
        });
      }
    };

    /**
     *
     * @param forget
     */
    $scope.navigate.next = (forget) => {
      forgetNextStep = forget;
      if (
        _.isEmpty(viewsPage) &&
        !$scope.isViewsIframeActive &&
        !$scope.localisationActif &&
        !$scope.moyensHumainsActif &&
        !$scope.formationActif &&
        !$scope.realisationEtEvaluationActif
      ) {
        $scope.goToStep('pageInformationsGenerales2', forget);
      } else {
        $scope.$broadcast('nextButtonClicked');

        if (!$scope.sectionAvisSituationValid) {
          $location.hash('avis-situation-section');
          $anchorScroll();
          return;
        }

        if ($scope.localisationActif) {
          //Detection des erreurs éventuelles sur le champ localisations
          const localisationIframe = angular.element('#localisationIframe');
          localisationIframe[0].contentWindow.postMessage(
            {
              action: 'validLocalisation',
              payload: _.get($scope, 'aide.localisations'),
            },

            '*'
          );

          if (_.isEmpty($scope.aide.localisations) && !controleCompletude) {
            return;
          }
        }

        if ($scope.realisationEtEvaluationActif) {
          // Check evaluation input values
          const evaluationField = $scope.pageOptions?.realisationEtEvaluation?.fields?.find(
            ({ reference }) => reference === 'evaluation'
          );
          const evaluationFilled = Boolean($scope.aide.evaluation);
          if (evaluationField.required && !evaluationField.hidden && !evaluationFilled && !controleCompletude) {
            return;
          }
        }

        // Check "moyens humains" input values
        const fields = $scope.pageOptions?.moyensHumains?.fields ?? [];
        const moyensHumainsElementRequired = fields.find(
          (field) => field.reference === 'description' && field.required && !field.hidden
        );
        const moyensHumainsSelectionnee = Boolean($scope.aide.moyensHumains?.description);

        if (
          $scope.moyensHumainsActif &&
          !moyensHumainsSelectionnee &&
          moyensHumainsElementRequired &&
          !controleCompletude
        ) {
          return;
        }

        // Le nombre d'ETPT doit être inférieur ou égal au nombre de personnes.
        if ($scope.moyensHumainsActif && _.get($scope, 'aide.moyensHumains')) {
          const foundError = _.find(_.get($scope, 'aide.moyensHumains.content'), function (moyen) {
            return !_.isNaN(moyen.nbPersonnes) && moyen.nbPersonnes < moyen.nbETPT;
          });
          if (foundError) {
            $location.hash('moyensHumainsTableau');
            $anchorScroll();
            return;
          } else {
            _.each($scope.aide.moyensHumains.content, function (moyenHumain) {
              delete moyenHumain.title;
              delete moyenHumain.nbEtptTotal;
              delete moyenHumain.nbPersonnesTotal;
            });
          }
        }

        const skipPage = _.isEmpty(viewsPage) && !$scope.isViewsIframeActive && !$scope.formationActif;
        if (skipPage) {
          $scope.goToStep('pageInformationsGenerales2', forget);
        }

        const iFramePromises = [];

        if (!_.isEmpty(viewsPage) && $scope.isViewsIframeActive) {
          const viewsValidationPromise = this.validateViewsAndWaitForUpdate({
            skipRequiredErrors: controleCompletude,
            showAllErrors: !controleCompletude,
          });

          iFramePromises.push(viewsValidationPromise);
        }

        if ($scope.formationActif) {
          const formationValidationPromise = formationCommunicationManager
            .sendEvent({
              action: 'validate',
              options: { saveInProgress: controleCompletude },
            })
            .manageEventWithPromise((msg, resolve, reject) => {
              bourseService.updateFormationAide(msg, resolve, reject, $scope.aide);
            })
            .catch(() => {
              $location.hash('formationIframe');
              $anchorScroll();
              throw new Error('A field from formation suivie is in error.');
            });

          iFramePromises.push(formationValidationPromise);
        }

        $q.all(iFramePromises)
          .then(() => {
            // goToStep has already been called if skipPage
            if (!skipPage) $scope.goToStep('pageInformationsGenerales2', forgetNextStep);
          })
          .catch(() => {
            // iframe validation / user rejected step change
            viewsCommunicationManager.closeUniqueEvent({ type: updateStateViewsId });
          });
      }
    };

    /**
     * Check if the data set on the demande views are valid and wait for them to be updated on the demande in memory
     *
     * @param {boolean} showAllErrors show all errors at the validation
     * @param {boolean} skipRequiredErrors ignore required fields for the page validation
     * @param {boolean} skipAllErrors ignore all fields for the validation
     * @returns {Promise} promise that resolves when the views are updated
     */
    this.validateViewsAndWaitForUpdate = ({
      showAllErrors = true,
      skipRequiredErrors = false,
      skipAllErrors = false,
    }) => {
      return viewsCommunicationManager
        .sendEvent({
          action: 'validViews',
          options: {
            showAllErrors,
            skipRequiredErrors,
            skipAllErrors,
          },
        })
        .manageUniqueEventWithPromise({
          type: updateStateViewsId,
          callback: (msg, resolve, reject) => {
            viewsService.updateStateViews($scope, msg, resolve, reject, $scope.teleserviceConfiguration);
          },
        });
    };

    /**
     * Initialize the controller
     *
     * @returns {void}
     */
    function initialize() {
      _.set($scope.informationsGeneralesConfiguration, 'select.ordreOptions', 'value');

      // Check if localisation are active the teleservice
      //? We disabled localisation if no hierarchie is visible
      $scope.localisationActif = localisationsService.isLocationActiveOnClaimantPortal($scope.teleserviceConfiguration);

      const realisationEtEvaluation = $scope.pageOptions.realisationEtEvaluation || {};
      $scope.realisationEtEvaluationActif =
        realisationEtEvaluation.actif &&
        // at least one field should be visible
        realisationEtEvaluation.fields?.some(({ hidden }) => !hidden);

      // Check if moyens humains are active the teleservice
      $scope.moyensHumainsActif = $scope.pageOptions?.moyensHumains?.actif ?? false;

      // Check if caracteristiques sociales are active the teleservice
      $scope.caracteristiquesSocialesActif = $scope.pageOptions?.caracteristiquesSociales?.actif ?? false;

      $scope.formationConfiguration = $scope.pageOptions?.formationSuivie ?? {};

      // Check formation data
      $scope.formationActif = $scope.formationConfiguration?.actif ?? false;
      if ($scope.formationActif) {
        // We block navigation to avoid skipping the page before the form validation
        $scope.navigate.block = true;

        $scope.formationTitle = $scope.formationConfiguration.title;
        $scope.formationIframeSrc = bourseService.generateFormationIframeSrc(
          $scope.aide.reference,
          $scope.contribution,
          false,
          $scope.showErrorsOnNavigate(),
          true
        );
      }

      // localisation actif
      if ($scope.localisationActif) {
        // We block navigation to avoid skipping the page before the form validation
        $scope.navigate.block = true;
        // get localisation iframe url
        $scope.localisationIframeSrc = localisationsService.getLocalisationsIframeUrl(
          $scope.teleserviceConfiguration,
          $scope.aide.localisations
        );
      }

      if ($scope.aide.views?.length > 0) {
        viewsPage = $scope.aide.views.filter(({ page }) => page === 'pageInformationsGenerales');
      }

      if (viewsPage.length > 0) {
        $scope.viewsIframeSrc = viewsService.getPageViewsIframeUrl({
          entity: $scope.aide,
          page: 'pageInformationsGenerales',
          theme: 'demandeur',
          readOnly: false,
          topTitleLevel: 5,
          contextHeaders: {
            'referentiel-tiers': [
              'Accept:application/vnd.mgdis.tiers-3.19.0+json',
              'Content-Type:application/vnd.mgdis.tiers-3.19.0+json',
            ],
          },
        });

        viewsCommunicationManager.manageEvent(updateViewsAide);
        viewsCommunicationManager.manageEvent(updateContextExpressions);
      }

      // Wait for iframes to be loaded
      $timeout(() => {
        iFrameResize(
          {
            heightCalculationMethod: 'taggedElement',
            checkOrigin: false,
            inPageLinks: true,
          },

          '#localisationIframe, #viewsIframe1, #formationIframe'
        );
      }, 0);

      $scope.loaded = true;
    }

    /**
     * Add listener on page creation
     */
    this.$onInit = () => {
      if (!$scope.pageOptions.actif) {
        $scope.navigate.next(true);
      }

      $scope.stepsWizard.steps = $scope.getSimpleSteps();
      $scope.stepsWizard.active = 'dossier';

      const iFrameCommunicationManagers = [];

      viewsCommunicationManager = new IFrameCommunicationManager('#viewsIframe1');
      iFrameCommunicationManagers.push(viewsCommunicationManager);

      formationCommunicationManager = new IFrameCommunicationManager('#formationIframe');
      iFrameCommunicationManagers.push(formationCommunicationManager);

      const localisationManager = new IFrameCommunicationManager();
      localisationManager.manageEvent(receiveMessageEvent);
      iFrameCommunicationManagers.push(localisationManager);

      // ! we wait for pending promises in depot workflow before displaying anything
      StoreService.depot.pendingPromises
        .promise()
        .then(() => initialize())
        .catch((err) => {
          $scope.alerts = alertsService.getAlertError('connected.config.depot.errors.save');
          $log.error(`[depotSimpleInformationsGeneralesController] StoreService pendingPromises: `, err);
          $scope.loaded = true;
        });

      // Remove listeners
      $scope.$on('$destroy', () => {
        iFrameCommunicationManagers.forEach((manager) => {
          manager.close();
        });
      });
    };
  },
]);

'use strict';

/**
 * @ngdoc component
 * @module aides
 * @name tabsListDemandes
 * @description
 *
 *   Display tabs of list of demandes
 */
class TabsListDemandesController {
  /**
   * Component controller
   *
   * @param {object} $q
   * @param {object} $state
   * @param {object} $stateParams
   * @param {object} $scope
   * @param {object} $translate
   * @param {object} aidesService
   * @param {object} IFrameCommunicationManager
   * @param {object} routeUtilsService
   */
  constructor(
    $q,
    $state,
    $stateParams,
    $scope,
    $translate,
    aidesService,
    IFrameCommunicationManager,
    routeUtilsService
  ) {
    this.$q = $q;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$scope = $scope;
    this.$translate = $translate;
    this.aidesService = aidesService;
    this.IFrameCommunicationManager = IFrameCommunicationManager;
    this.routeUtilsService = routeUtilsService;
    this.activeTabIndex = 1;
    this.alreadyRedirected = false;
  }

  /**
   * Initialize component
   *
   * @returns {void}
   */
  $onInit() {
    this.justificationOnly = this.$stateParams.justificationOnly;
    this.aidesRoute = 'app.connected.dashboard.aides.demandesAides.aides';
    this.sharedAidesRoute = 'app.connected.dashboard.aides.demandesAides.sharedAides';
    this.aidesLabel = this.$translate.instant('connected.dashboard.aides.demandesAides.tab');
    this.sharedAidesLabel = this.$translate.instant('connected.dashboard.aides.demandesAidesSharedPage.tab');
    this.#setTabItems(0, 0);
    this.displayCreateDemandeBtn = false;

    this.iFrameCommunicationManager = new this.IFrameCommunicationManager('#demandesFinancementIframe');
    this.onIframeEvent = ({ data = {} }) => {
      const { route, action, value } = data;
      if (route === 'demandes-financement-view.ux' && action === 'count-demandes') {
        this.updateAidesCount({ aidesCount: value.demandesNotShared, sharedAidesCount: value.demandesShared });

        if (!this.aidesCount && this.sharedAidesCount && !this.alreadyRedirected) {
          this.#updateActiveTabIndex();
          this.routeUtilsService.goToSharedDemandesList();
        }
        this.alreadyRedirected = true;
      }
    };
    this.iFrameCommunicationManager.manageEvent(this.onIframeEvent);

    this.updateAidesCount();

    /**
     * Update aides count when a demande is deleted
     *
     * @param {object} $event Event
     * @param {object} aide Aide
     */
    this.$scope.$on('delete-demande', () => this.updateAidesCount());
  }

  /**
   * Update activeTabIndex value depending on current state
   *
   * @returns {void}
   */
  #updateActiveTabIndex() {
    this.activeTabIndex = this.$state.current.name === this.sharedAidesRoute ? 2 : 1;
  }

  /**
   * Set tab items
   *
   * @param {number} aidesCount
   * @param {number} sharedAidesCount
   * @returns {void}
   */
  #setTabItems(aidesCount, sharedAidesCount) {
    /**
     * Get tab status
     * 'active' when selected
     * 'visible' otherwise
     *
     * @param {string} type
     * @returns {string}
     */
    const getStatus = (type = 'aides') => {
      if ((this.activeTabIndex === 1 && type === 'aides') || (this.activeTabIndex === 2 && type === 'sharedAides')) {
        return 'active';
      }
      return 'visible';
    };

    this.tabItems = [
      {
        label: this.aidesLabel,
        badge: aidesCount ? { value: aidesCount, label: this.aidesLabel, role: 'information' } : undefined,
        status: getStatus(),
      },
      {
        label: this.sharedAidesLabel,
        badge: sharedAidesCount
          ? { value: sharedAidesCount, label: this.sharedAidesLabel, role: 'information' }
          : undefined,
        status: getStatus('sharedAides'),
      },
    ];
  }

  /**
   * Update aides count
   *
   * @param {object} obj obj
   * @param {number} obj.aidesCount aides count
   * @param {number} obj.sharedAidesCount shared aides count
   * @returns {void}
   */
  updateAidesCount({ aidesCount = 0, sharedAidesCount = 0 } = {}) {
    this.aidesCount = aidesCount;
    this.displayCreateDemandeBtn = !!aidesCount && (this.publicSettingPortailDepot?.createDemande ?? true);
    this.sharedAidesCount = sharedAidesCount;
    this.#updateActiveTabIndex();
    this.#setTabItems(aidesCount, sharedAidesCount);
  }

  /**
   * Handle tab change event
   *
   * @param {object} event
   * @returns {void}
   */
  handleTabChange(event) {
    const index = event.detail;

    if (index === 1) {
      this.routeUtilsService.goToDemandesList();
    } else if (index === 2) {
      this.routeUtilsService.goToSharedDemandesList();
    }
  }

  /**
   * Go to the workflow deposer demande aide
   *
   * @returns {void}
   */
  goToCreateDemande = () => {
    this.routeUtilsService.goToDeposerDemandeAide();
  };

  /**
   * Destroy component
   *
   * @returns {void}
   */
  $onDestroy() {
    this.iFrameCommunicationManager.close();
  }
}

TabsListDemandesController.$inject = [
  '$q',
  '$state',
  '$stateParams',
  '$scope',
  '$translate',
  'aidesService',
  'IFrameCommunicationManager',
  'routeUtilsService',
];

angular.module('aides').component('tabsListDemandes', {
  templateUrl: 'aides/aides-components/tabs-list-demandes/tabs-list-demandes.component.html',
  controller: TabsListDemandesController,
  bindings: {
    publicSettingPortailDepot: '<',
  },
});

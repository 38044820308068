'use strict';
angular.module('common.services').factory('StoreService', ['$rootScope', '$q', StoreService]);

/**
 * This Service play the role of a Pseudo-Store
 * The goal is to maintain a common memory storage place
 * without need of $rootScope or similar solutions
 *
 * @param $rootScope
 * @param $q
 */
function StoreService($rootScope, $q) {
  const data = {
    stateHistory: {
      current: {},
      previous: {},
    },

    dashboardAlert: null,
    tiers: {
      patches: [],
    },

    demandeur: null,
    beneficiaire: null,
    depotPendingPromises: [],
    paiementPendingPromises: [],
    existingTiers: null,
    currentTiersRef: null,
    familleTiersCount: null,
  };

  return {
    dashboardAlert: {
      get() {
        return data.dashboardAlert;
      },
      set(alert) {
        data.dashboardAlert = alert;
      },
      clear() {
        data.dashboardAlert = null;
      },
    },

    famillesTiersCount: {
      /**
       * @returns {string} current tiers reference
       */
      get: () => {
        return data.familleTiersCount || {};
      },
      set: (count) => {
        data.familleTiersCount = count;
      },
    },

    currentTiersRef: {
      /**
       * @param {boolean} useLocalStorage get current tiers reference from localstorage
       * @returns {string} current tiers reference
       */
      get: (useLocalStorage = false) => {
        // We return our cached tiers or the one available in storage
        // We also check corresponding userid becasue we can only use our recorded
        // current tiers with it.
        if (!data.currentTiersRef || useLocalStorage) {
          try {
            const storedCurrentTiers = JSON.parse(localStorage.getItem('ls.current-tiers-ref'));
            const storedCurrentTiersRef = _.get(storedCurrentTiers, 'ref');

            const isValidUserId = _.get(storedCurrentTiers, 'userId') === _.get($rootScope, 'currentUser.id');
            const shouldReturnCurrentTiers = storedCurrentTiersRef && isValidUserId;
            // Warning /!\ When we select "Aucun Profil", we need to receive a empty String Value.
            // Null value is used to redirect to tiers-selection screen when first connect or coming from
            // external link. This will enforce the behaviour.
            const emptyReturnValue = storedCurrentTiers && isValidUserId ? '' : null;

            data.currentTiersRef = shouldReturnCurrentTiers ? storedCurrentTiersRef : emptyReturnValue;
          } catch (err) {
            data.currentTiersRef = null;
          }
        }
        return data.currentTiersRef;
      },
      /**
       * @param {string} tiersRef tiers reference
       * @param {boolean} updateLocalStorage update current tiers reference in localstorage too
       * @param {boolean} emitTiersUpdated broadcast a change tiers.current.updated event
       * @returns {void}
       */
      set: (tiersRef, updateLocalStorage = false, emitTiersUpdated = false) => {
        data.currentTiersRef = tiersRef || '';
        if (updateLocalStorage) {
          // We store our current tiers AND our current user id, because we wont' want to use
          // this tiers reference with another user.
          localStorage.setItem(
            'ls.current-tiers-ref',
            JSON.stringify({ userId: _.get($rootScope, 'currentUser.id'), ref: tiersRef || '' })
          );
        }
        if (emitTiersUpdated) {
          $rootScope.$broadcast('tiers.current.updated');
        }
      },
      /**
       * @param {boolean} updateLocalStorage unset current tiers reference in localstorage too
       * @returns {void}
       */
      unset: (updateLocalStorage = false) => {
        delete data.currentTiersRef;
        if (updateLocalStorage) {
          localStorage.removeItem('ls.current-tiers-ref');
        }
      },
    },

    tiers: {
      // Here, we keep an instance of our current modifications of tiers entity
      // at every API PATCH operation, we need to clean the property.
      patches: {
        /**
         * get patch list
         *
         * @returns {object} patches
         */
        get: () => data.tiers.patches,

        /**
         * get patch for an specific path
         *
         * @param {string} path path
         * @returns {string} path
         */
        getPath: (path) => {
          const pathFound = _.find(data.tiers.patches, ['path', path]);
          return pathFound;
        },
        /**
         * add a patch to array of patches
         *
         * @param {object} op op
         * @param {string} path path
         * @param {any} value value
         * @returns {void}
         */
        add: (op, path, value) => {
          if (path) {
            const pathFound = _.find(data.tiers.patches, ['path', path]);
            if (pathFound) {
              pathFound.op = op;
              pathFound.value = value;
            } else {
              data.tiers.patches.push({
                op,
                path,
                value,
              });
            }
          }
        },

        /**
         * Set array of patches
         *
         * @param {Array<object>} patches patches
         * @returns {void}
         */
        set: (patches = []) => {
          data.tiers.patches = patches;
        },

        /**
         * Clean the array of patches
         *
         * @returns {void}
         */
        clean: () => {
          data.tiers.patches = [];
        },
      },
    },

    demandeur: {
      get: () => data.demandeur,
      set: (demandeur) => {
        data.demandeur = demandeur;
        return data.demandeur;
      },
    },

    beneficiaire: {
      get: () => data.beneficiaire,
      set: (beneficiaire) => {
        data.beneficiaire = beneficiaire;
        return data.beneficiaire;
      },
    },

    depot: {
      pendingPromises: {
        push: (item) => data.depotPendingPromises.push(item),
        promise: () => $q.all(data.depotPendingPromises),
        clean: () => (data.depotPendingPromises = []),
      },
    },

    existingTiers: {
      set: (existingTiers) => (data.existingTiers = existingTiers),
      get: () => data.existingTiers,
      clean: () => (data.existingTiers = null),
    },

    demandePaiement: {
      pendingPromises: {
        push: (item) => data.paiementPendingPromises.push(item),
        promise: () => $q.all(data.paiementPendingPromises),
        clean: () => (data.paiementPendingPromises = []),
      },
    },
  };
}

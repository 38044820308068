'use strict';

// https://jira.mgdis.fr/browse/PLAID-2327
identiteTiers.$inject = ['$modal', '$translate', 'tiersThematiquesService'];
angular.module('tiers').directive('identiteTiers', identiteTiers);

identiteTiers.$inject = ['$modal', '$translate', 'tiersThematiquesService'];

/**
 *
 * @param $modal
 * @param $translate
 * @param tiersThematiquesService
 */
function identiteTiers($modal, $translate, tiersThematiquesService) {
  return {
    restrict: 'E',
    templateUrl: 'tiers/tiers-directives/identite-tiers/identite-tiers.html',
    replace: true,
    transclude: true,
    scope: {
      configuration: '=?',
      tiers: '=',
      readOnly: '=',
      namespace: '=',
      masterdata: '=',
    },

    link: function (scope) {
      //We use this variable to have only html temp bindings for some controls
      scope.ux = {};

      scope.viewConfiguration = _.merge(
        {
          ns: scope.namespace + '.identite-tiers',
        },

        scope.configuration
      );

      // Truncate descriptionActivite if necessary (when data comes from Api Entreprise)
      /**
       *
       * @param name
       * @param sizeLimit
       */
      function truncate(name, sizeLimit) {
        if (name) {
          return name.substring(0, sizeLimit);
        }
        return name;
      }
      const newDescription = truncate(
        scope.tiers.thematiquesLiees.association.descriptionActivites,
        scope.viewConfiguration.fields.description.maxlength || 4000
      );

      scope.tiers.thematiquesLiees.association.descriptionActivites = newDescription;

      tiersThematiquesService.getAllThematiquesAssociation().then(function (thematiques) {
        //We filter the thematiques based on famille parameters
        var thematiquesInFamille = _.get(scope.tiers, 'famille.expand.thematiques', []);
        var thematiquesFiltered = _.intersectionBy(thematiques.items, thematiquesInFamille, function (t) {
          return t.href;
        });

        scope.thematiquesCategories = _.filter(thematiquesFiltered, ['expand.niveau', 1]);
        scope.thematiquesSources = _.filter(thematiquesFiltered, ['expand.niveau', 2]);
      });

      //Use this variable to filter autocomplete
      var publics = _.get(scope.tiers, 'famille.expand.publics');

      var associationType = _.get(scope, 'tiers.thematiquesLiees.association.champsActionTerritoriaux[0]');
      if (associationType) {
        _.set(scope.ux, 'associationType', associationType);
      }

      scope.$watch('ux.associationType', function (newValue) {
        if (newValue) {
          var value = _.pick(newValue, ['href', 'title']);
          _.set(scope, 'tiers.thematiquesLiees.association.champsActionTerritoriaux', [value]);
          if (newValue.expand) {
            _.set(scope, 'tiers.thematiquesLiees.association.associationType', newValue.expand.reference);
          }
        } else {
          _.unset(scope, 'tiers.thematiquesLiees.association.champsActionTerritoriaux');
          _.unset(scope, 'tiers.thematiquesLiees.association.associationType');
        }
      });

      // We watch the boolean of utilitePublique
      // If the value is false, the dependant fields must be cleaned
      scope.$watch('tiers.thematiquesLiees.association.utilitePublique.value', function (newValue) {
        if (!newValue) {
          _.unset(scope.tiers, 'thematiquesLiees.association.utilitePublique.datePublicationJO');
          _.unset(scope.tiers, 'thematiquesLiees.association.utilitePublique.numero');
        }
      });

      // We watch the boolean of impotsCommerciaux
      // If the value is false, the dependant fields must be cleaned
      scope.$watch('tiers.thematiquesLiees.association.impotsCommerciaux', function (newValue) {
        if (!newValue) {
          _.unset(scope.tiers, 'thematiquesLiees.association.rescritFiscal');
        }
      });

      // Filtre une source pour la directive auto-complete de ng-tags-input
      scope.filterPublics = function (query) {
        // Remove accents
        var diacriticQuery = S(query).latinise().toString();
        // Fetch the source content the first time we try to filter it.
        return publics.filter(function (item) {
          var title = S(item.title).latinise().toString();
          return !query || _.includes(title.toUpperCase(), diacriticQuery.toUpperCase());
        });
      };

      // valid a tag only if it's in sources
      scope.validTag = function (value) {
        return !!_.find(publics, { href: value.href });
      };

      // Allow to control associationType value and delete if it's not valued
      scope.onAssociationTypeChange = function () {
        if (_.isEmpty(scope.ux.associationType)) {
          delete scope.ux.associationType;
        }
      };
    },
  };
}

'use strict';
detailsService.$inject = ['$http', 'configuration'];
angular.module('aides').factory('detailsService', detailsService);

/**
 *
 * @param $http
 * @param configuration
 */
function detailsService($http, configuration) {
  const masterDatas = {};

  const formatSource = (data) => {
    return {
      array: data.map(({ title: value, id: reference }) => {
        return { expand: { reference, libelle: { value } } };
      }),
    };
  };

  const getDefaultByKey = (key) => {
    switch (key) {
      case 'typeDepense':
        return {
          fieldType: 'boolean',
          defaultValues: ['FONCTIONNEMENT', 'INVESTISSEMENT'],
        };

      case 'modeFinancement':
        return {
          fieldType: 'boolean',
          defaultValues: ['DIRECT', 'INDIRECT'],
        };

      case 'typeInvestissement':
        return {
          fieldType: 'boolean',
          defaultValues: ['MATERIEL', 'IMMATERIEL'],
        };

      case 'financementDeDroit':
        return {
          fieldType: 'boolean',
          defaultValues: ['PRIVE', 'PUBLIC'],
        };

      case 'eligiblePSN':
        return {
          fieldType: 'boolean',
        };

      case 'methodeCalcul':
      case 'sousCategorieDepense':
      case 'regimeAideEtat':
      case 'typeDecisionJuridique':
      case 'formeDecisionJuridique':
        return {
          fieldType: 'select',
          defaultValues: [],
        };

      case 'dateLettreIntention':
      case 'dateDecision':
      case 'dateDecisionJuridique':
        return {
          fieldType: 'date',
        };

      case 'montantDemande':
      case 'montantSubventionnable':
      case 'montantPartPrincipale':
      case 'montantContrepartie':
        return {
          fieldType: 'number',
        };

      default:
        return;
    }
  };

  return {
    getMasterData: (key) => {
      const pathsByKey = {
        methodeCalcul: 'methodes-calcul',
        sousCategorieDepense: 'sous-categories-depense',
        regimeAideEtat: 'regimes-aide-etat',
        typeDecisionJuridique: 'types-decision-juridique',
        formeDecisionJuridique: 'formes-decision-juridique',
      };

      const path = pathsByKey[key];
      if (!path) return;

      if (!masterDatas[key]) {
        masterDatas[key] = $http
          .get(`${configuration.referentielPlanFinancement.api}/${path}?actif=true&$top=1000&$orderby=title`)
          .then(({ data: { data: masterdata } }) => {
            return formatSource(masterdata);
          })
          .catch((err) => {
            throw new Error('Failed to get masterdata', err);
          });
      }
      return masterDatas[key];
    },
    /**
     * get poste from ref-plan-fin or from poste
     *
     * @param {string} reference
     * @param dateCreation
     */
    getPoste: (reference, dateCreation) => {
      if (!reference) {
        return;
      }

      // Sometimes date arrives with a wrong format, so we force the wanted format
      const datePath = dateCreation ? `?date=${new Date(dateCreation).toISOString()}` : '';

      return $http
        .get(`${configuration.referentielPlanFinancement.api}/postes-financement/${reference}${datePath}`)
        .then(({ data: poste }) => {
          return poste;
        });
    },
    getDetailsConfiguration: (details) => {
      const detailsConfiguration = {};
      _.forEach(details, (detail, key) => {
        const defaultDetails = getDefaultByKey(key);
        if (defaultDetails) {
          defaultDetails.visibilite = detail.visibilite;
          defaultDetails.valeurParDefaut = detail.valeurParDefaut;
          defaultDetails.configuration = {
            label: detail.title,
            required: detail.obligatoire,
            help: detail.texteAide,
            helpTooltip: _.has(detail, 'texteAide'),
            error: {
              required: detail.messageErreurObligatoire,
              pattern: detail.messageErreurFormat,
              checkValidity: detail.messageErreurFormat,
            },
          };

          detailsConfiguration[key] = defaultDetails;
        }
      });
      return detailsConfiguration;
    },
  };
}

'use strict';
(function () {
  'use strict';
  dashboardPaiementsDemandesComplementPiecesController.$inject = [
    '$scope',
    '$modal',
    'alertsService',
    'demandePaiement',
    'aide',
    'aideComplementsConfiguration',
    'teleservicePaiement',
    'demandesPaiementService',
    '$state',
    '$rootScope',
    'previousState',
  ];
  angular
    .module('portailDepotDemandeAide.dashboard')
    .controller(
      'dashboardPaiementsDemandesComplementPiecesController',
      dashboardPaiementsDemandesComplementPiecesController
    );

  /**
   *
   * @param $scope
   * @param $modal
   * @param alertsService
   * @param demandePaiement
   * @param aide
   * @param aideComplementsConfiguration
   * @param teleservicePaiement
   * @param demandesPaiementService
   * @param $state
   * @param $rootScope
   * @param previousState
   */
  function dashboardPaiementsDemandesComplementPiecesController(
    $scope,
    $modal,
    alertsService,
    demandePaiement,
    aide,
    aideComplementsConfiguration,
    teleservicePaiement,
    demandesPaiementService,
    $state,
    $rootScope,
    previousState
  ) {
    // Acquiring complements
    $scope.demandePaiement = demandePaiement;

    // Récupère le dernier évènement avec son statut et sa date sur chaque demande de complément.
    var setLastEvent = function (dp) {
      _.each(dp.demandesComplementPieces, function (item) {
        if (_.get(item, 'history.events') && _.get(item, 'history.events').length) {
          var orderedEvents = item.history.events.sort(function (a, b) {
            return new Date(a.date).getTime() > new Date(b.date).getTime() ? -1 : 1;
          });
          item.lastDateAvancement = orderedEvents[0].date;
          item.lastStatusAvancement = orderedEvents[0].reference;
        }
      });
    };

    setLastEvent($scope.demandePaiement);

    $scope.aide = aide;
    // Shorcut for access to pieces
    $scope.persistenceConfiguration = _.get(teleservicePaiement, 'persistenceConfiguration.expand');
    // Page Options
    $scope.pageOptions = _.get(teleservicePaiement, 'workflow.demandePaiement.pagePieces.modelesPieces');

    $scope.goBack = function () {
      switch (_.get(previousState, 'name')) {
        case 'app.connected.dashboard.sollicitations':
          $state.go(previousState.name, previousState.params);
          break;
        default:
          //We reload the cached parent state
          $state.go('app.connected.dashboard.aides.demandesPaiement.list', {}, { reload: true });
      }
    };

    // We reload complements after a modification
    $scope.setDemandesComplement = function () {
      $scope.filteredDemandesComplement = _.get($scope.demandePaiement, 'demandesComplementPieces', []);
    };

    $scope.setDemandesComplement();

    //We reload the data after a modification
    $scope.reloadDemandePaiement = function () {
      return demandesPaiementService
        .getDemandePaiement($scope.demandePaiement.reference, {
          params: {
            $expand: 'pieces.documents',
          },
        })
        .then(function (d) {
          setLastEvent(d);
          $scope.demandePaiement = d;
          $scope.setDemandesComplement();
        });
    };

    $scope.fillComplement = function (complement) {
      // Copying aides' pieces into the persistence
      $scope.pieces = _.map(complement.pieces, function (piece) {
        var pieceVal = _.find($scope.demandePaiement.pieces, {
          reference: piece.reference,
        });

        var pieceConf = _.find($scope.pageOptions, {
          reference: piece.reference,
        });

        var mergedPiece = _.merge(pieceConf, pieceVal);

        mergedPiece.complementId = complement.id;

        _.each(mergedPiece.documents, function (d) {
          d.expand.isDeletable = true;
        });

        return mergedPiece;
      });

      // The persistence need to be registered in order to have an id
      $scope.openModalComplement();
    };

    //We save the documents inside the piece
    $scope.saveDemandePaiement = function (piece) {
      var pieceIndex = _.findIndex($scope.demandePaiement.pieces, function (p) {
        return p.reference === piece.reference;
      });

      var cleanedPiece = angular.copy(piece);

      _.each(cleanedPiece.documents, function (d) {
        delete d.expand;
      });

      var patches = [];

      // Manage missing piece documents attribute case
      if (_.isEmpty($scope.demandePaiement.pieces[pieceIndex].documents)) {
        const piece = $scope.demandePaiement.pieces[pieceIndex];
        piece.documents = cleanedPiece.documents;
        patches.push({
          op: 'replace',
          path: `/pieces/${pieceIndex}`,
          value: piece,
        });
      } else {
        patches.push({
          op: 'replace',
          path: '/pieces/' + pieceIndex + '/documents',
          value: cleanedPiece.documents,
        });
      }

      if (piece.conforme) {
        patches.push({
          op: 'remove',
          path: '/pieces/' + pieceIndex + '/conforme',
        });
      }

      demandesPaiementService.patchDemandePaiement($scope.demandePaiement.reference, patches).then(function () {
        $scope.reloadDemandePaiement();
      });
    };

    //We validate the pieces and change the avancement to REPONSE_DEMANDEUR
    $scope.sendComplements = function (pieces, modal) {
      var demandeComplIndex = _.findIndex($scope.demandePaiement.demandesComplementPieces, function (d) {
        return d.id === pieces[0].complementId;
      });

      var patches = [
        {
          op: 'replace',
          path: '/demandesComplementPieces/' + demandeComplIndex + '/avancement',
          value: 'REPONSE_DEMANDEUR',
        },
      ];

      patches.push({
        op: 'add',
        path: '/demandesComplementPieces/' + demandeComplIndex + '/history/events/-',
        value: {
          date: new Date(),
          reference: 'REPONSE_DEMANDEUR',
        },
      });

      demandesPaiementService
        .patchDemandePaiement($scope.demandePaiement.reference, patches)
        .then(function () {
          $scope.reloadDemandePaiement();
          modal.$hide();
          $scope.$broadcast(
            'alerts',
            alertsService.getAlertSuccess('connected.dashboard.aides.complements.validation-success'),
            'complements-success'
          );
          $rootScope.$broadcast('refresh-menu');
        })
        .catch(function () {
          modal.$broadcast(
            'alerts',
            alertsService.getAlertError('connected.dashboard.aides.complements.fill-modal.error.unknow'),
            'complements-error'
          );
        });
    };

    /**
     * Open the modal of piece-form to fill the complement
     *
     * @param  {[type]} persistence [description]
     */
    $scope.openModalComplement = function (persistence) {
      var scopeModal = $scope.$new();

      // Configuration and functions
      scopeModal.complementsConfiguration = angular.copy(aideComplementsConfiguration);
      scopeModal.complementsConfiguration.ns = 'connected.dashboard.aides.complements.fill-modal';
      // The size limit has to be converted into Mb for the directive
      scopeModal.complementsConfiguration.maxDocumentSize =
        scopeModal.complementsConfiguration.maxDocumentSize / 1024 / 1024;
      scopeModal.complementsConfiguration.allowedExtensions = scopeModal.complementsConfiguration.allowedExtension;
      scopeModal.persistenceConfiguration = $scope.persistenceConfiguration || scopeModal.complementsConfiguration;
      scopeModal.mdm = $scope.mdm;
      scopeModal.sendComplements = $scope.sendComplements;

      // Url where to upload files
      $scope.urlDocuments = $scope.demandePaiement._links['mgs:documents'].href;

      // Directory of file's icons
      scopeModal.urlFileIcons = './resources/images/file-icons/';

      // Opens the modal
      $modal({
        scope: scopeModal,
        template: 'dashboard/aides/demandes-paiement/demandes-complement-pieces/modal/modal-complements-paiement.html',
        backdrop: 'static',
      });
    };
  }
})();

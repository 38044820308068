'use strict';
/**
 * @ngdoc module
 * @module comites-recevabilite
 * @name comites-recevabilite
 * @description
 *
 *   The main module for all comites-recevabilite related services and directives for our portals
 */
angular.module('comitesRecevabilite', ['common', 'comitesRecevabilite.services', 'comitesRecevabilite.constants']);

angular.module('comitesRecevabilite.services', []);

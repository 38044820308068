'use strict';
angular.module('portailDepotDemandeAide.depot').controller('demandePaiementConfirmationController', [
  '$log',
  '$scope',
  '$state',
  '$stateParams',
  '$http',
  '$interval',
  'recapitulatifService',
  'alertsService',
  function ($log, $scope, $state, $stateParams, $http, $interval, recapitulatifService, alertsService) {
    'use strict';

    // Page Options
    $scope.pageOptions = _.get($scope, 'teleserviceConfiguration.workflow.pageConfirmation', '');

    // Navigation
    $scope.cleanNavigate();
    $scope.navigate.ns = _.get($scope, 'confirmationConfiguration.ns', '');
    $scope.navigate.noform = true;
    $scope.activePage();

    /**
     * Go back to the demandes paiement screen
     */
    $scope.navigate.finish = function () {
      $state.go('app.connected.dashboard.aides.demandesPaiement.list', {
        reference: _.get($scope, 'demandePaiement.demandeFinancement.expand.reference', {}),
        aide: _.get($scope, 'demandePaiement.demandeFinancement.expand', {}),
        teleservicePaiement: _.get($scope, 'demandePaiement.teleservicePaiement.expand.reference', {}),
        configurationId: ($scope, 'demandePaiement.teleservicePaiement.title', ''),
        financeur: _.get($scope, 'financeur.href', ''),
        dossier: $stateParams.dossier,
      });
    };

    $scope.stepsWizard.steps = [];
    $scope.stepsWizard.active = 'confirmation';

    /**
     * Set default recapitulatif .pdf href to use in download document
     *
     * @param {object} documentRecapitulatif
     * @param {object} properties
     */
    function setRecapitulatifHref(documentRecapitulatif, properties) {
      var targetUri;
      var configUrl;

      if (!_.get(properties, 'cmis:name.value') && !_.get(documentRecapitulatif, 'config.url', false)) return false;
      configUrl = _.get(documentRecapitulatif, 'config.url', false);

      if (!configUrl) return false;
      configUrl = configUrl.split('/');

      if (!configUrl && !configUrl.length >= 3) return false;
      targetUri = '/' + configUrl[1] + '/' + configUrl[2] + '/root?objectId=';
      return targetUri + _.get(properties, 'cmis:objectId.value', '');
    }

    /**
     * set recapitulatif in view
     *
     * @param {object} documentRecapitulatif
     */
    function setRecapitulatifData(documentRecapitulatif) {
      var properties = _.get(documentRecapitulatif, 'object.properties');
      var expandedProperties = _.get(documentRecapitulatif, 'expand.properties');
      var recapitulatif = {};

      // set recapUrl use in the downloadDocument() and display the download link in the screen
      recapitulatif.url = documentRecapitulatif.id || setRecapitulatifHref(documentRecapitulatif, properties);

      // set recapFileName use in downloadDocument()
      recapitulatif.fileName =
        _.get(
          expandedProperties,
          '.entity:document:originalfilename.value',
          _.get(properties, 'entity:originalfilename.value')
        ) || _.get(documentRecapitulatif, 'object.properties.entity:document:originalfilename.value');

      // set recapMimeType use in downloadDocument(
      recapitulatif.mimeType = _.get(
        expandedProperties,
        'cmis:contentStreamMimeType.value',
        _.get(properties, 'cmis:contentStreamMimeType.value')
      );

      return recapitulatif;
    }

    /**
     * close isRecap() $interval
     */
    function stopIsRecap() {
      $interval.cancel(isRecap);
      isRecap = false;
    }

    // load recap file when it is ready
    var isRecap = $interval(function () {
      // Document recap
      recapitulatifService
        .getRecapitulatifDemandePaiement($scope.demandePaiement, $scope.demandePaiement.pieces)
        .then(function (documentRecapitulatif) {
          if (documentRecapitulatif) {
            var recapitulatif = setRecapitulatifData(documentRecapitulatif);
            // set recapUrl use in the downloadDocument() and display the download link in the screen
            $scope.recapUrl = recapitulatif.url;
            // set recapFileName use in downloadDocument()
            $scope.recapFileName = recapitulatif.fileName;
            // set recapMimeType use in downloadDocument()
            $scope.recapMimeType = recapitulatif.mimeType;
          }
        });
      if ($scope.recapUrl) {
        stopIsRecap();
      }
    }, 3000);

    /**
     * Download the PDF recapitulatif document
     */
    $scope.downloadDocument = function () {
      $http
        .get($scope.recapUrl, {
          responseType: 'arraybuffer',
        })
        .then(function (response) {
          var mimeType = $scope.recapMimeType || 'application/pdf';
          var blob = new Blob([response.data], {
            type: mimeType,
          });

          saveAs(blob, $scope.recapFileName || 'recapitulatif.pdf');
        })
        .catch(function (error) {
          $log.error('Unable to download recapitulatif: ', error);
          $scope.$broadcast(
            'alerts',
            alertsService.getAlertError($scope.recapitulatifConfiguration.ns + '.error'),
            'confirmation-alerts'
          );
        });
    };
  },
]);

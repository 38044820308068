'use strict';
angular.module('common.directives').directive('domiciliationForm', [
  'Piece',
  function (Piece) {
    'use strict';

    return {
      restrict: 'E',
      templateUrl: 'common/common-directives/domiciliation-form/domiciliation-form.html',
      scope: {
        domiciliation: '=',
        namespace: '=?',
        viewConfiguration: '=',
        urlDocuments: '=',
        pieces: '=',
        validatePiece: '&',
        beforeUploadPiece: '&',
        afterUploadPiece: '&',
        bdfFile: '=',
        validationIbanFr: '=',
        allowedExtensions: '=',
        fileSizeLimit: '=',
        entity: '=',
        kind: '=',
        contribution: '=',
        mdm: '<',
        readOnly: '<',
      },

      controllerAs: '$ctrl',
      bindToController: true,
      controller: DomiciliationFormController,
      link: function (scope, element, attrs, $ctrl) {
        /**
         * Initialize the domiciliation
         *
         * @returns {Void}
         */
        function initializeDomiciliation() {
          $ctrl.domiciliation = scope.$ctrl.domiciliation || {};
          $ctrl.horsSEPA = _.get($ctrl.domiciliation, 'horsSEPA.length') > 0;

          const pieces = $ctrl.domiciliation.pieces || $ctrl.pieces || [];
          $ctrl.domiciliation.pieces = pieces.map((piece) => {
            const pieceDomiciliation = new Piece(piece);
            // Get envoiPostal configuration from the teleservice configuration
            pieceDomiciliation.envoiPostal = _.find($ctrl.pieces, { reference: piece.reference }).envoiPostal || false;

            return pieceDomiciliation;
          });
        }

        if (!$ctrl.domiciliation || !$ctrl.domiciliation.pieces) {
          initializeDomiciliation();
        }

        /**
         * This will be called when the directive is created, and when an expand needs to be made
         * on the piece's documents
         */
        scope.$watch('$ctrl.domiciliation', () => initializeDomiciliation());

        $ctrl.viewConfiguration.labelWidth = 2;
        $ctrl.viewConfiguration.fieldWidth = 6;
      },
    };
  },
]);

DomiciliationFormController.$inject = ['$q', '$log', 'aidesService'];

/**
 *
 * @param $q
 * @param $log
 * @param aidesService
 */
function DomiciliationFormController($q, $log, aidesService) {
  const expandDomiciliationDocument = (domiciliationBancaire, reference) => {
    if (!domiciliationBancaire || typeof domiciliationBancaire !== 'object')
      return $q.reject('expandDomiciliationBancaire - domiciliationBancaire must be a non empty object');
    const piece = domiciliationBancaire.pieces[0]; //? the domiciliation has only one piece
    if (!piece || typeof piece !== 'object') $q.reject('checkForExpand - piece must be a non empty object');
    if (!piece.documents || piece.documents.length === 0) return $q.resolve(domiciliationBancaire); //? no document to expand, returns unmodified
    let isExpanded = true;
    for (let i = 0; i < piece.documents.length; ++i) {
      //? this loop allows to break early if we need to expand the domiciliation
      const document = piece.documents[i];
      if (!document.expand) {
        isExpanded = false;
        break;
      }
    }
    if (isExpanded) return $q.resolve(domiciliationBancaire); // already expanded, returns unmodified

    return aidesService
      .getAideExpanded(reference, 'domiciliationBancaire.pieces.documents', this.contribution)
      .then((expandedAide) => expandedAide.domiciliationBancaire);
  };

  this.$onInit = () => {
    this.namespace = this.namespace || this.viewConfiguration.ns;

    this.urlFileIcons = './resources/images/file-icons/';

    // horsSEPA determines wether or not IBAN is used
    this.horsSEPA = _.get(this.domiciliation, 'horsSEPA.length') > 0;
    this.changeHorsSEPA = (value) => {
      if (value) {
        _.unset(this, 'domiciliation.IBAN');
        _.set(this, 'domiciliation.horsSEPA', [{ cle: 'numero' }]);
      } else {
        _.unset(this, 'domiciliation.horsSEPA');
        _.unset(this, 'domiciliation.IBAN.BIC');
      }
    };
    // A local configuration is needed (must be built before the template is loaded)
    this.ribConfiguration = {
      ns: this.namespace + '.rib',
      remoteValidation: this.viewConfiguration.remoteValidation,
    };

    const domiciliationBancaire = _.get(this.entity, 'domiciliationBancaire');
    if (!domiciliationBancaire) return $q.resolve(); //? when we're ceating a new domiciliation, it's undefined

    return expandDomiciliationDocument(domiciliationBancaire, this.entity.id)
      .then((expandedDomiciliation) => {
        this.domiciliation = expandedDomiciliation; //! will trigger the watcher above
      })
      .catch((error) => {
        $log.error(error);
      });
  };
}

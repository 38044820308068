'use strict';

class PartageAideModal {
  constructor(
    userSessionService,
    accountManagementService,
    validationService,
    $translate,
    PartageAideConstants,
    $element,
    ComponentsHelper
  ) {
    this.$translate = $translate;
    this.validationService = validationService;
    this.accountManagementService = accountManagementService;
    this.userSessionService = userSessionService;
    this.partageAideConstants = PartageAideConstants;
    this.$element = $element;
    this.ComponentsHelper = ComponentsHelper;
    this.notificationContent = 'aide-sharing-modal';
  }

  /**
   * Initialize component
   *
   * @returns {void}
   */
  $onInit() {
    this.sharings = this.resolve.sharings || [];
    this.sharingsEdited = false;
    this.data = {
      email: '',
      sharedEmails: [...this.sharings],
    };

    this.viewConfiguration = {
      fields: {
        email: {
          label: this.$translate.instant('connected.dashboard.aides.sharing.modal.labels.email'),
        },
      },
    };

    const userId = this.userSessionService.getUser().id;
    this.accountManagementService.getAccount(userId).then((user) => {
      this.demandeurEmail = _.get(user, 'emails[0]');
      this.isDemandeurEmailInList = this.sharings.includes(this.demandeurEmail);
    });
  }

  /**
   * Check if email can be added, set error otherwise
   *
   * @param {object} form form with email input
   * @returns {void}
   */
  checkEmailValidity(form) {
    if (form.email) {
      if (!this.data.email || this.data.email === '') {
        // Remove error if input is empty and set $touched to false to not show error on each key input
        delete this.error;
        form.email.$touched = false;
      } else if (this.data.email.toLowerCase() === this.demandeurEmail) {
        this.error = this.partageAideConstants.DEMANDEUR_EMAIL;
      } else if (this.data.sharedEmails.includes(this.data.email.toLowerCase())) {
        this.error = this.partageAideConstants.ALREADY_ADDED;
      } else if (form.email.$touched && !this.validationService.verifyEmail(this.data.email)) {
        this.error = this.partageAideConstants.BAD_FORMATTED;

        // If format error, check if length is too long
        const [userPart, domainPart] = this.data.email.split('@');

        const emailWithoutUserPart = `test@${domainPart}`;

        if (
          domainPart &&
          userPart &&
          this.validationService.verifyEmail(emailWithoutUserPart) &&
          userPart.length > 64
        ) {
          this.error = this.partageAideConstants.TOO_LONG;
        }
      } else {
        delete this.error;
      }
    }
  }

  /**
   * Add email to sharedEmails list
   *
   * @param {object} form with email input
   * @returns {void}
   */
  addEmail(form) {
    // We first check if email is valid, needed here if form is sent by clicking return
    form.email.$touched = true;
    this.checkEmailValidity(form);
    if (!this.error) {
      // Add email to sharedEmails list then empty the input
      this.data.sharedEmails.push(this.data.email.toLowerCase());
      delete this.data.email;
      form.email.$touched = false;

      this.sharingsEdited = !_.isEqual(this.data.sharedEmails.sort(), this.sharings.sort());
      this.ComponentsHelper.focusElement(`#email`, this.$element);
    }
  }

  /**
   * Remove email from sharedEmails list
   *
   * @param {string} email email to remove
   * @returns {void}
   */
  removeEmail(email) {
    const currentEmailIndex = this.data.sharedEmails.indexOf(email);
    this.data.sharedEmails = this.data.sharedEmails.filter((item) => item !== email);
    this.sharingsEdited = !_.isEqual(this.data.sharedEmails.sort(), this.sharings.sort());
    if (this.isDemandeurEmailInList && email === this.demandeurEmail) this.isDemandeurEmailInList = false;

    const previousEmailIndex = currentEmailIndex - 1;
    const nextEmailIndex = currentEmailIndex;
    // Set focus to the previous element if present, if not, to the next one, if no email, set focus to the input
    if (this.data.sharedEmails.length === 0) {
      this.ComponentsHelper.focusElement('#email', this.$element);
    }
    if (previousEmailIndex > -1) {
      this.ComponentsHelper.focusElement(`#emailsList-${previousEmailIndex}`, this.$element);
    } else if (nextEmailIndex > -1) {
      this.ComponentsHelper.focusElement(`#emailsList-${nextEmailIndex}`, this.$element);
    }
  }

  /**
   * Send sharedEmails list to parent component
   *
   * @returns {void}
   */
  save() {
    this.close({ $value: this.data.sharedEmails });
  }
}

PartageAideModal.$inject = [
  'userSessionService',
  'accountManagementService',
  'validationService',
  '$translate',
  'PartageAideConstants',
  '$element',
  'ComponentsHelper',
];

angular.module('aides').component('partageAideModal', {
  controller: PartageAideModal,
  templateUrl: 'aides/aides-components/partage-aide/modal/partage-aide-modal.html',
  bindings: {
    dismiss: '&',
    close: '&',
    resolve: '<',
  },
});
